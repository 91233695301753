import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { fontBold, fontRegular, colors, spacing } = STYLES.useStyles()

  const container: ViewStyle = {
    flexDirection: 'column',
    alignItems: 'center'
  }

  const textContainer: ViewStyle = {
    marginTop: spacing.l
  }

  const _text: TextStyle = {
    ...fontBold,
    textAlign: 'center',
    color: colors.brand,
  }

  const _textInner: TextStyle = {
    ..._text,
    position: 'absolute',
    zIndex: 0,
    left: 0,
    right: 0
  }

  const textInnerValue: TextStyle = {
    ..._textInner,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  }

  const textInnerType: TextStyle = {
    ..._textInner,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  }

  const textValue: TextStyle = {
    ..._text,
    fontSize: STYLES.fontSize.xl,
    lineHeight: STYLES.lineHeight.xl
  }

  const textType: TextStyle = {
    ..._text,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m
  }

  const description: TextStyle = {
    ...fontRegular,
    textAlign: 'center',
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    marginTop: spacing.s
  }

  const styles = StyleSheet.create({
    container,
    textContainer,
    textInnerValue,
    textInnerType,
    textValue,
    textType,
    description
  })

  return { styles }
}
