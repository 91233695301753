import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

export interface IconProps {
  size?: number
  color?: string
  style?: StyleProp<ViewStyle>
}

export type IconType = React.MemoExoticComponent<
  (props: IconProps) => JSX.Element
>

export { Close } from './Close'
export { BackArrow } from './BackArrow'
export { Cloud } from './Cloud'
export { Planner } from './Planner'
export { Progress } from './Progress'
export { ThumbUp } from './ThumbUp'
export { ThickArrow } from './ThickArrow'
export { Star } from './Star'
export { CheckList } from './CheckList'
export { Tick } from './Tick'
export { TickThin } from './TickThin'
export { Gear } from './Gear'
export { SignOut } from './SignOut'
export { Bell } from './Bell'
export { Shoe } from './Shoe'
export { DownChevron } from './DownChevron'
export { DownArrow } from './DownArrow'
export { Share } from './Share'
export { LeftChevron } from './LeftChevron'
export { RightChevron } from './RightChevron'
export { Search } from './Search'
export { Padlock } from './Padlock'
export { SelectAll } from './SelectAll'
export { DeselectAll } from './DeselectAll'
export { Edit } from './Edit'
export { Plus } from './Plus'
export { Bin } from './Bin'
export { Reset } from './Reset'
export { MoreDots } from './MoreDots'
export { Tree } from './Tree'
export { Crown } from './Crown'
export { ThumbUpOutline } from './ThumbUpOutline'
export { ThumbDownOutline } from './ThumbDownOutline'
export { TickRosetteOutline } from './TickRosetteOutline'
export { Download } from './Download'
