import React, { memo, useState, useMemo } from 'react'
import { Button, ButtonType, Text } from 'components'
import { StyleProp, ViewStyle } from 'react-native'
import { useStyles } from './styles'
import { GenerateId } from 'utils'

export interface ItemProps {
  id: string
  label: string
  description?: string
}

export interface Props extends ItemProps {
  onSelect: (id: string) => void
  style?: StyleProp<ViewStyle>
  selected?: boolean
}

export const SelectionCard = memo(({ selected = false, id, label, description, onSelect, style }: Props) => {
  const [_isPointerOver, _setIsPointerOver] = useState(false)
  const { styles } = useStyles()
  const _hasDescription = !!description
  const _descriptionId = useMemo(
    () => (_hasDescription ? GenerateId() : undefined),
    [_hasDescription]
  )

  const _onPress = () => onSelect?.(id)

  /* istanbul ignore next */
  const _onPointerEnter = () => {
    _setIsPointerOver(true)
  }
  /* istanbul ignore next */
  const _onPointerLeave = () => {
    _setIsPointerOver(false)
  }

  return (
    <Button
      type={ButtonType.blank}
      nativeContainerStyle={styles.nativeContainer}
      containerStyle={[styles.container, style]}
      textStyle={[
        styles.text,
        selected && styles.text_selected,
        /* istanbul ignore next */
        _isPointerOver && styles.text_pointerOver
      ]}
      testID="SelectionCard"
      label={label}
      onPress={_onPress}
      description={description}
      descriptionId={_descriptionId}
      state={{
        selected
      }}
      onPointerEnter={_onPointerEnter}
      onPointerLeave={_onPointerLeave}>
      <Text
        containerStyle={styles.labelContainer}
        textStyle={[styles.label, selected && styles.label_selected]}>
        {label}
      </Text>
      {_hasDescription && (
        <>
          <Text>{`\n`}</Text>
          <Text
            containerStyle={styles.descriptionContainer}
            textStyle={[
              styles.description,
              /* istanbul ignore next */
              selected && styles.description_selected
            ]}
            nativeID={_descriptionId}>
            {description}
          </Text>
        </>
      )}
    </Button>
  )
})
