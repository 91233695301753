import { Team } from 'models'

export const createTeams = async (teamNames: string[], orgainzationId: string) => {
  let _success = false

  for (let i = 0; i < teamNames.length; i++) {
    const _newTeam = new Team({
      organization_id: orgainzationId,
      name: teamNames[i]
    })

    _success = await _newTeam.save({ with: 'organization.id' })

    if (!_success) break
  }

  return _success
}