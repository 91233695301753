import { StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native'
import { getREM, STYLES } from 'styles'

export const useStyles = () => {
  const { colors, COLORS, spacing } = STYLES.useStyles()

  const announcements: ViewStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%'
  }

  const subsequentItem: ViewStyle = {
    marginTop: spacing.normal
  }

  const announcement: ViewStyle = {
    backgroundColor: COLORS.pipi,
    borderRadius: getREM(1),
    maxWidth: STYLES.grid.maxBoxWidth,
    width: '100%'
  }

  const announcement_image: ViewStyle = {
    maxWidth: STYLES.grid.maxTextWidth
  }

  const announcementHeader: ViewStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: spacing.s
  }

  const text: TextStyle = {
    color: colors.text,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  }

  const textContainer: ViewStyle = {
    marginBottom: 0
  }

  const announcementCloseContainer: ViewStyle = {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 0
  }

  const announcementClose: ViewStyle = {
    borderRadius: getREM(2.5)
  }

  const announcementCloseIconContainer: ViewStyle = {
    height: getREM(2.5),
    width: getREM(2.5)
  }

  const primaryActionContainer: ViewStyle = {
    alignItems: 'flex-start',
    marginTop: spacing.s
  }

  const primaryActionLabel: TextStyle = {
    paddingLeft: spacing.m,
    paddingRight: spacing.m
  }

  const announcementBackground: ImageStyle = {
    maxWidth: '100%'
  }

  const announcementRow: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center'
  }

  const announcementContent: ViewStyle = {
    flex: 1,
    padding: spacing.m
  }

  const styles = StyleSheet.create({
    announcement,
    announcement_image,
    announcementHeader,
    announcements,
    subsequentItem,
    text,
    textContainer,
    announcementCloseContainer,
    announcementClose,
    announcementCloseIconContainer,
    primaryActionContainer,
    primaryActionLabel,
    announcementBackground,
    announcementRow,
    announcementContent
  })

  return { styles }
}
