import React, { useRef, useState, useEffect, useCallback, memo } from 'react'
import { View, ViewProps, ScrollView, ScrollViewProps, Animated } from 'react-native'
import { useStyles } from './styles'

export interface Props extends ViewProps {
  onScrollEnd?: () => void
  contentContainerStyle?: ViewProps['style']
  spacerStyle?: ViewProps['style']
  visibleItems?: number
  gap?: number
}

export const HorizontalScrollView = memo(
  ({
    style,
    contentContainerStyle,
    spacerStyle,
    children,
    onScrollEnd,
    visibleItems = 1,
    gap = 8,
    ...props
  }: Props) => {
    const { styles } = useStyles()
    const _scrollViewRef = useRef<ScrollView>(null)
    const _indicatorBarRef = useRef<View>(null)
    const _indicatorRef = useRef(null)
    const _scrollViewX = useRef(new Animated.Value(0)).current
    const _isDrag = useRef(false)
    const _hasCalledOnScrollEnd = useRef(false)
    const _childrenCount = React.Children.count(children)

    let mockSizes

    if (process && process?.env?.JEST_WORKER_ID) {
      mockSizes = {
        container: 1024
      }
    }

    const [_sizes, _setSizes] = useState({
      difference: 0,
      visible: 0,
      indicator: 0,
      container: 0,
      containerOuter: 0,
      total: 0,
      ...mockSizes
    })

    /* istanbul ignore next */
    const _onScroll: ScrollViewProps['onScroll'] = ({
      nativeEvent: {
        contentOffset: { x }
      }
    }) => {
      if (_isDrag.current === false) {
        _scrollViewX.setValue(x)
      }

      if (
        _hasCalledOnScrollEnd.current === false &&
        x >= _sizes.total - _sizes.container
      ) {
        _hasCalledOnScrollEnd.current = true
        onScrollEnd?.()
      }
    }

    /* istanbul ignore next */
    const _onLayout: ViewProps['onLayout'] = ({
      nativeEvent: {
        layout: { width: containerOuter }
      }
    }) => {
      _indicatorBarRef.current?.measure((_x, _y, width) => {
        if (width) {
          const _singleGap = (gap * (visibleItems - 1)) / visibleItems
          const visible = width / visibleItems - _singleGap

          _setSizes((prevState) => ({
            ...prevState,
            container: width,
            containerOuter,
            visible
          }))
        }
      })
    }

    /* istanbul ignore next */
    const _updateSizes = useCallback(() => {
      _setSizes((prevState) => {
        const { container, visible } = prevState
        const total = visible * _childrenCount + gap * (_childrenCount - 1)

        let indicator = Math.ceil((container / total) * container)

        if (React.Children.count(children) === 1) {
          indicator = container
        }

        const difference = container > indicator ? container - indicator : 0

        return {
          ...prevState,
          difference,
          indicator,
          total
        }
      })
    }, [_childrenCount, children, gap])

    /* istanbul ignore next */
    const _onContentSizeChange: ScrollViewProps['onContentSizeChange'] = () => {
      _updateSizes()
    }

    /* istanbul ignore next */
    const _onResponderGrant: ViewProps['onResponderGrant'] = () => {
      _isDrag.current = true
    }

    /* istanbul ignore next */
    const _onResponderMove: ViewProps['onResponderMove'] = ({
      nativeEvent: { pageX }
    }) => {
      const { indicator, container, containerOuter, total } = _sizes
      const _newX = total * ((pageX - indicator / 2) / containerOuter)

      _scrollViewRef.current?.scrollTo({
        x: Math.min(Math.max(0, _newX), total - container),
        animated: false
      })

      _scrollViewX.setValue(_newX)
    }

    /* istanbul ignore next */
    const _onResponderRelease: ViewProps['onResponderRelease'] = () => {
      _isDrag.current = false
    }

    /* istanbul ignore next */
    const _onStartShouldSetResponder = () => true
    /* istanbul ignore next */
    const _onMoveShouldSetResponder = () => true
    /* istanbul ignore next */
    const _onResponderTerminationRequest = () => false

    /* istanbul ignore next */
    const _scrollViewDisplay = _sizes.container === 0 ? 'none' : 'flex'

    useEffect(() => {
      _hasCalledOnScrollEnd.current = false
      _updateSizes()
    }, [children, _updateSizes])

    return (
      <View
        style={[styles.container, style]}
        testID="HorizontalScrollView"
        {...props}>
        <ScrollView
          testID="HorizontalScrollViewScroller"
          ref={_scrollViewRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={16}
          onContentSizeChange={_onContentSizeChange}
          onScroll={_onScroll}
          contentContainerStyle={[contentContainerStyle]}
          style={{
            display: _scrollViewDisplay
          }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={[styles.spacer, spacerStyle]} />

            {React.Children.map(children, (child, index) => {
              /* istanbul ignore next */
              if (!child) return null

              return (
                <View
                  key={index}
                  style={{
                    width: _sizes.visible,
                    marginLeft: index === 0 ? 0 : gap
                  }}>
                  {child}
                </View>
              )
            })}

            <View style={[styles.spacer, spacerStyle]} />
          </View>
        </ScrollView>
        <View
          style={styles.indicatorContainer}
          onStartShouldSetResponder={_onStartShouldSetResponder}
          onMoveShouldSetResponder={_onMoveShouldSetResponder}
          onResponderGrant={_onResponderGrant}
          onResponderMove={_onResponderMove}
          onResponderRelease={_onResponderRelease}
          onResponderTerminationRequest={_onResponderTerminationRequest}
          onLayout={_onLayout}>
          <View ref={_indicatorBarRef} style={styles.indicatorBar}>
            <Animated.View
              ref={_indicatorRef}
              style={[
                styles.indicator,
                {
                  width: _sizes.indicator,
                  transform: [
                    {
                      translateX: Animated.multiply(
                        _scrollViewX,
                        _sizes.indicator / _sizes.container
                      ).interpolate({
                        inputRange: [0, _sizes.difference],
                        outputRange: [0, _sizes.difference],
                        extrapolate: 'clamp'
                      })
                    }
                  ]
                }
              ]}
            />
          </View>
        </View>
      </View>
    )
  }
)
