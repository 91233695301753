import { TextStyle } from 'react-native'

type fontType = {
  fontFamily: TextStyle['fontFamily']
  fontWeight: TextStyle['fontWeight']
  fontStyle: TextStyle['fontStyle']
}

export const useFont = () => {
  const fontRegular: fontType = {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontStyle: 'normal'
  }

  const fontRegularItalic: fontType = {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontStyle: 'italic'
  }

  const fontSemiBold: fontType = {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontStyle: 'normal'
  }

  const fontBold: fontType = {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontStyle: 'normal'
  }

  return {
    fontRegular,
    fontRegularItalic,
    fontSemiBold,
    fontBold
  }
}
