import React, { memo } from 'react'
import { Text, StyleProp, ViewStyle, View } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, IconType } from 'components'

export interface Props {
  slug?: string
  label: string
  containerStyle?: StyleProp<ViewStyle>
  backgroundColor?: string
  textColor?: string
  onPress?: (slug: string) => void
  ariaLabel?: string
  loading?: boolean
  Icon?: IconType
  href?: string
}

export const CategoryPill = memo(({
  slug,
  label,
  containerStyle,
  backgroundColor,
  textColor,
  onPress,
  ariaLabel,
  loading = false,
  Icon,
  href
}: Props) => {
  const { styles, defaultTextColor } = useStyles()

  const _hasOnPress = !!onPress && !!slug
  const _hasHref = !!href && !_hasOnPress
  const _hasIcon = !!Icon
  /* istanbul ignore next */
  const _hasTextColor = !!textColor && textColor !== ''
  /* istanbul ignore next */
  const _hasBackgroundColor = !!backgroundColor && backgroundColor !== ''

  const _onPress = () => {
    onPress?.(slug!)
  }

  if (!label) return null

  return (
    <Button
      isFake={!_hasOnPress && !_hasHref}
      type={ButtonType.blank}
      textStyle={[
        styles.container,
        containerStyle,
        /* istanbul ignore next */
        _hasBackgroundColor && { backgroundColor },
        (_hasOnPress || _hasHref) && styles.link,
        loading && styles.container_loading
      ]}
      onPress={_hasOnPress ? _onPress : undefined}
      label={ariaLabel}
      href={_hasHref ? href : undefined}
      testID="CategoryPill">
      {!loading && (
        <View style={styles.content}>
          {_hasIcon && (
            <Icon
              color={
                /* istanbul ignore next */
                _hasTextColor ? textColor : defaultTextColor
              }
              style={styles.icon}
            />
          )}
          <Text
            textBreakStrategy="simple"
            style={[
              styles.text,
              /* istanbul ignore next */
              _hasTextColor && { color: textColor }
            ]}>
            {label}
          </Text>
        </View>
      )}
    </Button>
  )
})
