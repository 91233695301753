import { TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useSharedStyles = () => {
  const { focused, disabled, fontSemiBold } = STYLES.useStyles()

  const sharedStyles = {
    label: {
      ...fontSemiBold,
      overflow: 'hidden',
      textAlign: 'center',
      maxWidth: STYLES.grid.maxInputWidth,
      alignItems: 'center',
      justifyContent: 'center'
    } as TextStyle,
    label_small: {
      borderRadius: getREM(2.1875 / 2),
      fontSize: STYLES.fontSize.s,
      lineHeight: STYLES.lineHeight.s,
      paddingTop: getREM(0.4375),
      paddingRight: getREM(0.75),
      paddingBottom: getREM(0.4375),
      paddingLeft: getREM(0.75)
    },
    label_normal: {
      borderRadius: getREM(2.5 / 2),
      fontSize: STYLES.fontSize.normal,
      lineHeight: STYLES.lineHeight.normal,
      paddingTop: getREM(0.5),
      paddingRight: getREM(1.25),
      paddingBottom: getREM(0.5),
      paddingLeft: getREM(1.25)
    },
    label_large: {
      borderRadius: getREM(3.75 / 2),
      fontSize: STYLES.fontSize.l,
      lineHeight: STYLES.lineHeight.l,
      paddingTop: getREM(0.84375),
      paddingRight: getREM(1.25),
      paddingBottom: getREM(0.84375),
      paddingLeft: getREM(1.25)
    },
    disabled,
    focused
  }

  return { sharedStyles }
}
