import { StyleSheet, TextStyle, ViewStyle, Platform, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = ({ isCentred }: { isCentred?: boolean }) => {
  const { COLORS, spacing, bp, fontBold, boxShadows } = STYLES.useStyles()

  const _largeBp = [bp.mobile_xl, bp.tablet, bp.desktop]
  const _smallBp = [bp.mobile_xs, bp.mobile_s, bp.mobile_normal]
  const _xSmallBp = [bp.mobile_xs, bp.mobile_s]

  let _borderRadius = getREM(1)

  if (bp.mobile_xs) {
    _borderRadius = getREM(0.5)
  }

  let _imageWidth = 80
  let _imageHeight = 80

  if (bp.not(_largeBp)) {
    _imageWidth = 70
    _imageHeight = 70
  }

  const _paddingVertical = spacing.l
  let _paddingHorizontal = spacing.l

  if (bp.is(_smallBp)) {
    _paddingHorizontal = spacing.s
  }

  const container: ViewStyle = {
    width: '100%',
    position: 'relative',
    backgroundColor: COLORS.white,
    borderRadius: _borderRadius,
    paddingLeft: _paddingHorizontal,
    paddingRight: _paddingHorizontal,
    paddingTop: _paddingVertical,
    paddingBottom: _paddingVertical,
    ...boxShadows.card.all
  }

  const container_pointerOver: ViewStyle = {
    ...boxShadows.card_pointerOver.all
  }

  const image: ImageStyle = {
    width: _imageWidth,
    height: _imageHeight,
    position: 'absolute',
    top: container.paddingTop
  }

  if (bp.is(_largeBp)) {
    image.left = container.paddingLeft
  }

  if (bp.not(_largeBp)) {
    image.right = container.paddingRight
  }

  if (bp.is(_xSmallBp)) {
    image.display = 'none'
  }

  const container_image: ViewStyle = {
    minHeight: (Platform.OS === 'web' ? `calc(${_paddingVertical} * 2 + ${_imageHeight}px)` : _paddingVertical * 2 + _imageHeight) as ViewStyle['minHeight']
  }

  if (bp.is(_largeBp)) {
    container_image.paddingLeft = (Platform.OS === 'web' ? `calc(${_paddingHorizontal} * 2 + ${_imageWidth}px)` : _paddingHorizontal * 2 + _imageWidth) as ViewStyle['paddingLeft']
  }

  if (bp.not(_largeBp)) {
    container_image.paddingRight = (Platform.OS === 'web' ? `calc(${_paddingHorizontal} * 2 + ${_imageWidth}px)` : _paddingHorizontal * 2 + _imageWidth) as ViewStyle['paddingRight']
  }

  if (bp.is(_xSmallBp)) {
    container_image.paddingRight = _paddingHorizontal
    container_image.paddingLeft = _paddingHorizontal
  }

  const primaryActionContainer: ViewStyle = {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }

  const primaryActionText: TextStyle = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: _borderRadius
  }

  const contentContainer: ViewStyle = {
    flexDirection: 'column',
    zIndex: 2
  }

  if (bp.is(_largeBp)) {
    contentContainer.flexDirection = 'row'
    contentContainer.alignItems = 'center'
    contentContainer.justifyContent = 'space-between'
  }

  const content: ViewStyle = {
    flex: 1
  }

  if (isCentred || bp.is(_smallBp)) {
    content.alignItems = 'center'
  }

  const content_button: ViewStyle = {
    paddingRight: _paddingHorizontal
  }

  const buttonContainer: ViewStyle = {}

  if (bp.not(_largeBp)) {
    buttonContainer.alignSelf = 'flex-start'
    buttonContainer.marginTop = spacing.m
  }

  if (bp.is(_smallBp)) {
    buttonContainer.alignSelf = 'center'
  }

  const title: TextStyle = {
    ...fontBold,
    color: COLORS.aubergine,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m
  }

  if (bp.is(_smallBp)) {
    title.fontSize = STYLES.fontSize.normal
    title.lineHeight = STYLES.lineHeight.normal
  }

  if (isCentred || bp.is(_smallBp)) {
    title.textAlign = 'center'
  }

  const children: ViewStyle = {
    zIndex: 2,
    paddingTop: spacing.m,
    marginTop: 'auto'
  }

  const styles = StyleSheet.create({
    container,
    container_pointerOver,
    container_image,
    image,
    primaryActionContainer,
    primaryActionText,
    contentContainer,
    content,
    content_button,
    buttonContainer,
    title,
    children
  })

  return { styles }
}
