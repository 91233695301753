import React, { useCallback, memo, useMemo, useContext } from 'react'
import {
  Heading,
  TreeOffset,
  ValueBox,
  View,
  CarbonFootprintHero,
  ClimateClock,
  ScoreHighlights,
  FootprintSections,
  ActivityIndicator,
  Carousel,
  Button,
  ButtonType,
  StepCarouselHeader,
  StepCardTemplate,
  StepCardTemplateProps,
  StepCarouselItemTemplateProps,
  Announcements,
  StepCarousel,
  SvgImage,
  StepButtons
} from 'components'
import { FootprintSectionsType } from '../../components/FootprintSections'
import { useTranslation } from 'react-i18next'
import { STYLES } from 'styles'
import { useLang } from './Lang'
import { useStyles } from './styles'
import {
  ShuffleArray,
  useNavigationContext,
  IController,
  ReplaceParam,
  LinkContext
} from 'utils'
import { IProgressGroupController, ProgressGroupController } from './Data'
import { CampaignTarget, CampaignTargetType } from 'components/CampaignTarget'
import { ItemCount } from 'components/StepCarousel'
import { CommitmentState } from 'models'

export interface ProgressGroupProps {
  controller?: IController<IProgressGroupController>
}

interface CustomItemTemplateProps extends StepCarouselItemTemplateProps {
  commitmentState?: StepCardTemplateProps['commitmentState']
}

export const ProgressGroup = memo(({
  controller = new ProgressGroupController()
}: ProgressGroupProps) => {
  const { announcements, person, campaign, isLoading, onReadAnnouncement } =
    controller.useController()

  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { spacing } = STYLES.useStyles()
  const navigationContext = useNavigationContext()

  const {
    challengeLeaderboardLinkUrl,
    stepsPageLinkUrl,
    todoListPageLinkUrl,
    stepsDoneLinkUrl,
    footprintPageLinkUrl,
    leaderboardLinkUrl
  } = useContext(LinkContext)

  const _hasAnnouncement = !!announcements && announcements.length > 0
  const _hasCarbonFootprint = !!person?.kgCo2eEmissions
  const _hasFootprintSections = !!person?.footprintSections
  const _hasMoreCommitments = (person?.commitments?.length || 0) > ItemCount

  const ItemTemplate = useCallback(
    ({ item, style, ...props }: CustomItemTemplateProps) => (
      <StepCardTemplate action={item} style={style} {...props} />
    ),
    []
  )

  const _isCampaignAvailable = !!campaign

  const _campaignTargetType = campaign?.target?.selected as CampaignTargetType

  const _campaignStats = {
    [CampaignTargetType.ParticipantsCount]: [
      campaign?.participantsCount,
      campaign?.target?.participantsCount
    ],
    [CampaignTargetType.PendingCommitmentsCount]: [
      campaign?.pendingCommitmentsCount,
      campaign?.target?.pendingCommitmentsCount
    ],
    [CampaignTargetType.SuccessfulCommitmentsCount]: [
      campaign?.successfulCommitmentsCount,
      campaign?.target?.successfulCommitmentsCount
    ]
  }[_campaignTargetType]

  const ShoeRed: typeof SvgImage = memo((props) => (
    <SvgImage image={require('images/ShoeRed.svg')} {...props} />
  ))
  const ShoeYellow: typeof SvgImage = memo((props) => (
    <SvgImage image={require('images/ShoeYellow.svg')} {...props} />
  ))

  /* istanbul ignore next */
  const _image: React.ComponentProps<typeof Carousel>['Image'] = campaign
    ?.target.displayPublicly
    ? memo((props) => (
        <CampaignTarget
          attribute={_campaignTargetType}
          current={_campaignStats[0]!}
          target={_campaignStats[1]!}
          {...props}
        />
      ))
    : undefined

  const leaderboardLinkButton = useMemo(() => {
    const organizationId = campaign?.organizationId

    if (!challengeLeaderboardLinkUrl || !organizationId) return undefined

    return {
      buttonProps: {
        label: t('progressGroup:campaign.viewLeaderboard'),
        onPress: () => {
          const _url = ReplaceParam(challengeLeaderboardLinkUrl, organizationId)

          navigationContext.navigate(_url)
        }
      }
    }
  }, [
    campaign?.organizationId,
    challengeLeaderboardLinkUrl,
    t,
    navigationContext
  ])

  const _leaderboardLinkUrl = useMemo(() => {
    if (
      !leaderboardLinkUrl ||
      !person?.highlightedOrganizationLeaderboardMembership?.organization?.id
    ) {
      return undefined
    }

    return ReplaceParam(
      leaderboardLinkUrl,
      person?.highlightedOrganizationLeaderboardMembership?.organization?.id
    )
  }, [
    leaderboardLinkUrl,
    person?.highlightedOrganizationLeaderboardMembership?.organization?.id
  ])

  if (isLoading) {
    return <ActivityIndicator />
  }

  return (
    <>
      {_isCampaignAvailable && (
        <StepButtons.TrackingContext.Provider
          value={{ source: 'ProgressGroup Challenges Banner' }}>
          <StepCarouselHeader
            key={`StepCarouselHeader-${
              /* istanbul ignore next */
              campaign?.campaignActions.length ?? 0
            }`}
            backgroundColor1="#EBD7EE"
            backgroundColor2="#DAA9E1"
            buttonColor="#90519D"
            ItemTemplate={ItemTemplate}
            Image={_image}
            hasTarget={campaign?.target.displayPublicly}
            steps={
              /* istanbul ignore next */
              campaign?.campaignActions?.map((ca) => ca.action) ?? []
            }
            title={campaign?.title}
            description={campaign?.description}
            emptyMessage={t('progressGroup:campaign.emptyMessage')}
            {...leaderboardLinkButton}
          />
        </StepButtons.TrackingContext.Provider>
      )}
      <View
        style={[
          styles.container,
          /* istanbul ignore next */
          !_isCampaignAvailable && styles.container_noHeader
        ]}>
        {_hasAnnouncement && (
          <Announcements
            containerStyle={styles.announcements}
            items={announcements}
            onRead={
              /* istanbul ignore next */
              (announcementId) => onReadAnnouncement(person?.id, announcementId)
            }
          />
        )}

        {_hasCarbonFootprint && (
          <CarbonFootprintHero
            value={person?.kgCo2eEmissions}
            title={t('progressGroup:carbonFootprint.title')}
            linkLabel={t('progressGroup:carbonFootprint.linkLabel')}
            linkUrl={stepsPageLinkUrl}
            isFixedSize
          />
        )}

        <TreeOffset value={person?.kgCo2eReductions} />

        <View style={styles.steps}>
          <Heading level={2} style={styles.stepsHeading}>
            {t('progressGroup:steps.title')}
          </Heading>
          <View style={styles.stepsGrid} spacer={spacing.l}>
            <ValueBox
              value={person?.pendingActionsCount ?? 0}
              label={t('progressGroup:steps.trying.text')}
              Image={ShoeYellow}
              linkText={t('progressGroup:steps.trying.cta')}
              linkURL={
                todoListPageLinkUrl
                  ? ReplaceParam(todoListPageLinkUrl)
                  : undefined
              }
            />
            <ValueBox
              value={person?.completeActionsCount ?? 0}
              label={t('progressGroup:steps.done.text')}
              Image={ShoeRed}
              linkText={t('progressGroup:steps.done.cta')}
              linkURL={stepsDoneLinkUrl}
            />
          </View>
        </View>
      </View>

      <View style={styles.stepsPending} testID="PendingSteps">
        <StepButtons.TrackingContext.Provider
          value={{ source: 'ProgressGroup Pending Steps' }}>
          <StepCarousel
            style={styles.stepsPendingCarousel}
            items={person?.commitments?.map((item) => item.action) || []}
            ItemTemplate={({ ...args }) => (
              <ItemTemplate
                {...args}
                commitmentState={CommitmentState.pending}
              />
            )}
            title={t('progressGroup:stepsPending.title')}
            testID="StepPendingCarouselHeader"
            emptyMessage={t('progressGroup:stepsPending.emptyMessage')}
          />
        </StepButtons.TrackingContext.Provider>

        {!_hasMoreCommitments && stepsPageLinkUrl && (
          <Button
            type={ButtonType.brand}
            href={stepsPageLinkUrl}
            label={t('progressGroup:stepsPending.linkLabel')}
            containerStyle={styles.button}
          />
        )}

        {_hasMoreCommitments && todoListPageLinkUrl && (
          <Button
            type={ButtonType.brand}
            href={ReplaceParam(todoListPageLinkUrl)}
            label={t('progressGroup:stepsPending.linkLabelMore')}
            containerStyle={styles.button}
          />
        )}
      </View>

      {_hasFootprintSections && (
        <Carousel
          containerStyle={styles.footprintSectionsCarousel}
          title={t('progressGroup:footprintSections.title')}
          linkLabel={t('progressGroup:footprintSections.cta')}
          linkUrl={footprintPageLinkUrl}>
          {ShuffleArray(
            Object.keys(person?.footprintSections).filter(
              (item) => item !== 'trees'
            )
          ).map((item: FootprintSectionsType) => (
            <FootprintSections.ProgressCard
              key={`${item}-box`}
              item={item}
              completePercentage={
                person?.footprintSections[item].complete_percentage
              }
            />
          ))}
        </Carousel>
      )}

      <View style={styles.container}>
        <ScoreHighlights
          containerStyle={styles.scoreHighlights}
          title={t('progressGroup:score.title')}
          score={person?.score ?? 0}
          organizationName={
            person?.highlightedOrganizationLeaderboardMembership?.organization
              ?.name
          }
          rank={person?.highlightedOrganizationLeaderboardMembership?.rank}
          linkLabel={t('progressGroup:score.cta')}
          linkUrl={_leaderboardLinkUrl}
        />

        <ClimateClock
          containerStyle={styles.climateClock}
          title={t('progressGroup:climateClock.title')}
          years={person?.climateClock?.years ?? null}
          months={person?.climateClock?.months ?? null}
          unlimited={person?.climateClock?.unlimited}
          linkLabel={t('progressGroup:climateClock.cta')}
          linkUrl={stepsPageLinkUrl}
        />
      </View>
    </>
  )
})

export * from './Data'
