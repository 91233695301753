import { User } from 'models'
import { QueryObserverBaseResult } from 'react-query'
import { PersistedSpraypaintRecord } from 'spraypaint'

export type DataType = PersistedSpraypaintRecord<User> | undefined

export type QueryKeyType = string[]

export type ResultType = QueryObserverBaseResult

export * from './controller'
export * from './types'
export * from './interfaces'
