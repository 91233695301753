import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { COLORS, bp, spacing, fontRegular, fontSemiBold, boxShadows } = STYLES.useStyles()

  const container = {} as ViewStyle

  const nativeContainer = {
    ...boxShadows.button.ios
  } as ViewStyle

  const text = {
    display: 'flex',
    maxWidth: STYLES.grid.maxInputWidth,
    minHeight: getREM(3.75),
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    borderRadius: getREM(0.625),
    overflow: 'hidden',
    backgroundColor: COLORS.paper,
    borderColor: COLORS.white,
    borderWidth: getREM(0.1875),
    borderStyle: 'solid',
    paddingTop: spacing.normal,
    paddingBottom: spacing.normal,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    textAlign: 'left',
    ...boxShadows.button.web,
    ...boxShadows.button.android
  } as TextStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    text.paddingTop = spacing.xs
    text.paddingBottom = spacing.xs
    text.paddingLeft = spacing.xs
    text.paddingRight = spacing.xs
  }

  if (bp.desktop) {
    text.paddingTop = spacing.m
    text.paddingBottom = spacing.m
  }

  const text_selected = {
    backgroundColor: COLORS.cherry
  } as TextStyle

  const text_pointerOver = {
    ...boxShadows.button_pointerOver.web
  } as TextStyle

  const labelContainer = {
    marginBottom: 0
  } as TextStyle

  const label = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: COLORS.aubergine
  } as TextStyle

  if (bp.is([bp.mobile_l, bp.mobile_xl, bp.tablet])) {
    label.fontSize = STYLES.fontSize.normal
    label.lineHeight = STYLES.lineHeight.normal
  }

  if (bp.desktop) {
    label.fontSize = STYLES.fontSize.m
    label.lineHeight = STYLES.lineHeight.m
  }

  const label_selected = {
    color: COLORS.white
  } as TextStyle

  const descriptionContainer = {
    marginBottom: 0
  } as TextStyle

  const description = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: COLORS.doveGrey
  } as TextStyle

  const description_selected = {
    color: COLORS.white
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    nativeContainer,
    text,
    text_selected,
    text_pointerOver,
    labelContainer,
    label,
    label_selected,
    descriptionContainer,
    description,
    description_selected
  })

  return { styles }
}
