import React, { memo } from 'react'
import {
  Text,
  TextProps,
  Platform,
  AccessibilityRole
} from 'react-native'
import { useStyles } from './styles'

export interface Props extends TextProps {
  level?: 1 | 2 | 3 | 4
}

export const Heading = memo(({ level = 2, children, style, ...props }: Props) => {
  const { styles } = useStyles()
  const _hLevel = `h${level}` as keyof typeof styles

  const _accessibility =
    Platform.OS === 'web'
      ? {
        accessibilityRole: 'heading' as AccessibilityRole,
        accessibilityLevel: level
      }
      : null

  return (
    <Text
      textBreakStrategy="simple"
      style={[styles[_hLevel], style]}
      {...props}
      {..._accessibility}>
      {children}
    </Text>
  )
})
