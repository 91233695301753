import React, { memo } from 'react'
import { Animated, ViewProps, View, Platform } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, Icons, IconProps, Logo } from 'components'

export interface Props extends ViewProps {
  offsetY?: Animated.Value
  showBackButton?: boolean
  onBackButtonPress?: () => void
  backButtonLabel?: string
  logoLinkUrl?: string
}

export const TopBar = ({
  onBackButtonPress,
  offsetY = new Animated.Value(0),
  showBackButton = false,
  backButtonLabel,
  style,
  logoLinkUrl,
  ...props
}: Props) => {
  const { styles } = useStyles()

  const _hasLogoLinkUrl = !!logoLinkUrl

  const BackArrow = memo(({ style, ...props }: IconProps) => {
    /* istanbul ignore next */
    const _scale = Platform.OS === 'web' ? 0.7 : 1
    return (
      <Icons.BackArrow
        {...props}
        style={[style, { transform: [{ scale: _scale }] }]}
      />
    )
  })

  return (
    <View style={[styles.container, style]} testID="TopBar" {...props}>
      <Animated.View
        style={[
          styles.background,
          {
            opacity: offsetY.interpolate({
              inputRange: [0, 100],
              outputRange: [0, 1],
              extrapolate: 'clamp'
            })
          }
        ]}
      />
      <View style={styles.topBar}>
        <View style={styles.backButton}>
          {showBackButton && (
            <Button
              label={backButtonLabel}
              onPress={onBackButtonPress}
              hideLabel
              type={ButtonType.icon}
              icon={BackArrow}
              testID="BackButton"
            />
          )}
        </View>

        {_hasLogoLinkUrl ? (
          <Button
            href={logoLinkUrl}
            type={ButtonType.blank}
            textStyle={styles.logoLink}
            label="logo"
          >
            <Logo style={styles.logo} testID="Logo" />
          </Button>
        ) : (
          <Logo style={styles.logo} testID="Logo" />
        )}
      </View>
    </View>
  )
}
