import React from 'react'
import { Text } from 'react-native'
import SimpleMarkdown from 'simple-markdown'
import { useStyles } from './styles'

export default Object.assign({}, SimpleMarkdown.defaultRules.strong, {
  react: (
    node: SimpleMarkdown.SingleASTNode,
    output: SimpleMarkdown.Output<React.ReactNode>,
    state: SimpleMarkdown.State
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { styles } = useStyles()
    return (
      <Text textBreakStrategy="simple" style={styles.strong} key={state.key}>
        {output(node.content, state)}
      </Text>
    )
  }
})
