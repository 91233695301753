import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'

@Model()
export class PeopleTarget extends ApplicationRecord {
  static jsonapiType = 'people_targets'

  /**
   * Whether the target dueAt date is still in the
   * future or not. ie. this is true if the target
   * is still in the future, and false if it has
   * expired.
   */
  @Attr({ persist: false }) active!: boolean

  /**
   * Whether the person's current emissions are now
   * below the target level. ie. this is true if there
   * is no kgCo2eRemaining, or the kgCo2eRemaining
   * is negative, and false if the kgCo2eRemaining
   * is positive.
   */
  @Attr({ persist: false }) complete!: boolean

  /**
   * The target CO2e emissions that the user should
   * be aiming for by the dueAt date in whole KG.
   */
  @Attr({ persist: false }) kgCo2eEmissions!: number

  /**
   * The CO2e emissions still left to cut to
   * meet the target in whole KG.
   */
  @Attr({ persist: false }) kgCo2eRemaining!: number

  @BelongsTo(Person) person!: Person
}
