import React, { memo, useState } from 'react'
import { GenerateId } from 'utils'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { SVG, SvgImage } from 'components'
import { useStyles as useGlobalStyles } from 'styles/STYLES/useStyles'
import { SvgProps } from 'react-native-svg'
import { View, Text } from 'react-native'
import { useStyles } from './styles'

export enum CampaignTargetType {
  ParticipantsCount = 'participants_count',
  PendingCommitmentsCount = 'pending_commitments_count',
  SuccessfulCommitmentsCount = 'successful_commitments_count'
}

export enum CampaignTargetTextPositionType {
  inside = 'inside',
  bottom = 'bottom'
}

export interface CampaignTargetProps {
  attribute: CampaignTargetType
  current: number
  selected?: boolean
  size?: number
  strokeWidthPercentage?: number
  target: number
  textPosition?: CampaignTargetTextPositionType
  svgProps?: SvgProps
  showBackgroundColour?: boolean
}

const IMAGES = {
  [CampaignTargetType.ParticipantsCount]: require('./Images/ParticipantsCount.svg'),
  [CampaignTargetType.PendingCommitmentsCount]: require('./Images/PendingCommitmentsCount.svg'),
  [CampaignTargetType.SuccessfulCommitmentsCount]: require('./Images/SuccessfulCommitmentsCount.svg')
}

export const computePercentageProgress = (
  currentValue: number,
  targetValue: number
) => {
  if (!currentValue || !targetValue) return 0

  if (currentValue >= targetValue) return 1

  return currentValue / targetValue
}

export const CampaignTarget = memo(
  ({
    attribute,
    current,
    selected = true,
    size = 300,
    strokeWidthPercentage = 0.14,
    target,
    textPosition = CampaignTargetTextPositionType.inside,
    svgProps,
    showBackgroundColour = false
  }: CampaignTargetProps) => {
    useLang()

    const { t } = useTranslation()
    const { COLORS } = useGlobalStyles()
    const { styles } = useStyles()

    const _viewBox = `0 0 ${size} ${size}`
    const _squareCenter = size / 2
    const _strokeWidth = size * strokeWidthPercentage
    const _defaultImageOffset = size * 0.1
    const _defaultImageSize = size - _strokeWidth * 2
    const _imageShouldOffset = textPosition === 'inside'
    const _imageOffset = _imageShouldOffset ? _defaultImageOffset : 0
    const _imageSize = _defaultImageSize - _imageOffset
    const _defaultImagePosition = (size - _imageSize) / 2
    const _imagePositionX = _defaultImagePosition
    const _imagePositionY = _defaultImagePosition + _imageOffset / 2
    const _progressCircleRadius = (size - _strokeWidth) / 2
    const _innerCircleRadius = _progressCircleRadius - _strokeWidth / 2
    const _clipPathRadius = _progressCircleRadius - _strokeWidth / 2

    const _progressStrokeDashArray = _progressCircleRadius * Math.PI * 2
    const _progressStrokeDashOffsetInitial = _progressStrokeDashArray
    const _strokeDashOffsetEnd =
      _progressStrokeDashOffsetInitial -
      _progressStrokeDashArray * computePercentageProgress(current, target)

    const [id] = useState(GenerateId())

    const _clipPathName = `Target__clipPath---${id}`

    const _currentString = Math.round(current)

    const _displayBottomText = textPosition === 'bottom'

    const _displayInnerText = textPosition === 'inside'

    const _innerTextPositionY = (strokeWidthPercentage + 0.1) * size - 16

    return (
      <View style={styles.container} testID="CampaignTarget">
        <View
          style={{
            width: size,
            height: size
          }}>
          <SVG.Svg
            width={size}
            height={size}
            viewBox={_viewBox}
            fontSize={0}
            {...svgProps}>
            <SVG.Defs>
              <SVG.ClipPath id={_clipPathName}>
                <SVG.Circle
                  cx={_squareCenter}
                  cy={_squareCenter}
                  r={_clipPathRadius}
                />
              </SVG.ClipPath>
            </SVG.Defs>

            {selected && (
              <>
                <SVG.Circle
                  cx={_squareCenter}
                  cy={_squareCenter}
                  r={_progressCircleRadius}
                  strokeWidth={`${_strokeWidth}px`}
                  fill={
                    /* istanbul ignore next */
                    showBackgroundColour ? COLORS.snuff : COLORS.transparent
                  }
                  stroke={COLORS.mushroom}
                />
                <SVG.Circle
                  cx={_squareCenter}
                  cy={_squareCenter}
                  r={_progressCircleRadius}
                  strokeWidth={`${_strokeWidth}px`}
                  transform={`rotate(-90 ${_squareCenter} ${_squareCenter})`}
                  fill={'none'}
                  stroke={COLORS.aubergine}
                  strokeLinecap={'butt'}
                  strokeLinejoin={'miter'}
                  strokeDasharray={_progressStrokeDashArray}
                  strokeDashoffset={_strokeDashOffsetEnd}
                />
              </>
            )}

            {
              /* istanbul ignore next */
              !selected && showBackgroundColour && (
                <SVG.Circle
                  cx={_squareCenter}
                  cy={_squareCenter}
                  r={_innerCircleRadius}
                  fill={COLORS.snuff}
                  strokeWidth={`${_strokeWidth}px`}
                  stroke={COLORS.transparent}
                />
              )
            }

            <SVG.Circle
              clipPath={`url(#${_clipPathName})`}
              cx={_squareCenter}
              cy={_squareCenter}
              r={_innerCircleRadius}
              fill={COLORS.transparent}
            />

            <SVG.G clipPath={`url(#${_clipPathName})`}>
              <SVG.G
                transform={`translate(${_imagePositionX},${_imagePositionY})`}>
                <SvgImage
                  image={IMAGES[attribute]}
                  width={_imageSize}
                  height={_imageSize}
                  isNested
                />
              </SVG.G>
            </SVG.G>
          </SVG.Svg>
          {_displayInnerText && (
            <>
              <Text
                style={[
                  styles.textInnerValue,
                  {
                    top: _innerTextPositionY
                  }
                ]}>
                {_currentString}
              </Text>
              <Text
                style={[
                  styles.textInnerType,
                  {
                    top: _innerTextPositionY + 24
                  }
                ]}>
                {t(`campaignTarget:${attribute}.type`)}
              </Text>
            </>
          )}
        </View>
        {_displayBottomText && (
          <View style={styles.textContainer} testID="CampaignTargetBottomText">
            <Text style={[styles.textValue]}>{_currentString}</Text>
            <Text style={[styles.textType]}>
              {t(`campaignTarget:${attribute}.type`)}
            </Text>
            <Text style={[styles.description]}>
              {t(`campaignTarget:${attribute}.description`)}
            </Text>
          </View>
        )}
      </View>
    )
  }
)
