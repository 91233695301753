import React, { memo, ComponentProps } from 'react'
import { View, ViewProps } from 'react-native'
import { CategoryPill } from 'components'
import { useStyles } from './styles'

export interface Props extends ViewProps {
  categoryPills?: ComponentProps<typeof CategoryPill>[]
}

export const CategoryPills = memo(({ categoryPills, style, ...props }: Props) => {
  const { styles } = useStyles()

  if (!categoryPills || categoryPills?.length === 0) return null

  return (
    <View style={[styles.container, style]} testID="CategoryPills" pointerEvents="box-none" {...props}>
      {categoryPills.map((item, index) => (
        <CategoryPill key={`cardCategoryPill-${index}`} containerStyle={styles.itemContainer} {...item} />
      ))}
    </View>
  )
})

