import React, { memo } from 'react'
import {
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native'
import { useStyles } from './styles'
import { ShareableProps, socialMediaTargets, SocialShareTarget } from './targets'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'

export interface Props {
  shareable: ShareableProps,
  style?: StyleProp<ViewStyle>,
  targets?: SocialShareTarget[]
}

export const SocialMediaButtons = memo(({
  shareable,
  style,
  targets
}: Props) => {
  useLang()

  const { t } = useTranslation()
  const { styles } = useStyles()

  if (shareable?.url === ''
    || shareable?.url == undefined) return null

  return (
    <View style={[styles.row, style]} testID="SocialMediaButtons">
      {(targets ?? socialMediaTargets).map((e) => (
        <TouchableOpacity
          accessibilityLabel={t('socialMediaButtons:ariaLabel', {
            value: e.name
          })}
          style={[styles.button]}
          onPress={() => e.onClick(shareable)}
          testID={`tid-${e.name}`}
          key={`k-${e.name}`}>
          <View style={[styles.icon, { backgroundColor: e.color }]}><e.icon /></View>
        </TouchableOpacity>
      ))}
    </View>
  )
})
