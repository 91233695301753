import { Attr, Model, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Action } from './Action'
import { Goal } from './Goal'

@Model()
export class ActionGoal extends ApplicationRecord {
  static jsonapiType = 'actions_goals'

  static endpoint = '/actions/goals'

  @Attr({ persist: false }) goalId!: string

  @BelongsTo(Action) action!: Action

  @BelongsTo(Goal) goal!: Goal
}
