import { User } from 'models'
import { useQuery } from 'react-query'

export const useUserRegionId = () => {
  const _getUserRegionId = async () => {
    return (await User
      .includes(['person'])
      .select({
        users: [''],
        people: ['locationsRegionId']
      })
      .find('current')
    ).data
  }

  const { data: user, isLoading } = useQuery(
    ['data', 'userRegionId'],
    _getUserRegionId
  )

  return {
    userRegionId: user?.person?.locationsRegionId || 'global',
    isLoading
  }
}