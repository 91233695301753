import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { spacing, sizing } = STYLES.useStyles()
  const insets = useSafeAreaInsets()

  const _containerTop = sizing.topBarHeight + insets.top

  const container = {
    paddingTop: getREM(_containerTop / baseSize),
    marginBottom: spacing.xl,
    width: '100%'
  } as ViewStyle

  const header = {
    paddingTop: getREM(_containerTop / baseSize),
    paddingBottom: getREM(sizing.headerPaddingBottom / baseSize),
    marginTop: getREM(-_containerTop / baseSize),
    width: '100%',
    overflow: 'hidden'
  } as ViewStyle

  const content = {
    paddingRight: spacing.page.padding.paddingRight,
    paddingLeft: spacing.page.padding.paddingLeft
  } as ViewStyle

  const backgroundContainer = {
    ...StyleSheet.absoluteFillObject
  } as ViewStyle

  const styles = StyleSheet.create({ container, header, content, backgroundContainer })

  return { styles }
}
