import { StyleSheet, ViewStyle, ImageStyle } from 'react-native'
import { STYLES } from 'styles'
import { useSharedStyles } from '../index'

export const useStyles = () => {
  const { spacing, bp } = STYLES.useStyles()
  const { imageWidth } = useSharedStyles()

  const _imageHeightRatio = 548.22 / 675.02

  const container = {
    position: 'relative',
    marginBottom: spacing.l,
    minWidth: imageWidth,
    minHeight: imageWidth * _imageHeightRatio
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    container.marginBottom = spacing.xl
  }

  const background = {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: imageWidth,
    height: imageWidth * _imageHeightRatio
  } as ImageStyle

  const children = {
    position: 'relative',
    zIndex: 1
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    background,
    children
  })

  return { styles }
}
