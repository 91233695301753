import { StyleSheet, ViewStyle, TextStyle, ImageStyle, Platform } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { box, colors, spacing, fontBold, fontRegular, bp, viewports } =
    STYLES.useStyles()

  const container = {
    ...box,
    alignItems: 'center',
    position: 'relative',
    paddingHorizontal: spacing.s,
    justifyContent: 'center'
  } as ViewStyle

  if (bp.gt(viewports.mobile_s)) {
    container.paddingHorizontal = spacing.l
  }

  if (bp.gt(viewports.mobile_normal)) {
    container.paddingHorizontal = spacing.xl
  }

  const value = {
    ...fontBold,
    color: colors.alternative,
    fontSize: STYLES.fontSize.xxl,
    lineHeight: STYLES.lineHeight.xxl
  } as TextStyle

  const label = {
    ...fontRegular,
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.normal,
    textAlign: 'center',
    ...Platform.select({
      web: {
        wordBreak: 'break-word'
      }
    })
  } as TextStyle

  if (bp.gt(viewports.tablet)) {
    label.textAlign = 'left'
  }

  const imageContainer = {} as ViewStyle

  if (bp.gt(viewports.tablet)) {
    imageContainer.marginTop = spacing.xs
    imageContainer.marginRight = spacing.s
  }

  const image = {
    width: '100%',
    height: '100%'
  } as ImageStyle

  const valueContainer = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center'
  } as ViewStyle

  if (bp.gt(viewports.tablet)) {
    container.paddingHorizontal = spacing.xl
    container.flexDirection = 'row'
    valueContainer.justifyContent = 'flex-start'
  }

  const valueUnit = {
    ...fontBold,
    color: colors.alternative,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m
  }

  const styles = StyleSheet.create({
    container,
    value,
    label,
    imageContainer,
    image,
    valueContainer,
    valueUnit
  })

  return { styles }
}
