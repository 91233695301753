import { Action, StepState } from 'models'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

export const useRecommendedSteps = () => {
  const { i18n } = useTranslation()

  const _getRecommendedSteps: () => Promise<Action[]> = async () => {
    const { data } = await Action
      .where({
        state: StepState.applicable,
        recommended: true,
        randomOrder: true
      })
      .includes(['impact', { 'themes': 'theme' }])
      .select({
        actions: [
          'summary',
          'param'
        ],
        impacts: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ],
        themes: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ]
      })
      .extraParams({ locale: i18n.language })
      .page(1)
      .per(5)
      .all()

    return data
  }

  const { data: recommendedSteps, isFetching } = useQuery(
    ['data', 'recommendedSteps', i18n.language],
    _getRecommendedSteps
  )

  return {
    recommendedSteps,
    isLoadingRecommendedSteps: isFetching
  }
}