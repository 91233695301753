import React, { createContext, useState, useRef, memo } from 'react'
import { ActivityIndicator } from 'components'
import {
  OnboardingGroupController,
  UseOnboardingGroupControllerType
} from './OnboardingGroupController'
import {
  NavigationContainer,
  DefaultTheme,
  useNavigationContainerRef
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import * as Pages from './OnboardingGroupPages'
import { STYLES } from 'styles'
import { View } from 'react-native'
import { useAnalytics } from 'utils'

export interface Props {
  controller?: OnboardingGroupController
}

type PagesType = keyof typeof Pages

type OnboardingGroupPagesType = {
  [Property in PagesType]: Property
}

export const OnboardingGroupPages = Object.keys(Pages).reduce((a, v) => ({ ...a, [v]: v }), {}) as OnboardingGroupPagesType

interface ContextProps extends UseOnboardingGroupControllerType {
  progress: PagesType[]
}

export { OnboardingGroupController }

/* istanbul ignore next */
export const OnboardingGroupContext = createContext<ContextProps>({
  progress: [],
  onComplete: () => undefined,
  isLoading: true,
  isOnboarded: false,
  person: undefined,
  getCountries: async () => undefined,
  getRegion: async () => null,
  getRegionsByCountryCount: async () => ({ count: 0, data: undefined }),
  getRegionsByCountry: async () => undefined,
  saveRegion: async () => undefined,
  getCarEstimates: async () => undefined,
  getCarModels: async () => undefined,
  getCarFootprint: async () => null,
  saveCarFootprint: async () => undefined,
  getAviationEstimates: async () => undefined,
  getAviationFootprint: async () => null,
  saveAviationFootprint: async () => undefined,
  getDietEstimates: async () => undefined,
  getDietFootprint: async () => null,
  saveDietFootprint: async () => undefined,
  getHouseTypes: async () => undefined,
  getHouseholdCount: () => 0,
  getHouseholdElectricityFootprint: async () => null,
  getHouseholdGasFootprint: async () => null,
  saveHouseholdFootprint: async () => undefined,
  saveHousehold: async () => undefined,
  getGoals: async () => undefined,
  getPersonGoals: async () => undefined,
  saveGoals: async () => undefined,
  getSteps: async () => ({
    data: [],
    total: 0,
    itemsPerPage: 0
  }),
  commitToStep: async () => undefined,
  globalId: '',
  saveName: async () => undefined,
  finalize: async () => undefined
})

const Stack = createNativeStackNavigator<Pages.PageParamList>()

export const OnboardingGroup = memo(({ controller = new OnboardingGroupController() }: Props) => {
  const { colors } = STYLES.useStyles()
  const { isLoading, person, isOnboarded, ...extraControllerProps } =
    controller.useController()
  const [_progress] = useState<ContextProps['progress']>([
    OnboardingGroupPages.Name,
    OnboardingGroupPages.Country,
    OnboardingGroupPages.State,
    OnboardingGroupPages.Car,
    OnboardingGroupPages.CarDetails,
    OnboardingGroupPages.Flights,
    OnboardingGroupPages.Diet,
    OnboardingGroupPages.House,
    OnboardingGroupPages.Bedrooms,
    OnboardingGroupPages.Household,
    OnboardingGroupPages.Goals
  ])
  const _initialRoute = person?.finalized ? OnboardingGroupPages.Summary : OnboardingGroupPages.Start
  const _routeRef = useRef<string | undefined>()
  const _navigationRef = useNavigationContainerRef()
  const { trackEvent, trackingEvents, getTrackingURL } = useAnalytics()

  const _trackScreen = (currentRoute: string) => {
    /* istanbul ignore next */
    if (!currentRoute) return

    const _pathname = Pages.PagePathnames[currentRoute]

    /* istanbul ignore next */
    if (!_pathname) return

    const _url = getTrackingURL(_pathname)

    trackEvent({
      eventName: trackingEvents.pageview,
      url: _url
    })

    trackEvent({
      eventName: trackingEvents.screenview,
      url: _url
    })
  }

  const _onReady = () => {
    _routeRef.current = _navigationRef?.getCurrentRoute()?.name

    /* istanbul ignore next */
    if (!isLoading && _routeRef.current) {
      _trackScreen(_routeRef.current)
    }
  }

  const _onStateChange: React.ComponentProps<
    typeof NavigationContainer
  >['onStateChange'] = (state) => {
    const _prevRoute = _routeRef.current

    /* istanbul ignore next */
    const _currentRoute =
      state?.routes?.[state.routes.length - 1]?.name ||
      _navigationRef?.getCurrentRoute()?.name

    /* istanbul ignore next */
    if (!isLoading && _currentRoute && _currentRoute !== _prevRoute) {
      _trackScreen(_currentRoute)
      _routeRef.current = _currentRoute
    }
  }

  if (isLoading || isOnboarded) {
    return <ActivityIndicator />
  }

  return (
    <OnboardingGroupContext.Provider
      value={{
        progress: _progress,
        isLoading,
        person,
        isOnboarded,
        ...extraControllerProps
      }}>
      <View style={{ flex: 1 }} testID="OnboardingGroup">
        <NavigationContainer
          ref={_navigationRef}
          independent={true}
          documentTitle={{ enabled: false }}
          theme={{
            ...DefaultTheme,
            colors: {
              ...DefaultTheme.colors,
              primary: colors.brand,
              background: colors.background,
              card: colors.background,
              text: colors.text,
              border: colors.navBorder,
              notification: colors.brand
            }
          }}
          onReady={_onReady}
          onStateChange={_onStateChange}>
          <Stack.Navigator
            initialRouteName={_initialRoute}
            screenOptions={{
              headerShown: false
            }}>
            <Stack.Screen
              name={OnboardingGroupPages.Start}
              component={Pages.Start}
            />
            <Stack.Screen
              name={OnboardingGroupPages.Name}
              component={Pages.Name}
            />
            <Stack.Group>
              <Stack.Screen
                name={OnboardingGroupPages.Country}
                component={Pages.Country}
              />
              <Stack.Screen
                name={OnboardingGroupPages.State}
                component={Pages.State}
              />
            </Stack.Group>
            <Stack.Group>
              <Stack.Screen
                name={OnboardingGroupPages.Car}
                component={Pages.Car}
              />
              <Stack.Screen
                name={OnboardingGroupPages.CarDetails}
                component={Pages.CarDetails}
              />
            </Stack.Group>
            <Stack.Screen
              name={OnboardingGroupPages.Flights}
              component={Pages.Flights}
            />
            <Stack.Screen
              name={OnboardingGroupPages.Diet}
              component={Pages.Diet}
            />
            <Stack.Group>
              <Stack.Screen
                name={OnboardingGroupPages.House}
                component={Pages.House}
              />
              <Stack.Screen
                name={OnboardingGroupPages.Bedrooms}
                component={Pages.Bedrooms}
              />
              <Stack.Screen
                name={OnboardingGroupPages.Household}
                component={Pages.Household}
              />
            </Stack.Group>
            <Stack.Screen
              name={OnboardingGroupPages.Goals}
              component={Pages.Goals}
            />
            <Stack.Screen
              name={OnboardingGroupPages.Summary}
              component={Pages.Summary}
            />
            <Stack.Group>
              <Stack.Screen
                name={OnboardingGroupPages.Steps}
                component={Pages.Steps}
              />
              <Stack.Screen
                name={OnboardingGroupPages.StepsSuccess}
                component={Pages.StepsSuccess}
              />
            </Stack.Group>
            <Stack.Screen
              name={OnboardingGroupPages.Success}
              component={Pages.Success}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </View>
    </OnboardingGroupContext.Provider>
  )
})
