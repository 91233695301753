import { ViewStyle } from 'react-native'
import { getREM } from '../../getREM'
import { useSpacing } from './useSpacing'
import { grid } from '../Grid'
import { useColors } from './useColors'
import { useBoxShadows } from './useBoxShadows'

export interface BoxProps extends ViewStyle {
  boxShadow?: string
}

export const useBox = () => {
  const { colors } = useColors()
  const { spacing } = useSpacing()
  const { boxShadows } = useBoxShadows()

  const box = {
    padding: spacing.normal,
    maxWidth: grid.maxBoxWidth,
    width: '100%',
    backgroundColor: colors.boxBackground,
    borderRadius: getREM(0.625),
    flexShrink: 1,
    ...boxShadows.box.all
  } as BoxProps

  return { box }
}
