import { StyleSheet, ViewStyle, Platform, PixelRatio } from 'react-native'
import { STYLES, baseSize } from 'styles'

export const useStyles = () => {
  const { bp, sizing } = STYLES.useStyles()

  const _columnMaxWidth = 31.25 * PixelRatio.getFontScale() * baseSize

  let _gap = 2.5 * PixelRatio.getFontScale() * baseSize

  if (bp.desktop) {
    _gap = 5 * PixelRatio.getFontScale() * baseSize
  }

  const container = {
    width: '100%',
    maxWidth: _columnMaxWidth * 2 + _gap,
    alignItems: 'center',
    gap: _gap
  } as ViewStyle

  if (bp.desktop) {
    container.flexDirection = 'row'
    container.alignItems = 'flex-start'
    container.justifyContent = 'center'
    container.marginTop = 'auto'
    container.marginBottom = 'auto'
  }

  const _shared = {
    alignItems: 'center',
    width: '100%',
    maxWidth: _columnMaxWidth,
    flexShrink: 1
  }

  const left = {
    ..._shared
  } as ViewStyle

  const right = {
    ..._shared
  } as ViewStyle

  if (Platform.OS === 'web' && bp.desktop) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    right.position = 'sticky'
    right.top = sizing.topBarHeightDefault
  }

  const styles = StyleSheet.create({
    container,
    left,
    right
  })

  return { styles }
}
