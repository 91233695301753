import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { spacing, bp } = STYLES.useStyles()

  const progressBar = {
    marginTop: spacing.l,
    marginBottom: spacing.xxxl,
    maxWidth: STYLES.grid.maxCardWidth
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    progressBar.marginTop = spacing.xxs
    progressBar.marginBottom = getREM(5.5)
  }

  const styles = StyleSheet.create({
    progressBar
  })

  return { styles }
}
