import React, { memo, forwardRef, useContext } from 'react'
import {
  Button,
  ButtonType,
  ShowCommitmentModal,
  CommitmentModalType
} from 'components'
import { useStepLang } from 'locales'
import { useTranslation } from 'react-i18next'
import { CommitmentState, Commitment } from 'models'
import {
  useErrorMessage,
  useAnalytics,
  useInvalidateDataQueries,
  GetScore,
  DestroySuppression,
  AuthContext
} from 'utils'
import { TrackingContext } from '../TrackingContext'
import { StepButtonProps } from '../index'

export const Commit = memo(
  forwardRef(({ action, ...props }: StepButtonProps, ref) => {
    useStepLang()

    const { t } = useTranslation()
    const { showErrorMessage } = useErrorMessage()
    const { trackEvent, trackingEvents } = useAnalytics()
    const { invalidateData } = useInvalidateDataQueries()
    const { isAuthenticated } = useContext(AuthContext)
    const { source } = useContext(TrackingContext)

    const _onPress = async () => {
      await DestroySuppression(action!.id)

      const _commitment = new Commitment({
        state: CommitmentState.pending,
        action_id: action!.id,
        action
      })

      const _success = await _commitment.save({ with: 'action' })

      if (!_success) {
        showErrorMessage()
        return
      }

      const _score = await GetScore()

      ShowCommitmentModal({
        modalType: CommitmentModalType.pending,
        score: _score,
        action
      })

      await invalidateData()

      trackEvent({
        eventName: trackingEvents.updateCommitment,
        props: {
          'commitment state': CommitmentState.pending,
          'action param': action!.param
        }
      })

      trackEvent({
        eventName: trackingEvents.stepsUserFlow,
        props: {
          source,
          type: 'commit',
          'action param': action!.param
        }
      })
    }

    if (!action || !isAuthenticated) return null

    return (
      <Button
        ref={ref}
        onPress={_onPress}
        testID="Commit"
        type={ButtonType.brand}
        label={t('step:buttons.commit')}
        {...props}
      />
    )
  })
)
