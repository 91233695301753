import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, fontSemiBold, fontRegular, COLORS } = STYLES.useStyles()

  const leaderboardChangeWrapper: ViewStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: colors.navBorder,
    borderWidth: getREM(0.0625),
    borderRadius: getREM(0.625),
    paddingVertical: getREM(0.25),
    paddingHorizontal: getREM(0.5),
    backgroundColor: COLORS.white
  }

  const leaderboardChangeValue: TextStyle = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.text
  }

  const leaderboardChangeIconWrapper: ViewStyle = {
    marginRight: getREM(0.25)
  }

  const leaderboardChangeIconEqual: ViewStyle = {
    backgroundColor: COLORS.lochmara,
    width: getREM(1),
    height: getREM(0.3125)
  }

  const leaderboardChangeUnit: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.text,
    marginLeft: getREM(0.25)
  }

  const styles = StyleSheet.create({
    leaderboardChangeWrapper,
    leaderboardChangeValue,
    leaderboardChangeIconWrapper,
    leaderboardChangeIconEqual,
    leaderboardChangeUnit
  })

  return { styles }
}
