import { Model, HasMany, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'
import { GroupMembershipOrganization } from './GroupMembershipOrganization'
import { GroupMembershipTeam } from './GroupMembershipTeam'
import { Organization } from './Organization'
import { Team } from './Team'

@Model()
export class GroupMembership extends ApplicationRecord {
  static jsonapiType = 'group_memberships'

  @HasMany(GroupMembershipOrganization) groupMembershipOrganizations!: GroupMembershipOrganization[]

  @HasMany(GroupMembershipTeam) groupMembershipTeams!: GroupMembershipTeam[]

  @HasMany(Organization) organizations!: Organization[]

  @HasMany(Team) teams!: Team[]

  @BelongsTo(Person) person!: Person
}
