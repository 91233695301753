import { Model, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'
import { Goal } from './Goal'

@Model()
export class PeopleGoal extends ApplicationRecord {
  static jsonapiType = 'people_goals'

  static endpoint = '/people/goals'

  @BelongsTo(Person) person!: Person

  @BelongsTo(Goal) goal!: Goal
}
