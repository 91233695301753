import React, { useContext, useState, memo } from 'react'
import { Text, ActivityIndicator, Logo } from 'components'
import {
  Layout,
  Shapes,
  Heading,
  NextButton,
  IllustrationBackground,
  useSharedStyles,
  ProgressBar,
  IllustrationBackgroundHeightRatio
} from '../Shared'
import { OnboardingGroupContext } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { HornsGesture, HornsGestureHeightRatio } from 'images/HornsGesture'
import { STYLES } from 'styles'
import { useStyles } from './styles'

export const Success = memo(() => {
  useLang()

  const { t } = useTranslation()
  const { person, onComplete } = useContext(OnboardingGroupContext)
  const { sharedStyles, imageWidth } = useSharedStyles()
  const { bp } = STYLES.useStyles()
  const { styles } = useStyles()
  const [_isUpdating, _setIsUpdating] = useState(false)

  let _imageWidth = imageWidth * 1.5

  /* istanbul ignore next */
  if (bp.is([bp.tablet, bp.desktop])) {
    _imageWidth = imageWidth
  }

  const _imageHeight = _imageWidth * HornsGestureHeightRatio

  const _onPress = async () => {
    /* istanbul ignore next */
    if (person) {
      _setIsUpdating(true)
      person.onboarded = true
      await person.save()
    }

    onComplete?.()
  }

  if (_isUpdating) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Success" hideTopBar>
      <ProgressBar
        isHidden
        label={t('onboardingSuccess:progressLabel')}
        max={0}
        value={0}
      />
      <Shapes>
        <Logo style={sharedStyles.logo} />

        <Heading style={{ textAlign: 'center' }}>
          {t('onboardingSuccess:title')}
        </Heading>

        <Text containerStyle={[sharedStyles.textContainer, styles.text]}>
          {t('onboardingSuccess:text')}
        </Text>

        <IllustrationBackground
          backgroundStyle={{
            width: _imageWidth,
            height: _imageWidth * IllustrationBackgroundHeightRatio
          }}
          style={{
            minWidth: _imageWidth,
            minHeight: _imageWidth * IllustrationBackgroundHeightRatio
          }}>
          <HornsGesture
            style={{
              width: _imageWidth,
              height: _imageHeight,
              marginTop: 0.1771374586679263 * _imageHeight
            }}
          />
        </IllustrationBackground>

        <NextButton
          label={t('onboardingSuccess:continue')}
          onPress={_onPress}
        />
      </Shapes>
    </Layout>
  )
})
