import { Action, StepState } from 'models'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { ArraySorter, useUserRegionId } from 'utils'

export const useAllApplicableSteps = () => {
  const { i18n } = useTranslation()
  const { userRegionId, isLoading: isLoadingUserRegionId } = useUserRegionId()

  const _getAllApplicableSteps: () => Promise<Action[]> = async () => {
    const { data } = await Action
      .where({ state: StepState.applicable, regionId: userRegionId })
      .includes(['impact', { 'themes': 'theme' }])
      .select({
        actions: [
          'summary',
          'param'
        ],
        impacts: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ],
        themes: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ]
      })
      .extraParams({ paginate: false, locale: i18n.language })
      .all()

    data?.sort((item1, item2) => ArraySorter(item1, item2, 'summary', 'asc'))

    return data
  }

  const { data: allApplicableSteps, isFetching } = useQuery(
    ['data', 'allApplicableSteps', i18n.language],
    _getAllApplicableSteps,
    {
      enabled: !isLoadingUserRegionId
    }
  )

  return {
    allApplicableSteps,
    isLoadingAllApplicableSteps: isFetching || isLoadingUserRegionId
  }
}