import { StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { box, colors, bp, spacing, fontBold } = STYLES.useStyles()

  const container = {
    ...box,
    height: getREM(10),
    marginBottom: spacing.xs,
    marginHorizontal: getREM(0.125)
  } as ViewStyle

  if (bp.not([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    container.flexDirection = 'row-reverse'
    container.height = getREM(8.75)
    container.paddingLeft = spacing.xl
    container.paddingRight = spacing.xl
  }

  if (bp.desktop) {
    container.paddingLeft = spacing.xxl
    container.paddingRight = spacing.xxl
  }

  const imageContainer = {
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    maxWidth: '100%'
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    imageContainer.width = '100%'
    imageContainer.height = getREM(3.75)
  }

  if (bp.not([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    imageContainer.width = '30%'
    imageContainer.height = getREM(8.75)
  }

  const image = {
    height: '100%',
    width: '100%'
  } as ImageStyle

  const content = {
    flexGrow: 1
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    content.alignItems = 'center'
    content.marginTop = spacing.xs
  }

  if (bp.not([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    content.marginRight = spacing.xl
    content.justifyContent = 'center'
  }

  if (bp.desktop) {
    content.marginRight = spacing.xxl
  }

  const _text = {
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    _text.fontSize = STYLES.fontSize.normal
    _text.lineHeight = STYLES.lineHeight.normal
  }

  const title = {
    ..._text,
    ...fontBold
  } as TextStyle

  const label = {
    ..._text
  } as TextStyle

  let _progressBarHeight = 0.5

  if (bp.not([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    _progressBarHeight = 0.75
  }

  const progressBarContainer = {
    width: '100%',
    height: getREM(_progressBarHeight),
    borderRadius: getREM(_progressBarHeight / 2),
    backgroundColor: colors.progressBarContainer,
    marginTop: spacing.xxs,
    marginBottom: spacing.xxs
  } as ViewStyle

  const progressBar = {
    backgroundColor: colors.progressBar,
    height: getREM(_progressBarHeight),
    borderRadius: getREM(_progressBarHeight / 2)
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    imageContainer,
    image,
    content,
    title,
    progressBarContainer,
    progressBar,
    label
  })

  return { styles }
}
