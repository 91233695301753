import { StyleSheet, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { bp } = STYLES.useStyles()

  const title = {
    textAlign: 'center', fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m
  } as TextStyle

  if (bp.desktop) {
    title.fontSize = STYLES.fontSize.l
    title.lineHeight = STYLES.lineHeight.l
  }

  const styles = StyleSheet.create({
    title
  })

  return { styles }
}
