import { Model, Attr, BelongsTo, HasOne, HasMany } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Notification } from './Notification'
import { NotificationsPreferences } from './NotificationsPreferences'
import { OrganizationLeaderboardsMembership } from './OrganizationLeaderboardsMembership'
import { PeopleTarget } from './PeopleTarget'
import { User } from './User'
import { Commitment } from './Commitment'
import { PersonalChecklistItem } from './PersonalChecklistItem'
import { PeopleGoal } from './PeopleGoal'
import { PeopleActionsSuppression } from './PeopleActionsSuppression'
import { Campaign } from './Campaign'
import { GroupMembership } from './GroupMembership'
import { GroupMembershipOrganization } from './GroupMembershipOrganization'
import { GroupMembershipTeam } from './GroupMembershipTeam'
import { PeopleAnnouncement } from './PeopleAnnouncement'

interface ClimateClock {
  months: number
  years: number
  unlimited: boolean
}

interface FootprintSection {
  complete_count: number
  complete_percentage: number
  total_count: number
}

interface FootprintSections {
  home: FootprintSection
  food: FootprintSection
  transport: FootprintSection
  purchases: FootprintSection
  services: FootprintSection
  trees: FootprintSection
}

@Model()
export class Person extends ApplicationRecord {
  static jsonapiType = 'people'

  @Attr({ persist: true }) firstName!: string

  @Attr({ persist: true }) lastName!: string

  @Attr({ persist: false }) pendingActionsCount!: number

  @Attr({ persist: false }) completeActionsCount!: number

  @Attr({ persist: true }) onboarded!: boolean

  @Attr({ persist: true }) finalized!: boolean

  @Attr({ persist: false }) hasUser!: boolean

  @Attr({ persist: false }) kgCo2eEmissions!: number

  @Attr({ persist: false }) kgCo2eReductions!: number

  @Attr({ persist: false }) kgCo2eForHome!: number
  @Attr({ persist: false }) kgCo2eForFood!: number
  @Attr({ persist: false }) kgCo2eForTransport!: number
  @Attr({ persist: false }) kgCo2eForPurchases!: number
  @Attr({ persist: false }) kgCo2eForServices!: number

  @Attr({ persist: false }) climateClock!: ClimateClock

  @Attr({ persist: false }) footprintSections!: FootprintSections

  @Attr({ persist: false }) score!: number

  @Attr({ persist: true }) locationsRegionId!: string | null

  @Attr({ persist: false }) organizationSlugs!: string[]

  @Attr({ persist: true }) householdMemberCount!: number

  @Attr({ persist: true }) updateHouseholdSnapshot!: boolean

  @Attr({ persist: false }) metaHouseholdFootprintKgCo2e!: {
    [key: string]: number
  }

  get footprintSectionsKgCo2eEmissions() {
    return [
      { key: 'home', value: this.kgCo2eForHome },
      { key: 'food', value: this.kgCo2eForFood },
      { key: 'transport', value: this.kgCo2eForTransport },
      { key: 'purchases', value: this.kgCo2eForPurchases },
      { key: 'services', value: this.kgCo2eForServices }
    ]
  }

  @HasOne(OrganizationLeaderboardsMembership)
  highlightedOrganizationLeaderboardMembership!: OrganizationLeaderboardsMembership

  @HasOne(PeopleTarget) nextTarget!: PeopleTarget

  @HasOne(NotificationsPreferences)
  notificationsPreferences!: NotificationsPreferences

  @HasMany(Commitment) commitments!: Commitment[]

  @HasMany(PersonalChecklistItem)
  personalChecklistItems!: PersonalChecklistItem[]

  @HasMany(Notification) notifications!: Notification[]

  @HasMany(PeopleGoal) goals!: PeopleGoal[]

  @HasMany(PeopleActionsSuppression) actionSuppressions!: PeopleActionsSuppression[]

  @HasMany(Campaign) campaigns!: Campaign[]

  @HasMany(GroupMembership) groupMemberships!: GroupMembership[]

  @HasMany(GroupMembershipOrganization) groupMembershipOrganizations!: GroupMembershipOrganization[]

  @HasMany(GroupMembershipTeam) groupMembershipTeams!: GroupMembershipTeam[]

  @HasMany(PeopleAnnouncement) peopleAnnouncements!: PeopleAnnouncement[]

  @BelongsTo(User) user!: User
}
