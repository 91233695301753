import React, { useEffect, useLayoutEffect, useState, memo } from 'react'
import { Animated, ViewProps, BackHandler } from 'react-native'
import { TopBar as TopBarComponent } from 'components'
import { useNavigation } from '@react-navigation/native'
import { useLang as useSharedLang } from '../Lang'
import { useTranslation } from 'react-i18next'

export interface Props extends ViewProps {
  offsetY?: Animated.Value
  showBackButton?: boolean
}

export const TopBar = memo(({ offsetY = new Animated.Value(0), showBackButton = false, style, ...props }: Props) => {
  useSharedLang()

  const { t } = useTranslation()
  const navigation = useNavigation()
  const [_backLabel, _setBackLabel] = useState<string>(
    t('onboardingShared:backPreviousStep')
  )

  if (navigation.canGoBack() === false) {
    showBackButton = false
  }

  const _onPress = () => {
    navigation.goBack()
  }

  /* istanbul ignore next */
  useEffect(() => {
    const _backAction = () => {
      if (showBackButton) navigation.goBack()
      return true
    }

    const _backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      _backAction
    )

    return () => {
      _backHandler.remove()
    }
  }, [navigation, showBackButton])

  /* istanbul ignore next */
  useLayoutEffect(() => {
    if (navigation.setOptions) {
      navigation.setOptions({
        gestureEnabled: showBackButton
      })
    }

    const _routes = navigation?.getState()?.routes || []
    const _prevRoute = _routes[_routes.length - 2]

    if (_prevRoute?.name) {
      const _pageTitle = t(
        `onboardingShared:pages.${_prevRoute.name}` as string
      )
      _setBackLabel(t('onboardingShared:back', { value: _pageTitle }))
    }
  }, [navigation, showBackButton, t])

  return (
    <TopBarComponent
      showBackButton={showBackButton}
      offsetY={offsetY}
      onBackButtonPress={_onPress}
      backButtonLabel={_backLabel}
      style={style}
      {...props}
    />
  )
})
