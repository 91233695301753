import { Attr, HasMany, HasOne, Model } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { CampaignAction } from './CampaignAction'
import { CampaignTargetModel } from './CampaignTargetModel'

@Model()
export class Campaign extends ApplicationRecord {
  static jsonapiType = 'campaigns'

  @Attr({ persist: false }) title!: string

  @Attr({ persist: false }) description!: string

  @Attr({ persist: false }) public!: boolean

  @Attr({ persist: false }) pendingCommitmentsCount!: number

  @Attr({ persist: false }) successfulCommitmentsCount!: number

  @Attr({ persist: false }) participantsCount!: number

  @Attr({ persist: false }) organizationId!: string

  @HasOne(CampaignTargetModel) target!: CampaignTargetModel

  @HasMany(CampaignAction) campaignActions!: CampaignAction[]
}
