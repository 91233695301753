import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export const useOrdinals = () => {
  useLang()
  const { t, i18n } = useTranslation()

  const _lang = i18n.language?.split('_')[0] || 'en'

  const _rules = new Intl.PluralRules(_lang, { type: 'ordinal' })

  const _suffixes = {
    zero: '',
    one: t('ordinals:one'),
    two: t('ordinals:two'),
    few: t('ordinals:few'),
    other: t('ordinals:other'),
    many: t('ordinals:other')
  }

  const suffix = (number: number) => {
    if (number === 0) return _suffixes['zero']
    return _suffixes[_rules.select(number)]
  }

  return { suffix }
}
