import { User } from 'models'
import { useQuery, useQueryClient } from 'react-query'

export class HouseholdFootprintGroupController {
  useController = () => {
    const queryClient = useQueryClient()

    const _getData = async () => {
      const { data } = await User
        .includes(['person'])
        .select({
          users: [''],
          people: ['householdMemberCount']
        })
        .selectExtra({ people: ['metaHouseholdFootprintKgCo2e'] })
        .extraParams({ paginate: false })
        .find('current')

      return data
    }

    const _queryKey = ['data', 'footprint', 'household']

    const { data: user, isLoading } = useQuery(_queryKey, _getData)

    const getHouseholdCount = () => {
      if (!user?.person?.householdMemberCount) return 0

      // remove 1 to exclude the current user
      return user.person.householdMemberCount - 1
    }

    const invalidateHouseholdData = async () => {
      await queryClient.invalidateQueries(_queryKey)
    }

    const saveHousehold = async (newMemberCount: number) => {
      if (!user?.person?.householdMemberCount) return false

      // add 1 to include the current user
      user.person.householdMemberCount = newMemberCount + 1
      user.person.updateHouseholdSnapshot = true

      const _success = await user.person.save()

      return _success
    }

    return {
      isLoading,
      person: user?.person,
      getHouseholdCount,
      saveHousehold,
      invalidateHouseholdData
    }
  }
}
