import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const ThumbUp = memo(({
  size = getREM(1.5),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()
  return (
    <SVG.Svg
      testID="ThumbUp"
      viewBox="0 0 17 16"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5085 5.43603H11.6547C11.8915 4.48642 12.5295 4.12503 12.5295 2.799C12.5295 0.378802 9.77296 -1.68468 9.07754 2.03634C8.87072 3.03837 6.59008 6.66472 5.57569 6.74461C5.23418 6.777 4.94757 7.0636 4.94757 7.4051V14.01C4.94757 14.3714 5.2567 14.658 5.60558 14.668C5.96696 14.6805 6.58507 14.8949 7.23308 15.1217C8.34711 15.5031 9.73792 15.989 11.211 15.989C13.1228 15.989 15.2885 16.0189 15.0744 13.3196C15.6328 12.7863 15.8496 11.8815 15.6328 11.1463C16.1312 10.4783 16.1836 9.71564 15.9219 8.95046C15.9219 8.95046 16.3731 5.4361 14.5087 5.4361L14.5085 5.43603Z"
        fill={color ?? colors.text}
      />
      <SVG.Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.287 7.41489V14.0096C4.287 14.3685 3.98788 14.6676 3.62651 14.6676H0.660492C0.299108 14.6676 0 14.371 0 14.0096V7.41489C0 7.0535 0.299119 6.75439 0.660492 6.75439H3.62651C3.98789 6.75439 4.287 7.05351 4.287 7.41489Z"
        fill={color ?? colors.text}
      />
    </SVG.Svg>
  )
})
