// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ShuffleArray = (origArray: any[]) => {
  const a = origArray.slice()

  do {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
  } while (JSON.stringify(a) === JSON.stringify(origArray))

  return a
}
