import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Plus = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Plus"
      viewBox="0 0 140 140"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.G
        fill="none"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <SVG.Path strokeWidth={8.749995} d="M4.375 70h131.25M70 4.375v131.25" />
      </SVG.G>
    </SVG.Svg>
  )
})
