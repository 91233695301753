import React, { memo, useCallback } from 'react'
import {
  Platform,
  StyleSheet,
  Text,
  ViewProps
} from 'react-native'
import { View } from 'components'
import { useStyles } from './styles'
import { STYLES } from 'styles'

export interface Props extends ViewProps {
  label?: string
  max: number
  value: number
  title?: string
  description?: string
  isVisualOnly?: boolean
}

export const ProgressBar = memo(
  ({
    label,
    max = 1,
    value,
    style,
    title,
    description,
    isVisualOnly = false,
    ...props
  }: Props) => {
    const { styles } = useStyles()
    const { spacing } = STYLES.useStyles()

    const _hasTitle = !!title
    const _hasDescription = !!description

    const _value = {
      min: 0,
      max: 100,
      now: (value / max) * 100
    }

    const _getChildren = useCallback(
      () =>
        new Array(max).fill('').map((_, index) => {
          let _width = 0
          const _indexPlusOne = index + 1

          if (_indexPlusOne <= Math.floor(value)) {
            _width = 100
          } else if (_indexPlusOne === Math.ceil(value)) {
            _width = (value % 1) * 100
          }

          return (
            <View
              key={index}
              style={[styles.item, index > 0 && styles.item_gap]}
              testID="ProgressBarItem">
              <View
                style={[
                  styles.itemBar,
                  {
                    width: `${_width}%`
                  }
                ]}
              />
            </View>
          )
        }),
      [max, styles.item, styles.itemBar, styles.item_gap, value]
    )

    const Bars = () => {
      if (Platform.OS === 'web') {
        const _ariaLive = isVisualOnly ? undefined : 'polite'

        return (
          <div
            aria-live={_ariaLive}
            aria-label={label}
            aria-valuenow={_value.now}
            aria-valuemin={_value.min}
            aria-valuemax={_value.max}
            role="progressbar"
            style={
              StyleSheet.flatten(
                styles.barContainer
              ) as unknown as React.CSSProperties
            }
            tabIndex={-1}>
            {_getChildren()}
            <Text style={styles.value}>{`${_value.now}%`}</Text>
          </div>
        )
      }

      return (
        <View
          style={styles.barContainer}
          testID="ProgressBar"
          accessibilityLabel={label}
          accessibilityRole="progressbar"
          accessibilityValue={_value}
          {...props}>
          {_getChildren()}
          <Text style={styles.value}>{`${_value.now}%`}</Text>
        </View>
      )
    }

    return (
      <View style={[styles.container, style]} testID="ProgressBarContainer">
        {(_hasTitle || _hasDescription) && (
          <View style={styles.textContainer} spacer={spacing.xs}>
            {_hasTitle && <Text style={styles.title}>{title}</Text>}
            {_hasDescription && (
              <Text style={styles.description}>{description}</Text>
            )}
          </View>
        )}
        <Bars />
      </View>
    )
  }
)
