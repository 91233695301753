import React, {
  useContext,
  useState,
  useEffect,
  memo,
  ComponentProps,
  useCallback
} from 'react'
import { ViewProps } from 'react-native'
import { PageProps } from '../index'
import {
  SelectionCards,
  SpeechBubble,
  Form,
  ActivityIndicator
} from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { FruitAndVeg, FruitAndVegHeightRatio } from 'images/FruitAndVeg'
import { STYLES } from 'styles'
import { useQuery } from 'react-query'

type SelectionCardsProps = ComponentProps<typeof SelectionCards>

export const Diet = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const { progress, getDietEstimates, getDietFootprint, saveDietFootprint } =
    useContext(OnboardingGroupContext)
  const { imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_selectedIds, _setSelectedIds] = useState<string[]>([])
  const [_isUpdating, _setIsUpdating] = useState(false)
  const { data: _dietEstimates, isLoading } = useQuery(
    ['onboarding', 'dietEstimates'],
    getDietEstimates
  )
  const { data: _dietFootprint } = useQuery(
    ['onboarding', 'dietFootprint'],
    getDietFootprint
  )
  const [_showError, _setShowError] = useState(false)

  const _isDisabled = _selectedIds.length === 0

  const _onPress = async () => {
    _setShowError(false)

    _setIsUpdating(true)

    let _success: boolean | undefined = false

    const _diet = _dietEstimates?.find((item) => item.id === _selectedIds[0])

    /* istanbul ignore next */
    if (_diet) {
      _success = await saveDietFootprint?.(_diet)
    }

    _setIsUpdating(false)

    if (_success) {
      navigation.navigate('House')
      return
    }

    _setShowError(true)
  }

  const _onSelect: SelectionCardsProps['onSelect'] = (id) => _setSelectedIds(id)

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.funFact')}
        text={t('onboardingDiet:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageWidth = imageWidth * 0.8
  const _imageHeight = _imageWidth * FruitAndVegHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <FruitAndVeg
          style={{
            width: _imageWidth,
            height: _imageHeight,
            transform: [
              {
                translateX: 0.1667 * _imageWidth
              }
            ]
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, _imageWidth]
  )

  useEffect(() => {
    if (_dietFootprint?.diet.id && _selectedIds.length === 0) {
      _setSelectedIds([_dietFootprint.diet.id])
    }
  }, [_dietFootprint, _selectedIds.length])

  if (_isUpdating || isLoading) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Diet" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingDiet:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.Diet) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingDiet:title')}</Heading>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <SelectionCards
              legend={t('onboardingDiet:title')}
              items={
                /* istanbul ignore next */
                _dietEstimates?.map((item) => ({
                  id: item.id!,
                  label: item.name,
                  description: item.description
                })) || []
              }
              onSelect={_onSelect}
              selectedIds={_selectedIds}
              style={{ marginBottom: spacing.xl }}
            />
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton
            onPress={_onPress}
            state={{
              disabled: _isDisabled
            }}
          />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
