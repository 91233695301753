import React, { memo, useMemo } from 'react'
import { ViewProps } from 'react-native'
import { useStyles } from './styles'
import { Heading, View, Icons, Text } from 'components'
import { STYLES } from 'styles'
import { GenerateId, WebAccessibilityRole } from 'utils'

export interface BenefitsListProps extends ViewProps {
  items: string[]
  title?: string
  titleLevel?: React.ComponentProps<typeof Heading>['level']
}

export const BenefitsList = memo(
  ({ items, title, titleLevel = 3, ...props }: BenefitsListProps) => {
    const { styles } = useStyles()
    const { spacing, COLORS } = STYLES.useStyles()
    const _id = useMemo(() => GenerateId(), [])

    return (
      <View testID="BenefitsList" {...props}>
        {title && (
          <Heading level={titleLevel} style={styles.heading}>
            {title}
          </Heading>
        )}
        <View
          accessibilityRole={WebAccessibilityRole('list')}
          spacer={spacing.l}>
          {items.map((item, index) => (
            <View
              style={[styles.benefitItem]}
              key={`BenefitsList-${_id}-${index}`}
              accessibilityRole={WebAccessibilityRole('listitem')}>
              <View style={[styles.benefitItemTick]}>
                <Icons.Tick color={COLORS.white} size={14} />
              </View>
              <Text
                containerStyle={styles.benefitItemTextContainer}
                textStyle={[styles.benefitItemText]}>
                {item}
              </Text>
            </View>
          ))}
        </View>
      </View>
    )
  }
)
