import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const SignOut = memo(({
  size = getREM(1.875),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="SignOut"
      viewBox="0 0 30 30"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M1.00015 15.801H16.5923L14.2919 18.0625C14.1026 18.2479 13.9961 18.5003 13.9961 18.7637C13.9961 19.0272 14.1026 19.2796 14.2919 19.465C14.4797 19.6519 14.7353 19.757 15.0021 19.757C15.2689 19.757 15.5245 19.6519 15.7123 19.465L19.7129 15.5147C19.804 15.4208 19.8752 15.3099 19.923 15.1887C20.023 14.9484 20.023 14.6786 19.923 14.4383C19.8752 14.317 19.804 14.2062 19.7129 14.1123L15.7123 10.162C15.4584 9.91157 15.0887 9.81369 14.7422 9.90539C14.3955 9.9971 14.1249 10.2643 14.0321 10.6066C13.9392 10.9487 14.0383 11.3138 14.2919 11.5645L16.5923 13.8259H1.00014C0.64272 13.8259 0.312548 14.0142 0.133949 14.3197C-0.0446498 14.6252 -0.0446498 15.0017 0.133949 15.3073C0.312548 15.6128 0.642731 15.8011 1.00014 15.8011L1.00015 15.801Z"
        fill={color ?? colors.text}
      />
      <SVG.Path
        d="M15.0025 0C11.7694 0.0125652 8.62603 1.04992 6.03477 2.95937C3.44374 4.86904 1.54213 7.54958 0.610266 10.6065C0.529671 10.8607 0.556686 11.136 0.684609 11.3703C0.812755 11.6044 1.03109 11.7779 1.2903 11.8509C1.54503 11.9243 1.81874 11.8948 2.05134 11.7687C2.28396 11.6428 2.45631 11.4307 2.53043 11.1795C3.4176 8.22026 5.35453 5.67578 7.98643 4.01142C10.6183 2.34734 13.769 1.675 16.8621 2.1176C19.955 2.56002 22.783 4.08788 24.8285 6.42125C26.8739 8.75462 27.9995 11.7374 27.9995 14.8235C27.9995 17.9094 26.8739 20.8922 24.8285 23.2257C22.7831 25.5591 19.955 27.0867 16.8621 27.5293C13.769 27.9718 10.6183 27.2994 7.98643 25.6354C5.35459 23.9713 3.41765 21.4267 2.53043 18.4679C2.46055 18.2128 2.28999 17.9961 2.05692 17.8661C1.82384 17.7362 1.54768 17.7041 1.29028 17.7766C1.03332 17.8498 0.816767 18.0215 0.689044 18.2536C0.561344 18.4855 0.532768 18.7582 0.610237 19.011C1.66709 22.5434 4.01883 25.56 7.2067 27.4731C10.3947 29.3859 14.1895 30.0573 17.8518 29.3567C21.514 28.656 24.7803 26.6332 27.0138 23.683C29.2472 20.7329 30.2875 17.0669 29.9317 13.4005C29.5756 9.7339 27.8492 6.33007 25.089 3.85212C22.3287 1.37463 18.7326 0.00128666 15.0023 0.00015801L15.0025 0Z"
        fill={color ?? colors.text}
      />
    </SVG.Svg>
  )
})
