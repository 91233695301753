// https://docs.expo.dev/versions/latest/react-native/textinput/#autocompletetype---android

import { TextInputProps } from 'react-native'

export default {
  none: 'off',
  creditCardNumber: 'cc-number',
  emailAddress: 'email',
  fullStreetAddress: 'street-address',
  postalCode: 'postal-code',
  telephoneNumber: 'tel',
  username: 'username',
  password: 'password'
} as {
  [key: string]: TextInputProps['autoComplete']
}
