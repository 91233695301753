import { Model, Attr } from 'spraypaint'
import { Estimate } from './Estimate'

@Model()
export class HouseType extends Estimate {
  static jsonapiType = 'house_types'

  static endpoint = '/house-types'

  @Attr({ persist: false }) lookupSlug!: string
}
