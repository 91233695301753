import { Attr, HasOne, Model } from 'spraypaint'
import { Action } from './Action'
import { ApplicationRecord } from './ApplicationRecord'
import { Campaign } from './Campaign'

@Model()
export class CampaignAction extends ApplicationRecord {
  static jsonapiType = 'campaigns_actions'

  @Attr({ persist: false }) campaignId!: string

  @Attr({ persist: false }) actionId!: string

  @HasOne(Campaign) campaign!: Campaign

  @HasOne(Action) action!: Action
}
