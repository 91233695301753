import React, { memo } from "react"
import { Text, View, ViewStyle, StyleProp } from "react-native"
import { useStyles } from "./styles"
import { useLang } from "./Lang"
import { useTranslation } from "react-i18next"
import { SvgImage } from 'components'

export interface Props {
  count: number,
  style?: StyleProp<ViewStyle>
}

export const HaveDoneCounter = memo(({
  count,
  style
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()

  return (
    <View style={[styles.counter, style]}>
      <SvgImage image={require('./Tick.svg')} style={styles.tick} />
      <View style={styles.text}>
        <Text style={styles.number}>{count.toLocaleString()}</Text>
        <Text style={styles.haveDoneText}>{t('haveDoneCounter:done')}</Text>
      </View>
    </View>
  )
})
