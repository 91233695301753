import { Theme } from 'models'
import { useQuery } from 'react-query'
import { useTranslation } from "react-i18next"
import { ArraySorter } from 'utils'

export const useThemeFilters = () => {
  const { i18n } = useTranslation()

  const _getThemeFilters: () => Promise<Theme[]> = async () => {
    const { data } = await Theme.select(['name', 'lookupSlug']).extraParams({ paginate: false, locale: i18n.language }).all()

    data?.sort((item1, item2) => ArraySorter(item1, item2, 'name', 'asc'))

    return data
  }

  const { data: themeFilters } = useQuery(['steps', 'filters', 'theme', i18n.language], _getThemeFilters)

  return { themeFilters }
}