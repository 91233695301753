import React, { memo, useCallback } from 'react'
import { StepCategoryPills, StepIcon } from 'components'
import { StyleProp, Text, View, ViewStyle } from 'react-native'
import { useStyles } from './styles'
import { StepInteractionsGroup } from 'groups'
import { Action } from 'models'

export interface Props {
  style?: StyleProp<ViewStyle>
  action: Action
}

export const StepDetailHeader = memo(({
  style,
  action
}: Props) => {
  const { styles, breakpoint } = useStyles()

  const CategoryPills = useCallback(() => (
    <StepCategoryPills
      themes={action.themes?.map((themeItem) => themeItem.theme)}
      impact={action.impact}
      style={styles.pill}
    />
  ), [styles.pill, action])

  if (!action) return null

  return (
    <View style={[styles.container, style]} testID="StepDetailHeader">
      <StepIcon
        style={[styles.icon]}
        actionSlug={action.slug}
        improvements={action.improvements}
        size={
          /* istanbul ignore next */
          breakpoint ? 'big' : 'default'
        }
      />

      <View style={[styles.content]}>
        <CategoryPills />

        <Text style={[styles.title]}>{action.name}</Text>

        <StepInteractionsGroup actionParam={action.param} />
      </View>
    </View>
  )
})
