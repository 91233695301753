import React from 'react'
import { Text } from 'react-native'
import SimpleMarkdown from 'simple-markdown'
import { useStyles } from './styles'
import { WebAccessibilityRole } from 'utils'

export default Object.assign({}, SimpleMarkdown.defaultRules.paragraph, {
  react: (
    node: SimpleMarkdown.SingleASTNode,
    output: SimpleMarkdown.Output<React.ReactNode>,
    state: SimpleMarkdown.State
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { styles } = useStyles()

    return (
      <Text
        key={state.key}
        style={[styles.container, state.containerStyle]}
        accessibilityRole={WebAccessibilityRole('paragraph')}
        textBreakStrategy="simple">
        <Text textBreakStrategy="simple" style={[styles.text, state.textStyle]} nativeID={state.nativeID}>
          {output(node.content, {
            ...state,
            contentCount: node.content.length
          })}
        </Text>
      </Text>
    )
  },
  match: SimpleMarkdown.blockRegex(
    /^((?:[^\n]|\n(?! *\n))+?)(?:\n *?)+(?:\n|(?=#))/
  )
})
