import { User } from 'models'
import { useQuery } from 'react-query'

export class FootprintGroupController {
  useController = () => {
    const _getData = async () => {
      return (
        await User
          .includes([{
            person: ['next_target']
          }])
          .select({
            users: [''],
            people: [
              'kg_co2e_emissions'
            ],
            people_targets: [
              'active',
              'complete',
              'kg_co2e_remaining',
              'kg_co2e_emissions'
            ]
          })
          .find('current')
      ).data
    }

    const { isLoading, data: user } = useQuery(['data', 'footprint'], _getData)

    return {
      person: user?.person,
      isLoading
    }
  }
}