import React, { memo } from 'react'
import { useStyles } from './styles'
import { View, Icons } from 'components'
import { Image, Text } from 'react-native'

interface LeaderboardAvatarProps {
  value?: string
  showCrown?: boolean
  label?: string
}

export const LeaderboardAvatar = memo(
  ({ value, showCrown = false, label }: LeaderboardAvatarProps) => {
    const { styles } = useStyles()

    return (
      <View
        testID="LeaderboardAvatar"
        style={[showCrown && styles.container_crown]}>
        <>
          {showCrown ? <Icons.Crown style={styles.crown} /> : null}
          {value ? (
            <Image
              source={{ uri: value }}
              style={styles.avatar}
              testID="LeaderboardAvatarImage"
            />
          ) : (
            <View
              style={styles.defaultAvatar}
              testID="LeaderboardAvatarDefault">
              <Text style={styles.defaultAvatarText}>
                {label?.substring(0, 1)}
              </Text>
            </View>
          )}
        </>
      </View>
    )
  }
)
