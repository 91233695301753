import { Model, BelongsTo } from 'spraypaint'
import { Footprint } from './Footprint'
import { Person } from './Person'
import { CarEstimate } from './CarEstimate'
import { CarModel } from './CarModel'

@Model()
export class PeopleCarFootprint extends Footprint {
  static jsonapiType = 'people_car_footprints'

  static endpoint = '/people/car/footprints'

  @BelongsTo(CarEstimate) estimate!: CarEstimate

  @BelongsTo(CarModel) model!: CarModel

  @BelongsTo(Person) person!: Person
}
