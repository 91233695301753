import { useContext } from 'react'
import { STYLES } from 'styles'
import { StyleSheet, Platform, TextStyle } from 'react-native'
import { HeaderContext } from 'components'

export const useStyles = (textStyle?: TextStyle) => {
  const { paragraph } = STYLES.useStyles()
  const { textColor } = useContext(HeaderContext)

  const _ratio = 0.8

  const _textStyle = Object.assign({}, paragraph, textStyle)

  const styles = StyleSheet.create({
    small: {
      fontFamily: paragraph.fontFamily,
      color: textColor || paragraph.color,
      fontSize: Platform.OS === 'web'
        ? `calc(${_ratio} * ${_textStyle.fontSize})`
        : _ratio * _textStyle.fontSize,
      lineHeight: Platform.OS === 'web'
        ? `calc(${_ratio} * ${_textStyle.lineHeight})`
        : _ratio * _textStyle.lineHeight
    } as TextStyle
  })

  return { styles }
}
