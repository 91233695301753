import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'
import { useWindowDimensions } from 'utils'

export const useStyles = ({
  childrenCount = 1,
  maxItemWidth = STYLES.grid.maxBoxWidth,
  isOverflowVisible = false
}) => {
  const { width } = useWindowDimensions()
  const { bp, spacing, fontBold, fontRegular, colors } = STYLES.useStyles()

  let _spacing = 1

  if (bp.mobile_xs) {
    _spacing = 0.5
  }

  let rawMaxItemWidth = 0

  if (Platform.OS === 'web') {
    const _maxItemWidthRem = parseFloat((maxItemWidth ?? 0).toString())
    rawMaxItemWidth = (_maxItemWidthRem + (_spacing * 2)) * baseSize
  } else {
    rawMaxItemWidth = maxItemWidth + getREM(_spacing * 2)
  }

  let spacerWidth = 0

  if (isOverflowVisible && width > rawMaxItemWidth) {
    spacerWidth = (width - rawMaxItemWidth) / 2
  }

  const _maxContentWidth = rawMaxItemWidth * childrenCount + (spacerWidth * 2)

  const container = {
    alignItems: 'center',
    width: '100%'
  } as ViewStyle

  const title = {
    textAlign: 'center',
    marginBottom: spacing.xl,
    paddingHorizontal: getREM(_spacing),
    maxWidth: STYLES.grid.maxTextWidth
  } as TextStyle

  const description = {
    ...fontBold,
    textAlign: 'center',
    marginBottom: spacing.xl,
    marginTop: Platform.OS === 'web'
      ? `calc(-1 * ${spacing.l})`
      : -1 * spacing.l,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    color: colors.brand,
    width: '100%',
    paddingHorizontal: getREM(_spacing),
    maxWidth: STYLES.grid.maxTextWidth
  } as TextStyle

  const emptyMessage = {
    ...fontRegular,
    textAlign: 'center',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand,
    width: '100%',
    paddingHorizontal: getREM(_spacing),
    maxWidth: STYLES.grid.maxTextWidth
  } as TextStyle

  const carousel = {
    width: '100%'
  } as ViewStyle

  if (!isOverflowVisible) {
    carousel.maxWidth = rawMaxItemWidth
  }

  const scrollview = {
    maxWidth: rawMaxItemWidth,
    width: '100%'
  } as ViewStyle

  if (isOverflowVisible && Platform.OS !== 'web') {
    scrollview.overflow = 'visible'
  }

  if (isOverflowVisible) {
    scrollview.maxWidth = undefined
  }

  const scrollContainer = {
    maxWidth: _maxContentWidth,
    paddingBottom: spacing.xxs
  } as ViewStyle

  const carouselItem = {
    maxWidth: Platform.OS === 'web'
      ? `calc(${rawMaxItemWidth}px - ${getREM(_spacing * 2)})`
      : rawMaxItemWidth,
    width: Platform.OS === 'web'
      ? `calc(100vw - ${getREM(_spacing * 2)})`
      : Math.min(rawMaxItemWidth, width),
    paddingLeft: getREM(_spacing),
    paddingRight: getREM(_spacing),
    flexShrink: 0,
    height: '100%',
    display: 'flex',
    boxSizing: Platform.OS === 'web'
      ? 'content-box'
      : undefined
  } as ViewStyle

  const link = {
    marginTop: spacing.normal
  } as ViewStyle

  const imageContainer = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center'
  } as ViewStyle

  const targetSize = 300

  const target = {
    width: targetSize,
    height: targetSize,
    marginRight: getREM(_spacing)
  } as ViewStyle

  if (bp.desktop_s) {
    const _targetScaleWidth = spacerWidth - (_spacing * baseSize * 2)
    const _scale = Math.min(1, _targetScaleWidth / targetSize)

    target.transform = [
      { translateX: targetSize / 2 },
      { scale: _scale },
      { translateX: -1 * (targetSize / 2) }
    ]
  }

  const styles = StyleSheet.create({
    container,
    scrollview,
    scrollContainer,
    title,
    description,
    emptyMessage,
    carousel,
    carouselItem,
    link,
    imageContainer,
    target
  })

  return {
    styles,
    spacerWidth,
    rawMaxItemWidth,
    pageWidth: width,
    targetSize
  }
}
