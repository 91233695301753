import React, { memo, useContext, forwardRef, ComponentProps } from 'react'
import { Button, ButtonType } from 'components'
import { useStepLang } from 'locales'
import { useTranslation } from 'react-i18next'
import { LinkContext, ReplaceParam } from 'utils'

export interface LearnMoreProps extends Partial<ComponentProps<typeof Button>> {
  param: string
}

export const LearnMore = memo(
  forwardRef(({ param, ...props }: LearnMoreProps, ref) => {
    useStepLang()

    const { t } = useTranslation()
    const { stepDetailPageLinkUrl } = useContext(LinkContext)

    if (!stepDetailPageLinkUrl || !param) return null

    return (
      <Button
        href={ReplaceParam(stepDetailPageLinkUrl, param)}
        testID="LearnMore"
        type={ButtonType.brandLink}
        label={t('step:buttons.learnMore')}
        ref={ref}
        {...props}
      />
    )
  })
)
