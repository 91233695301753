import { Model, Attr, BelongsTo, HasOne } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Action } from './Action'
import { Commitment } from './Commitment'
import { PersonalChecklistItem } from './PersonalChecklistItem'

@Model()
export class ChecklistItem extends ApplicationRecord {
  static jsonapiType = 'actions_checklist_items'

  static endpoint = '/actions/checklist-items'

  @Attr({ persist: false }) order!: number

  @Attr({ persist: false }) description!: string

  @BelongsTo(Action) action!: Action

  @BelongsTo(Commitment) commitment!: Commitment

  @HasOne(PersonalChecklistItem) personalChecklistItem!: PersonalChecklistItem

  constructor() {
    super()

    this.id = ''
  }
}
