/* istanbul ignore file */
import { StyleSheet, TextStyle, ImageStyle } from 'react-native'
import { STYLES } from 'styles'

export const useSharedStyles = () => {
  const { bp, spacing } = STYLES.useStyles()

  let imageWidth = 160

  if (bp.is([bp.mobile_xl])) {
    imageWidth = 200
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    imageWidth = 300
  }

  const textContainer = {
    textAlign: 'center',
    maxWidth: STYLES.grid.maxBoxWidth,
    marginBottom: spacing.l
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    textContainer.marginBottom = spacing.xl
  }

  if (bp.desktop) {
    textContainer.textAlign = 'left'
  }

  const logo = {
    width: 142,
    height: 35,
    marginBottom: spacing.l
  } as ImageStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    logo.width = 192
    logo.height = 46
  }

  if (bp.tablet) {
    logo.marginBottom = spacing.xl
  }

  if (bp.desktop) {
    logo.marginBottom = spacing.xxl
  }

  const sharedStyles = StyleSheet.create({
    textContainer,
    logo
  })

  return { sharedStyles, imageWidth }
}
