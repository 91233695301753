import { StyleSheet, ViewStyle } from 'react-native'

export const useStyles = () => {
  const container = {
    flexGrow: 1
  } as ViewStyle

  const rightImage = { marginBottom: 0 } as ViewStyle

  const styles = StyleSheet.create({
    container,
    rightImage
  })

  return { styles }
}
