// https://docs.expo.dev/versions/latest/react-native/textinput/#keyboardtype

import { TextInputProps } from 'react-native'

export default {
  default: 'default',
  email: 'email-address',
  number: 'numeric',
  username: 'email-address'
} as { [key: string]: TextInputProps['keyboardType'] }
