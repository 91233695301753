import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { COLORS, focused, disabled, fontSemiBold } = STYLES.useStyles()

  const row = {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    position: 'relative'
  } as ViewStyle

  const touchableArea = {
    paddingHorizontal: getREM(0.5625)
  } as ViewStyle

  const gutter = {
    backgroundColor: COLORS.steam,
    height: getREM(1.5625),
    width: getREM(3.125),
    borderRadius: getREM(0.78125)
  } as ViewStyle

  const thumb = {
    backgroundColor: COLORS.aubergine,
    height: getREM(1.5625),
    width: getREM(1.5625),
    borderRadius: getREM(0.78125)
  } as ViewStyle

  const text = {
    ...fontSemiBold,
    color: COLORS.doveGrey,
    textTransform: 'capitalize',
    lineHeight: STYLES.lineHeight.s,
    fontSize: STYLES.fontSize.s
  } as TextStyle

  const activeText = {
    ...fontSemiBold,
    color: COLORS.aubergine,
    textTransform: 'capitalize',
    lineHeight: STYLES.lineHeight.s,
    fontSize: STYLES.fontSize.s
  } as TextStyle

  const button = {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderColor: 'transparent',
    padding: 0,
    margin: 0,
    width: '100%',
    ...Platform.select({
      web: {
        cursor: 'pointer'
      }
    })
  } as ViewStyle

  const button_focused = {
    ...focused,
    outlineOffset: getREM(0.25),
    borderRadius: getREM(0.25)
  } as ViewStyle

  const styles = StyleSheet.create({
    row,
    gutter,
    thumb,
    touchableArea,
    text,
    activeText,
    button,
    button_focused,
    disabled
  })

  return { styles }
}
