import React, { memo, useState, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { useStyles } from './styles'
import { spacing } from 'styles/STYLES/Spacing'
import {
  ProProgressGroupController,
  IProProgressGroupController
} from './ProProgressGroupController'
import { IController, LinkContext, ReplaceParam } from 'utils'

import {
  ActivityIndicator,
  Button,
  ButtonType,
  Heading,
  HorizontalBarChart,
  Text,
  Toggle,
  ValueBox,
  View,
  AchievementItem,
  MediaObject,
  ProHeader,
  RoundedNavigation,
  RoundedNavigationItemProps,
  SvgImage
} from 'components'

export enum ProProgressGroupType {
  Organization = 'organization',
  Team = 'team'
}

export interface ProProgressGroupProps {
  controller?: IController<IProProgressGroupController>
  id: string
  navItems?: RoundedNavigationItemProps[]
  type: ProProgressGroupType
}

const TOGGLE_STATES = {
  TRYING: false,
  DONE: true
}

export const ProProgressGroup = memo(
  ({
    controller = new ProProgressGroupController(),
    id,
    navItems,
    type
  }: ProProgressGroupProps) => {
    useLang()
    const { t } = useTranslation()
    const { styles } = useStyles()

    const { data, isLoading } = controller.useController({
      id,
      type
    })

    const { leaderboardLinkUrl, teamLeaderboardLinkUrl, stepsPageLinkUrl } =
      useContext(LinkContext)

    const [stepsProgressToggle, setStepsProgressToggle] = useState(
      TOGGLE_STATES.TRYING
    )

    const [achievementsProgressToggle, setAchievementsProgressToggle] =
      useState(TOGGLE_STATES.TRYING)

    const [breakdownIsOpen, toggleBreakdown] = useState(false)

    const breakdownItems = Object.keys(
      data?.metaCo2eReductionSections || {}
    )?.map((key: string) => ({
      label: t(`proProgressGroup:${key}`),
      value:
        /* istanbul ignore next */ data?.metaCo2eReductionSections[key] || 0,
      unit: 'kg',
      id: key === 'trees' ? 'tree' : undefined
    }))

    const _leaderboardLinkUrl = useMemo(() => {
      /* istanbul ignore next */
      if (!leaderboardLinkUrl || !teamLeaderboardLinkUrl || !id) {
        return undefined
      }

      if (type === ProProgressGroupType.Team) {
        return ReplaceParam(teamLeaderboardLinkUrl, id)
      }

      return ReplaceParam(leaderboardLinkUrl, id)
    }, [id, leaderboardLinkUrl, teamLeaderboardLinkUrl, type])

    const _hasNavItems = !!navItems && navItems.length > 0

    const AlarmImage: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/AlarmClock.svg')} {...props} />
    ))
    const BottleImage: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/Bottle.svg')} {...props} />
    ))
    const CalendarImage: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/Calendar.svg')} {...props} />
    ))
    const FootprintGlobe: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/FootprintGlobe.svg')} {...props} />
    ))
    const Trophy: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/Trophy.svg')} {...props} />
    ))
    const Mountain: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/Mountain.svg')} {...props} />
    ))
    const Tap: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/Tap.svg')} {...props} />
    ))
    const Co2Cloud: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/Co2Cloud.svg')} {...props} />
    ))

    if (isLoading) {
      return <ActivityIndicator />
    }

    if (!data) return null

    return (
      <>
        <ProHeader
          backgroundImage={{ uri: data?.banner }}
          logoImage={{ uri: data?.logo }}
          name={data?.name}
          testID="ProProgressGroup"
        />
        <View style={styles.orgProgressContainer}>
          {_hasNavItems && (
            <RoundedNavigation items={navItems} style={styles.navContainer} />
          )}

          <View style={styles.stepsContainer}>
            <Heading level={2} style={styles.stepHeading}>
              {t('proProgressGroup:steps')}
            </Heading>

            <Text
              textStyle={styles.stepCount}
              containerStyle={styles.stepCountContainer}>
              {stepsProgressToggle === TOGGLE_STATES.TRYING
                ? data?.metaCommitments?.pending.total?.toLocaleString()
                : data?.metaCommitments?.successful?.total?.toLocaleString()}
            </Text>
            <Text
              textStyle={styles.totalStepsText}
              containerStyle={styles.totalStepsTextContainer}>
              {stepsProgressToggle === TOGGLE_STATES.TRYING
                ? t('proProgressGroup:totalStepsCommitted')
                : t('proProgressGroup:totalStepsDone')}
            </Text>
          </View>

          <View style={styles.valueBoxContainer} spacer={spacing.l}>
            <ValueBox
              value={
                stepsProgressToggle === TOGGLE_STATES.TRYING
                  ? data?.metaCommitments?.pending['7_days_total']
                  : data?.metaCommitments?.successful['7_days_total']
              }
              label={
                stepsProgressToggle === TOGGLE_STATES.TRYING
                  ? t('proProgressGroup:stepsCommittedThisWeek')
                  : t('proProgressGroup:stepsDoneThisWeek')
              }
              Image={AlarmImage}
              style={styles.valueBox}
              imageSize={60}
            />
            <ValueBox
              value={
                stepsProgressToggle === TOGGLE_STATES.TRYING
                  ? data?.metaCommitments?.pending['30_days_total']
                  : data?.metaCommitments?.successful['30_days_total']
              }
              label={
                stepsProgressToggle === TOGGLE_STATES.TRYING
                  ? t('proProgressGroup:stepsCommittedThisMonth')
                  : t('proProgressGroup:stepsDoneThisMonth')
              }
              Image={CalendarImage}
              style={styles.valueBox}
              imageSize={60}
            />
          </View>

          <Toggle
            accessibilityLabel={t('proProgressGroup:toggleSteps')}
            testID="toggle-steps"
            falseLabel={t('proProgressGroup:trying')}
            trueLabel={t('proProgressGroup:done')}
            value={stepsProgressToggle}
            onChange={() => setStepsProgressToggle(!stepsProgressToggle)}
            style={styles.progressToggle}
          />

          {stepsPageLinkUrl && (
            <Button
              label={t('proProgressGroup:viewAllSteps')}
              type={ButtonType.brand}
              href={stepsPageLinkUrl}
              containerStyle={styles.stepsButtonContainer}
            />
          )}

          <View style={styles.achievementsGroup}>
            <Heading level={2}>{t('proProgressGroup:achievements')}</Heading>

            <View style={styles.achievements}>
              {achievementsProgressToggle === TOGGLE_STATES.TRYING ? (
                <>
                  <AchievementItem
                    imageSize={100}
                    Image={Co2Cloud}
                    achievementValue={data?.metaReductions?.pending?.co2e}
                    achievementValueUnit="kg"
                    achievementLabel={t('proProgressGroup:committedCo2')}
                  />

                  <AchievementItem
                    imageSize={100}
                    Image={Tap}
                    achievementValue={data?.metaReductions?.pending.water}
                    achievementValueUnit="litres"
                    achievementLabel={t('proProgressGroup:committedWater')}
                  />

                  <AchievementItem
                    imageSize={100}
                    Image={Mountain}
                    achievementValue={data?.metaReductions?.pending.land}
                    achievementValueUnit="m2"
                    achievementLabel={t('proProgressGroup:committedLand')}
                  />

                  <AchievementItem
                    imageSize={100}
                    Image={BottleImage}
                    achievementValue={data?.metaReductions?.pending.plastic}
                    achievementValueUnit="items"
                    achievementLabel={t('proProgressGroup:committedPlastic')}
                  />
                </>
              ) : (
                <>
                  <AchievementItem
                    imageSize={100}
                    Image={Co2Cloud}
                    achievementValue={data?.metaReductions?.successful?.co2e}
                    achievementValueUnit="kg"
                    achievementLabel={t('proProgressGroup:successfulCo2')}
                  />

                  <AchievementItem
                    imageSize={100}
                    Image={Tap}
                    achievementValue={data?.metaReductions?.successful.water}
                    achievementValueUnit="litres"
                    achievementLabel={t('proProgressGroup:successfulWater')}
                  />

                  <AchievementItem
                    imageSize={100}
                    Image={Mountain}
                    achievementValue={data?.metaReductions?.successful.land}
                    achievementValueUnit="m2"
                    achievementLabel={t('proProgressGroup:successfulLand')}
                  />

                  <AchievementItem
                    imageSize={100}
                    Image={BottleImage}
                    achievementValue={data?.metaReductions?.successful.plastic}
                    achievementValueUnit="items"
                    achievementLabel={t('proProgressGroup:successfulPlastic')}
                  />
                </>
              )}
            </View>
          </View>

          <Toggle
            accessibilityLabel="toggle-achievements"
            falseLabel={t('proProgressGroup:trying')}
            trueLabel={t('proProgressGroup:done')}
            value={achievementsProgressToggle}
            onChange={() =>
              setAchievementsProgressToggle(!achievementsProgressToggle)
            }
            style={styles.progressToggle}
            testID="toggle-achievements"
          />

          <View style={styles.footprintSection}>
            <View style={styles.mediaObjectContainer}>
              <Heading level={2} style={styles.alignCenter}>
                {t('proProgressGroup:footprint')}
              </Heading>

              <MediaObject
                Image={FootprintGlobe}
                value={data?.kgCo2eEmissions}
                valueUnit="kg"
                label={t('proProgressGroup:averageCarbonFootprint')}
              />

              <Button
                onPress={() => toggleBreakdown(!breakdownIsOpen)}
                fullWidth
                containerStyle={styles.footPrintButton}
                type={ButtonType.brand}
                testID="toggle-breakdown"
                accessibilityLabel="toggle-breakdown">
                {!breakdownIsOpen
                  ? t('proProgressGroup:viewBreakdown')
                  : t('proProgressGroup:hideBreakdown')}
              </Button>

              {breakdownIsOpen && (
                <HorizontalBarChart
                  items={breakdownItems}
                  style={styles.barChartContainer}
                  testID="breakdown-chart"
                />
              )}
            </View>

            <View style={styles.mediaObjectContainer}>
              <Heading level={2} style={styles.alignCenter}>
                {t('proProgressGroup:score')}
              </Heading>

              <MediaObject
                Image={Trophy}
                value={data?.score}
                label={t('proProgressGroup:averageGikiScore')}
              />

              {_leaderboardLinkUrl && (
                <Button
                  fullWidth
                  containerStyle={styles.footPrintButton}
                  type={ButtonType.brand}
                  href={_leaderboardLinkUrl}>
                  {t('proProgressGroup:viewLeaderboard')}
                </Button>
              )}
            </View>
          </View>
        </View>
      </>
    )
  }
)
