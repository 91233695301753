import React, { useContext, useState, useEffect, useMemo, useCallback, memo, useRef } from 'react'
import { PageProps } from '../index'
import {
  Text,
  SpeechBubble,
  Select,
  Form,
  FieldSet,
  ActivityIndicator
} from 'components'
import {
  Layout,
  Column,
  useSharedLang,
  NextButton,
  Heading,
  IllustrationBackground,
  useSharedStyles,
  ProgressBar
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { ViewProps } from 'react-native'
import { STYLES } from 'styles'
import { Globe, GlobeHeightRatio } from 'images/Globe'
import { useQuery } from 'react-query'
import { ArraySorter } from 'utils'

type SelectProps = React.ComponentProps<typeof Select>

type OptionType = {
  id: string
  label: string
}

export const Country = memo(({ navigation }: PageProps) => {
  useSharedLang()
  useLang()

  const { t } = useTranslation()
  const { progress, getCountries, getRegion, getRegionsByCountryCount, saveRegion, person, globalId } = useContext(OnboardingGroupContext)
  const { spacing, bp } = STYLES.useStyles()
  const { sharedStyles, imageWidth } = useSharedStyles()
  const [_selectedId, _setSelectedId] = useState<SelectProps['selectedId']>()
  const _showRegions = useRef(false)
  const [_isUpdating, _setIsUpdating] = useState(false)
  const [_selectedRegionId, _setSelectedRegionId] = useState<string | undefined>()
  const { data: _countryData, isLoading } = useQuery(['onboarding', 'countries'], getCountries)
  const [_showError, _setShowError] = useState(false)

  const _countries = useMemo(() => {
    const _items: OptionType[] = _countryData?.map((country) => ({
      id: country.id!,
      label: country.name
    })) || []

    _items.push({
      id: globalId,
      label: t('onboardingCountry:globalLabel')
    })

    _items.sort((item1, item2) => ArraySorter(item1, item2, 'label', 'asc'))

    return _items
  }, [_countryData, globalId, t])

  const _isDisabled = typeof _selectedId === 'undefined'

  const _onPress = async () => {
    _setShowError(false)

    if (_showRegions.current && _selectedId) {
      // multiple regions
      navigation.navigate('State', {
        countryId: _selectedId
      })
      return
    }

    const _region = _selectedId === globalId ? null : _selectedRegionId

    /* istanbul ignore next */
    if (typeof _region !== 'undefined') {
      _setIsUpdating(true)
      const _success = await saveRegion(_region)
      _setIsUpdating(false)
      if (_success) {
        navigation.navigate('Car')
        return
      }
    }

    _setShowError(true)
  }

  const _onSelect: SelectProps['onSelect'] = useCallback(
    async (countryId) => {
      const { count, data } = await getRegionsByCountryCount(countryId)

      // even though the else if is triggered in the tests
      // the coverage checker still complains
      /* istanbul ignore else */
      if (count === 1 && data?.[0]) {
        _setSelectedRegionId(data[0].id)
        _showRegions.current = false
      } else if (count > 1) {
        _showRegions.current = true
      }

      _setSelectedId(countryId)
    },
    [getRegionsByCountryCount]
  )

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t(
          'onboardingShared:speechBubbleCategories.whyWeAreAsking'
        )}
        text={t('onboardingCountry:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageHeight = imageWidth * GlobeHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <Globe
          style={{
            width: imageWidth,
            height: _imageHeight,
            marginTop: 0.07692307692307693 * _imageHeight
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, imageWidth]
  )

  const _getRegion = useCallback(async () => {
    if (_selectedId || !person?.locationsRegionId) return

    const _region = await getRegion?.(person.locationsRegionId)

    const _countryId = _region?.country.id

    /* istanbul ignore next */
    if (_countryId) {
      _onSelect(_countryId)
    }
  }, [_onSelect, _selectedId, getRegion, person])

  useEffect(() => {
    void _getRegion()
  }, [person, _getRegion])

  useEffect(() => {
    if (_showError) _setShowError(false)
  }, [_showError])

  if (_isUpdating || isLoading) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Country" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingCountry:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.Country) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingCountry:title')}</Heading>
          <Text containerStyle={sharedStyles.textContainer}>{t('onboardingCountry:text')}</Text>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <FieldSet legend={t('onboardingCountry:title')}>
              <Select
                items={_countries}
                selectedId={_selectedId}
                onSelect={_onSelect}
                label={t('onboardingCountry:fieldLabel')}
                emptyLabel={t('onboardingCountry:fieldEmptyLabel')}
                hideLabel
              />
            </FieldSet>
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton onPress={_onPress} state={{
            disabled: _isDisabled
          }} />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
