import {
  StyleSheet,
  ViewStyle,
  Platform,
  TextStyle,
  DimensionValue
} from 'react-native'
import { STYLES, getREM } from 'styles'

export interface Props {
  hideLabel: boolean
}

export const useStyles = ({ hideLabel }: Props) => {
  const { spacing, colors, fontRegular, bp, boxShadows } = STYLES.useStyles()

  let _itemSize = 2.5

  if (hideLabel) {
    _itemSize = 1.875
  }

  const _borderSize = 0.125
  const _iconSize = _itemSize - _borderSize * 2
  let _spacing = spacing.xxs

  if (hideLabel) {
    _spacing = spacing.xs

    if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
      _spacing = spacing.xxs
    }
  }

  const paging: ViewStyle = {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: spacing.xs,
    marginRight: (Platform.OS === 'web'
      ? `-${_spacing}`
      : -_spacing) as DimensionValue,
    marginBottom: (Platform.OS === 'web'
      ? `-${_spacing}`
      : -_spacing) as DimensionValue,
    marginLeft: (Platform.OS === 'web'
      ? `-${_spacing}`
      : -_spacing) as DimensionValue,
    alignItems: 'center',
    justifyContent: 'center'
  }

  const pagingItemNativeContainer: ViewStyle = {
    ...boxShadows.button.ios
  }

  const pagingItem: TextStyle = {
    ...fontRegular,
    width: getREM(_itemSize),
    height: getREM(_itemSize),
    borderRadius: getREM(_itemSize / 2),
    borderColor: colors.brand,
    borderWidth: getREM(_borderSize),
    borderStyle: 'solid',
    backgroundColor: colors.brandContrast,
    color: colors.text,
    overflow: 'hidden',
    margin: _spacing,
    fontSize: STYLES.fontSize.normal,
    ...boxShadows.button.web,
    ...boxShadows.button.android,
    textAlign: 'center',
    lineHeight: getREM(_iconSize)
  }

  if (Platform.OS === 'android') {
    pagingItem.lineHeight = getREM(_itemSize)
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m])) {
    pagingItem.fontSize = STYLES.fontSize.s
  }

  const pagingItem_current: TextStyle = {
    backgroundColor: colors.brand,
    borderColor: colors.brand,
    color: colors.brandContrast
  }

  const pagingItem_pointerOver: TextStyle = {
    ...boxShadows.button_pointerOver.web
  }

  const ellipsis: TextStyle = {
    ...fontRegular,
    color: colors.text,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  }

  const icon: ViewStyle = {
    width: getREM(_iconSize / 2),
    height: getREM(_iconSize / 2)
  }

  if (Platform.OS === 'ios') {
    icon.marginTop = getREM(_iconSize / 4)
  }

  const iconContainer: ViewStyle = {
    width: getREM(_iconSize),
    height: getREM(_iconSize),
    justifyContent: 'center'
  }

  const styles = StyleSheet.create({
    paging,
    pagingItemNativeContainer,
    pagingItem,
    pagingItem_current,
    pagingItem_pointerOver,
    ellipsis,
    icon,
    iconContainer
  })

  return { styles }
}
