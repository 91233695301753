import { useContext } from 'react'
import { StyleSheet, TextStyle, Platform, ViewStyle } from 'react-native'
import { HeaderContext } from 'components'
import { STYLES, getREM } from 'styles'
import { useWindowDimensions } from 'utils'

export const useStyles = () => {
  const { textColor } = useContext(HeaderContext)
  const { colors, bp, spacing, fontBold } = STYLES.useStyles()
  const { width } = useWindowDimensions()

  const value: TextStyle = {
    ...fontBold,
    color: colors.brand,
    fontSize: STYLES.fontSize.xxl,
    lineHeight: STYLES.lineHeight.xxl
  }

  if (bp.tablet) {
    value.fontSize = getREM(2.5)
    value.lineHeight = getREM(2.5 * 1.5)
  }

  if (bp.desktop) {
    value.fontSize = getREM(3)
    value.lineHeight = getREM(3 * 1.5)
  }

  const label: TextStyle = {
    ...fontBold,
    color: textColor || colors.brand,
    fontSize: STYLES.fontSize.l,
    lineHeight: STYLES.lineHeight.l,
    textAlign: 'center'
  }

  if (bp.is([bp.mobile_l, bp.mobile_xl, bp.tablet, bp.desktop])) {
    label.fontSize = STYLES.fontSize.xl
    label.lineHeight = STYLES.lineHeight.xl
    label.marginTop = (Platform.OS === 'web' ? `-${spacing.normal}` : -spacing.normal) as unknown as TextStyle['marginTop']
  }

  const imageContainer: ViewStyle = {
    height: Math.min(222, width * 0.5006875)
  }

  if (bp.desktop) {
    imageContainer.height = Math.min(250, width * 0.5006875)
  }

  const styles = StyleSheet.create({
    container: {
      maxWidth: STYLES.grid.maxBoxWidth,
      width: '100%',
      alignSelf: 'center',
      flex: 0
    },
    imageContainer,
    image: {},
    valueContainer: {
      ...StyleSheet.absoluteFillObject,
      top: '17%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    value,
    label
  })

  return { styles }
}
