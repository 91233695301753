import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'
import { useStyles as useStylesFullScreenDialog } from 'components/FullScreenDialog/styles'
import { CommitmentModalType } from '../index'

interface UseStylesProps {
  modalType: CommitmentModalType
}

export const useStyles = ({ modalType }: UseStylesProps) => {
  const { bp, spacing, COLORS, fontBold, fontRegular, colors, boxShadows } = STYLES.useStyles()
  const { styles: FullScreenDialogStyles } = useStylesFullScreenDialog()

  const modalButtons: ViewStyle = {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center'
  }

  const modalButtonsLeft: ViewStyle = {}

  const modalButtonsRight: ViewStyle = {}

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m, bp.mobile_l])) {
    modalButtons.flexDirection = 'column'

    modalButtonsRight.marginTop = spacing.l
  }

  if (bp.not([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m, bp.mobile_l])) {
    modalButtons.width = '100%'

    modalButtonsLeft.width = '50%'
    modalButtonsLeft.paddingRight = spacing.normal
    modalButtonsLeft.alignItems = 'flex-end'

    modalButtonsRight.width = '50%'
    modalButtonsRight.paddingLeft = spacing.normal
    modalButtonsRight.alignItems = 'flex-start'
  }

  const scoreContainer: ViewStyle = {
    alignItems: 'center',
    marginBottom: spacing.l,
    flexDirection: 'row'
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m])) {
    scoreContainer.flexDirection = 'column'
  }

  const scorePrefixText: TextStyle = {
    ...fontRegular,
    color: colors.text,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  }

  const scoreText: TextStyle = {
    ...fontBold,
    color: COLORS.cherry,
    fontSize: STYLES.fontSize.xl,
    lineHeight: STYLES.lineHeight.xl
  }

  const modalContentHeading: TextStyle = {
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: spacing.xl
  }

  const recommendedStepsItemContainer: ViewStyle = {
    width: '100%'
  }

  if (bp.not([bp.tablet, bp.desktop])) {
    recommendedStepsItemContainer.paddingLeft = spacing.normal
    recommendedStepsItemContainer.paddingRight = spacing.normal
  }

  const recommendedStepsItem: ViewStyle = {
    ...boxShadows.none,
    borderColor: COLORS.iron,
    borderStyle: 'solid',
    borderWidth: getREM(0.125)
  }

  const recommendedStepsCarousel: ViewStyle = {
    marginLeft: (Platform.OS === 'web'
      ? `calc(-1 * (${FullScreenDialogStyles.background.paddingLeft} + ${spacing.normal}))`
      : -1 * (FullScreenDialogStyles.background.paddingLeft as number + spacing.normal as number)) as unknown as ViewStyle['marginLeft'],
    marginRight: (Platform.OS === 'web'
      ? `calc(-1 * (${FullScreenDialogStyles.background.paddingRight} + ${spacing.normal}))`
      : -1 * (FullScreenDialogStyles.background.paddingRight as number + spacing.normal as number)) as unknown as ViewStyle['marginRight'],
    marginBottom: spacing.xl
  }

  const benefits: ViewStyle = {
    width: '100%',
    marginBottom: spacing.xl
  }

  const modalCardStyle: ViewStyle = {
    maxWidth: (Platform.OS === 'web' ? `calc(${STYLES.grid.maxTextWidth} + (${spacing.normal} * 2))` : STYLES.grid.maxTextWidth + (spacing.normal * 2)) as unknown as ViewStyle['maxWidth'],
    paddingLeft: spacing.normal,
    paddingRight: spacing.normal,
    paddingBottom: spacing.xl
  }

  if (modalType === CommitmentModalType.pending) {
    modalCardStyle.maxWidth = STYLES.grid.maxBoxWidth
    modalCardStyle.paddingLeft = spacing.xl
    modalCardStyle.paddingRight = spacing.xl
  }

  const title: TextStyle = {
    marginBottom: 0,
    textAlign: 'center'
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m])) {
    title.marginBottom = spacing.xs
  }

  const styles = StyleSheet.create({
    modalButtons,
    modalButtonsLeft,
    modalButtonsRight,
    scoreContainer,
    scorePrefixText,
    scoreText,
    modalContentHeading,
    recommendedStepsItemContainer,
    recommendedStepsItem,
    recommendedStepsCarousel,
    benefits,
    modalCardStyle,
    title
  })

  return { styles }
}
