import { Action, Commitment, CommitmentState, User } from 'models'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

export interface Props {
  actionParam: string
  isAuthenticated: boolean
}

export class StepDetailsGroupController {
  useController = ({ actionParam, isAuthenticated }: Props) => {
    const { i18n } = useTranslation()

    const _getData = async () => {
      let region_id = 'global'

      if (isAuthenticated) {
        const { data: user } = await User.includes(['person']).select({ users: [''], people: ['locationsRegionId'] }).find('current')

        if (user?.person?.locationsRegionId) {
          region_id = user.person.locationsRegionId
        }
      }

      const { data: action } = await Action
        .where({ param: actionParam, region_id })
        .includes([
          { 'themes': 'theme' },
          'informationItems',
          'resources',
          'facts',
          'benefits',
          'tips',
          'impact',
          'ease',
          'checklistItems'
        ])
        .select({
          actions: [
            'name',
            'improvements',
            'themes',
            'slug',
            'summary',
            'description',
            'tags',
            'param'
          ],
          themes: [
            'name',
            'lookupSlug',
            'colorForeground',
            'colorBackground'
          ],
          impacts: [
            'name',
            'description',
            'rating',
            'lookupSlug',
            'colorForeground',
            'colorBackground'
          ],
          eases: [
            'name',
            'description',
            'rating'
          ],
          actionsInformationItems: [
            'description'
          ],
          actionsBenefits: [
            'description'
          ],
          actionsTips: [
            'description'
          ],
          actionsResources: [
            'category',
            'order',
            'title',
            'url'
          ],
          actionsChecklistItems: [
            'description',
            'order'
          ]
        })
        .selectExtra(['complete_commitments_count', 'pending_commitments_count'])
        .extraParams({ locale: i18n.language })
        .first()

      let commitment

      if (isAuthenticated && action?.id) {
        const { data: commitementData } = await Commitment
        .where({ action_id: action!.id })
        .select(['state'])
        .extraParams({ paginate: false })
        .all()

        commitment = commitementData?.find((item) => item.state !== CommitmentState.abandoned)
      }

      return { action, commitment }
    }

    const { data, isLoading: actionLoading } = useQuery(
      ['data', 'action', 'details', actionParam, i18n.language, isAuthenticated],
      _getData,
      {
        enabled: !!actionParam
      }
    )

    return {
      action: data?.action,
      commitment: data?.commitment,
      isLoading: actionLoading
    }
  }
}
