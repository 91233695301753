import React, { memo } from 'react'
import {
  View,
  ViewProps
} from 'react-native'
import { CategoryPill, SVG, Text, SvgImage } from 'components'
import { useStyles } from './styles'
import { STYLES } from 'styles'

export interface Props extends ViewProps {
  categoryLabel: string
  text?: string
}

export const SpeechBubble = memo(({ categoryLabel, text, style, children, ...props }: Props) => {
  const { styles } = useStyles()
  const { COLORS } = STYLES.useStyles()

  const _hasText = !!text

  const KeyMan: typeof SvgImage = memo((props) => <SvgImage image={require('images/KeyMan.svg')} {...props} />)

  return (
    <View style={[styles.container, style]} testID="SpeechBubble" {...props}>
      <View style={[styles.bubble, { backgroundColor: COLORS.paper }]}>
        <CategoryPill label={categoryLabel} backgroundColor={COLORS.studio} textColor={COLORS.white} containerStyle={styles.pill} />
        {_hasText && (
          <View style={styles.textContainer}>
            <Text>{text}</Text>
          </View>
        )}
        {children}
        <SVG.Svg viewBox="0 0 35 30.29" style={styles.triangle}>
          <SVG.Polygon fill={COLORS.paper} points="0 0, 35 0, 17.5 30.29, 0 0" />
        </SVG.Svg>
      </View>
      <View style={[styles.characterContainer, {
        backgroundColor: COLORS.hawkesBlue
      }]}>
        <KeyMan style={styles.character} />
      </View>
    </View>
  )
})
