import React, { useContext, memo } from 'react'
import { Text, StyleProp, ViewStyle } from 'react-native'
import { MassValue, View, Icons, HeaderContext } from 'components'
import { useTranslation } from 'react-i18next'
import { STYLES } from 'styles'
import { useLang } from './Lang'
import { useStyles } from './styles'

export interface Props {
  shortTermTarget: number
  longTermTarget: number
  remainder: number
  containerStyle?: StyleProp<ViewStyle>
}

export const CarbonFootprintTargets = memo(({
  shortTermTarget,
  longTermTarget,
  remainder,
  containerStyle
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { colors, spacing } = STYLES.useStyles()
  const { textColor } = useContext(HeaderContext)

  const _isOver = remainder < 0

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.shortTermTarget} spacer={spacing.l}>
        <View style={styles.shortTermTargetItem}>
          <Text
            textBreakStrategy="simple"
            style={styles.shortTermTargetItemTitle}>
            {t('carbonFootprintTargets:shortTermTarget.title')}
          </Text>
          <MassValue value={shortTermTarget} />
          <Text
            textBreakStrategy="simple"
            style={styles.shortTermTargetItemText}>
            {t('carbonFootprintTargets:shortTermTarget.text')}
          </Text>
        </View>
        <View style={styles.shortTermTargetItem}>
          <Text
            textBreakStrategy="simple"
            style={[
              styles.shortTermTargetItemTitle,
              _isOver && styles.shortTermTargetItemTitleAlt
            ]}>
            {_isOver
              ? t('carbonFootprintTargets:remainder.titleAlt')
              : t('carbonFootprintTargets:remainder.title')}
          </Text>
          <View style={styles.shortTermValueTargetItemGroup}>
            {_isOver && (
              <Icons.ThumbUp
                color={textColor || colors.positive}
                style={styles.shortTermTargetItemIcon}
              />
            )}
            <MassValue value={Math.abs(remainder)} />
          </View>
          <Text
            textBreakStrategy="simple"
            style={styles.shortTermTargetItemText}>
            {t('carbonFootprintTargets:remainder.text')}
          </Text>
        </View>
      </View>
      <View style={styles.longTermTarget}>
        <Text textBreakStrategy="simple" style={styles.longTermTargetText}>
          <Text style={styles.longTermTargetText}>
            {t('carbonFootprintTargets:longTermTarget.text')}
          </Text>{' '}
          <MassValue
            value={longTermTarget}
            valueStyle={styles.longTermTargetText}
            unitStyle={styles.longTermTargetText}
          />
        </Text>
      </View>
    </View>
  )
})
