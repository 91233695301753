import React, { memo } from 'react'
import { View, Text, StyleProp, ViewStyle } from 'react-native'
import { SvgImage } from 'components'
import { useStyles } from './styles'

export interface Props {
  value: number
  label: string
  style?: StyleProp<ViewStyle>
}

export const StepsValueHero = memo(({ value, label, style }: Props) => {
  const { styles } = useStyles()

  return (
    <View style={[styles.container, style]} testID="StepsValueHero" accessibilityRole="header" accessibilityLabel={`${value} ${label}`}>
      <View style={styles.imageContainer}>
        <SvgImage image={require('./MainImage.svg')} style={styles.image} {...{ 'aria-hidden': true }} />
        <View style={styles.valueContainer}>
          <Text textBreakStrategy="simple" style={styles.value}>
            {value}
          </Text>
        </View>
      </View>
      <Text textBreakStrategy="simple" style={styles.label}>
        {label}
      </Text>
    </View>
  )
})
