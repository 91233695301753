import React, { memo } from 'react'
import { Text, StyleProp, ViewStyle } from 'react-native'
import { View, Heading, Button, ButtonType, Icons } from 'components'
import { STYLES } from 'styles'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { useOrdinals } from 'utils'

export interface Props {
  containerStyle?: StyleProp<ViewStyle>
  title?: string
  linkUrl?: string
  linkLabel?: string
  score: number
  organizationName?: string
  rank?: number
}

export const ScoreHighlights = memo(({
  containerStyle,
  title,
  linkLabel,
  linkUrl,
  score,
  organizationName,
  rank
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { suffix } = useOrdinals()
  const { spacing } = STYLES.useStyles()

  const _hasTitle = !!title
  const _hasOrganizationRank = !!organizationName && !!rank
  const _hasLink = !!linkLabel && !!linkUrl

  return (
    <View style={[styles.container, containerStyle]}>
      {_hasTitle && (
        <Heading level={2} style={styles.title}>
          {title}
        </Heading>
      )}
      <View style={styles.boxGroup} spacer={spacing.normal}>
        <Text textBreakStrategy="simple" style={styles.score}>
          {t('format:number', { value: score })}
        </Text>
        {_hasOrganizationRank && (
          <View spacer={spacing.normal} style={styles.pillGroup}>
            <View style={styles.pillItem}>
              <View style={styles.pill}>
                <View style={styles.pillIcon}>
                  <View style={styles.pillIcon_stars}>
                    <Icons.Star style={styles.pillIcon_star} />
                    <Icons.Star style={styles.pillIcon_star} />
                    <Icons.Star style={styles.pillIcon_star} />
                  </View>
                </View>
                <Text textBreakStrategy="simple">
                  <Text textBreakStrategy="simple" style={styles.pillValue}>
                    {t('format:number', { value: rank })}
                  </Text>
                  <Text textBreakStrategy="simple" style={styles.pillUnit}>
                    {' '}
                    {suffix(rank)}
                  </Text>
                </Text>
              </View>
              <Text
                textBreakStrategy="simple"
                style={styles.pillLabel}
                numberOfLines={2}>
                {t('scoreHighlights:rank', { name: organizationName })}
              </Text>
            </View>
        </View>
        )}
      </View>
      {_hasLink && (
        <Button
          label={linkLabel}
          href={linkUrl}
          type={ButtonType.brand}
          containerStyle={styles.link}
        />
      )}
    </View>
  )
})
