import { useContext } from 'react'
import { ConfigurationContext } from './ConfigurationContext'
import { ConfigurationProps } from './ConfigurationProps'

const missingConfigurationContextWarning = "No configuration context has been registered."

export const useConfiguration:
  () => ConfigurationProps = 
  () => {
    const context = useContext<ConfigurationProps>(ConfigurationContext)

    if (!context) {
      throw new Error(missingConfigurationContextWarning)
    }

    return context
  }
