import React, { ComponentProps, memo, useMemo } from 'react'
import { Text } from 'react-native'
import { Pagination, View, ActivityIndicator } from 'components'
import { useStyles } from './styles'
import { LeaderboardAvatar } from './elements/Avatar'
import { LeaderboardBadge } from './elements/Badge'
import { LeaderboardPoints } from './elements/Points'
import { LeaderboardChange } from './elements/Change'
import {
  LeaderboardOptions as Options,
  LeaderboardOptionsProps
} from './elements/Options'
import {
  LeaderboardIndicators,
  LeaderboardIndicatorPosition
} from './elements/Indicators'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { LeaderboardTypes } from 'models'

export interface LeaderboardProps extends LeaderboardTypes.Leaderboard {
  sort?: LeaderboardOptionsProps['sort']
  filter?: LeaderboardOptionsProps['filter']
  onPaginationPress?: ComponentProps<typeof Pagination>['onPress']
  isLoading?: boolean
}

export const Leaderboard = memo(
  ({
    options,
    pagination,
    columns,
    rows,
    sort,
    filter,
    onPaginationPress,
    isLoading,
    downloadUrl
  }: LeaderboardProps) => {
    useLang()

    const { t } = useTranslation()
    const { styles } = useStyles()

    const _hasRows = rows && rows.length > 0

    const _topIndicators = useMemo(
      () =>
        options?.indicators?.filter(
          (item) => pagination?.current && item.page < pagination.current
        ),
      [options?.indicators, pagination]
    )

    const _bottomIndicators = useMemo(
      () =>
        options?.indicators?.filter(
          (item) => pagination?.current && item.page > pagination.current
        ),
      [options?.indicators, pagination]
    )

    const _filteredColumns = useMemo(
      () =>
        columns?.filter(
          (item) =>
            !item.filterable || item.filterable.id === filter?.selectedId
        ),
      [columns, filter]
    )

    const _renderCustomComponent = (
      cell: LeaderboardTypes.LeaderboardRowValues
    ) => {
      switch (cell.type || cell.id) {
        case 'avatar':
          return (
            <LeaderboardAvatar
              value={cell?.value?.toString()}
              showCrown={cell?.is_first}
              label={cell?.label}
            />
          )
        case 'badge_trying':
          return <LeaderboardBadge value={Number(cell.value)} type="trying" />
        case 'badge_completed':
          return (
            <LeaderboardBadge value={Number(cell.value)} type="completed" />
          )
        case 'score':
          return (
            <LeaderboardPoints value={Number(cell.value)} unit={cell?.unit} />
          )
        case 'change':
          return (
            <LeaderboardChange value={Number(cell.value)} unit={cell?.unit} />
          )
        case 'rank':
          return (
            <Text style={styles.rank} testID="LeaderboardRank">
              {t('format:number', { value: cell.value })}
            </Text>
          )
        default:
          return (
            <>
              <Text style={styles.rowCellText} textBreakStrategy="simple">
                {cell.value}
              </Text>
              {cell?.secondary_values?.map((value, index) => (
                <Text
                  key={index}
                  style={styles.rowCellSecondaryText}
                  textBreakStrategy="simple">
                  {value}
                </Text>
              ))}
            </>
          )
      }
    }

    return (
      <View style={styles.leaderboardWrapper} testID="Leaderboard">
        <Options sort={sort} filter={filter} downloadUrl={downloadUrl} />
        {options?.heading && (
          <Text style={styles.leaderboardHeading}>{options?.heading}</Text>
        )}

        {isLoading && (
          <View style={styles.loading}>
            <ActivityIndicator />
          </View>
        )}

        {!isLoading && !_hasRows && (
          <Text style={styles.emptyMessage} testID="LeaderboardEmptyMessage">
            {t('leaderboard:emptyMessage')}
          </Text>
        )}

        {!isLoading && _hasRows && (
          <>
            <View style={styles.columnHeadings} testID="LeaderboardHeadings">
              {_filteredColumns?.map(
                (column: LeaderboardTypes.LeaderboardColumn, index: number) => {
                  const activeSortColumn =
                    column.sortable?.id == sort?.selectedId

                  return (
                    <View
                      style={[
                        styles.columnHeading,
                        styles.individualHeadingStyle[column.id]
                      ]}
                      key={column.id}>
                      {activeSortColumn && (
                        <View style={styles.defaultSortColumnStyling} />
                      )}
                      <Text
                        style={[
                          styles.columnHeadingTitle,
                          index === 0 ? styles.columnHeadingTitleFirst : {}
                        ]}>
                        {column.label}
                      </Text>
                      {column?.sub_label && (
                        <Text style={styles.columnHeadingSubTitle}>
                          {column.sub_label}
                        </Text>
                      )}
                    </View>
                  )
                }
              )}
            </View>

            <View>
              {pagination?.visible && (
                <LeaderboardIndicators
                  items={_topIndicators}
                  position={LeaderboardIndicatorPosition.top}
                  onPress={onPaginationPress}
                />
              )}

              <View style={styles.rows} testID="LeaderboardRows">
                {rows?.map(
                  (row: LeaderboardTypes.LeaderboardRow, index: number) => {
                    const _isHighlighted = row.highlighted

                    const _isFinalRow =
                      !_isHighlighted && rows.length - 1 === index

                    return (
                      <View
                        style={[styles.row, _isFinalRow && styles.finalRow]}
                        key={index}>
                        {_isHighlighted && (
                          <View style={styles.highlightedRow} />
                        )}

                        {row?.rowValues?.map(
                          (cell: LeaderboardTypes.LeaderboardRowValues) => {
                            const _label = _filteredColumns?.find(
                              (column) => column.id === cell.id
                            )

                            const _isActiveSortColumn =
                              cell.sort_id == sort?.selectedId

                            if (
                              !!cell.filter_id &&
                              filter &&
                              cell.filter_id !== filter?.selectedId
                            ) {
                              return null
                            }

                            return (
                              <View
                                key={cell.id}
                                style={[
                                  styles.rowCell,
                                  styles.individualCellStyle[cell.id]
                                ]}>
                                {_isActiveSortColumn && (
                                  <View
                                    style={[
                                      styles.defaultSortColumnCellStyling,
                                      _isHighlighted &&
                                        styles.defaultSortColumnCellStyling_highlighted
                                    ]}
                                  />
                                )}

                                {_label?.label && (
                                  <View
                                    style={[
                                      styles.rowCellHeading,
                                      styles.individualRowCellHeading[cell.id]
                                    ]}>
                                    <Text style={[styles.rowCellHeadingTitle]}>
                                      {_label.label}
                                    </Text>
                                    {_label?.sub_label && (
                                      <Text
                                        style={styles.rowCellHeadingSubTitle}>
                                        {_label.sub_label}
                                      </Text>
                                    )}
                                  </View>
                                )}

                                <View style={styles.rowCellContent}>
                                  {_renderCustomComponent(cell)}
                                </View>
                              </View>
                            )
                          }
                        )}
                      </View>
                    )
                  }
                )}
              </View>

              {pagination?.visible && (
                <LeaderboardIndicators
                  items={_bottomIndicators}
                  position={LeaderboardIndicatorPosition.bottom}
                  onPress={onPaginationPress}
                />
              )}
            </View>
          </>
        )}

        {pagination?.visible && (
          <Pagination
            style={styles.pagination}
            currentItem={pagination.current - 1}
            totalItems={pagination.last}
            label={t('leaderboard:pagination')}
            showEllipsis
            isNumbered
            showBackForward
            onPress={(index) => onPaginationPress?.(index + 1)}
          />
        )}
      </View>
    )
  }
)
