import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Progress = memo(({
  size = getREM(1.5),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()
  return (
    <SVG.Svg
      testID="Progress"
      viewBox="0 0 18 19"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M8.14758 17.5278C11.9885 17.5278 16.8071 15.3629 16.9934 9.9623C17.2058 3.80287 12.3377 1.00012 8.38037 1.00012"
        stroke={color ?? colors.text}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <SVG.Circle
        cx="5.35399"
        cy="1.93126"
        r="0.931135"
        fill={color ?? colors.text}
      />
      <SVG.Circle
        cx="2.56065"
        cy="4.25914"
        r="0.931135"
        fill={color ?? colors.text}
      />
      <SVG.Circle
        cx="0.931135"
        cy="7.51805"
        r="0.931135"
        fill={color ?? colors.text}
      />
      <SVG.Circle
        cx="0.931135"
        cy="11.0099"
        r="0.931135"
        fill={color ?? colors.text}
      />
      <SVG.Circle
        cx="2.56065"
        cy="14.2688"
        r="0.931135"
        fill={color ?? colors.text}
      />
      <SVG.Circle
        cx="5.35399"
        cy="16.5967"
        r="0.931135"
        fill={color ?? colors.text}
      />
      <SVG.Path
        d="M5.35413 9.14746L8.03114 11.8245L12.454 7.51797"
        stroke={color ?? colors.text}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG.Svg>
  )
})
