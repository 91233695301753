import React, { memo } from 'react'
import { Heading, Text, Button, ButtonType, View } from 'components'
import { StyleProp, ViewStyle } from 'react-native'
import { useStyles } from './styles'

export interface Props {
  heading: string
  text: string
  linkLabel: string
  linkUrl: string
  style?: StyleProp<ViewStyle>
}

export const CTAMessage = memo(({
  heading,
  text,
  linkLabel,
  linkUrl,
  style
}: Props) => {
  const { styles } = useStyles()

  return (
    <View style={[styles.container, style]}>
      <Heading style={styles.heading} level={2}>
        {heading}
      </Heading>
      <Text containerStyle={styles.textContainer} textStyle={styles.text}>
        {text}
      </Text>
      <Button label={linkLabel} href={linkUrl} type={ButtonType.brand} />
    </View>
  )
})
