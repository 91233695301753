import { StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { colors, spacing, fontRegular } = STYLES.useStyles()

  const container = {
    alignItems: 'center',
    width: '100%'
  } as ViewStyle

  const _image = {
    width: 101,
    height: 70,
    marginBottom: spacing.xs
  } as ViewStyle

  const image = {
    ..._image
  } as ImageStyle

  const text = {
    ...fontRegular,
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    textAlign: 'center'
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    image,
    text
  })

  return { styles }
}
