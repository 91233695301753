import { StyleSheet, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { paragraph, fontSemiBold, focused } = STYLES.useStyles()

  const link = {
    ...fontSemiBold,
    fontSize: paragraph.fontSize,
    textDecorationLine: 'underline',
    color: paragraph.color,
  } as TextStyle

  const link_focused = {
    ...focused,
    outlineOffset: getREM(0.25),
    borderRadius: getREM(0.25)
  } as TextStyle

  const styles = StyleSheet.create({
    link,
    link_focused
  })

  return { styles }
}
