import React, { createContext, memo } from 'react'
import { View, ViewProps } from 'react-native'
import { useStyles } from './styles'

export interface Props extends ViewProps {
  textColor?: string
  backgroundImage?: React.ReactNode
  backgroundColor?: string
  contentStyle?: ViewProps['style']
}

interface ContextProps {
  textColor: string | undefined
}

export const HeaderContext = createContext<ContextProps>({
  textColor: undefined
})

export const Header = memo(({
  style,
  children,
  textColor = '',
  backgroundImage,
  backgroundColor,
  contentStyle,
  ...props
}: Props) => {
  const { styles } = useStyles()

  return (
    <View style={styles.container}>
      <View
        testID="Header"
        style={[
          styles.header,
          style,
          {
            backgroundColor
          }
        ]}
        {...props}>
        <HeaderContext.Provider value={{ textColor }}>
          <View
            style={styles.backgroundContainer}>
            {backgroundImage}
          </View>
          <View style={[styles.content, contentStyle]}>{children}</View>
        </HeaderContext.Provider>
      </View>
    </View>
  )
})
