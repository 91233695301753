import React, { memo } from 'react'
import { Card, CategoryPills, SvgImage } from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { STYLES } from 'styles'
import { ViewProps } from 'react-native'

export const Resources = {
  app: memo((props) => <SvgImage image={require('images/Resources/AppIcon.svg')} {...props} />) as typeof SvgImage,
  article: memo((props) => <SvgImage image={require('images/Resources/Article.svg')} {...props} />) as typeof SvgImage,
  book: memo((props) => <SvgImage image={require('images/Resources/Book.svg')} {...props} />) as typeof SvgImage,
  company: memo((props) => <SvgImage image={require('images/Resources/Company.svg')} {...props} />) as typeof SvgImage,
  guide: memo((props) => <SvgImage image={require('images/Resources/Guide.svg')} {...props} />) as typeof SvgImage,
  nearby: memo((props) => <SvgImage image={require('images/Resources/Nearby.svg')} {...props} />) as typeof SvgImage,
  ngo: memo((props) => <SvgImage image={require('images/Resources/Ngo.svg')} {...props} />) as typeof SvgImage,
  podcast: memo((props) => <SvgImage image={require('images/Resources/Podcast.svg')} {...props} />) as typeof SvgImage,
  presentation: memo((props) => <SvgImage image={require('images/Resources/Presentation.svg')} {...props} />) as typeof SvgImage,
  product: memo((props) => <SvgImage image={require('images/Resources/Product.svg')} {...props} />) as typeof SvgImage,
  video: memo((props) => <SvgImage image={require('images/Resources/Video.svg')} {...props} />) as typeof SvgImage,
  website: memo((props) => <SvgImage image={require('images/Resources/Website.svg')} {...props} />) as typeof SvgImage,
  whitepaper: memo((props) => <SvgImage image={require('images/Resources/Whitepaper.svg')} {...props} />) as typeof SvgImage
}

export interface Props extends ViewProps {
  title: string
  category: keyof typeof Resources
  linkUrl: string
}

export const ResourceCard = memo(({ title, category, linkUrl, ...props }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { COLORS } = STYLES.useStyles()

  const _CategoryPills = (
    <CategoryPills
      categoryPills={[{
        label: t(`resourceCard:${category}.categoryPillLabel`),
        textColor: COLORS.white,
        backgroundColor: COLORS.cherry
      }]}
    />
  )

  return (
    <Card
      testID="ResourceCard"
      CategoryPills={_CategoryPills}
      title={title}
      Image={Resources[category]}
      primaryActionLabel={`${t(`resourceCard:${category}.buttonLabel`)}: ${title}`}
      primaryActionFakeButtonLabel={t(`resourceCard:${category}.buttonLabel`)}
      primaryActionLink={linkUrl}
      {...props}
    />
  )
})
