import { createContext } from 'react'

interface AuthContextProps {
  isAuthenticated: boolean
  login?: () => void
  signup?: () => void
}

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false
})
