import { StyleSheet, ViewStyle } from 'react-native'

export const useStyles = () => {
  const container = {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  } as ViewStyle

  const styles = StyleSheet.create({
    container
  })

  return { styles }
}
