import React, { memo, useState, useEffect, useMemo, useCallback } from 'react'
import { useStyles } from './styles'
import {
  Heading,
  SearchInput,
  List,
  Pagination,
  View,
  FullScreenDialog,
  ShowMessage,
  MessageType,
  TextInput,
  Text,
  Toggle,
  Field,
  ActivityIndicator,
  Icons,
  SvgImage,
  CategoryPills
} from 'components'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { AdminListController, itemsPerPage } from './AdminListController'
import { STYLES } from 'styles'
import { AdminListActions } from './AdminListActions'
import { useErrorLang } from 'locales'
import { useNavigationContext, ReplaceParam } from 'utils'
import {
  Team,
  GroupMembershipRequest,
  GroupMembershipOrganization,
  GroupMembershipTeam
} from 'models'
import { ListItemProps } from 'components/List'
import { RecordKeyType } from './AdminListController/getData'
import { ViewProps } from 'react-native'

export enum AdminListTypes {
  organizationTeams = 'organizationTeams',
  organizationMembers = 'organizationMembers',
  organizationPendingMembers = 'organizationPendingMembers',
  teamMembers = 'teamMembers',
  teamPendingMembers = 'teamPendingMembers'
}

export const getLocaleKey = (type: AdminListTypes) => {
  switch (type) {
    case AdminListTypes.organizationMembers:
      return 'members'
    case AdminListTypes.organizationPendingMembers:
      return 'pendingMembers'
    case AdminListTypes.organizationTeams:
      return 'teams'
    case AdminListTypes.teamMembers:
      return 'members'
    case AdminListTypes.teamPendingMembers:
      return 'pendingMembers'
  }
}

export interface Props {
  controller?: AdminListController
  type: AdminListTypes
  organizationId?: string
  teamId?: string
  teamAdminPageLinkUrl?: string
  style?: ViewProps['style']
}

export const AdminList = memo(
  ({
    type,
    controller = new AdminListController(),
    organizationId,
    teamId,
    teamAdminPageLinkUrl,
    style
  }: Props) => {
    useLang()
    useErrorLang()
    const { t } = useTranslation()
    const navigationContext = useNavigationContext()
    const { styles } = useStyles()
    const { spacing, COLORS } = STYLES.useStyles()
    const [_hideRemoveDialog, _setHideRemoveDialog] = useState(true)
    const [_hideCreateTeams1Dialog, _setHideCreateTeams1Dialog] = useState(true)
    const [_hideCreateTeams2Dialog, _setHideCreateTeams2Dialog] = useState(true)
    const [_createTeamsValue, _setCreateTeamsValue] = useState('')
    const [_hideEditMemberRoleDialog, _setHideEditMemberRoleDialog] =
      useState(true)
    const [_memberRoleIsAdmin, _setMemberRoleIsAdmin] = useState(false)

    const {
      data,
      selectedItems,
      setSelectedItems,
      totalItems,
      currentPage,
      setCurrentPage,
      setSearchValue,
      refetch,
      isLoading,
      createTeams,
      removeItems,
      updateItems,
      updateMembershipRequests,
      hasSearchValue
    } = controller.useController({
      organizationId,
      teamId,
      type
    })

    const _localeKey = getLocaleKey(type)

    const WorkplaceImage: typeof SvgImage = memo((props) => (
      <SvgImage image={require('images/Workplace.svg')} {...props} />
    ))

    const onSelect = useCallback(
      (id: string) => {
        setSelectedItems((prevState) => {
          const _copy = [...prevState]
          const _index = _copy.indexOf(id)

          if (_index !== -1) {
            _copy.splice(_index, 1)
          } else {
            _copy.push(id)
          }

          return _copy
        })
      },
      [setSelectedItems]
    )

    const _getTags = useCallback(
      (isAdmin: boolean, teams?: { name: string; admin: boolean }[]) => {
        let _tags: React.ComponentProps<typeof CategoryPills>['categoryPills'] =
          []

        if (teams) {
          _tags = teams.map((tag) => ({
            label: tag.name,
            textColor: COLORS.white,
            backgroundColor: tag.admin ? COLORS.goblin : COLORS.aubergine,
            Icon: tag.admin ? Icons.Padlock : undefined
          }))
        }

        if (isAdmin) {
          _tags.unshift({
            label:
              type === AdminListTypes.teamMembers ? 'Team admin' : 'Org admin',
            textColor: COLORS.white,
            backgroundColor: COLORS.goblin,
            Icon: Icons.Padlock
          })
        }

        return _tags
      },
      [COLORS.aubergine, COLORS.goblin, COLORS.white, type]
    )

    const _items: ListItemProps[] = useMemo(() => {
      if (!data || data.length === 0) return []

      switch (type) {
        case AdminListTypes.organizationMembers:
        case AdminListTypes.teamMembers: {
          return (
            data as GroupMembershipOrganization[] | GroupMembershipTeam[]
          )?.map((item) => ({
            id: item.id!,
            primaryText: item.fullName,
            secondaryText: item.email,
            avatar: item.avatar,
            tags: _getTags(item.admin, item.teams),
            onSelect
          }))
        }
        case AdminListTypes.organizationPendingMembers:
        case AdminListTypes.teamPendingMembers: {
          return (data as GroupMembershipRequest[])?.map((item) => ({
            id: item.id!,
            primaryText: item.fullName,
            secondaryText: item.email,
            tertiaryText: item.verificationAnswer,
            avatar: item.avatar,
            onSelect
          }))
        }
        case AdminListTypes.organizationTeams: {
          return (data as Team[])?.map((item) => ({
            id: item.id!,
            primaryText: item.name,
            avatar: item.logo || WorkplaceImage,
            actionLabel: t('adminList:teams.viewLabel'),
            actionOnPress: () => {
              /* istanbul ignore next */
              if (!teamAdminPageLinkUrl || !item.id) return

              const _url = ReplaceParam(teamAdminPageLinkUrl, item.id)

              navigationContext.navigate(_url)
            },
            onSelect
          }))
        }
      }
    }, [
      WorkplaceImage,
      _getTags,
      data,
      navigationContext,
      onSelect,
      t,
      teamAdminPageLinkUrl,
      type
    ])

    const _pages = Math.ceil(totalItems / itemsPerPage)

    const _hasItems = !!_items && _items.length > 0
    const _hasSelectedItems = !!selectedItems && selectedItems.length > 0
    const _hasPagination = _pages > 1

    const _searchOnPress = (value: string) => {
      setSearchValue(value)
    }

    const _paginationOnPress = (index: number) => {
      setCurrentPage(index)
    }

    const _selectAllOnPress = () => {
      setSelectedItems(_items.map((item: { id: string }) => item.id))
    }

    const _deselectAllOnPress = () => {
      setSelectedItems([])
    }

    const _showMessage = (text: string) => {
      ShowMessage({
        type: MessageType.notice,
        isCloseable: true,
        text
      })
    }

    const _showErrorMessage = () => {
      ShowMessage({
        isCloseable: true,
        text: t('error:default')
      })
    }

    const _approveOnPress = async () => {
      const _success = await updateMembershipRequests(data, selectedItems, true)

      if (_success) {
        await refetch()
        _showMessage(t(`adminList:${_localeKey}.messages.approveSuccess`))
      } else {
        _showErrorMessage()
      }
    }

    const _declineOnPress = async () => {
      const _success = await updateMembershipRequests(
        data,
        selectedItems,
        false
      )

      if (_success) {
        await refetch()
        _showMessage(t(`adminList:${_localeKey}.messages.declineSuccess`))
      } else {
        _showErrorMessage()
      }
    }

    const _createTeams = async () => {
      if (!organizationId) {
        _showErrorMessage()
        return
      }

      const _success = await createTeams(
        _createTeamsValue.split('\n'),
        organizationId
      )

      _setHideCreateTeams1Dialog(true)
      _setHideCreateTeams2Dialog(true)
      _setCreateTeamsValue('')

      if (_success) {
        await refetch()
        _showMessage(t(`adminList:${_localeKey}.createTeams.success`))
      } else {
        _showErrorMessage()
      }
    }

    const _createTeamsReview = () => {
      /* istanbul ignore next */
      if (_createTeamsValue === '') return

      _setCreateTeamsValue((prevValue) => {
        const _uniqueValue = prevValue
          .split('\n')
          .filter((value, index, array) => array.indexOf(value) === index)

        return _uniqueValue.join('\n')
      })

      _setHideCreateTeams2Dialog(false)
    }

    const _editMemberRole = async () => {
      const _key = 'admin' as RecordKeyType
      const _success = await updateItems(
        data,
        selectedItems,
        _key,
        _memberRoleIsAdmin
      )

      _setHideEditMemberRoleDialog(true)

      if (_success) {
        setSelectedItems([])
        await refetch()
        _showMessage(t(`adminList:${_localeKey}.editMemberRole.success`))
      } else {
        _showErrorMessage()
      }
    }

    const _remove = async () => {
      const _success = await removeItems(data, selectedItems)

      _setHideRemoveDialog(true)

      if (_success) {
        setSelectedItems([])
        await refetch()
        _showMessage(t(`adminList:${_localeKey}.remove.success`))
      } else {
        _showErrorMessage()
      }
    }

    useEffect(() => {
      if (_hideEditMemberRoleDialog) {
        _setMemberRoleIsAdmin(false)
      }
    }, [_hideEditMemberRoleDialog])

    if (
      (type === AdminListTypes.organizationPendingMembers ||
        type === AdminListTypes.teamPendingMembers) &&
      !_hasItems &&
      !hasSearchValue
    ) {
      return null
    }

    return (
      <>
        <View style={[styles.container, style]} testID="AdminList">
          <Heading level={3} style={styles.title}>
            {t(`adminList:${_localeKey}.title`)}
          </Heading>
          <View style={styles.topSection} spacer={spacing.normal}>
            <SearchInput
              label={t(`adminList:${_localeKey}.title`)}
              onPress={_searchOnPress}
            />
            <AdminListActions
              type={type}
              hasItems={_hasItems}
              hasSelectedItems={_hasSelectedItems}
              selectAllOnPress={_selectAllOnPress}
              deselectAllOnPress={_deselectAllOnPress}
              createOnPress={() => _setHideCreateTeams1Dialog(false)}
              editOnPress={() => _setHideEditMemberRoleDialog(false)}
              removeOnPress={() => _setHideRemoveDialog(false)}
              approveOnPress={_approveOnPress}
              declineOnPress={_declineOnPress}
            />
          </View>
          {isLoading ? (
            <ActivityIndicator style={styles.list} />
          ) : (
            <List
              items={_items}
              selectedItems={selectedItems}
              emptyMessage={t(`adminList:${_localeKey}.messages.empty`)}
              style={styles.list}
            />
          )}
          {_hasPagination && (
            <Pagination
              currentItem={currentPage}
              totalItems={_pages}
              label={t(`adminList:${_localeKey}.title`)}
              onPress={_paginationOnPress}
              isNumbered
              showEllipsis
              showBackForward
              style={styles.pagination}
            />
          )}
        </View>
        <FullScreenDialog
          isHidden={_hideRemoveDialog}
          title={t(`adminList:${_localeKey}.remove.title`)}
          primaryActionCallback={_remove}
          primaryActionLabel={t(`adminList:${_localeKey}.remove.submit`)}
          closeLabel={t(`adminList:${_localeKey}.remove.cancel`)}
          closeCallback={() => _setHideRemoveDialog(true)}
          hideBox
          trackingLabel="AdminList Remove"
        />
        <FullScreenDialog
          isHidden={_hideEditMemberRoleDialog}
          title={t(`adminList:${_localeKey}.editMemberRole.title`)}
          primaryActionCallback={_editMemberRole}
          primaryActionLabel={t(
            `adminList:${_localeKey}.editMemberRole.submit`
          )}
          closeLabel={t(`adminList:${_localeKey}.editMemberRole.cancel`)}
          closeCallback={() => _setHideEditMemberRoleDialog(true)}
          hideBox
          trackingLabel="AdminList Edit Member Role">
          <Field label={t(`adminList:${_localeKey}.editMemberRole.label`)}>
            <Toggle
              value={_memberRoleIsAdmin}
              onChange={(value: boolean) => _setMemberRoleIsAdmin(value)}
              trueLabel={t(
                `adminList:${_localeKey}.editMemberRole.roles.admin`
              )}
              falseLabel={t(
                `adminList:${_localeKey}.editMemberRole.roles.member`
              )}
              accessibilityLabel={t(
                `adminList:${_localeKey}.editMemberRole.label`
              )}
            />
          </Field>
        </FullScreenDialog>
        <FullScreenDialog
          isHidden={_hideCreateTeams1Dialog}
          title={t(`adminList:${_localeKey}.createTeams.step1.title`)}
          primaryActionCallback={_createTeamsReview}
          primaryActionLabel={t(
            `adminList:${_localeKey}.createTeams.step1.submit`
          )}
          primaryActionDisabled={_createTeamsValue === ''}
          closeLabel={t(`adminList:${_localeKey}.createTeams.step1.cancel`)}
          closeCallback={() => {
            _setHideCreateTeams1Dialog(true)
            _setCreateTeamsValue('')
          }}
          hideBox
          trackingLabel="AdminList Create Teams Step 1">
          <TextInput
            label={t(`adminList:${_localeKey}.createTeams.step1.label`)}
            description={t(
              `adminList:${_localeKey}.createTeams.step1.description`
            )}
            placeholder={t(
              `adminList:${_localeKey}.createTeams.step1.placeholder`
            )}
            multiline
            value={_createTeamsValue}
            onChangeText={(value: string) => _setCreateTeamsValue(value)}
          />
        </FullScreenDialog>
        <FullScreenDialog
          isHidden={_hideCreateTeams2Dialog}
          title={t(`adminList:${_localeKey}.createTeams.step2.title`)}
          primaryActionCallback={_createTeams}
          primaryActionLabel={t(
            `adminList:${_localeKey}.createTeams.step2.submit`
          )}
          closeLabel={t(`adminList:${_localeKey}.createTeams.step2.cancel`)}
          closeCallback={() => _setHideCreateTeams2Dialog(true)}
          hideBox
          trackingLabel="AdminList Create Teams Step 2">
          <Text containerStyle={styles.createReviewTextContainer}>
            {_createTeamsValue}
          </Text>
        </FullScreenDialog>
      </>
    )
  }
)