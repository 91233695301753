import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Organization } from './Organization'
import { Team } from './Team'

@Model()
export class GroupMembershipRequest extends ApplicationRecord {
  static jsonapiType = 'group_membership_requests'

  @Attr({ persist: true }) state!: 'accepted' | 'declined' | 'pending' | 'revoked'
  @Attr({ persist: false }) verificationAnswer!: string
  @Attr({ persist: false }) email!: string
  @Attr({ persist: false }) fullName!: string
  @Attr({ persist: false }) avatar!: string

  @BelongsTo(Organization) organization!: Organization
  @BelongsTo(Team) team!: Team
}
