import { Button, ButtonType, Icons } from 'components'
import { Skateboard, SkateboardWithSpeechBubble } from 'images'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Share, Text, View } from 'react-native'
import { STYLES } from 'styles/STYLES'
import { CopyLink } from './CopyLink'
import { HaveDoneCounter } from './HaveDoneCounter'
import { useLang } from './Lang'
import { SocialMediaButtons } from './SocialMediaButtons'
import { useStyles } from './styles'

export interface Props {
  text: string
  url: string
  counter: number
}

export const ShareStepCTA = memo(({ text = '', url, counter = 0 }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { COLORS } = STYLES.useStyles()

  const _share = {
    title: t('shareStepCTA:spreadTheWord'),
    url,
    text
  }

  const isWeb = Platform.OS === 'web'
  // navigator.canShare will only return true for supporting browsers and if the protocol is https
  const showSingleShareButton =
    !isWeb || (isWeb && navigator?.canShare?.(_share))

  const nativeShareStepAction = () => {
    if (isWeb) {
      navigator.share(_share)
    } else {
      Share?.share({
        message: _share.text,
        url: _share.url,
        title: _share.title
      })
    }
  }

  const ShareIcon = memo(() => (
    <Icons.Share
      color={COLORS.white}
      size={STYLES.fontSize.l}
      style={[styles.shareIconStyle]}
    />
  ))

  return (
    <View style={[styles.card]} testID="ShareStepCTA">
      <SkateboardWithSpeechBubble style={[styles.bigImage]} />
      <View style={[styles.content]}>
        <View style={[styles.cta]}>
          <Skateboard style={[styles.smallImage]} />
          <View style={[styles.textContainer]}>
            <Text style={[styles.title]}>
              {t('shareStepCTA:spreadTheWord')}
            </Text>
            <Text style={[styles.explanation]}>
              {t('shareStepCTA:explanation')}
            </Text>
          </View>
        </View>

        <View>
          {!showSingleShareButton && (
            <CopyLink url={url} style={[styles.copyLink]} />
          )}

          {showSingleShareButton && (
            <View style={[styles.shareStepButton]}>
              <Button
                type={ButtonType.icon}
                containerStyle={[styles.shareStepButtonContainer]}
                textStyle={[styles.shareStepButtonText]}
                onPress={nativeShareStepAction}
                icon={ShareIcon}
                label={t('shareStepCTA:shareThisStep')}>
                <Text style={styles.shareStepButtonInnerText}>
                  {t('shareStepCTA:shareThisStep')}
                </Text>
              </Button>
            </View>
          )}

          <View style={styles.counterIcons}>
            <HaveDoneCounter count={counter} style={[styles.counter]} />

            {!showSingleShareButton && (
              <SocialMediaButtons
                style={styles.socialButtons}
                shareable={{
                  text,
                  url: url
                }}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  )
})
