import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'
import { Action } from './Action'
import { Commitment } from './Commitment'
import { ChecklistItem } from './ChecklistItem'

@Model()
export class PersonalChecklistItem extends ApplicationRecord {
  static jsonapiType = 'personal_checklist_items'

  static endpoint = '/personal-checklist-items'

  @Attr() completed!: string

  @BelongsTo(Person) person!: Person

  @BelongsTo(Action) action!: Action

  @BelongsTo(Commitment) commitment!: Commitment

  @BelongsTo(ChecklistItem) checklistItem!: ChecklistItem
}
