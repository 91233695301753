import React, { useState, memo, forwardRef, useMemo } from 'react'
import {
  TextInput as NativeTextInput,
  TextInputProps,
  NativeSyntheticEvent,
  TextInputSubmitEditingEventData,
  StyleProp,
  TextStyle
} from 'react-native'
import { AutoCompleteType, KeyboardType, TextContentType } from './utils'
import { useStyles } from './styles'
import { GenerateId } from 'utils'
import { Field } from 'components'
import { STYLES } from 'styles'

export interface Props extends TextInputProps, React.ComponentProps<typeof Field> {
  disabled?: boolean
  type?: string
  style?: StyleProp<TextStyle> | undefined
  containerStyle?: StyleProp<TextStyle> | undefined
}

const Component = forwardRef(({
  autoComplete = 'off',
  disabled = false,
  label,
  description,
  onChangeText,
  type = '',
  onSubmitEditing,
  containerStyle,
  style,
  multiline,
  hideLabel,
  numberOfLines,
  ...props
}: Props, ref: React.LegacyRef<NativeTextInput>) => {
  const { styles, mutlilineNumberOfLines } = useStyles()
  const _keyboardType = KeyboardType[type] ?? KeyboardType.default
  const _autoComplete = AutoCompleteType[autoComplete] ?? AutoCompleteType.none
  const _textContentType = TextContentType[autoComplete] ?? TextContentType.none
  const [_isFocused, _setIsFocused] = useState(false)
  const _descriptionId = useMemo(
    () => (description ? GenerateId() : undefined),
    [description]
  )
  const { COLORS } = STYLES.useStyles()

  const _onChangeText = async (value: string) => {
    if (disabled) return

    onChangeText?.(value)
  }

  const _onSubmitEditing = (
    event: NativeSyntheticEvent<TextInputSubmitEditingEventData>
  ) => {
    if (disabled) return

    onSubmitEditing?.(event)
  }

  /* istanbul ignore next */
  const _onFocus = () => {
    _setIsFocused(true)
  }

  /* istanbul ignore next */
  const _onBlur = () => {
    _setIsFocused(false)
  }

  return (
    <Field
      label={label}
      descriptionId={_descriptionId}
      description={description}
      hideLabel={hideLabel}
      style={containerStyle}>
      <NativeTextInput
        ref={ref}
        {...props}
        style={[
          styles.textInput,
          multiline && styles.textInput_multiline,
          disabled && styles.textInput_disabled,
          /* istanbul ignore next */
          _isFocused && styles.textInput_focused,
          style
        ]}
        accessibilityLabel={label}
        accessibilityHint={description}
        accessibilityState={{ disabled: disabled }}
        autoCapitalize="none"
        editable={!disabled}
        enablesReturnKeyAutomatically={!multiline}
        keyboardType={_keyboardType}
        onChangeText={_onChangeText}
        secureTextEntry={type === 'password'}
        textContentType={_textContentType}
        onSubmitEditing={_onSubmitEditing}
        autoCorrect={false}
        onFocus={_onFocus}
        onBlur={_onBlur}
        multiline={multiline}
        textAlignVertical="top"
        numberOfLines={numberOfLines || multiline ? mutlilineNumberOfLines : 1}
        placeholderTextColor={COLORS.doveGrey}
        /* 
          The following properties are written this way otherwise the following error is shown:
          > Property '...' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes & Readonly'.ts(2769)
          The property does exist so might work in newer versions of @types dependencies in the future.
        */
        {...{
          autoCompleteType: _autoComplete,
          accessibilityDescribedBy: _descriptionId,
          accessibilityDisabled: disabled
        }}
      />
    </Field>
  )
})

export const TextInput = memo(Component)