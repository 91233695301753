import React, { memo, useRef, useState } from 'react'
import { Text, View, ViewProps, Animated, Easing } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, SVG, SvgImage } from 'components'
import { useNativeDriver } from 'utils'

export interface Props extends ViewProps {
  CategoryPills?: React.ReactNode
  title: string
  primaryActionLabel?: string
  primaryActionLink?: string
  primaryActionOnPress?: () => void
  primaryActionFakeButtonLabel?: string
  Image?: typeof SVG.Svg | typeof SvgImage
  isCentred?: boolean
}

export const Card = memo(
  ({
    style,
    children,
    CategoryPills,
    title,
    primaryActionLabel,
    primaryActionLink,
    primaryActionOnPress,
    primaryActionFakeButtonLabel,
    Image,
    isCentred,
    ...props
  }: Props) => {
    const [_isPointerOver, _setIsPointerOver] = useState(false)
    const { styles } = useStyles({ isCentred })
    const _opacity = useRef(new Animated.Value(1)).current

    const _hasLink =
      !!primaryActionLabel && (!!primaryActionLink || !!primaryActionOnPress)
    const _hasChildren = !!children
    const _hasImage = !!Image
    const _hasButton = !!primaryActionFakeButtonLabel && _hasLink

    /* istanbul ignore next */
    const _setOpacity = (toValue: number, duration: number) => {
      Animated.timing(_opacity, {
        useNativeDriver,
        toValue,
        duration,
        easing: Easing.inOut(Easing.quad)
      }).start()
    }

    /* istanbul ignore next */
    const _opacityActive = () => {
      _setOpacity(0.6, 150)
    }

    /* istanbul ignore next */
    const _opacityInactive = () => {
      _setOpacity(1, 250)
    }

    /* istanbul ignore next */
    const _onPointerEnter = () => {
      _setIsPointerOver(true)
    }
    /* istanbul ignore next */
    const _onPointerLeave = () => {
      _setIsPointerOver(false)
    }

    return (
      <Animated.View
        style={[
          styles.container,
          _hasImage && styles.container_image,
          { opacity: _opacity },
          /* istanbul ignore next */
          _isPointerOver && styles.container_pointerOver,
          style
        ]}
        testID="Card"
        {...props}>
        {_hasLink && (
          <Button
            containerStyle={styles.primaryActionContainer}
            textStyle={styles.primaryActionText}
            label={primaryActionLabel}
            hideLabel
            onPress={primaryActionOnPress}
            onPressIn={_opacityActive}
            onPressOut={_opacityInactive}
            href={primaryActionLink}
            type={ButtonType.blank}
            onPointerEnter={_onPointerEnter}
            onPointerLeave={_onPointerLeave}
          />
        )}
        {_hasImage && <Image style={styles.image} testID="CardImage" />}
        <View style={styles.contentContainer} pointerEvents="box-none">
          <View
            style={[styles.content, _hasButton && styles.content_button]}
            pointerEvents="box-none">
            {CategoryPills}
            <View pointerEvents="none">
              <Text style={styles.title}>{title}</Text>
            </View>
          </View>
          {_hasButton && (
            <Button
              label={primaryActionFakeButtonLabel}
              type={ButtonType.normal}
              isFake
              containerStyle={styles.buttonContainer}
              testID="PrimaryActionFakeButton"
            />
          )}
        </View>
        {_hasChildren && (
          <View style={styles.children} pointerEvents="box-none">
            {children}
          </View>
        )}
      </Animated.View>
    )
  }
)
