import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class ElectricityEstimate extends ApplicationRecord {
  static jsonapiType = 'electricity_estimates'

  static endpoint = '/electricity/estimates'

  @Attr({ persist: false }) houseType!: string
  @Attr({ persist: false }) minBedrooms!: number
  @Attr({ persist: false }) maxBedrooms!: number
}
