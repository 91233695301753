import React, { useRef, memo, useEffect } from 'react'
import { View, Animated, ViewProps, Platform, StyleSheet, KeyboardAvoidingView } from 'react-native'
import { useStyles } from './styles'
import { TopBar, Props as TopBarProps } from '../TopBar'
import { useNativeDriver } from 'utils'
import { ShowMessage, ActivityIndicator } from 'components'
import { useSharedLang } from '../index'
import { useTranslation } from 'react-i18next'
import { useIsFocused } from '@react-navigation/native'
import { useErrorLang } from 'locales'

export interface Props extends ViewProps {
  showBackButton?: TopBarProps['showBackButton']
  showError?: boolean
  hideTopBar?: boolean
}

export const Layout = memo(({ children, style, showBackButton, showError = false, hideTopBar = false, ...props }: Props) => {
  useSharedLang()
  useErrorLang()

  const { t } = useTranslation()
  const { styles } = useStyles()
  const _offsetY = useRef(new Animated.Value(1)).current
  const _isFocused = useIsFocused()

  useEffect(() => {
    if (!showError) return

    ShowMessage({
      text: t('error:default'),
      isCloseable: true
    })
  }, [showError, t])

  if (!_isFocused) {
    return <ActivityIndicator />
  }

  return (
    <KeyboardAvoidingView
      behavior={
        /* istanbul ignore next */
        Platform.OS === 'ios' ? 'padding' : undefined
      }
      style={[styles.container, style]}
      testID="Layout"
      {...props}>
      {!hideTopBar && (
        <TopBar
          style={styles.topBar}
          offsetY={_offsetY}
          showBackButton={showBackButton}
        />
      )}
      <Animated.ScrollView
        contentContainerStyle={styles.scrollContentContainer}
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="none"
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: {
                  y: _offsetY
                }
              }
            }
          ],
          {
            useNativeDriver
          }
        )}
        scrollEventThrottle={16}>
        {
          /* istanbul ignore next */
          Platform.OS === 'web' ? (
            <main
              style={
                StyleSheet.flatten([
                  styles.content
                ]) as unknown as React.CSSProperties
              }
              id="Content"
              tabIndex={-1}>
              {children as React.ReactNode}
            </main>
          ) : (
            <View style={styles.content}>{children}</View>
          )
        }
      </Animated.ScrollView>
    </KeyboardAvoidingView>
  )
})
