import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { spacing, colors, fontRegular } = STYLES.useStyles()

  const heading: TextStyle = {
    marginBottom: spacing.m
  }

  const item: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'flex-start'
  }

  const itemTextContainer: ViewStyle = {
    marginLeft: spacing.normal,
    marginBottom: 0,
    flex: 1
  }

  const itemText: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    flex: 1
  }

  const itemBullet: ViewStyle = {
    backgroundColor: colors.alternative,
    width: getREM(1),
    height: getREM(1),
    borderRadius: getREM(0.5),
    marginTop: getREM(0.25),
    flexGrow: 0
  }

  const styles = StyleSheet.create({
    heading,
    item,
    itemText,
    itemTextContainer,
    itemBullet
  })

  return { styles }
}
