import { ViewStyle, TextStyle } from 'react-native'
import { useNormal } from './Normal'
import { useBrand } from './Brand'
import { useAlternative } from './Alternative'
import { useOutlined } from './Outlined'
import { useLink } from './Link'
import { useBrandLink } from './BrandLink'
import { useIcon } from './Icon'
import { useIconOutlined } from './IconOutlined'
import { useBlank } from './Blank'
import { usePlaceholder } from './Placeholder'

export interface TypeStylesProps {
  label?: ViewStyle | TextStyle
  nativeContainer?: ViewStyle | TextStyle
  small?: ViewStyle | TextStyle
  normal?: ViewStyle | TextStyle
  large?: ViewStyle | TextStyle
  focused?: ViewStyle | TextStyle
  pointerOver?: ViewStyle | TextStyle
}

export const useTypeStyles = () => {
  const { normal } = useNormal()
  const { brand } = useBrand()
  const { alternative } = useAlternative()
  const { outlined } = useOutlined()
  const { link } = useLink()
  const { brandLink } = useBrandLink()
  const { icon } = useIcon()
  const { iconOutlined } = useIconOutlined()
  const { blank } = useBlank()
  const { placeholder } = usePlaceholder()

  const typeStyles = {
    normal,
    brand,
    alternative,
    outlined,
    link,
    brandLink,
    icon,
    iconOutlined,
    blank,
    placeholder
  }

  return { typeStyles }
}
