import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Bell = memo(({
  size = getREM(1.5),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Bell"
      viewBox="0 0 24 25"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M18.482 20.0986C19.0581 21.5057 18.462 23.1485 17.1481 23.7646C15.8357 24.3823 14.305 23.7424 13.7296 22.3337C13.5748 21.9583 13.512 21.5648 13.5166 21.1788L17.8338 19.149C18.1012 19.4053 18.3272 19.7216 18.482 20.0986Z"
        fill={color ?? colors.text}
      />
      <SVG.Path
        d="M5.15122 12.7436C3.93306 9.45792 4.55286 6.32584 6.37397 4.27722C6.29889 4.15401 6.22304 4.03901 6.16635 3.89773C5.59021 2.49146 6.18703 0.851903 7.49943 0.235017C8.81182 -0.382691 10.3426 0.25966 10.9187 1.66511C10.9731 1.79818 11.003 1.93618 11.0351 2.07335C13.7634 2.01421 16.4563 3.79505 17.8637 6.82445C17.8637 6.82445 19.9484 11.9559 22.2376 12.3641C22.3081 12.3682 23.5362 12.4011 23.9108 13.3605C24.264 14.2681 23.537 15.483 22.2721 16.0671L8.57432 22.3961C7.31096 22.9801 5.91506 22.746 5.61626 21.817C5.3213 20.9011 5.96869 20.0493 6.02998 19.9737C7.32245 17.8446 5.15122 12.7436 5.15122 12.7436Z"
        fill={color ?? colors.text}
      />
    </SVG.Svg>
  )
})
