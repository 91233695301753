import React from 'react'
import { calculatePoints } from './config'
import { Point } from './Point'
import { SVG } from 'components'

export interface Props {
  animate?: boolean
  animationDelay?: number
  improvements: string[]
  size: 'default' | 'big' | 'huge'
}

export const Points = ({
  animate = false,
  animationDelay = 0,
  improvements,
  size
}: Props) => {
  const _pointsGroups = calculatePoints(
    improvements,
    size
  )

  return (
    <SVG.G>
      {_pointsGroups.map((group, index) => {
        return group.map(
          ({ pointx, pointy, dotDiameter, dotRadius }, pointIndex) => (
            <Point
              animate={animate}
              animationDelay={animationDelay}
              dotDiameter={dotDiameter}
              dotRadius={dotRadius}
              improvement={improvements[index]}
              index={pointIndex}
              key={pointIndex}
              pointx={pointx}
              pointy={pointy}
            />
          )
        )
      })}
    </SVG.G>
  )
}
