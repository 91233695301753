import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'
import { spacing } from 'styles/STYLES/Spacing'

export const useStyles = () => {
  const { COLORS, bp, fontSemiBold } = STYLES.useStyles()

  const _barHeightRem = 1.625

  const container = {
    maxWidth: STYLES.grid.maxBoxWidth,
    width: '100%'
  } as ViewStyle

  const item = {
    flexDirection: 'row',
    alignItems: 'flex-end'
  } as ViewStyle

  const itemLabel = {
    borderRightColor: COLORS.silver,
    borderRightWidth: getREM(0.0625),
    fontSize: STYLES.fontSize.xs,
    lineHeight: getREM(_barHeightRem),
    paddingRight: getREM(0.5),
    marginRight: getREM(0.5),
    minWidth: getREM(4.5),
    width: '25%'
  } as TextStyle

  const itemLabel_gap = {
    paddingTop: getREM(0.875)
  } as TextStyle

  const itemBarContainer = {
    flex: 1,
    marginRight: getREM(0.5)
  } as ViewStyle

  if (bp.mobile_xs) {
    itemBarContainer.display = 'none'
  }

  const itemBar = {
    borderRadius: getREM(0.375),
    height: getREM(_barHeightRem),
    backgroundColor: COLORS.aubergine
  } as ViewStyle

  const itemValue = {
    fontSize: STYLES.fontSize.xs,
    lineHeight: getREM(_barHeightRem),
    marginLeft: 'auto',
    minWidth: getREM(4),
    width: '10%',
    textAlign: 'right',
    paddingLeft: getREM(0.5)
  } as TextStyle

  if (bp.is([bp.mobile_l, bp.mobile_xl, bp.tablet, bp.desktop])) {
    itemValue.fontSize = STYLES.fontSize.s
  }

  const treeContainer = {
    ...itemBarContainer,
    flexDirection: 'row',
    alignItems: 'center'
  } as ViewStyle

  const treeContainerText = {
    color: COLORS.goblin,
    ...fontSemiBold,
    marginLeft: spacing.xs,
    textAlign: 'left',
    fontSize: STYLES.fontSize.xs
  } as TextStyle

  if (bp.is([bp.mobile_l, bp.mobile_xl, bp.tablet, bp.desktop])) {
    itemLabel.fontSize = STYLES.fontSize.s
    treeContainerText.fontSize = STYLES.fontSize.s
  }

  const styles = StyleSheet.create({
    container,
    item,
    itemLabel,
    itemLabel_gap,
    itemBarContainer,
    itemBar,
    itemValue,
    treeContainer,
    treeContainerText
  })

  return { styles }
}
