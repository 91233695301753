import { Platform } from 'react-native'
import { STYLES } from 'styles'
import { TypeStylesProps } from './index'

export const useBlank = () => {
  const { colors } = STYLES.useStyles()

  const blank = {
    label: {
      backgroundColor: colors.transparent,
      borderWidth: 0,
      padding: 0,
      ...Platform.select({
        web: {
          cursor: 'pointer'
        }
      })
    }
  } as TypeStylesProps

  return { blank }
}
