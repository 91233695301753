import { getREM } from '../getREM'

export const grid = {
  maxTextWidth: getREM(60), // 960
  maxTextBlockWidth: getREM(54.25), // 868
  maxInputWidth: getREM(31.25), // 500
  maxBoxWidth: getREM(38), // 608
  maxNotificationWidth: getREM(54.25), // 868
  maxContentWidth: getREM(90), // 1440
  maxCardWidth: getREM(29), // 464
  maxColumnWidth: getREM(45.625) // 730
}
