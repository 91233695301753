import { StyleSheet, ViewStyle } from 'react-native'
import { useWindowDimensions } from 'utils'

export const useStyles = () => {
  const { width } = useWindowDimensions()

  const container: ViewStyle = {
    width
  }

  const styles = StyleSheet.create({
    container
  })

  return { styles }
}
