import { Model, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'
import { Action } from './Action'

@Model()
export class PeopleActionsSuppression extends ApplicationRecord {
  static jsonapiType = 'people_actions_suppressions'

  static endpoint = '/people/actions/suppressions'

  @BelongsTo(Person) person!: Person

  @BelongsTo(Action) action!: Action
}
