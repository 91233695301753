import { ProgressCard } from './ProgressCard'

export const FootprintSections = {
  ProgressCard
}

export const FOOTPRINT_SECTIONS = {
  home: require('images/FootprintSections/home.svg'),
  food: require('images/FootprintSections/food.svg'),
  purchases: require('images/FootprintSections/purchases.svg'),
  services: require('images/FootprintSections/services.svg'),
  transport: require('images/FootprintSections/transport.svg'),
  trees: require('images/FootprintSections/trees.svg')
}

export type FootprintSectionsType = keyof typeof FOOTPRINT_SECTIONS
