import { createContext } from 'react'

export interface TrackEventProps {
  eventName: string
  props?: {
    [key: string]: undefined | null | string | boolean | number
  }
  url?: string
}

export type AnalyticsContextProps = {
  trackEvent: ({ eventName, props, url }: TrackEventProps) => void
}

export const DefaultAnalyticsProvider: AnalyticsContextProps = {
  trackEvent: () => {
    console.warn("No analytics context provider has been provided")
  }
}

export const AnalyticsContext = createContext<AnalyticsContextProps>(DefaultAnalyticsProvider)
