import { Model, BelongsTo } from 'spraypaint'
import { Footprint } from './Footprint'
import { Person } from './Person'
import { DietEstimate } from './DietEstimate'

@Model()
export class PeopleDietFootprint extends Footprint {
  static jsonapiType = 'people_diet_footprints'

  static endpoint = '/people/diet/footprints'

  @BelongsTo(DietEstimate) diet!: DietEstimate

  @BelongsTo(Person) person!: Person
}
