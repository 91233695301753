import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { bp, spacing, sizing } = STYLES.useStyles()
  const insets = useSafeAreaInsets()

  const container = {
    paddingRight: spacing.page.padding.paddingRight,
    paddingLeft: spacing.page.padding.paddingLeft,
    width: '100%'
  } as ViewStyle

  const container_noHeader = {
    marginTop: getREM((sizing.topBarHeight + insets.top) / baseSize),
    paddingTop: spacing.xxxl
  } as ViewStyle

  const button = {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center'
  } as ViewStyle

  const steps = {
    marginTop: spacing.xxxl,
    marginBottom: spacing.xxxl,
    width: '100%'
  } as ViewStyle

  const stepsHeading = {
    textAlign: 'center'
  } as TextStyle

  const stepsGrid = {
    maxWidth: STYLES.grid.maxBoxWidth,
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: spacing.normal
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    stepsGrid.flexDirection = 'column'
  }

  const stepsPending = {
    marginBottom: spacing.xxxl
  } as ViewStyle

  const stepsPendingCarousel = {
    marginBottom: spacing.normal
  } as ViewStyle

  const footprintSectionsCarousel = {
    marginBottom: spacing.xxxl
  } as ViewStyle

  const scoreHighlights = {
    marginBottom: spacing.xxxl
  } as ViewStyle

  const climateClock = {
    marginBottom: spacing.xxxl
  } as ViewStyle

  const announcements = {
    paddingBottom: spacing.xxxl
  } as ViewStyle

  const styles = StyleSheet.create({
    announcements,
    container,
    container_noHeader,
    button,
    steps,
    stepsHeading,
    stepsGrid,
    stepsPending,
    stepsPendingCarousel,
    footprintSectionsCarousel,
    scoreHighlights,
    climateClock
  })

  return { styles }
}
