import React, { memo } from 'react'
import { Text, StyleProp, ViewStyle } from 'react-native'
import { View, Heading, Button, ButtonType, SvgImage } from 'components'
import { STYLES } from 'styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { useStyles } from './styles'
import { Clock } from './Images/Clock'

export interface Props {
  containerStyle?: StyleProp<ViewStyle>
  title?: string
  linkLabel?: string
  linkUrl?: string
  linkOnPress?: () => void
  years: number | null
  months: number | null
  unlimited?: boolean
}

export const ClimateClock = memo(({
  containerStyle,
  title,
  linkLabel,
  linkUrl,
  linkOnPress,
  years,
  months,
  unlimited = false
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { spacing } = STYLES.useStyles()

  const _hasTitle = !!title
  const _hasLink = !!linkLabel && (!!linkUrl || !!linkOnPress)
  const _hasYears = years !== null && years > 0
  const _hasMonths = months !== null && months > 0

  const _unlimited = (years === null && months === null) || unlimited

  if (years === null && months === null && unlimited === false) return null

  return (
    <View style={[styles.container, containerStyle]} testID="ClimateClock">
      {_hasTitle && (
        <Heading level={2} style={styles.title}>
          {title}
        </Heading>
      )}
      <View style={styles.boxGroup} spacer={spacing.normal}>
        <View style={styles.clockGroup}>
          <View style={styles.cloudsContainer}>
            <SvgImage image={require('./Images/Clouds.svg')} style={styles.clouds} width={202} height={86} />
          </View>
          <View style={styles.clockContainer}>
            <Clock style={styles.clock} />
          </View>
        </View>
        <View style={styles.contentGroup}>
          {_unlimited ? (
            <Text textBreakStrategy="simple" style={styles.unlimited}>
              {t('climateClock:unlimited')}
            </Text>
          ) : (
            <>
              <Text textBreakStrategy="simple" style={styles.prefix}>
                {t('climateClock:prefix')}
              </Text>
              <View style={styles.calendar} spacer={spacing.l}>
                {_hasYears && (
                  <View style={styles.calendarItem}>
                    <Text
                      textBreakStrategy="simple"
                      style={styles.calendarItemValue}>
                      {t('format:number', { value: years })}
                    </Text>
                    <Text
                      textBreakStrategy="simple"
                      style={styles.calendarItemLabel}>
                      {t('climateClock:years')}
                    </Text>
                  </View>
                )}
                {_hasMonths && (
                  <View style={styles.calendarItem}>
                    <Text
                      textBreakStrategy="simple"
                      style={styles.calendarItemValue}>
                      {t('format:number', { value: months })}
                    </Text>
                    <Text
                      textBreakStrategy="simple"
                      style={styles.calendarItemLabel}>
                      {t('climateClock:months')}
                    </Text>
                  </View>
                )}
              </View>
              <Text textBreakStrategy="simple" style={styles.suffix}>
                {t('climateClock:suffix')}
              </Text>
            </>
          )}
          <Text textBreakStrategy="simple" style={styles.note}>
            {t('climateClock:note')}
          </Text>
        </View>
      </View>
      {_hasLink && (
        <Button
          type={ButtonType.brand}
          href={linkUrl}
          label={linkLabel}
          onPress={linkOnPress}
          containerStyle={styles.link}
        />
      )}
    </View>
  )
})
