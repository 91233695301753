import { Model, Attr, HasMany } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Region } from './Region'

@Model()
export class Country extends ApplicationRecord {
  static jsonapiType = 'locations_countries'

  static endpoint = '/locations/countries'

  @Attr({ persist: false }) name!: string

  @HasMany(Region) regions!: Region[]
}
