import React, { memo, ComponentProps, useMemo } from 'react'
import { View, ViewProps } from 'react-native'
import { Action } from 'models'
import { Carousel } from 'components'
import { STYLES, getREM } from 'styles'
import { useStyles } from './styles'

export { StepCardTemplate, StepCardTemplateProps } from '../StepCardTemplate'

export interface StepCarouselItemTemplateProps extends ViewProps {
  item: Action
}

type CarouselType = ComponentProps<typeof Carousel>

export interface Props extends ViewProps {
  Image?: CarouselType['Image']
  items: Action[]
  ItemTemplate: (props: StepCarouselItemTemplateProps) => JSX.Element
  title?: string
  description?: string
  hasTarget?: CarouselType['hasTarget']
  emptyMessage?: CarouselType['emptyMessage']
  isOverflowVisible?: CarouselType['isOverflowVisible']
}

export const ItemCount = 5

export const StepCarousel = memo(
  ({
    Image,
    items,
    ItemTemplate,
    title,
    description,
    hasTarget,
    emptyMessage,
    isOverflowVisible = true,
    style,
    ...props
  }: Props) => {
    const { styles } = useStyles()

    const _key = useMemo(() => items?.map((i) => i.id).join('_'), [items])

    return (
      <View testID="StepCarousel" style={[styles.container, style]} {...props}>
        <Carousel
          isOverflowVisible={isOverflowVisible}
          maxItemWidth={STYLES.grid.maxCardWidth}
          Image={Image}
          title={title}
          description={description}
          hasTarget={hasTarget}
          emptyMessage={emptyMessage}
          key={_key}>
          {items.slice(0, ItemCount).map((item) => (
            <ItemTemplate
              item={item}
              key={`stepCarousel-${item.id}`}
              style={{ marginVertical: getREM(0.5) }}
            />
          ))}
        </Carousel>
      </View>
    )
  }
)
