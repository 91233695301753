export const useColors = () => {
  const improvementColors = {
    water: '#39b6d1',
    land: '#a4cb26',
    po43e: '#21b68a',
    co2e: '#c44170',
    wellbeing: '#efdb3a',
    money: '#449a00',
    time: '#f9a832',
    fairness: '#ea9e9b',
    health: '#e44a4a',
    plastic: '#cf97cd'
  }

  // colour names: https://chir.ag/projects/name-that-color/
  const COLORS = {
    ...improvementColors,
    transparent: 'transparent',
    black: '#000000',
    white: '#ffffff',
    aubergine: '#5f2b68',
    lavenderBlush: '#FFF1F7',
    mushroom: '#f1ebea',
    steam: '#e1e3e6',
    cherry: '#c44170',
    paper: '#faf7f7',
    goblin: '#3a804e',
    revolver: '#210f24',
    snuff: '#ebd7ee',
    lochmara: '#0078d4',
    silver: '#c6c1c0',
    doveGrey: '#707070',
    whisper: '#F3F0F4',
    crimson: '#DD1212',
    studio: '#a149b0',
    hawkesBlue: '#e3e8fe',
    atlantis: '#a4cb26',
    mercury: '#e5e5e5',
    pastelPink: '#FFD2E2',
    cinnabar: '#E44A4A',
    peach: '#fa8f7a',
    apple: '#6abc82',
    pipi: '#fefbcc',
    mischka: '#e8e1ea',
    alto: '#d9d9d9',
    iron: '#DCDFE2',
    panache: '#f4faf6'
  }

  const colors = {
    transparent: COLORS.transparent,
    background: COLORS.white,
    footerBackground: COLORS.mushroom,
    boxBackground: COLORS.paper,
    buttonBackground: COLORS.steam,
    text: COLORS.revolver,
    footerText: COLORS.aubergine,
    brand: COLORS.aubergine,
    brandContrast: COLORS.white,
    alternative: COLORS.cherry,
    border: COLORS.silver,
    navBorder: COLORS.steam,
    positive: COLORS.goblin,
    positiveBackground: COLORS.panache,
    negative: COLORS.cherry,
    negativeBackground: COLORS.lavenderBlush,
    neutral: COLORS.lochmara,
    shadow: COLORS.black,
    focus: COLORS.snuff,
    progressBarContainer: COLORS.snuff,
    progressBar: COLORS.cherry,
    defaultCategoryPillBackground: COLORS.cherry,
    defaultCategoryPillText: COLORS.white
  }

  const improvements: { [key: string]: string } = {
    water: COLORS.water,
    land: COLORS.land,
    po43e: COLORS.po43e,
    co2e: COLORS.co2e,
    wellbeing: COLORS.wellbeing,
    money: COLORS.money,
    time: COLORS.time,
    fairness: COLORS.fairness,
    health: COLORS.health,
    plastic: COLORS.plastic
  }

  return {
    colors,
    COLORS,
    improvementColors: improvements
  }
}
