import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Search = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Search"
      viewBox="0 0 20 20"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path d="M17.8082 8.90411C17.8082 3.99543 13.8128 0 8.90411 0C3.99543 0 0 3.99543 0 8.90411C0 13.8128 3.99543 17.8082 8.90411 17.8082C11.0274 17.8082 12.9909 17.0548 14.5205 15.7991L18.6986 19.9772L20 18.6758L15.8219 14.5205C17.0548 12.9909 17.8082 11.0274 17.8082 8.90411ZM8.90411 15.9817C5 15.9817 1.82648 12.8082 1.82648 8.90411C1.82648 5 5 1.82648 8.90411 1.82648C12.8082 1.82648 15.9817 5 15.9817 8.90411C15.9817 12.8082 12.8082 15.9817 8.90411 15.9817Z" fill={color ?? colors.text} />
    </SVG.Svg>
  )
})
