import { STYLES } from 'styles'
import { StyleSheet } from 'react-native'

export const useStyles = () => {
  const { fontBold } = STYLES.useStyles()

  const styles = StyleSheet.create({
    strong: {
      ...fontBold
    }
  })

  return { styles }
}
