import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Padlock = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Padlock"
      viewBox="0 0 411.6 500"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M68.2,205.7c0-1.4,0-2.7,0-4c0.2-16.2-0.5-32.5,0.7-48.7c5-65,56.5-119,121.6-124.5
        c72.1-6.1,136.4,46.4,144.4,118.1c0.2,1.5,0.3,3.1,0.4,4.7c0.2,6.8-4.3,12-11,12.1c-13,0.2-26,0.1-39,0c-6.1-0.1-10.2-4-11-10.4
        c-4.1-31.6-27.4-54.7-54.6-61.6c-38.2-9.7-76.6,13.1-87.3,52c-1.6,5.9-2.6,12.2-2.7,18.4c-0.4,13.9-0.1,27.8-0.1,41.7
        c0,0.7,0.1,1.3,0.2,2.5c1.7,0,3.3,0,4.9,0c66.4,0,132.8,0,199.2,0c10.4,0,19.4,3.3,26.9,10.6c7.2,7.1,10.9,15.9,10.9,26.1
        c0,64.1,0.1,128.2,0,192.2c0,20.5-16.2,36.3-36.7,36.3c-89.2,0.1-178.4,0.1-267.7,0c-20.7,0-36.8-15.9-36.8-36.5
        c-0.1-64-0.1-127.9,0-191.9c0-20.2,14.8-35.4,34.9-36.7C66.1,205.9,67,205.8,68.2,205.7z M187.4,375.4
        C187.4,375.4,187.4,375.4,187.4,375.4c-0.1,3.3-0.1,6.7-0.1,10c0,3.4,0,6.9,0,10.3c0.1,8.9,6.2,15.3,14.6,15.3
        c8.9,0,14.6-6,14.6-15.4c0-13,0.1-26-0.2-39c-0.1-5.2,1.5-8.9,5.7-12.3c10.7-8.3,14-23.2,8.5-35.9c-5.3-12.2-19.2-20.2-32.1-18.6
        c-12.9,1.7-23.2,10.4-26.6,23c-3.5,13.1,0.5,23.9,10.9,32.3c3.2,2.6,4.7,5.6,4.6,9.7C187.3,361.8,187.4,368.6,187.4,375.4z"
        fill={color ?? colors.text}
      />
    </SVG.Svg>
  )
})
