import { StyleSheet, ViewStyle, TextStyle, Platform, ImageStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { box, colors, spacing, fontBold, fontSemiBold, boxShadows } = STYLES.useStyles()

  const container = {
    ...box,
    maxWidth: Platform.OS === 'web' ? `calc(${STYLES.grid.maxBoxWidth} / 2)` : STYLES.grid.maxBoxWidth / 2,
    alignItems: 'center',
    position: 'relative',
    flex: 1
  } as ViewStyle

  const container_pointerOver = {
    ...boxShadows.box_pointerOver.all
  } as ViewStyle

  const value = {
    ...fontBold,
    color: colors.brand,
    fontSize: STYLES.fontSize.xxl,
    lineHeight: STYLES.lineHeight.xxl
  } as TextStyle

  const label = {
    ...fontSemiBold,
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    textAlign: 'center'
  } as TextStyle

  const imageContainer = {
    marginTop: spacing.xs
  } as ViewStyle

  const image = {
    width: '100%',
    height: '100%'
  } as ImageStyle

  const fakeLink = {
    color: colors.alternative,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    marginTop: spacing.xs
  } as TextStyle

  const linkContainer = {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  } as ViewStyle

  const linkText = {
    width: '100%',
    height: '100%',
    borderRadius: box.borderRadius
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    container_pointerOver,
    value,
    label,
    imageContainer,
    image,
    fakeLink,
    linkContainer,
    linkText
  })

  return { styles }
}
