import React, { useState, useEffect, useRef, memo, useMemo } from 'react'
import { View, ScrollView, Text, Modal, Pressable, Dimensions, Platform } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, ButtonSize, Icons, Field } from 'components'
import { GenerateId } from 'utils'

export interface ItemProps {
  id: string
  label: string
}

export enum SelectType {
  normal,
  outline
}

export interface Props extends React.ComponentProps<typeof Field> {
  items: ItemProps[]
  onSelect: (id: string) => void
  disabled?: boolean
  selectedId?: string
  emptyLabel?: string
  type?: SelectType
}

export interface containerPositionProps {
  top?: number
  left?: number
  width?: number
}

export const Select = memo(({ items, onSelect, label, description, disabled, selectedId, style, emptyLabel, type = SelectType.normal, ...props }: Props) => {
  const [_isPointerOver, _setIsPointerOver] = useState<number | null>(null)
  const [_showList, _setShowList] = useState(false)
  const [_selectedId, _setSelectedId] = useState(selectedId)
  const [containerPosition, setContainerPosition] =
    useState<containerPositionProps>({})
  const { styles, listItemHeight, listVisibleItems, listMargin } = useStyles({
    showList: _showList,
    containerPosition,
    type
  })
  const _selectedItem = items?.find((item) => item.id === _selectedId)
  const _buttonRef = useRef<HTMLButtonElement>(null)
  const _buttonContainerRef = useRef<View & Text>(null)
  const _descriptionId = useMemo(
    () => (description ? GenerateId() : undefined),
    [description]
  )
  const _listId = useMemo(() => GenerateId(), [])

  /* istanbul ignore next */
  const _getMeasurements = () => {
    _buttonContainerRef?.current?.measure((_x, _y, width, height, px, py) => {
      if (py && height && px) {
        const _listHeight =
          listItemHeight * Math.min(listVisibleItems, items.length) +
          listMargin +
          listMargin
        const _windowHeight = Dimensions.get('window').height
        let _top = py + height

        if (_top + _listHeight > _windowHeight) {
          _top = py - _listHeight
        }

        setContainerPosition({
          top: _top,
          left: px,
          width
        })
      }
    })
  }

  const _onPress = () => {
    _setShowList(() => {
      if (Platform.OS === 'web') {
        window?.document?.body.setAttribute('locked', '')
      }

      _getMeasurements()

      return true
    })
  }

  const _closeList = () => {
    _setShowList(false)
    _buttonRef?.current?.focus()
  }

  const _onSelect = (id: string) => {
    _setSelectedId(id)
    onSelect?.(id)

    _closeList()
  }

  /* istanbul ignore next */
  const _onPointerEnter = (index: number) => {
    _setIsPointerOver(index)
  }
  /* istanbul ignore next */
  const _onPointerLeave = () => {
    _setIsPointerOver(null)
  }

  useEffect(() => {
    if (_selectedId !== selectedId) {
      _setSelectedId(selectedId)
    }
  }, [selectedId, _selectedId])

  useEffect(() => {
    if (!_showList && Platform.OS === 'web') {
      window?.document?.body.removeAttribute('locked')
    }
  }, [_showList])

  return (
    <Field
      {...props}
      style={[styles.container, style]}
      testID="Select"
      label={label}
      description={description}
      descriptionId={_descriptionId}>
      <Button
        ref={_buttonRef}
        containerRef={_buttonContainerRef}
        type={ButtonType.icon}
        icon={Icons.DownChevron}
        size={ButtonSize.small}
        onPress={_onPress}
        nativeContainerStyle={styles.buttonNativeContainer}
        containerStyle={styles.buttonContainer}
        textStyle={styles.buttonText}
        iconContainerStyle={styles.iconContainer}
        iconStyle={styles.icon}
        label={label}
        state={{
          expanded: _showList,
          disabled
        }}
        ariaControls={_listId}
        descriptionId={_descriptionId}
        description={description}>
        <Text style={styles.buttonInnerText}>
          {_selectedItem?.label || emptyLabel}
        </Text>
      </Button>
      <Modal
        onRequestClose={_closeList}
        animationType="fade"
        transparent={true}
        visible={_showList}
        nativeID={_listId}
        testID="SelectList">
        <Pressable
          style={styles.listContainer}
          onPress={_closeList}
          testID="SelectListClose">
          <View style={styles.list}>
            <ScrollView>
              {items?.map((item, index) => {
                const _isSelected = item.id === _selectedId
                const _onPressItem = () => {
                  _onSelect(item.id)
                }

                return (
                  <Button
                    key={item.id}
                    label={item.label}
                    onPress={_onPressItem}
                    textStyle={[
                      styles.listItemText,
                      _isSelected && styles.listItemText_selected,
                      index === items.length - 1 && styles.listItemText_last,
                      /* istanbul ignore next */
                      _isPointerOver === index &&
                        styles.listItemText_pointerOver
                    ]}
                    type={ButtonType.blank}
                    state={{
                      selected: _isSelected
                    }}
                    onPointerEnter={
                      /* istanbul ignore next */
                      () => _onPointerEnter(index)
                    }
                    onPointerLeave={_onPointerLeave}>
                    {item.label}
                  </Button>
                )
              })}
            </ScrollView>
          </View>
        </Pressable>
      </Modal>
    </Field>
  )
})
