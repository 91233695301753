import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { User, Organization, ApplicationRecord } from 'models'

export interface Props {
  publicSlug: string
  isAuthenticated: boolean
}

export class PublicOrganizationSignUpController {
  useController = ({ publicSlug, isAuthenticated }: Props) => {
    const { i18n } = useTranslation()

    const _getUserData = async () => {
      const { data } = await User
        .includes(['person'])
        .select({
          users: [''],
          people: ['onboarded', 'organizationSlugs']
        })
        .find('current')

      return data
    }

    const { data: user, isLoading: isUserLoading } = useQuery(
      ['data', 'user', ['onboarded', 'organizationSlugs']],
      _getUserData,
      {
        enabled: isAuthenticated,
        cacheTime: 0
      }
    )

    const _getOrganization = async () => {
      const { data } = await Organization
        .where({ publicSlug })
        .select({ organizations: ['banner', 'logo', 'name'] })
        .selectExtra({
          organizations: [
            'publicGreeting',
            'requiresVerification',
            'verificationQuestion'
          ]
        })
        .first()

      return data
    }

    const {
      isLoading: isOrganizationLoading,
      data: organization
    } = useQuery(
      ['data', 'publicOrganizationSignUp', publicSlug, i18n.language],
      _getOrganization
    )

    const _url = `${ApplicationRecord.baseUrl}${ApplicationRecord.apiNamespace}/organizations/${organization?.id}/add_member`

    const addToOrganization = async (verification_answer: string | undefined = undefined) => {
      const _response = await fetch(_url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${ApplicationRecord.jwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          verification_answer
        })
      })

      return _response.status === 200
    }

    return {
      isLoading: isOrganizationLoading || isUserLoading,
      organization,
      user,
      addToOrganization
    }
  }
}