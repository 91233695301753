import { EmailRegex } from 'utils'

export const GetEmailsFromString = (value: string) => {
  // split value by newline character
  // trim whitespace
  // filter out items that are only whitespace
  // filter out duplicates 
  const emails = value.split(/\n/).map((email) => email.trim()).filter((email, index, array) => /\S/.test(email) && array.indexOf(email) === index)

  let isValid = emails.length === 0 ? false : true
  let row = 0

  for (; row < emails.length; row++) {
    const email = emails[row]
    // check email exists and test against regex for expected format
    if (!email || EmailRegex.test(email) === false) {
      isValid = false
      break
    }
  }

  return { emails, row, isValid }
}