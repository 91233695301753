import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing, bp } = STYLES.useStyles()

  const barChart = {
    marginTop: spacing.xxl,
    marginBottom: spacing.xxl
  } as ViewStyle

  if (bp.desktop) {
    barChart.marginTop = 0
  }

  const text = {
    marginBottom: spacing.xxl
  } as TextStyle

  const styles = StyleSheet.create({
    barChart,
    text
  })

  return { styles }
}
