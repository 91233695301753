import {
  HouseType,
  HouseholdsElectricityFootprint,
  HouseholdsGasFootprint,
  GasEstimate,
  ElectricityEstimate
} from 'models'
import { DefaultProps } from '../index'
import { useTranslation } from 'react-i18next'

export type UseHouseType = {
  getHouseTypes: () => Promise<HouseType[] | undefined>
  getHouseholdCount: () => number
  getHouseholdElectricityFootprint: () => Promise<HouseholdsElectricityFootprint | null>
  getHouseholdGasFootprint: () => Promise<HouseholdsGasFootprint | null>
  saveHouseholdFootprint: (houseType: string, bedroomCount: number) => Promise<boolean | undefined>
  saveHousehold: (memberCount: number, firstName?: string, lastName?: string) => Promise<boolean | undefined>
}

export const useHouse = ({ globalId, person, source }: DefaultProps) => {
  const { i18n } = useTranslation()

  const getHouseTypes: UseHouseType['getHouseTypes'] = async () => {
    return (await HouseType
      .where({ region_id: person?.locationsRegionId || globalId })
      .select(['name', 'lookupSlug'])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()
    ).data
  }

  const getHouseholdCount: UseHouseType['getHouseholdCount'] = () => {
    // remove 1 to exclude the current user
    return person ? person.householdMemberCount - 1 : 0
  }

  const getHouseholdElectricityFootprint: UseHouseType['getHouseholdElectricityFootprint'] = async () => {
    return (await HouseholdsElectricityFootprint
      .where({ 'personId': person?.id })
      .order({ created_at: 'desc' })
      .includes(['estimate'])
      .select({
        householdsElectricityFootprints: [''],
        electricityEstimates: ['houseType']
      })
      .extraParams({ locale: i18n.language })
      .first()
    ).data
  }

  const getHouseholdGasFootprint: UseHouseType['getHouseholdGasFootprint'] = async () => {
    return (await HouseholdsGasFootprint
      .where({ 'personId': person?.id })
      .order({ created_at: 'desc' })
      .includes(['estimate'])
      .select({
        householdsGasFootprints: [''],
        gasEstimates: ['houseType']
      })
      .extraParams({ locale: i18n.language })
      .first()
    ).data
  }

  const saveHouseholdFootprint: UseHouseType['saveHouseholdFootprint'] = async (houseType, bedroomCount) => {
    const _success = []

    const _filter = (item: GasEstimate | ElectricityEstimate) =>
      (item.minBedrooms <= bedroomCount && item.maxBedrooms >= bedroomCount) ||
      (item.minBedrooms <= bedroomCount && item.maxBedrooms === null)

    // remove previous electricity footprints
    const { data: oldElectricityFootprints } = await HouseholdsElectricityFootprint
      .select([''])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()

    if (oldElectricityFootprints && oldElectricityFootprints.length > 0) {
      for (let i = 0; i < oldElectricityFootprints.length; i++) {
        await oldElectricityFootprints[i]?.destroy()
      }
    }

    // find new electricity estimate
    const { data: electricityEstimates } = await ElectricityEstimate
      .where({ region_id: person?.locationsRegionId || globalId })
      .where({ house_type: houseType })
      .select(['minBedrooms', 'maxBedrooms'])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()

    const _electricityEstimate = electricityEstimates?.find(_filter)

    // create new electricity footprint
    const _newElectricityFootprint = new HouseholdsElectricityFootprint({
      source,
      estimate: _electricityEstimate
    })

    const _electricityResponse = await _newElectricityFootprint.save({ with: 'estimate' })

    _success.push(_electricityResponse)

    // remove previous gas footprints
    const { data: oldGasFootprints } = await HouseholdsGasFootprint
      .select([''])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()

    if (oldGasFootprints && oldGasFootprints.length > 0) {
      for (let i = 0; i < oldGasFootprints.length; i++) {
        await oldGasFootprints[i]?.destroy()
      }
    }

    // find new gas estimate
    const { data: gasEstimates } = await GasEstimate
      .where({ region_id: person?.locationsRegionId || globalId })
      .where({ house_type: houseType })
      .select(['minBedrooms', 'maxBedrooms'])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()

    const _gasEstimate = gasEstimates?.find(_filter)

    // create new gas footprint
    const _newGasFootprint = new HouseholdsGasFootprint({
      source,
      estimate: _gasEstimate
    })

    const _gasResponse = await _newGasFootprint.save({ with: 'estimate' })

    _success.push(_gasResponse)

    return _success.every((item) => item === true)
  }

  const saveHousehold: UseHouseType['saveHousehold'] = async (newMemberCount) => {
    if (person) {
      // add 1 to include the current user
      person.householdMemberCount = newMemberCount + 1

      const _success = await person.save()

      return _success
    }

    return false
  }

  return {
    getHouseTypes,
    getHouseholdCount,
    getHouseholdElectricityFootprint,
    getHouseholdGasFootprint,
    saveHouseholdFootprint,
    saveHousehold
  }
}