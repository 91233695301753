import React, { memo } from 'react'
import { Animated } from 'react-native'
import { useStyles, StyleProps } from './styles'
import { SvgImage } from 'components'

export const Confetti = memo(({ startAnimation }: StyleProps) => {
  const { styles } = useStyles({ startAnimation })

  if (!styles) {
    return null
  }

  return (
    <Animated.View style={styles.container} testID="Confetti">
      <Animated.View style={styles.DotPink}>
        <SvgImage
          image={require('./Images/DotPink.svg')}
          width={4}
          height={4}
        />
      </Animated.View>
      <Animated.View style={styles.DotPink2}>
        <SvgImage
          image={require('./Images/DotPink.svg')}
          width={4}
          height={4}
        />
      </Animated.View>
      <Animated.View style={styles.DotYellow}>
        <SvgImage
          image={require('./Images/DotYellow.svg')}
          width={4}
          height={3}
        />
      </Animated.View>
      <Animated.View style={styles.SwirlBlue}>
        <SvgImage
          image={require('./Images/SwirlBlue.svg')}
          width={13}
          height={12}
        />
      </Animated.View>
      <Animated.View style={styles.SwirlPink}>
        <SvgImage
          image={require('./Images/SwirlPink.svg')}
          width={13}
          height={13}
        />
      </Animated.View>
      <Animated.View style={styles.SwirlPink2}>
        <SvgImage
          image={require('./Images/SwirlPink2.svg')}
          width={14}
          height={19}
        />
      </Animated.View>
      <Animated.View style={styles.SwirlYellow}>
        <SvgImage
          image={require('./Images/SwirlYellow.svg')}
          width={15}
          height={19}
        />
      </Animated.View>
      <Animated.View style={styles.TriangleBlue}>
        <SvgImage
          image={require('./Images/TriangleBlue.svg')}
          width={5}
          height={5}
        />
      </Animated.View>
      <Animated.View style={styles.TriangleDarkGreen}>
        <SvgImage
          image={require('./Images/TriangleDarkGreen.svg')}
          width={5}
          height={5}
        />
      </Animated.View>
      <Animated.View style={styles.TriangleGreen}>
        <SvgImage
          image={require('./Images/TriangleGreen.svg')}
          width={5}
          height={4}
        />
      </Animated.View>
    </Animated.View>
  )
})
