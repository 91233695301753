import { useState, useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { LeaderboardsTeamChallenge, LeaderboardTypes, Campaign } from 'models'
import { GetPagination } from 'utils'
import { UseControllerProps, GetDataType } from '../../types'

export const itemsPerPage = 10
export const defaultPage = 1

export class TeamChallengeController {
  _getData: GetDataType = async ({
    organizationId,
    locale,
    currentPage,
    sortBy
  }) => {
    const { data: campaign } = await Campaign.where({
      public: true,
      organizationId,
      underway: true
    })
      .select([''])
      .first()

    const campaignId = campaign?.id

    if (!campaignId) return null

    let _call = LeaderboardsTeamChallenge.where({
      organizationId,
      campaignId
    })
      .select({
        TeamChallenges: ['row_values', 'highlighted']
      })
      .page(currentPage)
      .per(itemsPerPage)
      .extraParams({ locale })

    if (sortBy) {
      _call = _call.order(sortBy)
    }

    const { data, meta } = await _call.all()

    const { columns, ...options } = meta

    const downloadUrl = options.downloadable
      ? `/api/v1/organizations/${organizationId}/team-challenge/${campaignId}/enqueue-download?locale=${locale}`
      : undefined

    return {
      rows: data,
      pagination: GetPagination({
        totalItems: options?.total,
        itemsPerPage,
        currentPage
      }),
      columns,
      options,
      downloadUrl
    }
  }

  useController = ({ organizationId }: UseControllerProps) => {
    const { i18n } = useTranslation()
    const [currentPage, setCurrentPage] = useState(defaultPage)
    const [sortBy, setSortBy] = useState<undefined | string>(undefined)
    const [filterBy, setFilterBy] = useState<undefined | string>(undefined)

    const { data, isFetching: isLoading } = useQuery(
      [
        'data',
        'leaderboard',
        'organization',
        'member',
        organizationId,
        i18n.language,
        currentPage,
        sortBy
      ],
      () =>
        this._getData({
          organizationId: organizationId!,
          locale: i18n.language,
          currentPage,
          sortBy
        }),
      {
        enabled: !!organizationId,
        keepPreviousData: true
      }
    )

    const _filterByItems = useMemo(
      () =>
        Array.from(
          new Set<string>(
            data?.columns
              ?.filter(
                (item: LeaderboardTypes.LeaderboardColumn) => !!item.filterable
              )
              .map((item: LeaderboardTypes.LeaderboardColumn) =>
                JSON.stringify(item.filterable)
              ) || []
          ),
          (item) => JSON.parse(item)
        ),
      [data?.columns]
    )

    const _onSelectFilter = useCallback(
      (id: string) => {
        setFilterBy(id)

        // find first sortable column that matches current filter
        const column = data?.columns?.find(
          (item: LeaderboardTypes.LeaderboardColumn) =>
            item.filterable?.id === id && item.sortable
        )

        if (column?.sortable?.id) {
          setSortBy(column.sortable.id)
        }
      },
      [data?.columns]
    )

    const _selectedIdFilter = useMemo(() => {
      return filterBy || _filterByItems?.[0]?.id
    }, [_filterByItems, filterBy])

    const _sortByItems = useMemo(
      () =>
        Array.from(
          new Set<string>(
            data?.columns
              ?.filter((item: LeaderboardTypes.LeaderboardColumn) => {
                if (item.filterable) {
                  return (
                    !!item.sortable && item.filterable?.id === _selectedIdFilter
                  )
                }

                return !!item.sortable
              })
              .map((item: LeaderboardTypes.LeaderboardColumn) =>
                JSON.stringify(item.sortable)
              ) || []
          ),
          (item) => JSON.parse(item)
        ),
      [_selectedIdFilter, data?.columns]
    )

    const _selectedIdSort = useMemo(() => {
      return sortBy || _sortByItems?.[0]?.id
    }, [sortBy, _sortByItems])

    return {
      isLoading,
      data,
      setCurrentPage,
      sort: {
        selectedId: _selectedIdSort,
        onSelect: setSortBy,
        items: _sortByItems
      },
      filter: {
        selectedId: _selectedIdFilter,
        onSelect: _onSelectFilter,
        items: _filterByItems
      }
    }
  }
}