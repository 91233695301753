import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'
import { useSharedStyles } from 'components/Button/Label/styles/useSharedStyles'

export const useStyles = () => {
  const { colors, bp } = STYLES.useStyles()
  const { sharedStyles } = useSharedStyles()

  const container = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    container.flexDirection = 'column'
  }

  const inputContainer = {
    marginBottom: 0,
    width: 'auto',
    flexShrink: 1
  } as ViewStyle

  const input = {
    borderColor: colors.brand,
    borderWidth: getREM(0.125),
    backgroundColor: colors.brandContrast,
    height: 'auto',
    paddingTop: sharedStyles.label_normal.paddingTop,
    paddingBottom: sharedStyles.label_normal.paddingBottom,
    paddingLeft: sharedStyles.label_normal.paddingLeft,
    paddingRight: sharedStyles.label_normal.paddingRight,
    fontSize: sharedStyles.label_normal.fontSize,
    lineHeight: sharedStyles.label_normal.lineHeight,
    borderRadius: sharedStyles.label_normal.borderRadius
  } as TextStyle

  const actions = {
    flexDirection: 'row'
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    inputContainer,
    input,
    actions
  })

  return { styles }
}
