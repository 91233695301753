import React, { memo } from 'react'
import { Text, StyleProp, TextStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

export interface Props {
  children?: React.ReactNode | React.ReactNode[]
  value?: number
  unit?: string
  containerStyle?: StyleProp<TextStyle>
  valueStyle?: StyleProp<TextStyle>
  unitStyle?: StyleProp<TextStyle>
}

export const MassValue = memo(({
  children,
  value,
  unit = 'kg',
  containerStyle,
  valueStyle,
  unitStyle
}: Props) => {
  const { t } = useTranslation()
  const { styles } = useStyles()

  return (
    <Text
      textBreakStrategy="simple"
      style={[styles.valueContainer, containerStyle]}>
      <Text textBreakStrategy="simple" style={[styles.value, valueStyle]}>
        <>
          {value && t('format:number', { value })}
          {children}
        </>
      </Text>
      <Text textBreakStrategy="simple" style={[styles.unit, unitStyle]}>
        {' '}
        {unit}
      </Text>
    </Text>
  )
})
