import { useQuery, useQueryClient } from 'react-query'
import { Announcement, PeopleAnnouncement } from 'models'
import { useTranslation } from "react-i18next"

export const useUnreadAnnouncement = () => {
  const queryClient = useQueryClient()
  const { i18n } = useTranslation()

  const queryKey = ['data', 'announcement', i18n.language]

  const _getAnnouncements = async () => {
    const { data } = await Announcement
      .where({ active: true, read: false })
      .select(['body'])
      .extraParams({ locale: i18n.language })
      .all()

    return data
  }

  const { isLoading, data: announcements } = useQuery(
    queryKey,
    _getAnnouncements
  )

  const readAnnouncement = async (
    personId: string | undefined,
    announcementId: string
  ) => {
    const peopleAnnouncement = new PeopleAnnouncement({
      personId,
      announcementId
    })
    await peopleAnnouncement.save()
    await queryClient.invalidateQueries(queryKey)
  }

  return {
    isLoading,
    announcements,
    readAnnouncement
  }
}