import React, { memo } from 'react'
import { Text, View } from 'react-native'
import { useStyles } from './styles'
import { Toggle } from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export interface Props {
  name: string
  message: string
  value: boolean
  onChange?: (arg: boolean) => void
}

export const Setting = memo(({ name, message, value, onChange }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()

  return (
    <View style={[styles.container]}>
      <View style={[styles.description]}>
        <Text textBreakStrategy="simple" style={[styles.name]}>
          {name}
        </Text>
        <Text textBreakStrategy="simple" style={[styles.message]}>
          {message}
        </Text>
      </View>

      <View style={[styles.toggle]}>
        <Toggle
          accessibilityLabel={name}
          falseLabel={t('setting:off')}
          trueLabel={t('setting:on')}
          value={value}
          onChange={onChange}
        />
      </View>
    </View>
  )
})
