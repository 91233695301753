export const Events = {
  pageview: 'pageview',
  screenview: 'screenview',
  carouselPaging: 'Carousel Paging',
  showFullScreenDialog: 'Show FullScreenDialog',
  closeMessage: 'Close message',
  changeLocale: 'Change Locale',
  signIn: 'Sign in',
  signOut: 'Sign out',
  onboardingTakeAStep: 'Onboarding take a step',
  onboardingGoal: 'Onboarding goal',
  createCommitment: 'Create commitment',
  updateCommitment: 'Update commitment',
  suppressStep: 'Suppress action',
  stepFilter: 'Step filter',
  stepsUserFlow: 'Steps User Flow',
  leaderboardDownload: 'Download report'
}
