import React, { useState, useCallback, useRef, useEffect, Dispatch, SetStateAction, memo } from 'react'
import { View, Text, ViewProps, PixelRatio } from 'react-native'
import { useStyles } from './styles'
import { FieldSet, Filter, Button, ButtonType } from 'components'
import { baseSize } from 'styles'

type FilterProps = React.ComponentProps<typeof Filter>

export interface Props extends ViewProps {
  title: string
  hideTitle?: boolean
  items: FilterProps[]
  selectedItems?: string[]
  visibleRows?: number
  showMoreLabel?: string
  showLessLabel?: string
  onPress: FilterProps['onPress']
  showMore?: boolean
  onShowMorePress?: Dispatch<SetStateAction<boolean>>
  hiddenFilters?: string[]
}

export const FilterGroup = memo(
  ({
    title,
    hideTitle = false,
    items,
    selectedItems = [],
    visibleRows,
    showMoreLabel,
    showLessLabel,
    style,
    onPress,
    showMore = false,
    onShowMorePress,
    hiddenFilters = [],
    ...props
  }: Props) => {
    const { styles } = useStyles()
    const [_showMore, _setShowMore] = useState<null | boolean>(false)

    const [_hiddenFilters, _setHiddenFilters] = useState<string[]>(hiddenFilters)
    const _filters = useRef<{ [key: string]: View | null }>({})
    const _containerRef = useRef(null)

    const _filterHeight = 2.5 * PixelRatio.getFontScale() * baseSize
    const _filterMargin = 0.1875 * PixelRatio.getFontScale() * baseSize
    const _filterFullHeight = _filterHeight + _filterMargin * 2
    
    /* istanbul ignore next */
    const _checkFilters = useCallback(() => {
      if (!visibleRows || !_filters.current || !_containerRef.current) return

        const _tempFilters: string[] = []
        const _checkedFilters: string[] = []

      for (const key in _filters.current) {
        _filters.current[key]?.measureLayout(
          _containerRef.current,
          (_left, top) => {
            _checkedFilters.push(key)
            const _hide = top >= _filterFullHeight * visibleRows

            if (_hide) {
              _tempFilters.push(key)
            }

            if (_checkedFilters.length === items.length) {
              _setHiddenFilters(_tempFilters)
            }
          }, () => {})
        }
    }, [_filterFullHeight, items.length, visibleRows])

    const _toggleOnPress = () => {
      if (onShowMorePress) {
        onShowMorePress(!_showMore)
      } else {
        _setShowMore(!_showMore)
      }
    }

    /* istanbul ignore next */
    const _onLayout = () => {
      _checkFilters()
    }

    useEffect(() => {
      _setShowMore(showMore)
    }, [showMore])

    if (!items || items.length === 0) return null

    return (
      <>
        <View onLayout={_onLayout} />
        <FieldSet
          style={[styles.container, style]}
          testID="FilterGroup"
          legend={title}
          {...props}>
          {!hideTitle && <Text style={styles.title}>{title}</Text>}
          <View
            ref={_containerRef}
            style={[
              styles.filters,
              !_showMore &&
                !!visibleRows && {
                  maxHeight: _filterFullHeight * visibleRows
                }
            ]}>
            {items.map((item) => {
              const _isActive = selectedItems.includes(item.slug)
              const _isHidden = !_showMore && _hiddenFilters.includes(item.slug)

              return (
                <Filter
                  key={`filter-${item.slug}`}
                  {...item}
                  isActive={_isActive}
                  onPress={onPress}
                  style={{
                    margin: _filterMargin,
                    height: _filterHeight
                  }}
                  hide={_isHidden}
                  ref={(itemRef) => (_filters.current[item.slug] = itemRef)}
                />
              )
            })}
          </View>
          {_hiddenFilters.length > 0 && (
            <Button
              label={_showMore ? showLessLabel : showMoreLabel}
              type={ButtonType.brand}
              onPress={_toggleOnPress}
              textStyle={styles.toggle}
              testID="FilterGroupToggle"
            />
          )}
        </FieldSet>
      </>
    )
  }
)
