import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Cloud = memo(({
  size = getREM(1.5),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()
  return (
    <SVG.Svg
      testID="Cloud"
      viewBox="0 0 21 13"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M12.765 5.45237C13.2997 4.40475 15.9736 3.35713 18.1127 4.66666C19.8239 5.71428 20.0735 7.54761 19.9844 8.33333C19.7705 11.0571 17.2214 11.9127 15.9736 12H4.7434C3.4956 11.9127 1 11.0571 1 8.33333C1 5.60952 3.4956 4.92856 4.7434 4.92856C5.01078 3.61904 6.40119 1 9.82372 1C13.2463 1 14.6367 2.92064 14.904 3.88096"
        stroke={color ?? colors.text}
        strokeWidth="1"
      />
    </SVG.Svg>
  )
})
