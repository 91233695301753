import { CarEstimate, CarModel, PeopleCarFootprint } from 'models'
import { DefaultProps } from '../index'
import { useTranslation } from 'react-i18next'

export type UseCarType = {
  getCarEstimates: () => Promise<CarEstimate[] | undefined>
  getCarModels: () => Promise<CarModel[] | undefined>
  getCarFootprint: () => Promise<PeopleCarFootprint | null>
  saveCarFootprint: (estimate?: CarEstimate, model?: CarModel) => Promise<boolean | undefined>
}

export const useCar = ({
  source,
  person,
  globalId
}: DefaultProps) => {
  const { i18n } = useTranslation()

  const getCarEstimates: UseCarType['getCarEstimates'] = async () => {
    return (await CarEstimate.where({ region_id: person?.locationsRegionId || globalId }).select(['name']).extraParams({ paginate: false, locale: i18n.language }).all()).data
  }

  const getCarModels: UseCarType['getCarModels'] = async () => {
    return (await CarModel.where({ region_id: person?.locationsRegionId || globalId }).select(['name']).extraParams({ paginate: false, locale: i18n.language }).all()).data
  }

  const getCarFootprint: UseCarType['getCarFootprint'] = async () => {
    return (await PeopleCarFootprint.order({ created_at: 'desc' }).select({ peopleCarFootprints: [''], models: [''] }).includes(['model', 'estimate']).extraParams({ locale: i18n.language }).first()).data
  }

  const saveCarFootprint: UseCarType['saveCarFootprint'] = async (estimate, model) => {
    const { data: oldFootprints } = await PeopleCarFootprint.select(['']).extraParams({ paginate: false, locale: i18n.language }).all()

    if (oldFootprints && oldFootprints.length > 0) {
      for (let i = 0; i < oldFootprints.length; i++) {
        await oldFootprints[i]?.destroy()
      }
    }

    const _newFootprint = new PeopleCarFootprint()

    if (estimate && model) {
      _newFootprint.source = source
      _newFootprint.estimate = estimate
      _newFootprint.model = model
    } else {
      _newFootprint.applicable = false
    }

    return await _newFootprint.save({ with: ['estimate', 'model'] })
  }

  return {
    getCarEstimates,
    getCarModels,
    getCarFootprint,
    saveCarFootprint
  }
}