/* istanbul ignore file */
/* istanbul comment added to remove file from test coverage checker as we are modifying the native components slightly to stop them from being focusable */
import React, { memo } from 'react'
import * as NativeSVG from 'react-native-svg'
import { ViewProps, Platform } from 'react-native'

const _tabIndex = Platform.OS === 'web' ? undefined : { tabIndex: -1 }

const _focusable = Platform.OS === 'web' ? undefined : false

const Svg = memo((props: NativeSVG.SvgProps) => (
  <NativeSVG.Svg
    {...props}
    {..._tabIndex}
    focusable={_focusable}
    pointerEvents="none"
    accessibilityRole="image"
  />
))

interface GProps extends NativeSVG.GProps {
  children: React.ReactNode | React.ReactNode[]
}

const G = memo((props: GProps) => <NativeSVG.G {...props} {..._tabIndex} />)

interface DefsProps {
  children: React.ReactNode | React.ReactNode[]
}

const Defs = memo((props: DefsProps) => (
  <NativeSVG.Defs {...props} {..._tabIndex} />
))

interface ClipPathProps extends NativeSVG.ClipPathProps {
  children: React.ReactNode | React.ReactNode[]
}

const ClipPath = memo((props: ClipPathProps) => (
  <NativeSVG.ClipPath {...props} {..._tabIndex} />
))

const Image = memo((props: NativeSVG.ImageProps) => (
  <NativeSVG.Image {...props} {..._tabIndex} />
))

const Path = memo((props: NativeSVG.PathProps) => (
  <NativeSVG.Path {...props} {..._tabIndex} />
))

const Circle = memo((props: NativeSVG.CircleProps) => (
  <NativeSVG.Circle {...props} {..._tabIndex} />
))

const Polygon = memo((props: NativeSVG.PolygonProps) => (
  <NativeSVG.Polygon {...props} {..._tabIndex} />
))

const Rect = memo((props: NativeSVG.RectProps) => (
  <NativeSVG.Rect {...props} {..._tabIndex} />
))

const Ellipse = memo((props: NativeSVG.EllipseProps) => (
  <NativeSVG.Ellipse {...props} {..._tabIndex} />
))

const Text = memo((props: NativeSVG.TextProps & ViewProps) => (
  <NativeSVG.Text {...props} {..._tabIndex} />
))

const TSpan = memo((props: NativeSVG.TSpanProps & ViewProps) => (
  <NativeSVG.TSpan {...props} {..._tabIndex} />
))

export const SVG = {
  Svg,
  G,
  Path,
  Circle,
  Polygon,
  Rect,
  Ellipse,
  Text,
  TSpan,
  Defs,
  ClipPath,
  Image
}
