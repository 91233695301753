import React, { memo, useContext } from 'react'
import {
  isCommitmentStateCompleted,
  ReplaceParam,
  LinkContext,
  AuthContext
} from 'utils'
import { CardActions, StepCard, StepButtons, CategoryPill } from 'components'
import { CommitmentState, Action } from 'models'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { ViewProps } from 'react-native'
import { useStepLang } from 'locales'

export interface StepCardTemplateProps extends ViewProps {
  action: Action
  commitmentState?: CommitmentState
  categoryPillOnPress?: React.ComponentProps<typeof CategoryPill>['onPress']
  isCardContentCentred?: boolean
  primaryActionOnPress?: React.ComponentProps<
    typeof StepCard
  >['primaryActionOnPress']
}

export const StepCardTemplate = memo(
  ({
    action,
    commitmentState,
    style,
    categoryPillOnPress,
    isCardContentCentred,
    ...props
  }: StepCardTemplateProps) => {
    useStepLang()

    const { styles } = useStyles()
    const { t } = useTranslation()
    const { stepDetailPageLinkUrl } = useContext(LinkContext)
    const { isAuthenticated } = useContext(AuthContext)

    const _hasCommitmentState = !!commitmentState
    const _isCompleted =
      _hasCommitmentState && isCommitmentStateCompleted(commitmentState)
    const _isPending = commitmentState === CommitmentState.pending

    /* istanbul ignore next */
    const _primaryActionLink =
      stepDetailPageLinkUrl && action
        ? ReplaceParam(stepDetailPageLinkUrl, action.param)
        : undefined

    if (!action) {
      return null
    }

    return (
      <StepCard
        testID="StepCardTemplate"
        title={action.summary}
        themes={action.themes?.map((themeItem) => themeItem.theme)}
        impact={action.impact}
        style={[styles.stepCard, style]}
        primaryActionLabel={t('step:buttons.learnMoreAbout', {
          value: action.summary
        })}
        primaryActionLink={_primaryActionLink}
        categoryPillOnPress={categoryPillOnPress}
        isCentred={isCardContentCentred}
        {...props}>
        <CardActions isCentred={isCardContentCentred}>
          {isAuthenticated && !_isCompleted && !_isPending && (
            <StepButtons.Commit action={action} />
          )}

          {isAuthenticated && _isCompleted && (
            <StepButtons.Abandon action={action} />
          )}

          {isAuthenticated && _isPending && (
            <StepButtons.Succeed action={action} />
          )}

          <StepButtons.LearnMore param={action.param} isFake />
        </CardActions>
      </StepCard>
    )
  }
)
