import { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { LeaderboardsOrganizationMember, LeaderboardTypes } from 'models'
import { GetPagination } from 'utils'
import { UseControllerProps, GetDataType } from '../../types'

export const itemsPerPage = 10
export const defaultPage = 1

export class OrganizationMemberController {
  _getData: GetDataType = async ({
    organizationId,
    locale,
    currentPage,
    sortBy
  }) => {
    let _call = LeaderboardsOrganizationMember.where({ organizationId })
      .select({
        organizationMembers: ['row_values', 'highlighted']
      })
      .page(currentPage)
      .per(itemsPerPage)
      .extraParams({ locale })

    if (sortBy) {
      _call = _call.order(sortBy)
    }

    const { data, meta } = await _call.all()

    const { columns, ...options } = meta

    return {
      rows: data,
      pagination: GetPagination({
        totalItems: options?.total,
        itemsPerPage,
        currentPage
      }),
      columns,
      options
    }
  }

  useController = ({ organizationId }: UseControllerProps) => {
    const { i18n } = useTranslation()
    const [currentPage, setCurrentPage] = useState(defaultPage)
    const [sortBy, setSortBy] = useState<undefined | string>(undefined)

    const { data, isFetching: isLoading } = useQuery(
      [
        'data',
        'leaderboard',
        'organization',
        'member',
        organizationId,
        i18n.language,
        currentPage,
        sortBy
      ],
      () =>
        this._getData({
          organizationId: organizationId!,
          locale: i18n.language,
          currentPage,
          sortBy
        }),
      {
        enabled: !!organizationId,
        keepPreviousData: true
      }
    )

    const _sortByItems: LeaderboardTypes.LeaderboardFilterItem[] = useMemo(
      () =>
        data?.columns
          ?.filter((item) => !!item.sortable)
          .map((item) => item.sortable!) || [],
      [data?.columns]
    )

    const _selectedId = useMemo(() => {
      return sortBy || _sortByItems?.[0]?.id
    }, [sortBy, _sortByItems])

    return {
      isLoading,
      data,
      setCurrentPage,
      sort: {
        selectedId: _selectedId,
        onSelect: setSortBy,
        items: _sortByItems
      }
    }
  }
}