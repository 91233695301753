import React, { useState, useEffect, memo, useMemo } from 'react'
import {
  View,
  TextInput,
  ViewProps
} from 'react-native'
import { Button, ButtonType, Field } from 'components'
import { useStyles } from './styles'
import { GenerateId } from 'utils'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export interface Props extends React.ComponentProps<typeof Field> {
  step?: number
  min?: number
  max?: number
  value: number
  onChange: (value: number) => void
  disabled?: boolean
  descriptionId?: string
  fieldStyle?: ViewProps['style']
  inputStyle?: ViewProps['style']
}

export const NumberInput = memo(({ step = 1, min = 0, max, label, description, descriptionId, disabled = false, onChange, value, fieldStyle, inputStyle, style, ...props }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const _descriptionId = useMemo(
    () => (descriptionId || description ? GenerateId() : undefined),
    [descriptionId, description]
  )
  const [_isFocused, _setIsFocused] = useState(false)
  const [_value, _setValue] = useState(value.toString())
  const _validRegex = new RegExp(/^\d+$/g)
  const _validDecimalRegex = new RegExp(/^\d+(\.\d{0,2})?$/g)
  const _zeroRegex = new RegExp(/^0\d+$/g)
  const _hasMax = !!max
  const _isIncreaseDisabled = (_hasMax && value >= max) || disabled
  const _isDecreaseDisabled = value <= min || disabled
  const _isDecimal = step % 1 !== 0
  const [_isPointerOverDecrease, _setIsPointerOverDecrease] = useState(false)
  const [_isPointerOverIncrease, _setIsPointerOverIncrease] = useState(false)

  const _changeValue = (newValue: string, isButton = false) => {
    /* istanbul ignore next */
    if (disabled) return

    if (!isButton) {
      if (newValue === '') {
        newValue = '0'
      } else if (newValue === '.') {
        newValue = '0.'
      } else if (_zeroRegex.test(newValue)) {
        newValue = parseInt(newValue, 10).toString()
      } else if (!_isDecimal && !_validRegex.test(newValue)) {
        newValue = _value
      } else if (_isDecimal && !_validDecimalRegex.test(newValue)) {
        newValue = _value
      }
    }

    if (_hasMax && parseFloat(newValue) > max) {
      newValue = max.toString()
    }

    if (parseFloat(newValue) < min) {
      newValue = min.toString()
    }

    _setValue(newValue)

    onChange(parseFloat(newValue))
  }

  const _increaseValue = () => {
    if (_isIncreaseDisabled) return

    const _valueNumeric = parseFloat(_value)
    const _newValue = _valueNumeric + step
    const _newValueString = _newValue.toString()

    _changeValue(_newValueString, true)
  }

  const _decreaseValue = () => {
    if (_isDecreaseDisabled) return

    const _valueNumeric = parseFloat(_value)
    const _newValue = _valueNumeric - step
    const _newValueString = _newValue.toString()

    _changeValue(_newValueString, true)
  }

  /* istanbul ignore next */
  const _onFocus = () => {
    _setIsFocused(true)
  }

  /* istanbul ignore next */
  const _onBlur = () => {
    _setIsFocused(false)
  }

  /* istanbul ignore next */
  const _onPointerEnterDecrease = () => {
    !_isDecreaseDisabled && _setIsPointerOverDecrease(true)
  }
  /* istanbul ignore next */
  const _onPointerLeaveDecrease = () => {
    _setIsPointerOverDecrease(false)
  }

  /* istanbul ignore next */
  const _onPointerEnterIncrease = () => {
    _setIsPointerOverIncrease(true)
  }
  /* istanbul ignore next */
  const _onPointerLeaveIncrease = () => {
    _setIsPointerOverIncrease(false)
  }

  useEffect(() => {
    if (value.toString() !== _value) {
      _setValue(value.toString())
    }
  }, [value, _value])

  return (
    <Field
      label={label}
      description={description}
      descriptionId={_descriptionId}
      style={fieldStyle}
      {...props}>
      <View
        style={[
          styles.container,
          style,
          /* istanbul ignore next */
          _isFocused && styles.container_focused
        ]}
        testID="NumberInput">
        <Button
          type={ButtonType.blank}
          onPress={_decreaseValue}
          label={`${t('numberInput:decrease')}: ${label}`}
          testID="NumberInputDecrease"
          textStyle={[
            styles.buttonTextStyle,
            _isDecreaseDisabled && styles.buttonTextStyle_disabled,
            /* istanbul ignore next */
            _isPointerOverDecrease && styles.buttonTextStyle_pointerOver
          ]}
          state={{ disabled: _isDecreaseDisabled }}
          nativeContainerStyle={styles.buttonNativeContainer}
          onPointerEnter={_onPointerEnterDecrease}
          onPointerLeave={_onPointerLeaveDecrease}>
          -
        </Button>
        <TextInput
          testID="NumberInputField"
          style={[styles.value, inputStyle]}
          value={_value}
          keyboardType="numeric"
          onChangeText={_changeValue}
          editable={!disabled}
          onFocus={_onFocus}
          onBlur={_onBlur}
          autoCorrect={false}
          accessibilityLabel={label}
          accessibilityHint={description}
          accessibilityState={{ disabled: disabled }}
          accessibilityValue={{
            min,
            max,
            now: value
          }}
          /* 
            The following properties are written this way otherwise the following error is shown:
            > Property '...' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes & Readonly'.ts(2769)
            The property does exist so might work in newer versions of @types dependencies in the future.
          */
          {...{
            accessibilityDescribedBy: _descriptionId,
            accessibilityDisabled: disabled
          }}
        />
        <Button
          type={ButtonType.blank}
          onPress={_increaseValue}
          label={`${t('numberInput:increase')}: ${label}`}
          testID="NumberInputIncrease"
          textStyle={[
            styles.buttonTextStyle,
            _isIncreaseDisabled && styles.buttonTextStyle_disabled,
            /* istanbul ignore next */
            _isPointerOverIncrease && styles.buttonTextStyle_pointerOver
          ]}
          state={{ disabled: _isIncreaseDisabled }}
          nativeContainerStyle={styles.buttonNativeContainer}
          onPointerEnter={_onPointerEnterIncrease}
          onPointerLeave={_onPointerLeaveIncrease}>
          +
        </Button>
      </View>
    </Field>
  )
})
