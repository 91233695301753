import React, { useState, memo } from 'react'
import { Text, View, ViewProps } from 'react-native'
import { useStyles } from './styles'
import { Action } from 'models'
import { Button, ButtonType } from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export interface Props extends ViewProps {
  visibleItems?: number
  items?: Action[]
  emptyMessage?: string
  ItemTemplate?: ({ item }: { item: Action }) => JSX.Element
}

export const StepList = memo(({ visibleItems, items, emptyMessage, style, ItemTemplate, ...props }: Props) => {
  useLang()

  const { t } = useTranslation()
  const { styles } = useStyles()
  const _initialVisibleItems = visibleItems || items?.length
  const [_visibleItems, _setVisibleItems] = useState(_initialVisibleItems)
  const [_showMore, _setShowMore] = useState(false)

  const _hasItems = !!items && items.length > 0 && !!ItemTemplate

  const _isSingle = _hasItems && items.length === 1

  const _showToggle = !!visibleItems && _hasItems && items.length > visibleItems

  const _toggleSteps = () => {
    _setShowMore(!_showMore)

    _setVisibleItems(
      _visibleItems !== _initialVisibleItems
        ? _initialVisibleItems
        : items?.length
    )
  }

  if (!_hasItems && !emptyMessage) return null

  if (!_hasItems) {
    return (
      <View style={[styles.container, style]} testID="StepList" {...props}>
        <Text style={styles.emptyMessage}>{emptyMessage}</Text>
      </View>
    )
  }

  return (
    <View style={[styles.container, style]} testID="StepList" {...props}>
      <View
        style={[
          styles.grid,
          /* istanbul ignore next */
          _isSingle && styles.grid_single
        ]}>
        {items.slice(0, _visibleItems).map((item) => (
          <View
            style={[
              styles.gridItem,
              /* istanbul ignore next */
              _isSingle && styles.gridItem_single
            ]}
            key={`stepList-${item.id}`}>
            <ItemTemplate item={item} />
          </View>
        ))}
      </View>
      {_showToggle && (
        <Button
          label={
            _showMore
              ? t('stepList:showLessLabel')
              : t('stepList:showMoreLabel')
          }
          onPress={_toggleSteps}
          type={ButtonType.brand}
          containerStyle={styles.toggleContainer}
        />
      )}
    </View>
  )
})
