import { Commitment, CommitmentState } from 'models'

export const GetCommitment = async (action_id?: string, filter?: CommitmentState) => {
  if (!action_id) return undefined

  const { data: commitments } = await Commitment.where({ action_id }).select(['state']).extraParams({ paginate: false }).all()

  if (filter) {
    return commitments?.find((item) => item.state === filter)
  }

  return commitments?.find((item) => item.state !== CommitmentState.abandoned)
}