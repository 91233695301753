import { Model, Attr, HasOne } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'

@Model()
export class Notification extends ApplicationRecord {
  static jsonapiType = 'notifications'

  @Attr({ persist: false }) createdAt!: string

  get createdDate(): Date {
    return new Date(Date.parse(this.createdAt))
  }

  @Attr({ persist: true }) read!: boolean

  @Attr({ persist: false }) title!: string

  @Attr({ persist: false }) body!: string

  @Attr({ persist: false }) url!: string

  @Attr({ persist: false }) nativeUrl!: string

  @Attr({ persist: false }) category!: string

  @HasOne(Person) person!: Person
}
