import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing, colors, fontRegular } = STYLES.useStyles()

  const heading: ViewStyle = {
    marginBottom: spacing.m
  }

  const benefitItem: ViewStyle = {
    flexDirection: 'row'
  }

  const benefitItemTick: ViewStyle = {
    borderRadius: 9999,
    backgroundColor: colors.alternative,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    flexDirection: 'row'
  }

  const benefitItemTextContainer: ViewStyle = {
    marginLeft: spacing.m,
    marginBottom: 0,
    flex: 1
  }

  const benefitItemText: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    flex: 1
  }

  const styles = StyleSheet.create({
    heading,
    benefitItem,
    benefitItemText,
    benefitItemTextContainer,
    benefitItemTick
  })

  return { styles }
}
