import React from 'react'
import { Line } from './Line'
import { SVG } from 'components'

export interface Props {
  improvements: string[]
  size: 'default' | 'big' | 'huge'
}

export const Lines = ({
  improvements,
  size
}: Props) => {
  return (
    <SVG.G>
      {improvements.map((improvement, index) => {
        return (
          <Line
            key={index}
            improvement={improvement}
            index={index}
            length={improvements.length}
            size={size}
          />
        )
      })}
    </SVG.G>
  )
}
