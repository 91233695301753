import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const buttonContainer = {
    width: '100%',
    maxWidth: STYLES.grid.maxInputWidth
  } as ViewStyle

  const styles = StyleSheet.create({
    buttonContainer
  })

  return { styles }
}
