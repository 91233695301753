import React, { ComponentProps, useEffect, memo, useRef } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import {
  CheckboxList,
  View,
  StepCard,
  CardActions,
  StepButtons
} from 'components'
import { useStyles } from './styles'
import { Action } from 'models'

type CheckboxListProps = ComponentProps<typeof CheckboxList>
type StepCardProps = ComponentProps<typeof StepCard>

export interface Props {
  items: CheckboxListProps['items']
  selectedItems?: CheckboxListProps['selectedItems']
  onPress: CheckboxListProps['onPress']
  style?: StyleProp<ViewStyle>
  categoryPillOnPress?: StepCardProps['categoryPillOnPress']
  action: Action
}

export const StepCheckboxListItem = memo(({
  items,
  selectedItems = [],
  onPress,
  style,
  categoryPillOnPress,
  action
}: Props) => {
  const { styles } = useStyles()
  const _succeedRef = useRef<any>()
  const _succeedTriggered = useRef(false)
  const saveSucceed = StepButtons.useSucceed()

    const _hasItems = items && items.length > 0

  const _triggerSucceed = async () => {
    await saveSucceed(action)
  }

  useEffect(() => {
    if (_hasItems && items.every((item) => selectedItems.includes(item.id)) && !_succeedTriggered.current) {
      _succeedTriggered.current = true
      _triggerSucceed()
    }
  }, [items, _hasItems, selectedItems])

  return (
    <View style={[styles.container, style]} testID="StepCheckboxListItem">
      <StepCard
        title={action.name}
        impact={action.impact}
        themes={action.themes?.map((themeItem) => themeItem.theme)}
        categoryPillOnPress={categoryPillOnPress}
        style={styles.card}
      >
        {_hasItems && (
          <CheckboxList items={items} selectedItems={selectedItems} onPress={onPress} />
        )}

        <CardActions style={styles.actions}>
          <StepButtons.Succeed action={action} ref={itemRef => _succeedRef.current = itemRef} />
          <StepButtons.LearnMore param={action.param} />
        </CardActions>
      </StepCard>
    </View>
  )
})
