import { SVG } from 'components'
import React from 'react'
import { calculateLine, circleRadius, dot } from './config'
import { useStyles } from './styles'

export interface Props {
  animate?: boolean
  animationDelay?: number
  improvement?: string
  length?: number
  index?: number
  size?: 'default' | 'big' | 'huge'
}

export interface ElementStylesProps {
  transform: string
  strokeDashoffset?: string
}

export const Line = ({
  improvement,
  index = 0,
  length = 0,
  size = 'default'
}: Props) => {
  const { circumference, rotate, offset, radius } = calculateLine(
    length,
    index,
    size
  )

  const { improvements } = useStyles()

  return (
    <SVG.Circle
      fill="transparent"
      stroke={improvements[improvement ?? '']}
      strokeLinecap="round"
      strokeWidth={dot.diameter[size]}
      r={radius}
      cx={circleRadius.lines[size]}
      cy={circleRadius.lines[size]}
      strokeDasharray={`${circumference} ${circumference}`}
      strokeDashoffset={offset}
      transform={`rotate(${rotate}deg)`}
    />
  )
}
