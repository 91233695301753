import { useQueryClient } from 'react-query'

export const useInvalidateDataQueries = () => {
  const queryClient = useQueryClient()

  const invalidateData = async () => {
    await queryClient.invalidateQueries('data', { refetchInactive: true }, { cancelRefetch: true })
  }

  return { invalidateData }
}