import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Shoe = memo(({
  size = getREM(1.5),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()
  return (
    <SVG.Svg
      testID="Shoe"
      viewBox="0 0 28 16"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M26.6479 11.5384C26.6479 11.5384 26.6479 8.54051 23.6999 8.0409C20.7519 7.54128 15.0097 6.54188 13.2408 5.04306L9.74152 1.046C9.74152 1.046 3.93508 1.046 1.57681 0.546387L0.999976 11.5381"
        stroke={color ?? colors.text}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <SVG.Path
        d="M1.02616 11.5383H26.6347L26.6479 15.2023H0.999976L1.02616 11.5383Z"
        stroke={color ?? colors.text}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <SVG.Path
        d="M15.5336 6.65308L14.6787 8.36294"
        stroke={color ?? colors.text}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <SVG.Path
        d="M12.9691 4.94318L11.2593 8.3629"
        stroke={color ?? colors.text}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG.Svg>
  )
})
