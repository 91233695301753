import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { bp, COLORS, focused, input, fontRegular, boxShadows } = STYLES.useStyles()

  const container = {
    ...input,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: getREM(0.125),
    paddingRight: getREM(0.125)
  } as ViewStyle

  if (bp.desktop) {
    container.paddingLeft = getREM(0.8125)
    container.paddingRight = getREM(0.8125)
  }

  const container_focused = {
    ...focused
  } as ViewStyle

  const buttonNativeContainer = {
    ...boxShadows.button.ios
  } as ViewStyle

  const buttonTextStyle = {
    ...fontRegular,
    width: getREM(1.875),
    height: getREM(1.875),
    borderRadius: getREM(1.875 / 2),
    backgroundColor: COLORS.aubergine,
    color: COLORS.white,
    fontSize: getREM(1.5),
    lineHeight: getREM(2),
    padding: 0,
    margin: getREM(0.1875),
    overflow: 'hidden',
    textAlign: 'center',
    ...boxShadows.button.web,
    ...boxShadows.button.android
  } as TextStyle

  if (bp.desktop) {
    buttonTextStyle.width = getREM(2.375)
    buttonTextStyle.height = getREM(2.375)
    buttonTextStyle.lineHeight = getREM(2.5)
    buttonTextStyle.borderRadius = getREM(2.375 / 2)
  }

  const buttonTextStyle_pointerOver = {
    ...boxShadows.button_pointerOver.web
  } as TextStyle

  const buttonTextStyle_disabled = {
    ...Platform.select({
      web: {
        cursor: 'default'
      }
    })
  } as TextStyle

  const value = {
    color: input.color,
    fontFamily: input.fontFamily,
    fontSize: input.fontSize,
    lineHeight: input.lineHeight,
    borderWidth: 0,
    textAlign: 'center',
    padding: 0,
    backgroundColor: input.backgroundColor,
    minWidth: 0
  } as TextStyle

  if (Platform.OS === 'web') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value.outlineWidth = 0
  }

  if (bp.desktop) {
    value.fontSize = STYLES.fontSize.m
    value.lineHeight = STYLES.lineHeight.m
  }

  const styles = StyleSheet.create({
    container,
    container_focused,
    buttonNativeContainer,
    buttonTextStyle,
    buttonTextStyle_pointerOver,
    buttonTextStyle_disabled,
    value
  })

  return { styles }
}
