export interface GetPaginationProps {
  currentPage: number
  itemsPerPage?: number
  totalItems?: number
}

export const GetPagination = ({ currentPage, itemsPerPage = 10, totalItems = 0 }: GetPaginationProps) => {
  const _lastPage = Math.max(1, Math.ceil(totalItems / itemsPerPage))

  return {
    current: currentPage,
    first: 1,
    last: _lastPage,
    next: Math.min(_lastPage, currentPage + 1),
    previous: Math.max(1, currentPage - 1),
    visible: _lastPage > 1
  }
}