import React, { memo } from 'react'
import { AdminListTypes, getLocaleKey } from '../index'
import { Button, ButtonType, View, Icons } from 'components'
import { STYLES } from 'styles'
import { useStyles } from './styles'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { ViewProps } from 'react-native'

export enum AdminListActionTypes {
  selectAll,
  deselectedAll,
  create,
  edit,
  remove,
  approve,
  decline
}

export interface Props extends ViewProps {
  type: AdminListTypes
  hasItems?: boolean
  hasSelectedItems?: boolean
  selectAllOnPress: () => void
  deselectAllOnPress: () => void
  createOnPress: () => void
  editOnPress: () => void
  removeOnPress: () => void
  approveOnPress: () => void
  declineOnPress: () => void
}

export const AdminListActions = memo(({
  type,
  hasItems = false,
  hasSelectedItems = false,
  selectAllOnPress,
  deselectAllOnPress,
  createOnPress,
  editOnPress,
  removeOnPress,
  approveOnPress,
  declineOnPress,
  style,
  ...props
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { spacing } = STYLES.useStyles()
  const { styles } = useStyles()

  const _localeKey = getLocaleKey(type)

  let _actions = []

  switch (type) {
    case AdminListTypes.organizationTeams:
      _actions = [
        AdminListActionTypes.create,
        AdminListActionTypes.selectAll,
        AdminListActionTypes.deselectedAll,
        AdminListActionTypes.remove
      ]
      break
    case AdminListTypes.organizationMembers:
      _actions = [
        AdminListActionTypes.selectAll,
        AdminListActionTypes.deselectedAll,
        AdminListActionTypes.edit,
        AdminListActionTypes.remove
      ]
      break
    case AdminListTypes.organizationPendingMembers:
      _actions = [
        AdminListActionTypes.selectAll,
        AdminListActionTypes.deselectedAll,
        AdminListActionTypes.approve,
        AdminListActionTypes.decline
      ]
      break
    case AdminListTypes.teamMembers:
      _actions = [
        AdminListActionTypes.selectAll,
        AdminListActionTypes.deselectedAll,
        AdminListActionTypes.edit,
        AdminListActionTypes.remove
      ]
      break
    case AdminListTypes.teamPendingMembers:
      _actions = [
        AdminListActionTypes.selectAll,
        AdminListActionTypes.deselectedAll,
        AdminListActionTypes.approve,
        AdminListActionTypes.decline
      ]
      break
  }

  if (_actions.length === 0) return null

  return (
    <View
      style={[styles.container, style]}
      spacer={spacing.xs}
      testID="AdminListActions"
      {...props}>
      {_actions.map((action) => {
        switch (action) {
          case AdminListActionTypes.selectAll:
            return (
              <Button
                key={action}
                hideLabel
                label={t(`adminListActions:${_localeKey}.selectAll`)}
                type={ButtonType.iconOutlined}
                icon={Icons.SelectAll}
                onPress={selectAllOnPress}
                state={{ disabled: !hasItems }}
              />
            )
          case AdminListActionTypes.deselectedAll:
            return (
              <Button
                key={action}
                hideLabel
                label={t(`adminListActions:${_localeKey}.deselectAll`)}
                type={ButtonType.iconOutlined}
                icon={Icons.DeselectAll}
                onPress={deselectAllOnPress}
                state={{ disabled: !hasItems || !hasSelectedItems }}
              />
            )
          case AdminListActionTypes.create:
            return (
              <Button
                key={action}
                hideLabel
                label={t(`adminListActions:${_localeKey}.create`)}
                type={ButtonType.iconOutlined}
                icon={Icons.Plus}
                onPress={createOnPress}
              />
            )
          case AdminListActionTypes.edit:
            return (
              <Button
                key={action}
                hideLabel
                label={t(`adminListActions:${_localeKey}.edit`)}
                type={ButtonType.iconOutlined}
                icon={Icons.Edit}
                onPress={editOnPress}
                state={{ disabled: !hasItems || !hasSelectedItems }}
              />
            )
          case AdminListActionTypes.remove:
            return (
              <Button
                key={action}
                hideLabel
                label={t(`adminListActions:${_localeKey}.remove`)}
                type={ButtonType.iconOutlined}
                icon={Icons.Bin}
                onPress={removeOnPress}
                state={{ disabled: !hasItems || !hasSelectedItems }}
              />
            )
          case AdminListActionTypes.approve:
            return (
              <Button
                key={action}
                hideLabel
                label={t(`adminListActions:${_localeKey}.approve`)}
                type={ButtonType.iconOutlined}
                icon={Icons.TickThin}
                onPress={approveOnPress}
                state={{ disabled: !hasItems || !hasSelectedItems }}
              />
            )
          case AdminListActionTypes.decline:
            return (
              <Button
                key={action}
                hideLabel
                label={t(`adminListActions:${_localeKey}.decline`)}
                type={ButtonType.iconOutlined}
                icon={Icons.Bin}
                onPress={declineOnPress}
                state={{ disabled: !hasItems || !hasSelectedItems }}
              />
            )
        }
      })}
    </View>
  )
})