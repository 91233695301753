import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const {
    fontBold,
    spacing,
    bp,
    viewports,
    colors
  } = STYLES.useStyles()

  const breakpoint = bp.gt(viewports.mobile_l)

  const container = {
    display: 'flex',
    flexDirection: breakpoint
      ? 'row'
      : 'column',
    alignSelf: breakpoint
      ? 'flex-start'
      : 'center',
    justifyContent: 'center',
    alignItems: breakpoint
      ? 'flex-start'
      : 'center',
    flex: 1,
    width: '100%',
    maxWidth: breakpoint
      ? null
      : STYLES.grid.maxInputWidth
  } as ViewStyle

  const content = {
    flex: 1,
    width: '100%',
    alignSelf: 'center'
  } as ViewStyle

  const icon = {
    marginVertical: breakpoint
      ? 0
      : spacing.l,
    marginRight: breakpoint
      ? spacing.l
      : 0
  } as ViewStyle

  const title = {
    ...fontBold,
    fontSize: STYLES.fontSize.xl,
    lineHeight: STYLES.lineHeight.xl,
    marginBottom: spacing.xs,
    color: colors.brand,
    alignSelf: breakpoint
      ? 'flex-start'
      : 'center',
    textAlign: breakpoint
      ? 'auto'
      : 'center'
  } as TextStyle

  const row = {
    alignItems: breakpoint
      ? 'flex-start'
      : 'center'
  } as ViewStyle

  if (bp.gt(viewports.mobile_l)) {
    row.alignItems = "flex-start"
  }

  const pill = {
    marginBottom: 0,
    alignSelf: breakpoint
      ? 'flex-start'
      : 'center'
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    content,
    icon,
    title,
    pill,
    row
  })

  return { styles, breakpoint }
}
