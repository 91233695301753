import React from 'react'
import { Heading } from 'components'
import SimpleMarkdown from 'simple-markdown'

export default Object.assign({}, SimpleMarkdown.defaultRules.heading, {
  react: (
    node: SimpleMarkdown.SingleASTNode,
    output: SimpleMarkdown.Output<React.ReactNode>,
    state: SimpleMarkdown.State
  ) => {
    return (
      <Heading key={state.key} level={node.level} style={state.containerStyle}>
        {output(node.content, state)}
      </Heading>
    )
  },
  match: SimpleMarkdown.blockRegex(/^ *(#{1,6}) *([^\n]+?) *#* *(?:\n *)*\n/)
})
