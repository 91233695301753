import React, { useState, useRef, useEffect, useCallback, memo, useMemo } from 'react'
import { Animated, Dimensions, Text } from 'react-native'
import { Button, ButtonType } from 'components'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { useAnalytics, useNativeDriver } from 'utils'
import { EventRegister } from 'react-native-event-listeners'

export enum MessageType {
  notice = 'notice',
  alert = 'alert'
}

interface StateProps {
  text: string
  isCloseable?: boolean
  onClose?: () => void
  type?: MessageType
  isPersistent?: boolean
  isOpen?: boolean
}

export const duration = 600

export const delayDuration = 10000

export const MessageEventKey = 'showMessageKey'

export const ShowMessage = (state: StateProps) => {
  EventRegister.emit(MessageEventKey, state)
}

export const MessageContainer = memo(() => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { trackEvent, trackingEvents } = useAnalytics()
  const _windowHeight = Dimensions.get('window').height
  const _translateY = useRef(new Animated.Value(_windowHeight * -1)).current
  const _width = useRef(new Animated.Value(100)).current
  const _message = useRef('')

  const _initialState = useMemo(
    () => ({
      text: '',
      isCloseable: false,
      onClose: undefined,
      type: MessageType.alert,
      isPersistent: false,
      isOpen: false
    }),
    []
  )

  const [_state, _setState] = useState<StateProps>(_initialState)

  const _delay = useRef(
    Animated.timing(_width, {
      toValue: 0,
      duration: delayDuration,
      useNativeDriver: false
    })
  )

  const _hide = useCallback(
    (callback?: () => void) => {
      _delay.current.reset()

      Animated.timing(_translateY, {
        toValue: _windowHeight * -1,
        duration: duration,
        useNativeDriver
      }).start(() => {
        _message.current = _initialState.text
        if (_state.onClose) {
          _state.onClose()
        }

        if (callback) {
          callback()
        } else {
          _setState(_initialState)
        }
      })
    },
    [_initialState, _state, _translateY, _windowHeight]
  )

  const _show = useCallback(() => {
    Animated.timing(_translateY, {
      toValue: 0,
      duration: duration,
      useNativeDriver
    }).start(() => {
      if (!_state.isPersistent) {
        _delay.current.start(({ finished }) => {
          if (finished) {
            _hide()
          }
        })
      }
    })
  }, [_hide, _state.isPersistent, _translateY])

  const _onClose = async () => {
    trackEvent({
      eventName: trackingEvents.closeMessage,
      props: {
        text: _state.text
      }
    })
    _hide()
  }

  const _updateState = useCallback(
    (newState: StateProps) => {
      _setState({
        ..._initialState,
        isOpen: true,
        ...newState
      })
    },
    [_initialState]
  )

  useEffect(() => {
    if (_state.text === _message.current || _state.text === '') return

    _message.current = _state.text

    _show()
  }, [_show, _state])

  useEffect(() => {
    const _listener = EventRegister.addEventListener(
      MessageEventKey,
      (newState: StateProps) => {
        if (_message.current !== _initialState.text) {
          _hide(() => {
            _updateState(newState)
          })
        } else {
          _updateState(newState)
        }
      }
    )

    return () => {
      EventRegister.removeEventListener(_listener as string)
    }
  }, [_hide, _initialState.text, _updateState])

  return (
    <Animated.View
      style={[
        styles.container,
        _state.type && styles[`container__${_state.type}`],
        {
          transform: [{ translateY: _translateY }]
        }
      ]}>
      {!_state.isPersistent && (
        <Animated.View
          style={[
            styles.indicator,
            {
              width: _width.interpolate({
                inputRange: [0, 100],
                outputRange: ['0%', '100%']
              })
            }
          ]}
        />
      )}
      <Text style={styles.text}>{_state.text}</Text>
      {_state.isCloseable && (
        <Button
          type={ButtonType.blank}
          textStyle={styles.close}
          onPress={_onClose}
          label={t('message:close')}
        />
      )}
    </Animated.View>
  )
})
