import React, { memo } from 'react'
import { Image, ImageBackground, ViewProps, ImageURISource } from 'react-native'
import { ScreenBackgroundImage } from 'components/ScreenBackgroundImage'
import { Header, View, Heading, SvgImage } from 'components'
import { useStyles } from './styles'

export interface Props extends ViewProps {
  backgroundImage?: ImageURISource
  logoImage?: ImageURISource
  logoLabel?: string
  name?: string
  useFallbackLogo?: boolean
  fallbackLogoImage?: Image
}

export const ProHeader = memo(
  ({
    backgroundImage,
    logoImage,
    logoLabel,
    name,
    useFallbackLogo = true,
    fallbackLogoImage,
    style,
    ...props
  }: Props) => {
    const { styles } = useStyles()
    const hasBackgroundImage = !!backgroundImage?.uri
    const hasLogo = !!logoImage?.uri
    const hasName = !!name
    const fallbackLogoSource = (fallbackLogoImage || <SvgImage image={require('images/Workplace.svg')} />) as React.ReactNode

    return (
      <>
        <Header
          testID="proHeader"
          backgroundImage={
            hasBackgroundImage ? (
              <ImageBackground
                style={styles.proHeaderImage}
                source={backgroundImage}
                testID="proHeaderImage"
                resizeMode="cover"
              />
            ) : (
              <ScreenBackgroundImage color1="#F28E84" color2="#E26F63" />
            )
          }
          style={[styles.header, style]}
          contentStyle={styles.headerContent}
          {...props}>
          {hasLogo ? (
            <View style={styles.logo} testID="proHeaderLogo">
              <Image
                source={logoImage}
                accessibilityLabel={logoLabel}
                style={styles.logoImage}
              />
            </View>
          ) : (
            useFallbackLogo && (
              <View style={styles.logo}>
                <View style={styles.logoImage}>{fallbackLogoSource}</View>
              </View>
            )
          )}
        </Header>

        {hasName && (
          <View style={styles.nameContainer}>
            <Heading level={1} style={styles.name}>
              {name}
            </Heading>
          </View>
        )}
      </>
    )
  }
)
