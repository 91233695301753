import { useContext } from 'react'
import { StyleSheet, Platform, TextStyle } from 'react-native'
import { STYLES } from 'styles'
import { HeaderContext } from 'components'

export const useStyles = () => {
  const { textColor } = useContext(HeaderContext)
  const { colors, fontBold } = STYLES.useStyles()

  const valueContainer = {
    textAlign: 'center',
    ...Platform.select({
      web: {
        wordBreak: 'break-word'
      }
    })
  } as TextStyle

  const value = {
    ...fontBold,
    color: textColor || colors.brand,
    fontSize: STYLES.fontSize.xl,
    lineHeight: STYLES.lineHeight.xl
  } as TextStyle

  const unit = {
    ...fontBold,
    color: textColor || colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.xl,
  } as TextStyle

  const styles = StyleSheet.create({
    valueContainer,
    value,
    unit
  })

  return { styles }
}
