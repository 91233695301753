import { StyleSheet, TextStyle, ViewStyle, Platform } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { fontRegular, spacing, bp } = STYLES.useStyles()

  let _spacer = spacing.normal

  if (bp.tablet) {
    _spacer = spacing.s
  }
  
  const container = {
    width: '100%'
  } as ViewStyle

  const emptyMessage = {
    ...fontRegular,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m,
    textAlign: 'center',
    maxWidth: STYLES.grid.maxTextWidth,
    alignSelf: 'center'
  } as TextStyle

  const grid = {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginHorizontal: 'auto',
    maxWidth: Platform.OS === 'web' ? `calc(${STYLES.grid.maxTextWidth} + (${_spacer} * 2))` : STYLES.grid.maxTextWidth + (_spacer * 2),
    width: '100%'
  } as ViewStyle

  const grid_single: ViewStyle = {
    alignItems: 'center',
    justifyContent: 'center'
  }

  const gridItem = {
    paddingBottom: spacing.l,
    width: '100%',
    alignItems: 'center'
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    gridItem.width = '50%'
    gridItem.paddingLeft = _spacer
    gridItem.paddingRight = _spacer
  }

  if (bp.desktop) {
    gridItem.paddingBottom = spacing.xl
  }

  const gridItem_single: ViewStyle = {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: STYLES.grid.maxCardWidth
  }

  const toggleContainer = {
    alignSelf: 'center',
    marginTop: spacing.xs
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    emptyMessage,
    grid,
    grid_single,
    gridItem,
    gridItem_single,
    toggleContainer
  })

  return { styles }
}
