// https://docs.expo.dev/versions/latest/react-native/textinput/#textcontenttype---ios

import { TextInputProps } from 'react-native'

export default {
  none: 'none',
  URL: 'URL',
  addressCity: 'addressCity',
  addressCityAndState: 'addressCityAndState',
  addressState: 'addressState',
  countryName: 'countryName',
  creditCardNumber: 'creditCardNumber',
  emailAddress: 'emailAddress',
  familyName: 'familyName',
  fullStreetAddress: 'fullStreetAddress',
  givenName: 'givenName',
  jobTitle: 'jobTitle',
  location: 'location',
  middleName: 'middleName',
  name: 'name',
  namePrefix: 'namePrefix',
  nameSuffix: 'nameSuffix',
  nickname: 'nickname',
  organizationName: 'organizationName',
  postalCode: 'postalCode',
  streetAddressLine1: 'streetAddressLine1',
  streetAddressLine2: 'streetAddressLine2',
  sublocality: 'sublocality',
  telephoneNumber: 'telephoneNumber',
  username: 'username',
  password: 'password',
  newPassword: 'newPassword',
  oneTimeCode: 'oneTimeCode'
} as {
  [key: string]: TextInputProps['textContentType']
}