import React, { memo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonType,
  ButtonSize,
  CategoryPill,
  Text,
  Icons,
  SvgImage,
  View
} from 'components'
import { useLang } from './Lang'
import { STYLES } from 'styles'
import { useStyles } from './styles'

export interface AnnouncementProps {
  id: string
  body: string
  category?: string
  primaryActionLabel?: string
  primaryActionOnPress?: (id: string) => void
  backgroundColor?: string
  Image?: typeof SvgImage
}

interface Props extends AnnouncementProps {
  containerStyle?: StyleProp<ViewStyle>
  onRead: (id: string) => void
}

export const Announcement = memo(
  ({
    body,
    containerStyle,
    onRead,
    id,
    category,
    primaryActionLabel,
    primaryActionOnPress,
    backgroundColor,
    Image
  }: Props) => {
    useLang()
    const { t } = useTranslation()
    const { styles } = useStyles()
    const { COLORS, bp } = STYLES.useStyles()

    const _hasActionButton = !!primaryActionLabel && !!primaryActionOnPress
    const _hasBackgroundColor = !!backgroundColor
    const _hasImage = !!Image && bp.is([bp.tablet, bp.desktop])

    const _onRead = () => {
      onRead(id!)
    }

    const _primaryActionOnPress = () => {
      primaryActionOnPress?.(id!)
    }

    if (!id) return null

    return (
      <View
        style={[
          styles.announcement,
          containerStyle,
          _hasBackgroundColor && {
            backgroundColor
          },
          _hasImage && styles.announcement_image
        ]}
        testID="Announcement">
        <View style={styles.announcementRow}>
          <View style={styles.announcementContent}>
            <View style={styles.announcementHeader}>
              <CategoryPill
                backgroundColor={COLORS.studio}
                label={category || t('announcements:categoryPill')}
              />
            </View>
            <Text containerStyle={styles.textContainer} textStyle={styles.text}>
              {body}
            </Text>
            {_hasActionButton && (
              <Button
                type={ButtonType.brand}
                label={primaryActionLabel}
                onPress={_primaryActionOnPress}
                containerStyle={styles.primaryActionContainer}
                textStyle={styles.primaryActionLabel}
                size={ButtonSize.small}
              />
            )}
          </View>
          {_hasImage && (
            <Image
              style={styles.announcementBackground}
              testID="AnnouncementImage"
            />
          )}
        </View>
        <Button
          hideLabel
          icon={Icons.Close}
          iconColor={COLORS.aubergine}
          label={t('announcements:button')}
          onPress={_onRead}
          type={ButtonType.blank}
          containerStyle={styles.announcementCloseContainer}
          textStyle={styles.announcementClose}
          iconContainerStyle={styles.announcementCloseIconContainer}
        />
      </View>
    )
  }
)
