import React, { useState, createContext, useContext, useEffect } from 'react'
import { Dimensions, Platform, EmitterSubscription } from 'react-native'

const WindowDimensionsContext = createContext<number>(0)

interface ProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

export const UseWindowDimensionsProvider = ({ children }: ProviderProps) => {
  const [_width, _setWidth] = useState(Dimensions.get('window').width)

  const _changeWidth = () => {
    _setWidth(Dimensions.get('window').width)
  }

  useEffect(() => {
    let subscription: EmitterSubscription

    if (Platform.OS === 'web') {
      window?.addEventListener?.('resize', _changeWidth)
    } else {
      subscription = Dimensions.addEventListener('change', _changeWidth)
    }

    return () => {
      if (Platform.OS === 'web') {
        window?.removeEventListener?.('resize', _changeWidth)
      }

      subscription?.remove()
    }
  }, [])

  return (
    <WindowDimensionsContext.Provider value={_width}>
      {children}
    </WindowDimensionsContext.Provider>
  )
}

export const useWindowDimensions = () => {
  const width = useContext(WindowDimensionsContext)

  return {
    width
  }
}
