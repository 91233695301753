import { useMemo } from 'react'
import { useWindowDimensions } from 'utils'
import { PixelRatio, Platform } from 'react-native'
import { baseSize } from '../../index'

// max rem viewport widths
const viewports = {
  mobile_xs: 16.24375, // 259.9
  mobile_s: 19.99375, // 319.9
  mobile_normal: 23.43125, // 374.9
  mobile_m: 26.55625,  // 424.9
  mobile_l: 39.99375, // 639.9
  mobile_xl: 47.99375, // 767.9
  tablet: 63.99375, // 1023.9
  desktop_s: 74.99375, // 1199.9
  desktop_normal: 89.99375, // 1439.9
}

type ViewportsType = {
  [key in keyof typeof viewports]: boolean
}

export interface BPType extends ViewportsType {
  is: (sizes: boolean[]) => boolean
  not: (sizes: boolean[]) => boolean
  gt: (viewport: number) => boolean
  desktop: boolean
  desktop_m: boolean
}

export const useBreakpoints = () => {
  const { width } = useWindowDimensions()

  const _remWidth = useMemo(() => {
    let _width = width / baseSize / PixelRatio.getFontScale()

    if (Platform.OS === 'web' && window?.document?.documentElement) {
      _width = width / parseFloat(window.getComputedStyle(window.document.documentElement, null).getPropertyValue("font-size"))
    }

    return _width
  }, [width])

  const bp: BPType = {
    is: (sizes) => sizes.includes(true),
    not: (sizes) => !sizes.includes(true),
    gt: (viewport) => _remWidth > viewport,
    mobile_xs: _remWidth <= viewports.mobile_xs,
    mobile_s: _remWidth > viewports.mobile_xs && _remWidth <= viewports.mobile_s,
    mobile_normal: _remWidth > viewports.mobile_s && _remWidth <= viewports.mobile_normal,
    mobile_m: _remWidth > viewports.mobile_normal && _remWidth <= viewports.mobile_m,
    mobile_l: _remWidth > viewports.mobile_m && _remWidth <= viewports.mobile_l,
    mobile_xl: _remWidth > viewports.mobile_l && _remWidth <= viewports.mobile_xl,
    tablet: _remWidth > viewports.mobile_xl && _remWidth <= viewports.tablet,
    desktop: _remWidth > viewports.tablet,
    desktop_s: _remWidth > viewports.tablet && _remWidth <= viewports.desktop_s,
    desktop_normal: _remWidth > viewports.desktop_s && _remWidth <= viewports.desktop_normal,
    desktop_m: _remWidth > viewports.desktop_normal
  }

  return { bp, viewports }
}
