import { Action, StepState } from 'models'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { ArraySorter } from 'utils'

interface Props {
  showCompleted: boolean
}

export const useAllCompletedSteps = ({ showCompleted }: Props) => {
  const { i18n } = useTranslation()

  const _getAllCompletedSteps: () => Promise<Action[]> = async () => {
    const { data } = await Action
      .where({ state: StepState.complete })
      .includes(['impact', { themes: 'theme' }])
      .select({
        actions: ['summary', 'param'],
        impacts: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ],
        themes: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ]
      })
      .extraParams({ paginate: false, locale: i18n.language })
      .all()

    data?.sort((item1, item2) => ArraySorter(item1, item2, 'summary', 'asc'))

    return data
  }

  const { data: allCompletedSteps, isFetching: isLoadingCompletedSteps } = useQuery(
    ['data', 'allCompletedSteps', i18n.language],
    _getAllCompletedSteps,
    {
      enabled: showCompleted
    }
  )

  return {
    allCompletedSteps,
    isLoadingCompletedSteps
  }
}