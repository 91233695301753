import { STYLES, getREM } from 'styles'
import { TypeStylesProps } from './index'

export const useLink = () => {
  const { colors, fontSemiBold } = STYLES.useStyles()

  const link = {
    label: {
      ...fontSemiBold,
      backgroundColor: colors.transparent,
      color: colors.text,
      textDecorationLine: 'underline'
    },
    small: { fontSize: STYLES.fontSize.s, lineHeight: STYLES.lineHeight.s },
    normal: {
      fontSize: STYLES.fontSize.normal,
      lineHeight: STYLES.lineHeight.normal
    },
    large: { fontSize: STYLES.fontSize.l, lineHeight: STYLES.lineHeight.l },
    focused: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      outlineOffset: getREM(0.25),
      borderRadius: getREM(0.25)
    },
    pointerOver: {
      textDecorationLine: 'underline'
    }
  } as TypeStylesProps

  return { link }
}
