import React, { memo, useState } from 'react'
import { View, Text, StyleProp, ViewStyle } from 'react-native'
import { Button, ButtonType, SvgImage } from 'components'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import { getREM } from 'styles'

export interface Props {
  value: number
  style?: StyleProp<ViewStyle>
  label?: string
  Image?: typeof SvgImage
  imageSize?: number
  linkText?: string
  linkURL?: string
}

export const ValueBox = memo(({
  value,
  style,
  label,
  Image,
  imageSize = getREM(5),
  linkText,
  linkURL
}: Props) => {
  const { t } = useTranslation()
  const { styles } = useStyles()
  const [_isPointerOver, _setIsPointerOver] = useState(false)

  const _hasLabel = !!label
  const _hasImage = !!Image
  const _hasLink = !!linkText && !!linkURL

  /* istanbul ignore next */
  const _onPointerEnter = () => {
    _setIsPointerOver(true)
  }
  /* istanbul ignore next */
  const _onPointerLeave = () => {
    _setIsPointerOver(false)
  }

  return (
    <View
      style={[
        styles.container,
        style,
        /* istanbul ignore next */
        _isPointerOver && styles.container_pointerOver
      ]}>
      <Text textBreakStrategy="simple" style={styles.value}>
        {t('format:number', { value })}
      </Text>
      {_hasLabel && (
        <Text textBreakStrategy="simple" style={styles.label}>
          {label}
        </Text>
      )}
      {_hasImage && (
        <View
          style={[
            styles.imageContainer,
            { width: imageSize, height: imageSize }
          ]}>
          <Image testID="ValueBoxImage" style={styles.image} />
        </View>
      )}
      {_hasLink && (
        <>
          <Button
            type={ButtonType.link}
            isFake
            label={linkText}
            textStyle={styles.fakeLink}
          />
          <Button
            type={ButtonType.blank}
            label={linkText}
            containerStyle={styles.linkContainer}
            textStyle={styles.linkText}
            href={linkURL}
            hideLabel
            onPointerEnter={_onPointerEnter}
            onPointerLeave={_onPointerLeave}
          />
        </>
      )}
    </View>
  )
})
