import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class ActionTip extends ApplicationRecord {
  static jsonapiType = 'actions_tips';

  @Attr({ persist: false }) description!: string

  @Attr({ persist: false }) order!: number
}
