import React, { memo } from 'react'
import { SVG } from 'components'
import { getREM } from 'styles'
import { IconProps } from '../index'

export const Crown = memo(({ size = getREM(2), style }: IconProps) => {
  return (
    <SVG.Svg
      viewBox="0 0 88 66"
      fill="none"
      testID="Crown"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}>
      <SVG.Path
        d="M42.017 21.4586C23.6089 24.3903 16.4258 33.0331 15.1353 36.9881L48.2106 39.6764L70.8183 30.0402C68.8879 25.9581 60.4251 18.5268 42.017 21.4586Z"
        fill="#A149B0"
      />
      <SVG.Path
        d="M19.7264 50.7576L7.09352 27.4065L8.12247 25.8716C16.845 35.3719 26.2742 35.2869 29.8985 34.0568C37.5322 32.2926 39.3086 17.9017 39.2426 10.9267L41.8219 10.5159C48.6157 28.0792 55.6188 30.5154 58.2712 29.538C63.6282 31.1918 72.0977 20.6116 75.6629 15.1148L77.5954 16.178L72.746 42.3135L73.613 47.7572C74.1766 54.2482 56.5646 57.9802 47.6881 59.0348C26.6922 63.0055 20.9476 59.246 20.6998 56.8699L19.7264 50.7576Z"
        fill="white"
      />
      <SVG.Path
        d="M19.7264 50.7576L7.09352 27.4065L8.12247 25.8716C16.845 35.3719 26.2742 35.2869 29.8985 34.0568C37.5322 32.2926 39.3086 17.9017 39.2426 10.9267L41.8219 10.5159C48.6157 28.0792 55.6188 30.5154 58.2712 29.538C63.6282 31.1918 72.0977 20.6116 75.6629 15.1148L77.5954 16.178L72.746 42.3135L73.613 47.7572C74.1766 54.2482 56.5646 57.9802 47.6881 59.0348C26.6922 63.0055 20.9476 59.246 20.6998 56.8699L19.7264 50.7576Z"
        fill="#FEC701"
      />
      <SVG.Circle
        cx="5.98499"
        cy="24.9682"
        r="3.96237"
        transform="rotate(-9.04917 5.98499 24.9682)"
        fill="white"
      />
      <SVG.Circle
        cx="5.98499"
        cy="24.9682"
        r="3.96237"
        transform="rotate(-9.04917 5.98499 24.9682)"
        fill="#FEC701"
      />
      <SVG.Circle
        cx="40.5231"
        cy="10.1398"
        r="3.96237"
        transform="rotate(-9.04917 40.5231 10.1398)"
        fill="white"
      />
      <SVG.Circle
        cx="40.5231"
        cy="10.1398"
        r="3.96237"
        transform="rotate(-9.04917 40.5231 10.1398)"
        fill="#FEC701"
      />
      <SVG.Circle
        cx="77.1559"
        cy="13.6331"
        r="3.96237"
        transform="rotate(-9.04917 77.1559 13.6331)"
        fill="white"
      />
      <SVG.Circle
        cx="77.1559"
        cy="13.6331"
        r="3.96237"
        transform="rotate(-9.04917 77.1559 13.6331)"
        fill="#FEC701"
      />
      <SVG.Path
        d="M41.576 38.4V36.336H45.432V48H43.128V38.4H41.576Z"
        fill="white"
      />
      <SVG.Path
        d="M41.576 38.4V36.336H45.432V48H43.128V38.4H41.576Z"
        fill="#5F2B68"
      />
    </SVG.Svg>
  )
})
