import { Model, BelongsTo } from 'spraypaint'
import { Footprint } from './Footprint'
import { GasEstimate } from './GasEstimate'

@Model()
export class HouseholdsGasFootprint extends Footprint {
  static jsonapiType = 'households_gas_footprints'

  static endpoint = '/households/gas/footprints'

  @BelongsTo(GasEstimate) estimate!: GasEstimate
}
