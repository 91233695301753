import { StyleSheet, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { colors, fontSemiBold, fontRegular, bp } = STYLES.useStyles()

  const leaderboardPointsValue: TextStyle = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.l,
    lineHeight: STYLES.lineHeight.l,
    color: colors.brand
  }

  if (bp.mobile_xl) {
    leaderboardPointsValue.fontSize = STYLES.fontSize.m
    leaderboardPointsValue.lineHeight = STYLES.lineHeight.m
  }

  const leaderboardPointsValueUnit: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.l,
    color: colors.brand
  }

  if (bp.mobile_xl) {
    leaderboardPointsValueUnit.lineHeight = STYLES.lineHeight.m
  }

  const styles = StyleSheet.create({
    leaderboardPointsValue,
    leaderboardPointsValueUnit
  })

  return { styles }
}
