import React, {
  useContext,
  useState,
  useEffect,
  memo,
  useCallback
} from 'react'
import { ViewProps } from 'react-native'
import { PageProps } from '../index'
import {
  SelectionCards,
  SpeechBubble,
  Form,
  Select,
  ActivityIndicator
} from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { Car as CarImage, CarHeightRatio } from 'images/Car'
import { STYLES } from 'styles'
import { useQuery } from 'react-query'

type SelectionCardsProps = React.ComponentProps<typeof SelectionCards>
type SelectProps = React.ComponentProps<typeof Select>

export const CarDetails = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const {
    progress,
    getCarEstimates,
    getCarModels,
    getCarFootprint,
    saveCarFootprint
  } = useContext(OnboardingGroupContext)
  const { imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_selectedIdsCarEstimates, _setSelectedIdsCarEstimates] = useState<
    string[]
  >([])
  const [_selectedIdCarModel, _setSelectedIdCarModel] =
    useState<SelectProps['selectedId']>()
  const [_isUpdating, _setIsUpdating] = useState(false)
  const { data: _carEstimates, isLoading: isLoadingCarEstimates } = useQuery(
    ['onboarding', 'carEstimates'],
    getCarEstimates
  )
  const { data: _carModels, isLoading: isLoadingCarModels } = useQuery(
    ['onboarding', 'carModels'],
    getCarModels
  )
  const { data: _carFootprint } = useQuery(
    ['onboarding', 'carFootprint'],
    getCarFootprint
  )
  const [_showError, _setShowError] = useState(false)

  const _isDisabled =
    _selectedIdsCarEstimates.length === 0 ||
    typeof _selectedIdCarModel === 'undefined'

  const _onPress = async () => {
    _setShowError(false)

    _setIsUpdating(true)

    const _estimate = _carEstimates?.find(
      (item) => item.id === _selectedIdsCarEstimates[0]
    )

    const _model = _carModels?.find((item) => item.id === _selectedIdCarModel)

    const _success = await saveCarFootprint(_estimate, _model)

    _setIsUpdating(false)

    if (_success) {
      navigation.navigate('Flights')
      return
    }

    _setShowError(true)
  }

  const _onSelectCarEstimates: SelectionCardsProps['onSelect'] = (id) =>
    _setSelectedIdsCarEstimates(id)

  const _onSelectCarModel: SelectProps['onSelect'] = (id) =>
    _setSelectedIdCarModel(id)

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.didYouKnow')}
        text={t('onboardingCarDetails:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageHeight = imageWidth * CarHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <CarImage
          style={{
            width: imageWidth,
            height: _imageHeight,
            marginTop: 0.42857142857142855 * _imageHeight
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, imageWidth]
  )

  useEffect(() => {
    if (_carFootprint) {
      if (
        _carFootprint?.estimate?.id &&
        _selectedIdsCarEstimates.length === 0
      ) {
        _setSelectedIdsCarEstimates([_carFootprint.estimate.id])
      }

      if (
        _carFootprint?.model?.id &&
        typeof _selectedIdCarModel === 'undefined'
      ) {
        _setSelectedIdCarModel(_carFootprint.model.id)
      }
    }
  }, [_carFootprint, _selectedIdsCarEstimates.length, _selectedIdCarModel])

  if (_isUpdating || isLoadingCarEstimates || isLoadingCarModels) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="CarDetails" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingCarDetails:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.CarDetails) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingCarDetails:title')}</Heading>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <SelectionCards
              legend={t('onboardingCarDetails:title')}
              items={
                /* istanbul ignore next */
                _carEstimates?.map((item) => ({
                  id: item.id!,
                  label: item.name,
                  description: item.description
                })) || []
              }
              onSelect={_onSelectCarEstimates}
              selectedIds={_selectedIdsCarEstimates}
              style={{ marginBottom: spacing.xxl }}
            />

            <Heading>{t('onboardingCarDetails:carModelFieldLabel')}</Heading>

            <Select
              hideLabel
              label={t('onboardingCarDetails:carModelFieldLabel')}
              emptyLabel={t('onboardingCarDetails:carModelFieldEmptyLabel')}
              items={
                /* istanbul ignore next */
                _carModels?.map((item) => ({
                  id: item.id!,
                  label: item.name
                })) || []
              }
              onSelect={_onSelectCarModel}
              selectedId={_selectedIdCarModel}
            />
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton
            onPress={_onPress}
            state={{
              disabled: _isDisabled
            }}
          />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
