import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const CheckList = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="CheckList"
      viewBox="0 0 20 15"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M1 2.13514L2.88525 4L6 1"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <SVG.Path
        d="M1 7.13514L2.88525 9L6 6"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <SVG.Path
        d="M1 12.1351L2.88525 14L6 11"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <SVG.Path
        d="M8 2.5H19"
        stroke={color ?? colors.text}
        strokeLinecap="round"
      />
      <SVG.Path
        d="M8 7.5H19"
        stroke={color ?? colors.text}
        strokeLinecap="round"
      />
      <SVG.Path
        d="M8 12.5H19"
        stroke={color ?? colors.text}
        strokeLinecap="round"
      />
    </SVG.Svg>
  )
})
