import {
  Footprint
} from 'models'
import { useLocation, UseLocationType } from './useLocation'
import { useCar, UseCarType } from './useCar'
import { useAviation, UseAviationType } from './useAviation'
import { useDiet, UseDietType } from './useDiet'
import { useHouse, UseHouseType } from './useHouse'
import { useGoal, UseGoalType } from './useGoal'
import { usePerson, UsePersonType } from './usePerson'
import { useStep, UseStepType } from './useStep'

export interface DefaultProps {
  source: Footprint['source']
  globalId: string
  person: UsePersonType['person']
}

type BaseType = {
  isOnboarded: boolean
  globalId: string
  onComplete?: () => void
}

export type OnboardingGroupControllerInitType = {
  onComplete?: () => void
}

export type UseOnboardingGroupControllerType = BaseType &
  UseLocationType &
  UseCarType &
  UseAviationType &
  UseDietType &
  UseHouseType &
  UseGoalType &
  UseStepType &
  UsePersonType

const source = 'estimated'
const globalId = 'null'

export class OnboardingGroupController {
  public constructor(init?: OnboardingGroupControllerInitType) {
    Object.assign(this, init)
  }

  useController: () => UseOnboardingGroupControllerType = () => {
    const { person, isLoading, ...extraPersonProps } = usePerson()

    const props: DefaultProps = {
      source,
      globalId,
      person
    }

    const location = useLocation(props)
    const car = useCar(props)
    const aviation = useAviation(props)
    const diet = useDiet(props)
    const house = useHouse(props)
    const goal = useGoal()
    const step = useStep()

    return {
      isLoading,
      person,
      ...extraPersonProps,
      isOnboarded: person?.onboarded || false,
      globalId,
      ...this,
      ...location,
      ...car,
      ...aviation,
      ...diet,
      ...house,
      ...goal,
      ...step
    }
  }
}