import i18next, { LanguageDetectorModule } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector, { LocalePickerKey } from './LanguageDetector'
import { DefaultLocale, Locales } from './Locales'

export { Locales, DefaultLocale, LocalePickerKey }

export const i18n = i18next.createInstance()

i18n
  .use(LanguageDetector as unknown as LanguageDetectorModule)
  .use(initReactI18next)
  .init({
    appendNamespaceToMissingKey: true,
    defaultNS: '',
    resources: {
      en: {
        format: {
          number: '{{value, number}}'
        }
      }
    },
    supportedLngs: Object.keys(Locales),
    fallbackLng: DefaultLocale,
    react: {
      useSuspense: false
    },
    compatibilityJSON: 'v3'
  })
