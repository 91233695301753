import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Team } from './Team'

@Model()
export class GroupMembershipTeam extends ApplicationRecord {
  static jsonapiType = 'group_membership_teams'

  @Attr({ persist: true }) admin!: boolean
  @Attr({ persist: false }) email!: string
  @Attr({ persist: false }) fullName!: string
  @Attr({ persist: false }) avatar!: string
  @Attr({ persist: false }) teams!: {
    name: string
    admin: boolean
  }[]

  @BelongsTo(Team) team!: Team
}
