import { StyleSheet, ViewStyle } from 'react-native'

export const useStyles = () => {
  const stepCard = {
    width: '100%',
    flexGrow: 1
  } as ViewStyle

  const styles = StyleSheet.create({
    stepCard
  })

  return { styles }
}
