import { DataType, RecordKeyType } from '../getData'

export const updateItems = async (data: DataType, ids: string[], key: RecordKeyType, value: unknown) => {
  if (!data || data.length === 0 || !ids || ids.length === 0) return false

  let _success = false

  for (let i = data.length - 1; i >= 0; i--) {
    const _item = data[i]

    if (typeof _item !== 'undefined' && typeof _item[key] !== 'undefined') {
      const _index = ids.indexOf(_item.id!)

      if (_index > -1) {
        _item[key] = value as never
        _success = await _item.save()

        if (!_success) break

        ids.splice(_index, 1)
      }
    }

    if (ids.length === 0) break
  }

  return _success
}