import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class ActionFact extends ApplicationRecord {
  static jsonapiType = 'actions_facts';

  @Attr({ persist: false }) description!: string

  @Attr({ persist: false }) order?: number
}
