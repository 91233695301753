import { SpraypaintBase, Model } from 'spraypaint'

@Model()
export class ApplicationRecord extends SpraypaintBase {

  static baseUrl: string
  static apiNamespace: string
  static clientApplication: string

  /*
   * By default spraypaint will retrieve the JWT token from a similarly named local storage entry.
   * For clarity the token should be explicitly retrieved from the authentication provider.
   */
  static generateAuthHeader(token: string) {
    return `Bearer ${token}`
  }
}
