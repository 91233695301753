import { AviationEstimate, PeopleAviationFootprint, PeopleAviationFootprintEstimate } from 'models'
import { DefaultProps } from '../index'
import { useTranslation } from 'react-i18next'

export type UseAviationType = {
  getAviationEstimates: () => Promise<AviationEstimate[] | undefined>
  getAviationFootprint: () => Promise<PeopleAviationFootprint | null>
  saveAviationFootprint: (estimates: AviationEstimate[], values: { [id: string]: number }) => Promise<boolean | undefined>
}

export const useAviation = ({
  globalId,
  source,
  person
}: DefaultProps) => {
  const { i18n } = useTranslation()

  const getAviationEstimates: UseAviationType['getAviationEstimates'] = async () => {
    return (await AviationEstimate
      .where({ region_id: person?.locationsRegionId || globalId })
      .select(['name', 'description'])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()
    ).data
  }

  const getAviationFootprint: UseAviationType['getAviationFootprint'] = async () => {
    return (await PeopleAviationFootprint
      .order({ created_at: 'desc' })
      .includes({ 'footprintEstimates': ['footprint', 'estimate'] })
      .select({
        peopleAviationFootprints: [''],
        aviationEstimates: [''],
        peopleAviationFootprintEstimates: ['quantity']
      })
      .extraParams({ locale: i18n.language })
      .first()
    ).data
  }

  const saveAviationFootprint: UseAviationType['saveAviationFootprint'] = async (estimates, values) => {
    if (estimates && values) {
      const { data: oldFootprints } = await PeopleAviationFootprint.select(['']).extraParams({ paginate: false, locale: i18n.language }).all()

      if (oldFootprints && oldFootprints.length > 0) {
        for (let i = 0; i < oldFootprints.length; i++) {
          await oldFootprints[i]?.destroy()
        }
      }

      const _estimates = estimates.filter((item) => !!item.id && !!values[item.id])

      // The footprint will only be applicable if there
      // are estimates with positive quantities.
      const _applicable = _estimates.length > 0

      // The footprint will only have a source if it is
      // applicable. Otherwise the source will be null.
      const _source = _applicable ? source : null

      const _newFootprint = new PeopleAviationFootprint({
        source: _source,
        applicable: _applicable,
        footprintEstimates: _estimates.map((item) => (
          new PeopleAviationFootprintEstimate({
            estimate: item,
            quantity:
            /* istanbul ignore next */ item.id ? values[item.id] : 0
          })
        ))
      })

      return await _newFootprint.save({
        with: {
          footprintEstimates: 'estimate'
        }
      })
    }

    return false
  }

  return {
    getAviationEstimates,
    getAviationFootprint,
    saveAviationFootprint
  }
}