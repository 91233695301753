import { Action, Commitment, StepState } from 'models'
import { PersistedSpraypaintRecord } from 'spraypaint'
import { useTranslation } from 'react-i18next'

export interface ResponseType {
  data: PersistedSpraypaintRecord<Action>[]
  total: number
  itemsPerPage: number
}

export type UseStepType = {
  getSteps: (page: number) => Promise<ResponseType>
  commitToStep: (action: Action) => Promise<boolean | undefined>
}

export const itemsPerPage = 6

export const useStep = () => {
  const { i18n } = useTranslation()
  let _recommendedSteps: PersistedSpraypaintRecord<Action>[] = []

  const _getRecommendedSteps = async () => {
    const { data: steps } = await Action
      .where({
        state: StepState.applicable,
        recommended: true,
        randomOrder: true
      })
      .includes(['impact', { 'themes': 'theme' }])
      .select({
        actions: ['summary'],
        impacts: [
          'name',
          'colorBackground',
          'colorForeground'
        ],
        themes: [
          'name',
          'colorBackground',
          'colorForeground'
        ]
      })
      .extraParams({ paginate: false, locale: i18n.language })
      .all()

    return steps
  }

  const getSteps: UseStepType['getSteps'] = async (page) => {
    if (_recommendedSteps.length === 0) {
      _recommendedSteps = await _getRecommendedSteps()
    }

    const _end = page * itemsPerPage
    const _start = _end - itemsPerPage
    const data = _recommendedSteps?.slice(_start, _end) || []
    const total = _recommendedSteps?.length || 0

    return { data, total, itemsPerPage }
  }

  const commitToStep: UseStepType['commitToStep'] = async (action) => {
    const _newCommitment = new Commitment({
      action,
      state: 'pending'
    })

    return await _newCommitment.save({ with: 'action.id' })
  }

  return {
    getSteps,
    commitToStep
  }
}