import React, { useRef, useEffect, forwardRef, memo } from 'react'
import { useStyles } from './styles'
import { Button, ButtonType, Icons } from 'components'
import { PixelRatio, Animated, ViewProps, View } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'

export interface Props extends ViewProps {
  slug: string
  label: string
  isActive?: boolean
  onPress?: (id: string) => void
  style?: ViewProps['style']
  hide?: boolean
}

const Component = forwardRef(({ slug, label, isActive, onPress, style, hide = false, ...props }: Props, ref: React.LegacyRef<View>) => {
  const activeDuration = 250
  const notActiveDuration = 125
  const tickContainerSize = 1.125 * PixelRatio.getFontScale() * baseSize
  const { styles } = useStyles({
    tickContainerSize
  })
  const { COLORS } = STYLES.useStyles()

  const _stylingActive = {
    opacity: 1,
    scale: 1,
    padding: 0,
    width: tickContainerSize
  }

  const _stylingNotActive = {
    opacity: 0,
    scale: 0.7,
    padding: tickContainerSize / 2,
    width: 0
  }

  const _styling = isActive ? _stylingActive : _stylingNotActive

  const _opacity = useRef(new Animated.Value(_styling.opacity)).current
  const _scale = useRef(new Animated.Value(_styling.scale)).current
  const _padding = useRef(new Animated.Value(_styling.padding)).current
  const _width = useRef(new Animated.Value(_styling.width)).current

  const _showTick = Animated.sequence([
    Animated.parallel([
      Animated.timing(_padding, {
        toValue: _stylingActive.padding,
        duration: activeDuration,
        useNativeDriver: false
      }),
      Animated.timing(_width, {
        toValue: _stylingActive.width,
        duration: activeDuration,
        useNativeDriver: false
      })
    ]),
    Animated.parallel([
      Animated.timing(_opacity, {
        toValue: _stylingActive.opacity,
        duration: activeDuration,
        useNativeDriver: false
      }),
      Animated.timing(_scale, {
        toValue: _stylingActive.scale,
        duration: activeDuration,
        useNativeDriver: false
      })
    ])
  ])

  const _hideTick = Animated.sequence([
    Animated.parallel([
      Animated.timing(_opacity, {
        toValue: _stylingNotActive.opacity,
        duration: notActiveDuration,
        useNativeDriver: false
      }),
      Animated.timing(_scale, {
        toValue: _stylingNotActive.scale,
        duration: notActiveDuration,
        useNativeDriver: false
      })
    ]),
    Animated.parallel([
      Animated.timing(_padding, {
        toValue: _stylingNotActive.padding,
        duration: notActiveDuration,
        useNativeDriver: false
      }),
      Animated.timing(_width, {
        toValue: _stylingNotActive.width,
        duration: notActiveDuration,
        useNativeDriver: false
      })
    ])
  ])

  const _onPress = () => {
    onPress?.(slug)
  }

  useEffect(() => {
    if (hide) return

    if (isActive) {
      _hideTick.stop()
      _showTick.start()
    } else {
      _showTick.stop()
      _hideTick.start()
    }
  }, [_hideTick, _showTick, hide, isActive])

  useEffect(() => {
    if (!hide) return

    _opacity.setValue(_styling.opacity)
    _scale.setValue(_styling.scale)
    _padding.setValue(_styling.padding)
    _width.setValue(_styling.width)
  }, [_opacity, _padding, _scale, _styling.opacity, _styling.padding, _styling.scale, _styling.width, _width, hide, isActive])

  return (
    <View style={[styles.container, isActive && styles.container_active, style, hide && { opacity: 0 }]} ref={ref} {...props}>
      <Button
        type={ButtonType.blank}
        label={label}
        hideLabel
        onPress={_onPress}
        containerStyle={styles.buttonContainer}
        textStyle={styles.buttonText}
        testID="Filter"
        state={
          { disabled: hide }
        }
      />
      <Animated.View style={[
        styles.tickContainer,
        {
          opacity: _opacity,
          transform: [
            {
              scale: _scale
            }
          ],
          width: _width
        }
      ]}>
        <Icons.Tick color={COLORS.snuff} size={getREM(0.5)} />
      </Animated.View>
      <Animated.Text
        style={[
          styles.text,
          {
            paddingLeft: _padding,
            paddingRight: _padding
          }
        ]}
      >
        {label}
      </Animated.Text>
    </View>
  )
})

export const Filter = memo(Component)
