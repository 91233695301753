import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Organization } from './Organization'
import { Person } from './Person'

@Model()
export class OrganizationLeaderboardsMembership extends ApplicationRecord {
  static jsonapiType = 'organization_leaderboard_memberships'

  @Attr({ persist: false }) rank!: number

  @BelongsTo(Person) person!: Person

  @BelongsTo(Organization) organization!: Organization
}
