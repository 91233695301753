import { AdminListTypes } from '../../index'
import { GroupMembershipOrganization, GroupMembershipTeam, Team, GroupMembershipRequest } from 'models'
import { EmailRegex, WritableKeys } from 'utils'

export interface Props {
  type: AdminListTypes
  searchValue?: string
  currentPage: number
  organizationId?: string
  teamId?: string
  itemsPerPage: number
}

type RecordType = GroupMembershipTeam | Team | GroupMembershipRequest | GroupMembershipOrganization

export type RecordKeyType = WritableKeys<RecordType>

export type DataType = RecordType[]

export interface GetDataReturnProps {
  data?: DataType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>
}

export type GetDataType = (props: Props) => Promise<GetDataReturnProps>

export const getData: GetDataType = async ({ type, searchValue = '', currentPage, organizationId, teamId, itemsPerPage }: Props) => {
  let request

  const _isSearchValueAnEmail = EmailRegex.test(searchValue)

  switch (type) {
    case AdminListTypes.organizationMembers: {
      request = GroupMembershipOrganization
        .where({ organizationId })
        .where(_isSearchValueAnEmail ? { email: searchValue } : { fullName: searchValue })
        .select([
          'email',
          'fullName',
          'teams',
          'admin',
          'avatar'
        ])
      break
    }
    case AdminListTypes.organizationPendingMembers: {
      request = GroupMembershipRequest
        .where({ organizationId })
        .where({ state: 'pending' })
        .where(_isSearchValueAnEmail ? { email: searchValue } : { fullName: searchValue })
        .select([
          'state',
          'verificationAnswer',
          'email',
          'fullName',
          'avatar'
        ])
      break
    }
    case AdminListTypes.organizationTeams: {
      request = Team
        .where({ organizationId })
        .where({
          name: searchValue
        })
        .order('name')
        .select(['name', 'logo'])
      break
    }
    case AdminListTypes.teamMembers: {
      request = GroupMembershipTeam
        .where({ teamId })
        .where(_isSearchValueAnEmail ? { email: searchValue } : { fullName: searchValue })
        .select([
          'email',
          'fullName',
          'teams',
          'admin',
          'avatar'
        ])
      break
    }
    case AdminListTypes.teamPendingMembers: {
      request = GroupMembershipRequest
        .where({ teamId })
        .where({ state: 'pending' })
        .where(_isSearchValueAnEmail ? { email: searchValue } : { fullName: searchValue })
        .select([
          'state',
          'verificationAnswer',
          'email',
          'fullName',
          'avatar'
        ])
      break
    }
  }

  if (request) {
    const { data, meta } = await request
      .page(currentPage + 1)
      .per(itemsPerPage)
      .stats({ total: 'count' })
      .all()

    return { data, meta }
  }

  return { data: undefined, meta: undefined }
}
