import { Attr, Model } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class CampaignTargetModel extends ApplicationRecord {
  static jsonapiType = 'campaigns_targets'

  @Attr({ persist: false }) campaignId!: string

  @Attr({ persist: false }) participantsCount!: number

  @Attr({ persist: false }) pendingCommitmentsCount!: number

  @Attr({ persist: false }) successfulCommitmentsCount!: number

  @Attr({ persist: false }) selected!: string

  @Attr({ persist: false }) displayPublicly!: boolean
}
