import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const DownArrow = memo(({
  size = getREM(1.0625),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="DownArrow"
      viewBox="0.2044 0.4923 12.59 15.98"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M 7.3519 16.1151 L 12.4434 11.0236 C 12.9126 10.5543 12.9122 9.7953 12.4434 9.3264 C 11.9745 8.8576 11.215 8.8576 10.7462 9.3264 L 7.6921 12.3805 L 7.6909 1.6828 C 7.6909 1.0269 7.1567 0.4927 6.5003 0.4923 C 5.8439 0.4919 5.3085 1.0273 5.3085 1.6841 L 5.3064 12.3826 L 2.2532 9.3294 C 1.7839 8.8601 1.0248 8.8606 0.556 9.3294 C 0.0872 9.7983 0.0872 10.5577 0.556 11.0266 L 5.6475 16.1181 C 6.1155 16.586 6.8826 16.5844 7.3519 16.1151 Z"
        fill={color ?? colors.text}
      />
    </SVG.Svg>
  )
})
