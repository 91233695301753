import React, { memo } from 'react'
import { View } from 'react-native'
import { TeamChallengeController } from './TeamChallengeController'
import { Leaderboard } from 'components'

export interface Props {
  organizationId: string
  controller?: TeamChallengeController
}

export const TeamChallenge = memo(
  ({ organizationId, controller = new TeamChallengeController() }: Props) => {
    const { data, isLoading, setCurrentPage, sort, filter } =
      controller.useController({
        organizationId
      })

    return (
      <View testID="TeamChallenge">
        <Leaderboard
          {...data}
          onPaginationPress={setCurrentPage}
          sort={sort}
          filter={filter}
          isLoading={isLoading}
        />
      </View>
    )
  }
)
