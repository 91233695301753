import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'
import { Footprint } from './Footprint'
import { Person } from './Person'
import { PeopleAviationFootprintEstimate } from './PeopleAviationFootprintEstimate'

@Model()
export class PeopleAviationFootprint extends Footprint {
  static jsonapiType = 'people_aviation_footprints'

  static endpoint = '/people/aviation/footprints'

  @Attr() footprintEstimatesNew!: {
    estimate_id: string
    quantity: number
  }

  @HasMany(PeopleAviationFootprintEstimate) footprintEstimates!: PeopleAviationFootprintEstimate[]

  @BelongsTo(Person) person!: Person
}
