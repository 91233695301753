import { Platform } from 'react-native'
import { getREM } from '../getREM'

export const fontSize = {
  xxs: getREM(0.625), // 10
  xs: getREM(0.75), // 12
  s: getREM(0.875), // 14
  normal: getREM(1), // 16
  m: getREM(1.125), // 18
  l: getREM(1.375), // 22
  xl: getREM(1.875), // 30
  xxl: getREM(2.375) // 38
}

export const lineHeight = {
  xxs: fontSize.xxs * 1.5,
  xs: fontSize.xs * 1.5,
  s: fontSize.s * 1.5,
  normal: fontSize.normal * 1.5,
  m: fontSize.m * 1.5,
  l: fontSize.l * 1.5,
  xl: fontSize.xl * 1.5,
  xxl: fontSize.xxl * 1.5
} as {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

if (Platform.OS === 'web') {
  lineHeight.xxs = '150%'
  lineHeight.xs = '150%'
  lineHeight.s = '150%'
  lineHeight.normal = '150%'
  lineHeight.m = '150%'
  lineHeight.l = '150%'
  lineHeight.xl = '150%'
  lineHeight.xxl = '150%'
}
