import { SetItemProps } from './Props'

export const Storage = {
  getItem: async (key: string) => {
    return window?.localStorage?.getItem(key) || null
  },
  setItem: async ({ key, value }: SetItemProps) => {
    window?.localStorage?.setItem(key, value)
  },
  removeItem: async (key: string) => {
    window?.localStorage?.removeItem(key)
  }
}