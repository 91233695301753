import React from 'react'
import { Text } from 'react-native'
import SimpleMarkdown from 'simple-markdown'
import { useStyles } from './styles'

export default {
  order: SimpleMarkdown.defaultRules.paragraph.order + 0.5,
  match: (source: string) => {
    return /^<small>([\s\S]+?)<\/small>/.exec(source)
  },
  parse: (capture: SimpleMarkdown.Capture, parse: SimpleMarkdown.Parser, state: SimpleMarkdown.State) => {
    return {
      content: parse(capture[1]!, state)
    }
  },
  react: (
    node: SimpleMarkdown.SingleASTNode,
    output: SimpleMarkdown.Output<React.ReactNode>,
    state: SimpleMarkdown.State
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { styles } = useStyles(state.textStyle)
    return (
      <Text
        textBreakStrategy="simple"
        style={[
          styles.small,
          /* istanbul ignore next */
          state.textStyle?.color && { color: state.textStyle.color }
        ]}
        key={state.key}>
        {output(node.content, state)}
      </Text>
    )
  }
}
