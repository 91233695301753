import React, { ComponentProps, memo } from 'react'
import { Card, StepCategoryPills } from 'components'
import { useStyles } from './styles'
import { ViewProps } from 'react-native'

type CardProps = ComponentProps<typeof Card>

type StepCategoryPillsProps = ComponentProps<typeof StepCategoryPills>

export interface Props extends Partial<CardProps & StepCategoryPillsProps> {
  children?: ViewProps['children']
  title: string
}

export const StepCard = memo(
  ({
    style,
    title,
    themes,
    impact,
    categoryPillOnPress,
    children,
    isCentred,
    ...props
  }: Props) => {
    const { styles } = useStyles({ isCentred })

    const CategoryPills = (
      <StepCategoryPills
        themes={themes}
        impact={impact}
        categoryPillOnPress={categoryPillOnPress}
        style={styles.categoryPills}
      />
    )

    return (
      <Card
        {...props}
        style={[styles.container, style]}
        testID="StepCard"
        title={title}
        CategoryPills={CategoryPills}
        isCentred={isCentred}
        {...props}>
        {children}
      </Card>
    )
  }
)
