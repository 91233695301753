import { Model, BelongsTo } from 'spraypaint'
import { Footprint } from './Footprint'
import { ElectricityEstimate } from './ElectricityEstimate'

@Model()
export class HouseholdsElectricityFootprint extends Footprint {
  static jsonapiType = 'households_electricity_footprints'

  static endpoint = '/households/electricity/footprints'

  @BelongsTo(ElectricityEstimate) estimate!: ElectricityEstimate
}
