import React, {
  useContext,
  useState,
  useEffect,
  memo,
  ComponentProps,
  useCallback
} from 'react'
import { ViewProps } from 'react-native'
import { PageProps } from '../index'
import { SelectionCards, SpeechBubble, Form } from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { House as HouseImage, HouseHeightRatio } from 'images/House'
import { STYLES } from 'styles'
import { useQuery } from 'react-query'

type SelectionCardsProps = ComponentProps<typeof SelectionCards>

export const House = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const {
    progress,
    getHouseTypes,
    getHouseholdElectricityFootprint,
    getHouseholdGasFootprint
  } = useContext(OnboardingGroupContext)
  const { imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_selectedIds, _setSelectedIds] = useState<string[]>([])
  const { data: _houseTypes } = useQuery(
    ['onboarding', 'houseTypes'],
    getHouseTypes
  )
  const { data: _electricityFootprint } = useQuery(
    ['onboarding', 'electricityFootprint'],
    getHouseholdElectricityFootprint
  )
  const { data: _gasFootprint } = useQuery(
    ['onboarding', 'gasFootprint'],
    getHouseholdGasFootprint
  )

  const _isDisabled = _selectedIds.length === 0

  const _onPress = () => {
    const _houseType = _houseTypes?.find((item) => item.id === _selectedIds[0])

    navigation.navigate('Bedrooms', {
      houseType: _houseType?.lookupSlug
    })
  }

  const _onSelect: SelectionCardsProps['onSelect'] = (id) => _setSelectedIds(id)

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.didYouKnow')}
        text={t('onboardingHouse:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageWidth = imageWidth * 0.6
  const _imageHeight = _imageWidth * HouseHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <HouseImage
          style={{
            width: _imageWidth,
            height: _imageHeight,
            transform: [
              {
                translateX: 0.3889 * _imageWidth
              }
            ]
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, _imageWidth]
  )

  useEffect(() => {
    if (_houseTypes && _electricityFootprint && _gasFootprint) {
      /* istanbul ignore next */
      if (
        typeof _electricityFootprint?.estimate.houseType !== 'undefined' &&
        _electricityFootprint?.estimate.houseType.toLowerCase() ===
          _gasFootprint?.estimate.houseType.toLowerCase()
      ) {
        const _estimate = _houseTypes.find(
          (item) =>
            item.name.toLowerCase() ===
            _electricityFootprint?.estimate.houseType.toLowerCase()
        )

        /* istanbul ignore next */
        if (_estimate?.id) {
          if (_selectedIds.length === 0) _setSelectedIds([_estimate.id])
        }
      }
    }
  }, [_houseTypes, _electricityFootprint, _gasFootprint, _selectedIds.length])

  return (
    <Layout testID="House" showBackButton>
      <ProgressBar
        label={t('onboardingHouse:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.House) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingHouse:title')}</Heading>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <SelectionCards
              legend={t('onboardingHouse:title')}
              items={
                /* istanbul ignore next */
                _houseTypes?.map((item) => ({
                  id: item.id!,
                  label: item.name
                })) || []
              }
              onSelect={_onSelect}
              selectedIds={_selectedIds}
              style={{ marginBottom: spacing.xl }}
            />
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton
            onPress={_onPress}
            state={{
              disabled: _isDisabled
            }}
          />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
