import { STYLES } from 'styles'
import { Platform } from 'react-native'
import { TypeStylesProps } from './index'

export const usePlaceholder = () => {
  const { COLORS, colors, boxShadows } = STYLES.useStyles()

  const placeholder = {
    nativeContainer: {
      ...boxShadows.button.ios
    },
    label: {
      width: 100,
      textDecorationLine: 'none',
      backgroundColor: COLORS.whisper,
      color: colors.text,
      ...Platform.select({
        web: {
          border: 0,
          cursor: 'pointer'
        }
      }),
      ...boxShadows.button.web,
      ...boxShadows.button.android
    },
    pointerOver: {
      ...boxShadows.button_pointerOver.web
    }
  } as TypeStylesProps

  return { placeholder }
}
