import { Platform } from 'react-native'

export const useDisabled = () => {
  const disabled = {
    opacity: 0.5,
    ...Platform.select({
      web: {
        cursor: 'default'
      }
    })
  }

  return {
    disabled
  }
}