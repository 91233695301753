import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, bp, spacing, fontBold, fontRegular } = STYLES.useStyles()

  const container = {
    alignItems: 'center',
    width: '100%'
  } as ViewStyle

  const title = {
    marginBottom: 0,
    textAlign: 'center'
  } as ViewStyle

  const boxGroup = {
    marginTop: spacing.xs,
    alignItems: 'center',
    width: '100%'
  } as ViewStyle

  const score = {
    ...fontBold,
    color: colors.alternative,
    fontSize: STYLES.fontSize.xxl,
    lineHeight: STYLES.lineHeight.xxl,
    textAlign: 'center'
  } as TextStyle

  const pillGroup = {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    flexShrink: 1,
    maxWidth: getREM(18.5)
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    pillGroup.flexDirection = 'column'
    pillGroup.alignItems = 'center'
  }

  if (bp.desktop) {
    pillGroup.maxWidth = getREM(22.25)
  }

  const pillItem = {
    maxWidth: getREM(8.75),
    width: '100%'
  } as ViewStyle

  if (bp.desktop) {
    pillItem.maxWidth = getREM(10.625)
  }

  const pill = {
    borderWidth: getREM(0.0625),
    borderStyle: 'solid',
    borderColor: colors.border,
    backgroundColor: colors.background,
    paddingTop: spacing.xxs,
    paddingBottom: spacing.xxs,
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    alignItems: 'center',
    borderRadius: getREM(2),
    flexDirection: 'row',
    justifyContent: 'center'
  } as ViewStyle

  if (bp.desktop) {
    pill.paddingTop = spacing.xs
    pill.paddingBottom = spacing.xs
  }

  const pillIcon = {
    flexShrink: 1,
    marginRight: spacing.xxs
  } as ViewStyle

  if (bp.desktop) {
    pillIcon.marginRight = spacing.xs
  }

  const pillIcon_positive = {
    transform: [
      {
        rotate: '180deg'
      }
    ]
  } as ViewStyle

  const pillIcon_negative = {} as ViewStyle

  const pillIcon_neutral = {
    height: getREM(0.5625),
    width: getREM(1.125),
    backgroundColor: colors.neutral
  } as ViewStyle

  const pillIcon_stars = {
    flexDirection: 'row'
  } as ViewStyle

  const pillIcon_star = {
    width: getREM(0.75),
    height: getREM(0.75)
  } as ViewStyle

  if (bp.desktop) {
    pillIcon_star.width = getREM(1)
    pillIcon_star.height = getREM(1)
  }

  const _pillText = {
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  if (bp.desktop) {
    _pillText.fontSize = STYLES.fontSize.m
    _pillText.lineHeight = STYLES.lineHeight.m
  }

  const pillValue = {
    ..._pillText,
    ...fontBold
  } as TextStyle

  const pillUnit = {
    ..._pillText,
    ...fontRegular
  } as TextStyle

  const pillLabel = {
    ...fontRegular,
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    textAlign: 'center'
  } as TextStyle

  const link = {
    marginTop: spacing.normal
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    title,
    boxGroup,
    score,
    pillGroup,
    pillItem,
    pill,
    pillIcon,
    pillIcon_positive,
    pillIcon_negative,
    pillIcon_neutral,
    pillIcon_stars,
    pillIcon_star,
    pillValue,
    pillUnit,
    pillLabel,
    link
  })

  return { styles }
}
