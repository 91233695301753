import { StyleSheet, TextStyle } from 'react-native'

export const useStyles = () => {
  const text = {
    textAlign: 'center'
  } as TextStyle

  const styles = StyleSheet.create({
    text
  })

  return { styles }
}
