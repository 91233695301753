import React, { memo } from 'react'
import { useStyles } from './styles'
import {
  ActivityIndicator,
  CarbonFootprintHero,
  CarbonFootprintTargets
} from 'components'
import { FootprintGroupController } from './FootprintGroupController'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'

export interface Props {
  controller?: FootprintGroupController
}

export const FootprintGroup = memo(
  ({ controller = new FootprintGroupController() }: Props) => {
    useLang()
    const { t } = useTranslation()
    const { styles } = useStyles()
    const { person, isLoading } = controller.useController()

    const _hasCarbonFootprint = !!person?.kgCo2eEmissions
    const _hasTarget = !!person?.nextTarget?.active

    if (isLoading) {
      return <ActivityIndicator />
    }

    return (
      <>
        {_hasCarbonFootprint && (
          <CarbonFootprintHero
            value={person?.kgCo2eEmissions}
            title={t('footprintGroup:carbonFootprint.title')}
            linkLabel={t('footprintGroup:carbonFootprint.linkLabel')}
            isFixedSize
          />
        )}

        {_hasTarget && (
          <CarbonFootprintTargets
            containerStyle={styles.carbonFootprintTargets}
            shortTermTarget={person?.nextTarget?.kgCo2eEmissions}
            longTermTarget={
              person?.nextTarget?.kgCo2eEmissions > 2500 ? 2500 : 0
            }
            remainder={person?.nextTarget?.kgCo2eRemaining}
          />
        )}
      </>
    )
  }
)
