import { STYLES, getREM } from 'styles'
import { StyleSheet, TextStyle, Platform } from 'react-native'

export const useStyles = () => {
  const { focused, input, disabled, spacing, COLORS } = STYLES.useStyles()

  const mutlilineNumberOfLines = 8

  const _multilineHeight = Platform.OS === 'web'
    ? `
      calc(
        ((${input.fontSize} * 1.5) * ${mutlilineNumberOfLines}) 
        + (${spacing.m} * 2)
      )
    `
    : (input.lineHeight! * mutlilineNumberOfLines) + (spacing.m * 2)

  const textInput_multiline = {
    height: _multilineHeight,
    overflow: 'scroll',
    paddingTop: spacing.m,
    paddingBottom: spacing.m,
    borderRadius: getREM(1),
    borderColor: COLORS.aubergine,
    borderWidth: getREM(0.125),
    backgroundColor: COLORS.white
  } as TextStyle

  const styles = StyleSheet.create({
    textInput: input,
    textInput_disabled: disabled,
    textInput_focused: focused,
    textInput_multiline
  })

  return { styles, mutlilineNumberOfLines }
}
