import React, { useMemo, RefObject, useCallback, useRef, useState, memo } from 'react'
import { View, Platform, ScrollView, ViewProps } from 'react-native'
import { useStyles } from './styles'
import { FilterGroup } from 'groups'
import { StepsGroupController } from './StepsGroupController'
import { STYLES } from 'styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { Theme, StepState, CommitmentState } from 'models'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  ActivityIndicator,
  Heading,
  Icons,
  StepCarouselItemTemplateProps,
  StepList,
  Toggle,
  StepCardTemplate,
  StepCarouselHeader,
  Carousel,
  IconProps,
  SvgImage,
  StepButtons
} from 'components'
import { useAnalytics } from 'utils'

export interface Props {
  controller?: StepsGroupController
  scrollViewRef?: RefObject<ScrollView>
  selectedFilters?: string[]
  onFilterChange?: (filters: string[]) => void
  selectedState?: string
  onStateChange?: (state: string) => void
}

interface UpdateFiltersProps {
  slug: string
  isImpact?: boolean
  isTheme?: boolean
  isToggle?: boolean
}

export { StepsGroupController }

export const StepsGroup = memo(
  ({
    controller = new StepsGroupController(),
    scrollViewRef,
    selectedFilters = [],
    onFilterChange,
    selectedState,
    onStateChange
  }: Props) => {
    useLang()

    const { t } = useTranslation()
    const { styles } = useStyles()
    const insets = useSafeAreaInsets()
    const { COLORS, sizing } = STYLES.useStyles()
    const _hasCheckedInitialFilter = useRef(false)
    const [_showMoreFilters, _setShowMoreFilters] = useState(false)
    const _showCompleted = selectedState === StepState.complete
    const { trackEvent, trackingEvents } = useAnalytics()

    const {
      isLoading,
      recommendedSteps,
      allApplicableSteps,
      themeFilters,
      impactFilters,
      allCompletedSteps,
      isLoadingCompletedSteps,
      isLoadingAllApplicableSteps,
      commitments
    } = controller.useController({
      showCompleted: _showCompleted
    })

    const _hasRecommendedSteps =
      !!recommendedSteps && recommendedSteps.length > 0
    const _hasSelectedFilters =
      selectedFilters.filter((item) => !!item).length > 0

    const _isStepsLoading =
      isLoadingCompletedSteps || isLoadingAllApplicableSteps

    const _listRef = useRef<View>(null)
    const _listY = useRef(0)

    const _impactFiltersY = useRef(0)
    const _impactFiltersSlugs = useMemo(
      () => impactFilters?.map((item) => item.lookupSlug) || [],
      [impactFilters]
    )

    const _themeFiltersY = useRef(0)
    const _themeFiltersSlugs = useMemo(
      () => themeFilters?.map((item) => item.lookupSlug) || [],
      [themeFilters]
    )

    /* istanbul ignore next */
    const _scrollTo = useCallback(
      (y: number) => {
        if (y && y > 0) {
          const _newY = y - sizing.topBarHeight - insets.top

          if (scrollViewRef?.current) {
            scrollViewRef.current.scrollTo({
              y: _newY,
              animated: true
            })
          } else if (Platform.OS === 'web') {
            window.scrollTo({
              top: _newY,
              behavior: 'smooth'
            })
          }
        }
      },
      [insets.top, scrollViewRef, sizing.topBarHeight]
    )

    /* istanbul ignore next */
    const _headerButtonOnPress = useCallback(() => {
      _scrollTo(_listY.current)
    }, [_scrollTo])

    /* istanbul ignore next */
    const _scrollToFilterGroup = useCallback(
      (slug?: string) => {
        let _hasImpact = false
        let _hasTheme = false

        if (slug) {
          if (_impactFiltersSlugs.includes(slug)) {
            _hasImpact = true
          }

          if (_themeFiltersSlugs.includes(slug)) {
            _hasTheme = true
          }
        } else {
          selectedFilters.forEach((filter) => {
            if (_impactFiltersSlugs.includes(filter)) {
              _hasImpact = true
            }

            if (_themeFiltersSlugs.includes(filter)) {
              _hasTheme = true
            }
          })
        }

        if (_hasImpact && _hasTheme) {
          _scrollTo(_listY.current)
        } else if (_hasImpact) {
          _scrollTo(_listY.current + _impactFiltersY.current)
        } else if (_hasTheme) {
          _scrollTo(_listY.current + _themeFiltersY.current)
        }
      },
      [_impactFiltersSlugs, _scrollTo, _themeFiltersSlugs, selectedFilters]
    )

    /* istanbul ignore next */
    const _checkInitialFilters = () => {
      if (_hasCheckedInitialFilter.current) return

      if (
        _listY.current === 0 ||
        _impactFiltersY.current === 0 ||
        _themeFiltersY.current === 0
      )
        return

      _hasCheckedInitialFilter.current = true

      if (_hasSelectedFilters) {
        _setShowMoreFilters(true)
        _scrollToFilterGroup()
      }
    }

    /* istanbul ignore next */
    const _onLayout: ViewProps['onLayout'] = ({
      nativeEvent: {
        layout: { y }
      }
    }) => {
      if (_listY.current !== y && y > 0) {
        _listY.current = y
      }
      _checkInitialFilters()
    }

    /* istanbul ignore next */
    const _impactFilterGroupOnLayout: ViewProps['onLayout'] = ({
      nativeEvent: {
        layout: { y }
      }
    }) => {
      if (_impactFiltersY.current !== y && y > 0) {
        _impactFiltersY.current = y
      }
      _checkInitialFilters()
    }

    /* istanbul ignore next */
    const _themeFilterGroupOnLayout: ViewProps['onLayout'] = ({
      nativeEvent: {
        layout: { y }
      }
    }) => {
      if (_themeFiltersY.current !== y && y > 0) {
        _themeFiltersY.current = y
      }
      _checkInitialFilters()
    }

    const _updateFilters = useCallback(
      ({ slug, isToggle = false }: UpdateFiltersProps) => {
        const _index = selectedFilters.findIndex((item) => item === slug)

        // even though the else if is triggered in the tests
        // the coverage checker still complains
        /* istanbul ignore else */
        if (isToggle && _index > -1) {
          selectedFilters.splice(_index, 1)
        } else if (_index === -1) {
          selectedFilters.push(slug)
        }

        onFilterChange?.(selectedFilters)
      },
      [onFilterChange, selectedFilters]
    )

    const _updateShowCompleted = (value: boolean) => {
      onStateChange?.(value ? StepState.complete : '')
    }

    const _impactFiltersOnPress = (slug: string) => {
      trackEvent({
        eventName: trackingEvents.stepFilter,
        props: {
          type: 'impact',
          source: 'filter',
          slug,
          toggled: selectedFilters.includes(slug)
        }
      })

      _updateFilters({ slug, isToggle: true })
    }

    const _themeFiltersOnPress = (slug: string) => {
      trackEvent({
        eventName: trackingEvents.stepFilter,
        props: {
          type: 'theme',
          source: 'filter',
          slug,
          toggled: selectedFilters.includes(slug)
        }
      })

      _updateFilters({ slug, isToggle: true })
    }

    const _categoryPillOnPress = useCallback(
      (slug: string) => {
        const _isImpact = !!impactFilters?.find(
          (item) => item.lookupSlug === slug
        )
        /* istanbul ignore next */
        const _slugType = _isImpact ? 'impact' : 'theme'

        trackEvent({
          eventName: trackingEvents.stepFilter,
          props: {
            source: 'category',
            type: _slugType,
            slug,
            toggled: selectedFilters.includes(slug)
          }
        })

        _setShowMoreFilters(true)
        _scrollToFilterGroup(slug)
        _updateFilters({ slug })
      },
      [
        _scrollToFilterGroup,
        _updateFilters,
        impactFilters,
        selectedFilters,
        trackEvent,
        trackingEvents.stepFilter
      ]
    )

    const ItemTemplate = useCallback(
      ({ item, style, ...props }: StepCarouselItemTemplateProps) => (
        <StepCardTemplate
          action={item}
          categoryPillOnPress={_categoryPillOnPress}
          style={style}
          commitmentState={
            commitments?.find(
              /* istanbul ignore next */
              (commitment) =>
                commitment.action.id === item.id &&
                commitment.state !== CommitmentState.abandoned
            )?.state as CommitmentState
          }
          {...props}
        />
      ),
      [_categoryPillOnPress, commitments]
    )

    const ItemTemplateRecommended = useCallback(
      ({ item, style, ...props }: StepCarouselItemTemplateProps) => (
        <StepCardTemplate
          action={item}
          categoryPillOnPress={_categoryPillOnPress}
          style={style}
          {...props}
        />
      ),
      [_categoryPillOnPress]
    )

    const _steps = useMemo(() => {
      return _showCompleted ? allCompletedSteps : allApplicableSteps
    }, [_showCompleted, allApplicableSteps, allCompletedSteps])

    const _getFilteredSteps = () => {
      if (!_hasSelectedFilters) {
        return _steps
      }

      /* istanbul ignore next */
      const _impactFilters =
        impactFilters
          ?.filter((item) => {
            return selectedFilters.includes(item.lookupSlug)
          })
          .map((item) => item.lookupSlug) || []

      /* istanbul ignore next */
      const _themeFilters =
        themeFilters
          ?.filter((item) => {
            return selectedFilters.includes(item.lookupSlug)
          })
          .map((item) => item.lookupSlug) || []

      const _items = _steps?.filter((item) => {
        const _impact = item.impact.lookupSlug
        /* istanbul ignore next */
        const _themes =
          item.themes?.map((themeItem) => themeItem.theme.lookupSlug) ?? []

        let _hasImpact = false
        let _hasTheme = false

        if (_impactFilters.length === 0) {
          _hasImpact = true
        } else {
          _hasImpact = _impactFilters.includes(_impact)
        }

        if (_themeFilters.length === 0) {
          _hasTheme = true
        } else if (_themes.length > 0) {
          _themes.forEach((theme: Theme['lookupSlug']) => {
            /* istanbul ignore next */
            if (_themeFilters.includes(theme)) {
              _hasTheme = true
            }
          })
        }

        return _hasImpact && _hasTheme
      })

      return _items
    }

    const _icon = memo((props: IconProps) => (
      <Icons.DownArrow {...props} color={COLORS.white} />
    ))

    /* istanbul ignore next */
    const StepCarouselHeaderImage = memo((props) => (
      <SvgImage image={require('images/ShoeStepMessage.svg')} {...props} />
    ))

    if (isLoading) {
      return <ActivityIndicator testID="ActivityIndicatorIsLoading" />
    }

    return (
      <>
        {_hasRecommendedSteps && (
          <StepButtons.TrackingContext.Provider
            value={{ source: 'StepsGroup Recommended Steps' }}>
            <StepCarouselHeader
              backgroundColor1="#FFB7D6"
              backgroundColor2="#E97BA3"
              buttonColor={COLORS.cherry}
              ItemTemplate={ItemTemplateRecommended}
              Image={
                StepCarouselHeaderImage as React.ComponentProps<
                  typeof Carousel
                >['Image']
              }
              steps={recommendedSteps}
              title={t('stepsGroup:header.title')}
              buttonProps={{
                label: t('stepsGroup:header.button'),
                onPress: _headerButtonOnPress,
                icon: _icon
              }}
            />
          </StepButtons.TrackingContext.Provider>
        )}

        <View
          style={[
            styles.container,
            /* istanbul ignore next */
            !_hasRecommendedSteps && styles.container_noHeader
          ]}
          testID="StepsGroup"
          ref={_listRef}
          onLayout={_onLayout}>
          <Heading level={1} style={styles.heading}>
            {t('stepsGroup:title')}
          </Heading>
          <Toggle
            accessibilityLabel={t('stepsGroup:filters.toggle.ariaLabel')}
            value={_showCompleted}
            falseLabel={t('stepsGroup:filters.toggle.applicable')}
            trueLabel={t('stepsGroup:filters.toggle.completed')}
            onChange={_updateShowCompleted}
            style={styles.toggle}
            testID="ToggleShowCompleted"
          />

          {impactFilters && (
            <FilterGroup
              testID="FilterGroupImpact"
              title={t('stepsGroup:filters.impact.title')}
              onPress={_impactFiltersOnPress}
              items={impactFilters.map((item) => ({
                slug: item.lookupSlug,
                label: item.name
              }))}
              style={styles.filterGroup}
              selectedItems={selectedFilters}
              onLayout={_impactFilterGroupOnLayout}
            />
          )}

          {themeFilters && (
            <FilterGroup
              testID="FilterGroupTheme"
              title={t('stepsGroup:filters.theme.title')}
              onPress={_themeFiltersOnPress}
              items={themeFilters.map((item) => ({
                slug: item.lookupSlug,
                label: item.name
              }))}
              showMoreLabel={t('stepsGroup:filters.theme.showMoreLabel')}
              showLessLabel={t('stepsGroup:filters.theme.showLessLabel')}
              style={styles.filterGroup}
              selectedItems={selectedFilters}
              visibleRows={2}
              showMore={_showMoreFilters}
              onShowMorePress={_setShowMoreFilters}
              onLayout={_themeFilterGroupOnLayout}
            />
          )}

          {_isStepsLoading && (
            <View style={styles.activityIndicator}>
              <ActivityIndicator testID="ActivityIndicatorSteps" />
            </View>
          )}

          {!_isStepsLoading && (
            <StepButtons.TrackingContext.Provider
              value={{
                source: `StepsGroup ${
                  _showCompleted ? 'Completed' : 'Applicable'
                } Steps`
              }}>
              <StepList
                style={styles.list}
                visibleItems={8}
                emptyMessage={t('stepsGroup:list.emptyMessage')}
                items={_getFilteredSteps()}
                ItemTemplate={ItemTemplate}
                testID={
                  _showCompleted ? 'StepsListCompleted' : 'StepsListApplicable'
                }
              />
            </StepButtons.TrackingContext.Provider>
          )}
        </View>
      </>
    )
  }
)