import { Model, Attr, HasOne, HasMany } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { ChecklistItem } from './ChecklistItem'
import { Commitment } from './Commitment'
import { PersonalChecklistItem } from './PersonalChecklistItem'
import { Impact } from './Impact'
import { ActionTheme } from './ActionTheme'
import { ActionGoal } from './ActionGoal'
import { ActionInformationItem } from './ActionInformationItem'
import { ActionResource } from './ActionResource'
import { ActionFact } from './ActionFact'
import { ActionBenefit } from './ActionBenefit'
import { ActionTip } from './ActionTip'
import { Ease } from './Ease'

@Model()
export class Action extends ApplicationRecord {
  static jsonapiType = 'actions'

  @Attr({ persist: false }) name!: string

  @Attr({ persist: false }) description!: string

  @Attr({ persist: false }) param!: string

  @Attr({ persist: false }) promise!: string

  @Attr({ persist: false }) summary!: string

  @Attr({ persist: false }) section!: string

  @Attr({ persist: false }) completionCriterion!: string

  @Attr({ persist: false }) improvements!: string[]

  @Attr({ persist: false }) slug!: string

  @Attr({ persist: false }) tags!: string[]

  @Attr({ persist: false }) footprintParam!: string

  @Attr({ persist: false }) completeCommitmentsCount!: number

  @Attr({ persist: false }) pendingCommitmentsCount!: number

  @HasOne(Impact) impact!: Impact

  @HasOne(Ease) ease!: Ease

  @HasOne(Commitment) commitment!: Commitment

  @HasMany(ActionTheme) themes!: ActionTheme[]

  @HasMany(ActionGoal) goals!: ActionGoal[]

  @HasMany(PersonalChecklistItem) personalChecklistItems!: PersonalChecklistItem[]

  @HasMany(ChecklistItem) checklistItems!: ChecklistItem[]

  @HasMany(ActionInformationItem) informationItems!: ActionInformationItem[]

  @HasMany(ActionResource) resources!: ActionResource[]

  @HasMany(ActionFact) facts!: ActionFact[]

  @HasMany(ActionBenefit) benefits!: ActionBenefit[]

  @HasMany(ActionTip) tips!: ActionTip[]
}
