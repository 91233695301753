import React, { useEffect, useState, memo } from 'react'
import { View, Text, ViewProps, ViewStyle } from 'react-native'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { Icons } from 'components'
import { STYLES } from 'styles/STYLES'
import { useLang } from './Lang'

interface ItemProps {
  label: string
  value: number
  unit?: string
  width?: string
  id?: string
}

export interface Props extends ViewProps {
  items: ItemProps[]
}

export const HorizontalBarChart = memo(({ items, style, ...props }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const [_items, _setItems] = useState<ItemProps[] | null>(null)
  const { COLORS } = STYLES.useStyles()

  useEffect(() => {
    if (!items) return

    // Filter out the tree from the list of items if it exists
    const _tempItems: ItemProps[] = JSON.parse(
      JSON.stringify(items.filter((item) => item?.id !== 'tree'))
    )

    _tempItems.sort((item1, item2) => {
      const _diff = item2.value - item1.value

      if (_diff !== 0) return _diff

      if (item1.label.toUpperCase() < item2.label.toUpperCase()) return -1
      if (item1.label.toUpperCase() > item2.label.toUpperCase()) return 1

      return 0
    })

    _tempItems.forEach((item) => {
      if (!item.width) {
        item.width = `${Math.ceil(
          (Math.abs(item.value) / Math.abs(_tempItems[0]!.value)) * 100
        )}%`
      }
    })

    // Add the tree back to the list of items if it exists
    const _tree = items.find((item) => item?.id === 'tree')
    if (_tree && _tree.value < 0) _tempItems.push(_tree!)

    _setItems(_tempItems)
  }, [items])

  if (!items || items.length === 0) return null

  return (
    <View
      style={[styles.container, style]}
      testID="HorizontalBarChart"
      {...props}>
      {_items?.map((item: ItemProps, index: number) => {
        const _isNotFirst = index > 0

        return (
          <View key={index} style={styles.item}>
            <Text
              style={[styles.itemLabel, _isNotFirst && styles.itemLabel_gap]}
              numberOfLines={1}
              ellipsizeMode="tail">
              {item.label}
            </Text>

            {item?.id && item?.id === 'tree' ? (
              <View
                style={styles.treeContainer}
                testID="HorizontalBarChartItemTreeOffset">
                <Icons.Tree color={COLORS.goblin} />
                <Text style={styles.treeContainerText}>
                  {t('horiztontalBarChart:treeOffset', {
                    value: Math.abs(item.value)
                  })}
                  {item.unit}
                </Text>
              </View>
            ) : (
              <>
                <View style={styles.itemBarContainer}>
                  <View
                    style={[
                      styles.itemBar,
                      { width: item.width as unknown as ViewStyle['width'] }
                    ]}
                    testID={`HorizontalBarChartItemBar_${index}`}
                  />
                </View>
                <Text
                  style={styles.itemValue}
                  testID={`HorizontalBarChartItemValue_${index}`}>
                  {t('format:number', { value: item.value })}
                  {item.unit}
                </Text>
              </>
            )}
          </View>
        )
      })}
    </View>
  )
})
