import { useQuery } from 'react-query'
import { OrganizationInvitationBatch, Team } from 'models'

export interface Props {
  organizationId: string
  enableGetTeams: boolean
}

export class AssignUsersToTeamController {
  _organizationId: string | undefined = undefined

  _getTeams = async () => {
    const { data } = await Team
      .where({ organizationId: this._organizationId })
      .select(['name'])
      .extraParams({ paginate: false })
      .all()

    return data
  }

  _assignUsers = async (teamId: string, emails: string[]) => {
    if (!this._organizationId) return false

    const _batch = new OrganizationInvitationBatch({
      emails,
      teamId,
      organizationId: this._organizationId
    })

    const _success = await _batch.save()

    return _success
  }

  useController = ({ organizationId, enableGetTeams }: Props) => {
    this._organizationId = organizationId

    const { isLoading, data: teams } = useQuery(
      ['data', 'teams'],
      this._getTeams,
      { enabled: !!organizationId && enableGetTeams }
    )

    return {
      isLoading,
      teams,
      assignUsers: this._assignUsers
    }
  }
}