import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Organization } from './Organization'

interface MetaCommitmentsParams {
  'total': number
  '7_days_total': number
  '30_days_total': number
}

interface MetaReductionsParams {
  co2e: number
  land: number
  water: number
  plastic: number
}

interface MetaCommitments {
  pending: MetaCommitmentsParams
  successful: MetaCommitmentsParams
}

interface MetaReductions {
  pending: MetaReductionsParams
  successful: MetaReductionsParams
}

interface MetaReductionSections {
  [section: string]: number
}

@Model()
export class Team extends ApplicationRecord {
  static jsonapiType = 'teams'

  @Attr({ persist: true }) organizationId!: string
  @Attr({ persist: true }) name!: string
  @Attr({ persist: false }) score!: number
  @Attr({ persist: false }) metaCommitments!: MetaCommitments
  @Attr({ persist: false }) kgCo2eEmissions!: number
  @Attr({ persist: false }) metaReductions!: MetaReductions
  @Attr({ persist: false }) metaCo2eReductionSections!: MetaReductionSections
  @Attr({ persist: false }) totalSteps!: number
  @Attr({ persist: false }) banner!: string
  @Attr({ persist: false }) logo!: string

  @BelongsTo(Organization) organization!: Organization
}
