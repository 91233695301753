import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class NotificationsPreferences extends ApplicationRecord {
  static jsonapiType = 'notifications_preferences'

  @Attr() productUpdates!: boolean

  @Attr() reminders!: boolean
}
