import { STYLES, getREM } from 'styles'
import { StyleSheet, Platform, ViewStyle, TextStyle } from 'react-native'

export const useStyles = () => {
  const { colors, paragraph, fontRegular } = STYLES.useStyles()

  const styles = StyleSheet.create({
    list: {
      width: '100%',
      marginBottom: (Platform.OS === 'web' ? `calc(${paragraph.marginBottom} / 2)` : paragraph.marginBottom as number / 2) as unknown as ViewStyle['marginBottom']
    },
    listItem: {
      flexDirection: 'row',
      marginBottom: (Platform.OS === 'web' ? `calc(${paragraph.marginBottom} / 2)` : paragraph.marginBottom as number / 2) as unknown as ViewStyle['marginBottom'],
      alignItems: 'flex-start'
    },
    number: {
      ...fontRegular,
      fontSize: paragraph.fontSize,
      lineHeight: paragraph.lineHeight,
      color: colors.text,
      width: getREM(1.5),
      flexShrink: 0
    },
    bullet: {
      fontFamily: paragraph.fontFamily,
      color: paragraph.color,
      fontSize: STYLES.fontSize.xl,
      lineHeight: (Platform.OS === 'web' ? `calc(${paragraph.fontSize} * 1.5)` : paragraph.fontSize * 2) as TextStyle['lineHeight'],
      width: getREM(1.5),
      flexShrink: 0
    },
    text: {
      fontSize: paragraph.fontSize,
      lineHeight: paragraph.lineHeight,
      fontFamily: paragraph.fontFamily,
      color: paragraph.color,
      flex: 1
    }
  })

  return { styles }
}
