import { StyleSheet } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const styles = StyleSheet.create({
    fullWidth: {
      maxWidth: STYLES.grid.maxInputWidth,
      width: '100%'
    }
  })

  return { styles }
}
