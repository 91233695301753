import React, { memo } from 'react'
import { SVG } from 'components'
import { IconProps } from '../index'
import { getREM } from 'styles'

export const ThickArrow = memo(({
  size = getREM(1.125),
  color,
  style,
  ...props
}: IconProps) => {
  return (
    <SVG.Svg
      testID="ThickArrow"
      viewBox="0 0 17 18"
      fill={color}
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path d="M8.41389 18L0.655273 10.125H5.08877L5.08877 0L11.739 0L11.739 10.125H16.1725L8.41389 18Z" />
    </SVG.Svg>
  )
})
