import React, {
  useContext,
  useState,
  memo,
  ComponentProps,
  useMemo,
  useCallback
} from 'react'
import { ViewProps } from 'react-native'
import { PageParamList } from '../index'
import {
  SpeechBubble,
  Form,
  NumberInput,
  FieldSet,
  ActivityIndicator
} from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { House as HouseImage, HouseHeightRatio } from 'images/House'
import { STYLES } from 'styles'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { GenerateId } from 'utils'

type NumberInputProps = ComponentProps<typeof NumberInput>

type PageProps = NativeStackScreenProps<PageParamList, 'Household'>

export const Household = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const { progress, getHouseholdCount, saveHousehold } = useContext(
    OnboardingGroupContext
  )
  const { imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_value, _setValue] = useState<number>(getHouseholdCount())
  const [_isUpdating, _setIsUpdating] = useState(false)
  const [_showError, _setShowError] = useState(false)
  const _descriptionId = useMemo(() => GenerateId(), [])

  const _onPress = async () => {
    _setShowError(false)

    _setIsUpdating(true)

    const _success = await saveHousehold(
      _value,
      t('onboardingHousehold:housemate')
    )

    _setIsUpdating(false)

    if (_success) {
      navigation.navigate('Goals')
      return
    }

    _setShowError(true)
  }

  /* istanbul ignore next */
  const _onChange: NumberInputProps['onChange'] = (value) => _setValue(value)

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.didYouKnow')}
        text={t('onboardingHousehold:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageWidth = imageWidth * 0.6
  const _imageHeight = _imageWidth * HouseHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <HouseImage
          style={{
            width: _imageWidth,
            height: _imageHeight,
            transform: [
              {
                translateX: 0.3889 * _imageWidth
              }
            ]
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, _imageWidth]
  )

  if (_isUpdating) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Household" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingHousehold:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.Household) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading nativeID={_descriptionId}>
            {t('onboardingHousehold:title')}
          </Heading>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <FieldSet legend={t('onboardingHousehold:title')}>
              <NumberInput
                label={t('onboardingHousehold:fieldLabel')}
                descriptionId={_descriptionId}
                step={1}
                min={0}
                max={20}
                onChange={_onChange}
                value={_value}
              />
            </FieldSet>
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton onPress={_onPress} />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
