import { StyleSheet, ViewStyle, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { COLORS, spacing, bp, sizing } = STYLES.useStyles()
  const insets = useSafeAreaInsets()

  const container = {
    width: '100%',
    marginTop: -insets.top,
    height: sizing.topBarHeightDefault + insets.top,
    paddingTop: insets.top,
    paddingLeft: spacing.page.padding.paddingLeft,
    paddingRight: spacing.page.padding.paddingRight,
    position: 'relative'
  } as ViewStyle

  const background = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: COLORS.white
  } as ViewStyle

  const topBar = {
    height: sizing.topBarHeightDefault,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: STYLES.grid.maxContentWidth,
    width: '100%',
    alignSelf: 'center',
    paddingLeft: getREM(3.3125),
    paddingRight: getREM(3.3125)
  } as ViewStyle

  const backButton = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    justifyContent: 'center'
  } as ViewStyle

  const logo = {
    width: 142,
    height: 35
  } as ImageStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    logo.width = 192
    logo.height = 46
  }

  const logoLink = {
    width: logo.width,
    height: logo.height,
    marginTop: 15
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    logoLink.marginTop = 5
  }

  const styles = StyleSheet.create({
    container,
    background,
    topBar,
    backButton,
    logo,
    logoLink
  })

  return { styles }
}
