import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class Theme extends ApplicationRecord {
  static jsonapiType = 'themes'

  @Attr({ persist: false }) lookupSlug!: string
  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) colorBackground!: string
  @Attr({ persist: false }) colorForeground!: string
}
