import React, { memo } from 'react'
import { View, ViewProps } from 'react-native'
import { WebAccessibilityRole } from 'utils'

export const Form = memo(({ style, ...props }: ViewProps) => (
  <View
    style={[
      { width: '100%' },
      style
    ]}
    {...props}
    accessibilityRole={WebAccessibilityRole('form')}
    testID="Form">
    {props.children}
  </View>
))
