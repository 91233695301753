import React, { memo } from 'react'
import {
  ActivityIndicator as NativeActivityIndicator,
  View,
  ViewProps
} from 'react-native'
import { useStyles } from './styles'
import { STYLES } from 'styles'

export const ActivityIndicator = memo(({ ...props }: ViewProps) => {
  const { styles } = useStyles()
  const { colors } = STYLES.useStyles()

  return (
    <View style={styles.container} testID="ActivityIndicator" {...props}>
      <NativeActivityIndicator size="large" color={colors.brand} />
    </View>
  )
})
