import React, { memo } from 'react'
import { View, ViewProps } from 'react-native'
import { useStyles } from './styles'
import { SvgImage } from 'components'

export const Shapes = memo(({ style, children, ...props }: ViewProps) => {
  const { styles } = useStyles()

  return (
    <View style={[styles.container, style]} testID="Shapes" {...props}>
      <SvgImage image={require('./Shape1.svg')} style={styles.shape1} />
      <SvgImage image={require('./Shape2.svg')} style={styles.shape2} />
      <SvgImage image={require('./Shape3.svg')} style={styles.shape3} />
      {children}
    </View>
  )
})
