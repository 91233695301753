import React, { memo } from 'react'
import { PageProps } from '../index'
import { Layout, useSharedStyles, IllustrationBackground, Shapes, NextButton, Heading, IllustrationBackgroundHeightRatio } from '../Shared'
import {
  CharacterQuestions,
  CharacterQuestionsHeightRatio
} from 'images/CharacterQuestions'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import { Logo } from 'components'
import { STYLES } from 'styles'

export const Start = memo(({ navigation }: PageProps) => {
  useLang()

  const { t } = useTranslation()
  const { imageWidth, sharedStyles } = useSharedStyles()
  const { styles } = useStyles()
  const { bp } = STYLES.useStyles()

  let _backgroundWidth = imageWidth * 1.4
  let _imageWidth = imageWidth * 1.2

  /* istanbul ignore next */
  if (bp.is([bp.tablet, bp.desktop])) {
    _backgroundWidth = imageWidth
    _imageWidth = imageWidth * 0.8
  }

  const _imageHeight = _imageWidth * CharacterQuestionsHeightRatio

  const _onPress = () => navigation.navigate('Name')

  return (
    <Layout testID="Start" hideTopBar>
      <Shapes>
        <Logo style={sharedStyles.logo} />

        <Heading style={styles.title}>{t('onboardingStart:title')}</Heading>

        <IllustrationBackground
          backgroundStyle={{
            top: 0.133 * _imageHeight,
            width: _backgroundWidth,
            height: _backgroundWidth * IllustrationBackgroundHeightRatio
          }}
          style={{
            minWidth: _backgroundWidth,
            minHeight: _backgroundWidth * IllustrationBackgroundHeightRatio
          }}>
          <CharacterQuestions
            style={{
              width: _imageWidth,
              height: _imageHeight
            }}
          />
        </IllustrationBackground>

        <NextButton onPress={_onPress} />
      </Shapes>
    </Layout>
  )
})
