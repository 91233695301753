import React, { memo } from 'react'
import { ImageProps, StyleSheet, ImageBackground } from 'react-native'

export interface SvgImageProps extends Partial<ImageProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image?: any
  width?: number
  height?: number
  testID?: string
  isNested?: boolean
}

export const SvgImage = memo(
  ({
    image,
    style,
    width,
    height,
    testID = 'SvgImage',
    isNested = false,
    ...props
  }: SvgImageProps) => {
    const {
      width: imageWidth,
      height: imageHeight,
      ...imageStyles
    } = StyleSheet.flatten(style) || {}

    const _width = imageWidth || width
    const _height = imageHeight || height
    const styles = [
      {
        width: _width || '100%',
        height: _height || '100%'
      },
      imageStyles
    ]

    if (image?.default) {
      return (
        <image.default
          style={styles}
          width={_width}
          height={_height}
          testID={testID}
          {...props}
        />
      )
    }

    if (typeof image === 'string') {
      if (isNested) {
        return (
          <image
            href={image}
            width={_width as string | number | undefined}
            height={_height as string | number | undefined}
            data-testid={testID}
          />
        )
      }

      return (
        <ImageBackground
          source={{ uri: image }}
          resizeMode="contain"
          style={styles}
          imageStyle={{
            width: '100%',
            height: '100%'
          }}
          testID={testID}
          {...props}
        />
      )
    }

    return null
  }
)