import { useBreakpoints } from './useBreakpoints'
import { getREM } from '../../getREM'
import { fontSize, lineHeight } from '../FontSize'
import { useColors } from './useColors'
import { grid } from '../Grid'
import { useSpacing } from './useSpacing'
import { TextStyle } from 'react-native'
import { useFont } from './useFont'

export const useInput = () => {
  const { bp } = useBreakpoints()
  const { COLORS } = useColors()
  const { spacing } = useSpacing()
  const { fontRegular } = useFont()

  const input = {
    ...fontRegular,
    width: '100%',
    height: getREM(2.625),
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    borderRadius: getREM(2.625 / 2),
    borderWidth: getREM(0.0625),
    borderColor: COLORS.mercury,
    borderStyle: 'solid',
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    maxWidth: grid.maxInputWidth,
    color: COLORS.aubergine,
    backgroundColor: COLORS.paper,
    textAlign: 'left',
    overflow: 'hidden'
  } as TextStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    input.paddingLeft = spacing.normal
    input.paddingRight = spacing.normal
  }

  if (bp.desktop) {
    input.height = getREM(3.875)
    input.borderRadius = getREM(3.875 / 2)
    input.fontSize = fontSize.m
    input.lineHeight = lineHeight.m
    input.paddingTop = spacing.m
    input.paddingBottom = spacing.m
  }

  return {
    input
  }
}