import React, { memo } from 'react'
import { View, Text, StyleProp, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import { getREM } from 'styles'
import { SvgImage } from 'components'

export interface Props {
  value?: number
  style?: StyleProp<ViewStyle>
  label?: string
  Image?: typeof SvgImage
  imageSize?: number
  valueUnit?: string
}

export const MediaObject = memo(
  ({ value, style, label, Image, imageSize = getREM(5), valueUnit }: Props) => {
    const { t } = useTranslation()
    const { styles } = useStyles()

    const _hasLabel = !!label
    const _hasImage = !!Image
    const _hasValue = !!value

    return (
      <View style={[styles.container, style]}>
        {_hasImage && (
          <View
            style={[
              styles.imageContainer,
              { width: imageSize, height: imageSize },
            ]}
          >
            <Image testID="MediaObjectImage" style={styles.image} />
          </View>
        )}
        <View>
          {_hasValue && (
            <View style={styles.valueContainer}>
              <Text textBreakStrategy="simple" style={styles.value}>
                {t('format:number', { value })}
              </Text>
              <Text textBreakStrategy="simple" style={styles.valueUnit}>
                {valueUnit}
              </Text>
            </View>
          )}
          {_hasLabel && (
            <Text
              textBreakStrategy="simple"
              style={styles.label}
              adjustsFontSizeToFit
            >
              {label}
            </Text>
          )}
        </View>
      </View>
    )
  }
)
