import React, {
  useContext,
  useState,
  useEffect,
  memo,
  useCallback
} from 'react'
import { ViewProps } from 'react-native'
import { PageProps } from '../index'
import {
  SelectionCards,
  SpeechBubble,
  Form,
  ActivityIndicator
} from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { Car as CarImage, CarHeightRatio } from 'images/Car'
import { STYLES } from 'styles'
import { useQuery } from 'react-query'

type SelectionCardsProps = React.ComponentProps<typeof SelectionCards>

export const Car = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const { progress, getCarFootprint, saveCarFootprint } = useContext(
    OnboardingGroupContext
  )
  const { imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_selectedIds, _setSelectedIds] = useState<string[]>([])
  const [_isUpdating, _setIsUpdating] = useState(false)
  const { data: _carFootprint } = useQuery(
    ['onboarding', 'carFootprint'],
    getCarFootprint,
    {
      cacheTime: 0
    }
  )
  const [_showError, _setShowError] = useState(false)

  const _isDisabled = _selectedIds.length === 0

  const yesId = 'yes'
  const noId = 'no'

  const options = [
    {
      id: yesId,
      label: t('onboardingShared:positiveLabel')
    },
    {
      id: noId,
      label: t('onboardingShared:negativeLabel')
    }
  ]

  const _onPress = async () => {
    _setShowError(false)

    if (_selectedIds.includes(yesId)) {
      navigation.navigate('CarDetails')
      return
    }

    _setIsUpdating(true)

    const _success = await saveCarFootprint()

    _setIsUpdating(false)

    if (_success) {
      navigation.navigate('Flights')
      return
    }

    _setShowError(true)
  }

  /* istanbul ignore next */
  const _onSelect: SelectionCardsProps['onSelect'] = (id) => _setSelectedIds(id)

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.topTips')}
        text={t('onboardingCar:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageHeight = imageWidth * CarHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <CarImage
          style={{
            width: imageWidth,
            height: _imageHeight,
            marginTop: 0.42857142857142855 * _imageHeight
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, imageWidth]
  )

  useEffect(() => {
    if (_carFootprint && _selectedIds.length === 0) {
      if (_carFootprint.estimate && _carFootprint.model) {
        _setSelectedIds([yesId])
      } else {
        _setSelectedIds([noId])
      }
    }
  }, [_carFootprint, _selectedIds.length])

  if (_isUpdating) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Car" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingCar:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.Car) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingCar:title')}</Heading>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form style={{ marginBottom: spacing.xl }}>
            <SelectionCards
              legend={t('onboardingCar:title')}
              items={options}
              onSelect={_onSelect}
              selectedIds={_selectedIds}
            />
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton
            onPress={_onPress}
            state={{
              disabled: _isDisabled
            }}
          />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
