import React, { memo, forwardRef, useContext } from 'react'
import { Button, ButtonType, ShowMessage } from 'components'
import { useStepLang } from 'locales'
import { useTranslation } from 'react-i18next'
import { PeopleActionsSuppression } from 'models'
import {
  useErrorMessage,
  useAnalytics,
  useInvalidateDataQueries,
  AuthContext
} from 'utils'
import { useQuery } from 'react-query'
import { TrackingContext } from '../TrackingContext'
import { StepButtonProps } from '../index'

export const Suppress = memo(
  forwardRef(({ action, ...props }: StepButtonProps, ref) => {
    useStepLang()

    const { t } = useTranslation()
    const { showErrorMessage } = useErrorMessage()
    const { trackEvent, trackingEvents } = useAnalytics()
    const { invalidateData } = useInvalidateDataQueries()
    const { isAuthenticated } = useContext(AuthContext)
    const { source } = useContext(TrackingContext)

    const _getSuppression = async () => {
      return (
        await PeopleActionsSuppression.where({ action_id: action!.id })
          .select([''])
          .first()
      ).data
    }

    const { data: suppression, isLoading } = useQuery(
      ['data', 'suppressions', action?.param],
      _getSuppression,
      { enabled: !!action?.id && isAuthenticated }
    )

    const _isSuppressed = !!suppression

    const _onPress = async () => {
      /* istanbul ignore next */
      if (_isSuppressed) {
        // silent return if already suppressed
        return
      }

      const _newSuppression = new PeopleActionsSuppression({
        action_id: action!.id,
        action
      })

      const _success = await _newSuppression.save({ with: 'action' })

      if (!_success) {
        showErrorMessage()
        return
      }

      ShowMessage({
        text: t('step:messages.suppress'),
        isCloseable: true
      })

      await invalidateData()

      trackEvent({
        eventName: trackingEvents.suppressStep,
        props: {
          'action param': action!.param
        }
      })

      trackEvent({
        eventName: trackingEvents.stepsUserFlow,
        props: {
          source,
          type: 'suppress',
          'action param': action!.param
        }
      })
    }

    if (!action || !isAuthenticated || isLoading) return null

    return (
      <Button
        ref={ref}
        onPress={_onPress}
        testID="Suppress"
        type={ButtonType.normal}
        label={t('step:buttons.suppress')}
        state={{
          disabled: _isSuppressed
        }}
        {...props}
      />
    )
  })
)
