import { Campaign, User, Person } from 'models'
import { QueryObserverBaseResult } from 'react-query'
import { PersistedSpraypaintRecord } from 'spraypaint'
import { IController } from 'utils/Controllers'
import { useUserData } from './useUserData'
import { useUnreadAnnouncement } from './useUnreadAnnouncement'
import { AnnouncementProps } from 'components/Announcements/Announcement'

export type ResultType = QueryObserverBaseResult

export type DataType = PersistedSpraypaintRecord<User> | undefined

export type QueryKeyType = string | unknown[]

export interface IProgressGroupController {
  isLoading: boolean
  person: Person | undefined
  campaign: Campaign | undefined
  announcements: AnnouncementProps[]
  onReadAnnouncement: (personId: string | undefined, announcementId: string) => Promise<void>
}

export class ProgressGroupController implements IController<
  IProgressGroupController
> {
  public constructor(init?: Partial<ProgressGroupController>) {
    Object.assign(this, init)
  }

  useController = () => {
    const {
      isLoading: userDataLoading,
      person,
      campaign
    } = useUserData()

    const {
      announcements,
      readAnnouncement: onReadAnnouncement,
      isLoading: announcementLoading
    } = useUnreadAnnouncement()

    const isLoading = userDataLoading || announcementLoading

    return {
      isLoading,
      person,
      campaign,
      announcements: announcements || [],
      onReadAnnouncement
    }
  }
}
