import { StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { box, colors, bp, spacing, fontBold, fontRegular, fontSemiBold } = STYLES.useStyles()

  const container = {
    alignItems: 'center',
    width: '100%'
  } as ViewStyle

  const title = {
    textAlign: 'center'
  } as TextStyle

  let boxGroup = {
    alignItems: 'center',
    width: '100%'
  } as ViewStyle

  if (bp.desktop) {
    boxGroup = {
      ...boxGroup,
      ...box,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: spacing.normal,
      marginBottom: spacing.normal
    }
  }

  const contentGroup = {
    alignItems: 'center',
    flexShrink: 1,
    width: '100%'
  } as ViewStyle

  const clockGroup = {
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 188,
    height: 114
  } as ViewStyle

  if (bp.desktop) {
    clockGroup.width = 221
    clockGroup.height = 142
  }

  const cloudsContainer = {
    marginBottom: 10
  } as ViewStyle

  const clouds = {
    width: 188,
    height: 78
  } as ImageStyle

  if (bp.desktop) {
    clouds.width = 221
    clouds.height = 93
  }

  const clockContainer = {
    position: 'absolute',
    bottom: -10,
    left: 17,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  } as ViewStyle

  const clock = {
    width: 154,
    height: 154
  } as ViewStyle

  if (bp.desktop) {
    clock.width = 187
    clock.height = 187
  }

  const _text = {
    ...fontSemiBold,
    textAlign: 'center',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    _text.fontSize = STYLES.fontSize.normal
    _text.lineHeight = STYLES.lineHeight.normal
  }

  const unlimited = {
    ..._text
  } as TextStyle

  const prefix = {
    ..._text
  } as TextStyle

  const suffix = {
    ..._text
  } as TextStyle

  const note = {
    ...fontRegular,
    textAlign: 'center',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand,
    marginTop: spacing.xs
  } as TextStyle

  const link = {
    marginTop: spacing.normal
  } as ViewStyle

  if (bp.desktop) {
    link.marginTop = 0
  }

  const calendar = {
    marginTop: spacing.normal,
    marginBottom: spacing.normal,
    maxWidth: getREM(19),
    alignSelf: 'center',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    calendar.flexDirection = 'column'
    calendar.flexWrap = 'nowrap'
  }

  let calendarItem = {
    ...box,
    maxWidth: getREM(8.75)
  } as ViewStyle

  if (bp.desktop) {
    calendarItem = {
      maxWidth: getREM(8.75),
      width: '100%',
      backgroundColor: colors.brandContrast,
      borderColor: colors.border,
      borderWidth: getREM(0.0625),
      borderStyle: 'solid',
      borderRadius: getREM(0.625),
      padding: spacing.normal
    }
  }

  const calendarItemValue = {
    ...fontBold,
    textAlign: 'center',
    fontSize: STYLES.fontSize.xxl,
    lineHeight: STYLES.lineHeight.xxl,
    color: colors.brand
  } as TextStyle

  const calendarItemLabel = {
    ...fontSemiBold,
    textAlign: 'center',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.alternative
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    title,
    boxGroup,
    contentGroup,
    clockGroup,
    cloudsContainer,
    clouds,
    clockContainer,
    clock,
    unlimited,
    prefix,
    suffix,
    note,
    link,
    calendar,
    calendarItem,
    calendarItemValue,
    calendarItemLabel
  })

  return { styles }
}
