import { StyleSheet, TextStyle, ViewStyle, Platform, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { COLORS, colors, fontRegular, fontBold, bp, spacing } = STYLES.useStyles()

  let imageWidth = 160

  if (bp.is([bp.mobile_m, bp.mobile_l])) {
    imageWidth = 250
  }

  if (bp.is([bp.mobile_xl])) {
    imageWidth = 300
  }

  if (bp.tablet) {
    imageWidth = 300
  }

  if (bp.desktop) {
    imageWidth = 430
  }

  let _valueWidth = getREM(4.5)

  if (bp.is([bp.mobile_l, bp.mobile_xl, bp.tablet, bp.desktop])) {
    _valueWidth = getREM(5.5)
  }

  let _gap = spacing.s

  if (bp.is([bp.tablet, bp.desktop])) {
    _gap = spacing.l
  }

  const saveButton: ViewStyle = {
    alignSelf: 'center',
    marginTop: (Platform.OS === 'web'
      ? `calc(-1 * ${spacing.normal})`
      : -1 * spacing.normal) as ViewStyle['marginTop']
  }

  const table: ViewStyle = {}

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m])) {
    table.marginLeft = (Platform.OS === 'web'
      ? `calc(-1 * ${spacing.page.padding.paddingLeft})`
      : -1 * spacing.page.padding.paddingLeft) as ViewStyle['marginLeft']

    table.marginRight = (Platform.OS === 'web'
      ? `calc(-1 * ${spacing.page.padding.paddingRight})`
      : -1 * spacing.page.padding.paddingRight) as ViewStyle['marginRight']
  }

  const tableHeading: TextStyle = {
    textAlign: 'left'
  } as TextStyle

  if (bp.not([bp.desktop])) {
    tableHeading.textAlign = 'center'
  }

  const tableHead: ViewStyle = {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: spacing.normal,
    paddingLeft: _gap,
    paddingRight: _gap
  }

  const tableHeadLabel: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    width: _valueWidth,
    textAlign: 'center',
    paddingLeft: _gap,
    ...Platform.select({
      web: {
        boxSizing: 'content-box'
      }
    })
  }

  if (bp.is([bp.mobile_l, bp.mobile_xl, bp.tablet, bp.desktop])) {
    tableHeadLabel.fontSize = STYLES.fontSize.s
    tableHeadLabel.lineHeight = STYLES.lineHeight.s
  }

  const row: ViewStyle = {
    borderBottomWidth: getREM(0.0625),
    borderBottomColor: COLORS.steam,
    borderStyle: 'solid',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: spacing.s,
    paddingBottom: spacing.s,
    paddingLeft: _gap,
    paddingRight: _gap
  }

  const row_first: ViewStyle = {
    borderTopWidth: getREM(0.0625),
    borderTopColor: COLORS.steam,
    borderStyle: 'solid'
  }

  const row_odd: ViewStyle = {
    backgroundColor: COLORS.paper
  }

  const rowIcon: ImageStyle = {
    marginRight: _gap,
    width: getREM(1.375),
    height: getREM(1.375)
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    rowIcon.display = 'none'
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    rowIcon.width = getREM(2.375)
    rowIcon.height = getREM(2.375)
  }

  const rowLabel: TextStyle = {
    ...fontRegular,
    marginRight: 'auto',
    color: colors.text,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    rowLabel.fontSize = STYLES.fontSize.m
    rowLabel.lineHeight = STYLES.lineHeight.m
  }

  const rowValue: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.normal,
    width: _valueWidth,
    textAlign: 'center',
    color: COLORS.aubergine,
    paddingLeft: _gap,
    ...Platform.select({
      web: {
        boxSizing: 'content-box'
      }
    }),
    flexShrink: 0
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    rowValue.fontSize = STYLES.fontSize.normal
    rowValue.lineHeight = STYLES.lineHeight.l
  }

  const rowValueNumber: TextStyle = {
    ...fontBold,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    rowValueNumber.fontSize = STYLES.fontSize.l
    rowValueNumber.lineHeight = STYLES.lineHeight.l
  }

  const messageContainer: ViewStyle = {
    borderRadius: getREM(1),
    backgroundColor: COLORS.snuff,
    padding: spacing.s,
    marginTop: spacing.normal,
    marginBottom: spacing.xl
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    messageContainer.padding = spacing.m
  }

  const messageText: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    color: COLORS.aubergine
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    messageText.fontSize = STYLES.fontSize.m
    messageText.lineHeight = STYLES.lineHeight.m
  }

  const styles = StyleSheet.create({
    saveButton,
    table,
    tableHeading,
    tableHead,
    tableHeadLabel,
    row,
    row_first,
    row_odd,
    rowIcon,
    rowLabel,
    rowValue,
    rowValueNumber,
    messageContainer,
    messageText
  })

  return { styles, imageWidth }
}
