import { Storage } from '../Storage'
import { Platform } from 'react-native'
import { Locales, DefaultLocale } from './Locales'
import { getLocales } from 'expo-localization'

export const LocalePickerKey = 'lang'

const _saveLocale = async (lang: string) => {
  await Storage.setItem({ key: LocalePickerKey, value: lang })
}

export default {
  type: 'languageDetector',
  async: true,
  detect: async (callback: (locale: string) => void) => {
    let _deviceLanguage = getLocales()?.[0]?.languageCode
    let _htmlLanguage: string | undefined

    // even though the else if is triggered in the tests
    // the coverage checker still complains
    /* istanbul ignore else */
    if (Platform.OS === 'web') {
      if (typeof document !== 'undefined' && document?.documentElement?.lang) {
        _deviceLanguage = document.documentElement.lang
        _htmlLanguage = document.documentElement.lang
      } else if (typeof navigator !== 'undefined' && navigator?.language) {
        _deviceLanguage = navigator.language
      }
    }

    const _checkLocale = (lang?: string) => {
      if (!lang || !Object.keys(Locales).includes(lang)) {
        lang = _htmlLanguage || DefaultLocale
      }

      return lang
    }

    const _storedLocale = await Storage.getItem(LocalePickerKey)
    let _qsLocale = null

    if (Platform.OS === 'web' && typeof window !== 'undefined') {
      const _params = new URLSearchParams(window.location.search)
      _qsLocale = _params.get('locale')
    }

    // if one of these locales are not supported i18next will
    // fallback to default locale
    //
    // even though the else if is triggered in the tests
    // the coverage checker still complains
    /* istanbul ignore else */
    if (_qsLocale) {
      // querystring
      const _lang = _checkLocale(_qsLocale)

      await _saveLocale(_lang)

      callback(_lang)
    } else if (_htmlLanguage) {
      // html tag
      const _lang = _checkLocale(_htmlLanguage)

      await _saveLocale(_lang)

      callback(_lang)
    } else if (_storedLocale) {
      // storage
      const _lang = _checkLocale(_storedLocale)

      callback(_lang)
    } else if (_deviceLanguage) {
      // device
      let _lang = _deviceLanguage

      if (_lang.indexOf('-') > -1) {
        _lang = _lang.split('-')[0]!
      }

      if (_lang.indexOf('_') > -1) {
        _lang = _lang.split('_')[0]!
      }

      _lang = _checkLocale(_lang)

      await _saveLocale(_lang)

      callback(_lang)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cacheUserLanguage: () => { }
}
