import React, { memo, useMemo, useContext } from 'react'
import { ViewProps } from 'react-native'
import { CategoryPills, CategoryPill } from 'components'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { Theme, Impact } from 'models'
import { LinkContext } from 'utils'

export interface Props {
  themes?: Theme[]
  impact?: Impact
  categoryPillOnPress?: React.ComponentProps<typeof CategoryPill>['onPress']
  style?: ViewProps['style']
}

export const StepCategoryPills = memo(({ categoryPillOnPress, themes, impact, style }: Props) => {
  useLang()

  const { t } = useTranslation()
  const { stepsPageLinkUrl } = useContext(LinkContext)

  const _categoryPills = useMemo(() => {
    const _items = []

    const _hasHref = !categoryPillOnPress && !!stepsPageLinkUrl

    if (impact) {
      _items.push({
        slug: impact.lookupSlug,
        label: impact.name,
        backgroundColor: impact.colorBackground,
        textColor: impact.colorForeground,
        onPress: categoryPillOnPress,
        ariaLabel: t('stepCategoryPills:categoryPillAriaLabel', { value: impact.name }),
        href: _hasHref ? `${stepsPageLinkUrl}?filter=${impact.lookupSlug}` : undefined
      })
    }

    if (themes) {
      themes.forEach((item: Theme) => {
        _items.push({
          slug: item.lookupSlug,
          label: item.name,
          backgroundColor: item.colorBackground,
          textColor: item.colorForeground,
          onPress: categoryPillOnPress,
          ariaLabel: t('stepCategoryPills:categoryPillAriaLabel', { value: item.name }),
          href: _hasHref ? `${stepsPageLinkUrl}?filter=${item.lookupSlug}` : undefined
        })
      })
    }

    return _items
  }, [impact, t, themes])

  return (
    <CategoryPills style={style} testID="StepCategoryPills" categoryPills={_categoryPills} />
  )
})

