import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class Footprint extends ApplicationRecord {
  static jsonapiType = 'footprints'

  @Attr() source!: 'estimated' | 'actual'
  @Attr() applicable?: boolean
}
