import React, {
  useContext,
  useState,
  useEffect,
  memo,
  ComponentProps,
  useCallback
} from 'react'
import { ViewProps } from 'react-native'
import { PageProps, PagePathnames } from '../index'
import {
  SelectionCards,
  SpeechBubble,
  Form,
  Text,
  ActivityIndicator
} from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { Lightbulb, LightbulbHeightRatio } from 'images/Lightbulb'
import { STYLES } from 'styles'
import { useQuery } from 'react-query'
import { useAnalytics } from 'utils'

type SelectionCardsProps = ComponentProps<typeof SelectionCards>

export const Goals = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const { progress, getGoals, getPersonGoals, saveGoals } = useContext(
    OnboardingGroupContext
  )
  const { sharedStyles, imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_selectedIds, _setSelectedIds] = useState<string[]>([])
  const [_isUpdating, _setIsUpdating] = useState(false)
  const { data: _goals, isLoading } = useQuery(
    ['onboarding', 'goals'],
    getGoals
  )
  const { data: _personGoals } = useQuery(
    ['onboarding', 'personGoals'],
    getPersonGoals
  )
  const [_showError, _setShowError] = useState(false)
  const { trackEvent, trackingEvents, getTrackingURL } = useAnalytics()

  const _isDisabled = _selectedIds.length === 0

  const _onPress = async () => {
    _setShowError(false)

    /* istanbul ignore next */
    if (_goals) {
      _setIsUpdating(true)

      const _selectedGoals = _goals.filter((item) =>
        _selectedIds.includes(item.id!)
      )

      const _success = await saveGoals(_selectedGoals)

      const _pathname = PagePathnames['Steps']

      /* istanbul ignore next */
      if (_pathname && _selectedGoals.length > 0) {
        const _url = getTrackingURL(_pathname)

        _selectedGoals.forEach((item) => {
          trackEvent({
            eventName: trackingEvents.onboardingGoal,
            props: {
              goalId: item.id,
              goalName: item.name
            },
            url: _url
          })
        })
      }

      _setIsUpdating(false)

      if (_success) {
        navigation.navigate('Summary')
        return
      }
    }

    _setShowError(true)
  }

  const _onSelect: SelectionCardsProps['onSelect'] = (id) => _setSelectedIds(id)

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.topTips')}
        text={t('onboardingGoals:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageWidth = imageWidth * 0.6
  const _imageHeight = _imageWidth * LightbulbHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <Lightbulb
          style={{
            width: _imageWidth,
            height: _imageHeight,
            transform: [
              {
                translateX: 0.333 * _imageWidth
              },
              {
                translateY: 0.16290182450043442 * _imageHeight
              }
            ]
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, _imageWidth]
  )

  useEffect(() => {
    if (_personGoals && _selectedIds.length === 0) {
      _setSelectedIds(() => {
        const _newIds: typeof _selectedIds = []

        _personGoals.forEach((item) => {
          /* istanbul ignore next */
          if (item.goal.id) {
            _newIds.push(item.goal.id)
          }
        })

        return _newIds
      })
    }
  }, [_personGoals, _selectedIds.length])

  if (_isUpdating || isLoading) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Goals" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingGoals:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.Goals) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingGoals:title')}</Heading>
          <Text containerStyle={sharedStyles.textContainer}>
            {t('onboardingGoals:text')}
          </Text>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <SelectionCards
              legend={t('onboardingGoals:title')}
              items={
                /* istanbul ignore next */
                _goals?.map((item) => ({
                  id: item.id!,
                  label: item.name
                })) || []
              }
              onSelect={_onSelect}
              selectedIds={_selectedIds}
              style={{ marginBottom: spacing.xl }}
              isMulti
            />
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton onPress={_onPress} state={{ disabled: _isDisabled }} />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
