import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Edit = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Edit"
      viewBox="0 0 24 24"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        fill="none"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
        d="M20.107 3.897a2.89 2.89 0 0 0-4.102.028L4.5 15.428l-1.448 5.52 5.522-1.446L20.08 7.999a2.886 2.886 0 0 0 .028-4.101h0zm-4.431.358 4.074 4.072m-5.736-2.411 4.074 4.072m-13.59 5.44 2.857 1.219 1.221 2.851m7.475-11.546-8.696 8.694"
      />
    </SVG.Svg>
  )
})
