import React from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Select } from 'components'

export { Start } from './Start'
export { Name } from './Name'
export { Country } from './Country'
export { State } from './State'
export { Car } from './Car'
export { CarDetails } from './CarDetails'
export { Flights } from './Flights'
export { Diet } from './Diet'
export { House } from './House'
export { Bedrooms } from './Bedrooms'
export { Household } from './Household'
export { Goals } from './Goals'
export { Summary } from './Summary'
export { Steps } from './Steps'
export { StepsSuccess } from './StepsSuccess'
export { Success } from './Success'

export type PageParamList = {
  Start: undefined
  Name: undefined
  Country: undefined
  State: {
    countryId?: React.ComponentProps<typeof Select>['selectedId']
  }
  Car: undefined
  CarDetails: undefined
  Flights: undefined
  Diet: undefined
  House: undefined
  Bedrooms: {
    houseType: string | undefined
  }
  Household: undefined
  Goals: undefined
  Summary: undefined
  Steps: undefined
  StepsSuccess: undefined
  Success: undefined
}

export const PagePathnames: { [key: string]: string } = {
  'Start': '/onboarding',
  'Name': '/onboarding/name',
  'Country': '/onboarding/region',
  'State': '/onboarding/region_state',
  'Car': '/onboarding/car',
  'CarDetails': '/onboarding/car_details',
  'Flights': '/onboarding/flights',
  'Diet': '/onboarding/diet',
  'House': '/onboarding/building',
  'Bedrooms': '/onboarding/bedrooms',
  'Household': '/onboarding/occupancy',
  'Goals': '/onboarding/goals',
  'Summary': '/onboarding/summary',
  'Steps': '/onboarding/steps',
  'StepsSuccess': '/onboarding/steps_success',
  'Success': '/onboarding/success'
}

export type PageProps = NativeStackScreenProps<PageParamList>
