import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from '../ApplicationRecord'
import { LeaderboardRowValues } from './types'

@Model()
export class Leaderboard extends ApplicationRecord {
  static jsonapiType = 'leaderboards'

  @Attr({ persist: false }) rowValues!: LeaderboardRowValues[]
  @Attr({ persist: false }) highlighted!: boolean
}
