import React, { useEffect, memo } from 'react'
import { View, Modal, ScrollView, ViewProps, ModalProps } from 'react-native'
import {
  Heading,
  Text,
  Button,
  ButtonType,
  ButtonSize,
  SVG,
  Icons,
  SvgImage,
  ActivityIndicator
} from 'components'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { WebAccessibilityRole, useAnalytics } from 'utils'

export interface Props {
  Image?: typeof SVG.Svg | typeof SvgImage
  imageWidth?: number
  imageHeight?: number
  title?: string
  text?: string
  primaryActionLabel?: string
  primaryActionCallback?: () => void
  primaryActionDisabled?: boolean
  closeCallback?: () => void
  closeLabel?: string
  isHidden: boolean
  animationType?: ModalProps['animationType']
  style?: ViewProps['style']
  children?: ViewProps['children']
  hideBox?: boolean
  trackingLabel?: string
  isLoading?: boolean
  cardStyle?: ViewProps['style']
}

export const FullScreenDialog = memo(
  ({
    Image,
    imageWidth,
    imageHeight,
    title,
    text,
    primaryActionCallback,
    primaryActionLabel,
    primaryActionDisabled = false,
    closeCallback,
    closeLabel,
    isHidden = true,
    animationType = 'fade',
    style,
    children,
    hideBox = false,
    trackingLabel,
    isLoading = false,
    cardStyle
  }: Props) => {
    useLang()
    const { t } = useTranslation()
    const { styles } = useStyles()
    const { trackEvent, trackingEvents } = useAnalytics()

    const _hasImage = !!Image
    const _hasImageWidth = !!imageWidth
    const _hasImageHeight = !!imageHeight
    const _hasPrimaryAction = !!primaryActionCallback && !!primaryActionLabel
    const _hasCloseCallback = !!closeCallback
    const _hasCloseLabel = !!closeLabel

    useEffect(() => {
      if (!isHidden && trackingLabel) {
        trackEvent({
          eventName: trackingEvents.showFullScreenDialog,
          props: { title: trackingLabel }
        })
      }
    }, [
      isHidden,
      trackEvent,
      trackingEvents.showFullScreenDialog,
      trackingLabel
    ])

    return (
      <Modal
        style={[styles.container, style]}
        statusBarTranslucent={true}
        animationType={animationType}
        visible={!isHidden}
        onRequestClose={closeCallback}
        transparent={false}
        testID="FullScreenDialog">
        <ScrollView contentContainerStyle={styles.background}>
          <View style={styles.contentContainer}>
            {isLoading && <ActivityIndicator />}
            {!isLoading && (
              <View
                style={[styles.content, !hideBox && styles.card, cardStyle]}
                accessibilityRole={WebAccessibilityRole('dialog')}
                testID="FullScreenDialogContent">
                {_hasImage && (
                  <View
                    style={[
                      styles.imageContainer,
                      _hasImageWidth && { width: imageWidth },
                      _hasImageHeight && { height: imageHeight }
                    ]}
                    testID="FullScreenDialogImageContainer">
                    <Image
                      testID="FullScreenDialogImage"
                      style={styles.image}
                    />
                  </View>
                )}

                {title && (
                  <Heading style={styles.title} level={2}>
                    {title}
                  </Heading>
                )}

                {text && (
                  <Text containerStyle={styles.textContainer}>{text}</Text>
                )}

                {children}

                {_hasPrimaryAction && (
                  <Button
                    label={primaryActionLabel}
                    onPress={primaryActionCallback}
                    type={ButtonType.brand}
                    state={{ disabled: primaryActionDisabled }}
                  />
                )}

                {_hasCloseLabel && _hasCloseCallback && (
                  <Button
                    type={ButtonType.link}
                    label={closeLabel}
                    onPress={closeCallback}
                    size={ButtonSize.small}
                    containerStyle={styles.closeButtonContainer}
                  />
                )}

                {_hasCloseCallback && (
                  <Button
                    type={ButtonType.icon}
                    label={t('fullScreenDialog:cancel')}
                    onPress={closeCallback}
                    icon={Icons.Close}
                    hideLabel
                    textStyle={styles.closeIcon}
                    containerStyle={[
                      styles.closeIconContainer,
                      hideBox && { right: 0 }
                    ]}
                  />
                )}
              </View>
            )}
          </View>
        </ScrollView>
      </Modal>
    )
  }
)