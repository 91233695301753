import { SVG } from 'components'
import React, { memo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { iconSize } from './config'
import { Icon } from './Icons'
import { Lines } from './Lines'
import { Points } from './Points'
import { useStyles } from './styles'

export interface Props {
  border?: boolean
  completed?: boolean
  improvements: string[]
  actionSlug: string
  size?: 'default' | 'big' | 'huge'
  style?: StyleProp<ViewStyle>
}

export const StepIcon = memo(
  ({
    border = true,
    completed = false,
    improvements = [],
    size = 'default',
    actionSlug,
    style = null
  }: Props) => {
    const { styles } = useStyles(size)

    const _viewBoxSize = String(iconSize[size])

    const _improvements = improvements?.map((i) => i.toLowerCase())

    return (
      <View
        style={[styles.icon, border ? styles.iconBorder : {}, style]}
        testID="StepIcon">
        <View style={styles.imageContainer}>
          <Icon actionSlug={actionSlug} style={styles.image} />
        </View>
        <SVG.Svg
          style={styles.border}
          viewBox={`0 0 ${_viewBoxSize} ${_viewBoxSize}`}
          testID={'svg'}>
          {completed ? (
            <Lines improvements={_improvements} size={size} />
          ) : (
            <Points improvements={_improvements} size={size} />
          )}
        </SVG.Svg>
      </View>
    )
  }
)
