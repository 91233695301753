import { StyleSheet, ViewStyle, Platform, ImageStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { bp, spacing, sizing } = STYLES.useStyles()

  const container = {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    position: 'relative'
  } as ViewStyle

  const shape1 = {
    position: 'absolute',
    zIndex: 0,
    top: -60,
    left: Platform.OS === 'web' ? `calc(${spacing.page.padding.paddingLeft} * -1)` : spacing.page.padding.paddingLeft * -1,
    width: 42,
    height: 94
  } as ImageStyle

  const shape2 = {
    position: 'absolute',
    zIndex: 0,
    left: Platform.OS === 'web' ? `calc(${spacing.page.padding.paddingLeft} * -1)` : spacing.page.padding.paddingLeft * -1,
    bottom: -60,
    width: 72,
    height: 123
  } as ImageStyle

  const shape3 = {
    position: 'absolute',
    zIndex: 0,
    right: Platform.OS === 'web' ? `calc(${spacing.page.padding.paddingRight} * -1)` : spacing.page.padding.paddingRight * -1,
    bottom: 60,
    width: 67,
    height: 136
  } as ImageStyle

  if (bp.is([bp.mobile_s, bp.mobile_xs])) {
    shape1.display = 'none'
    shape2.display = 'none'
    shape3.display = 'none'
  }

  if (bp.is([bp.mobile_l, bp.mobile_xl])) {
    shape1.width = 55
    shape1.height = 125
    shape1.top = -80,

      shape2.width = 95
    shape2.height = 163

    shape3.width = 89
    shape3.height = 180
  }

  if (bp.tablet) {
    shape1.width = 100
    shape1.height = 226
    shape1.top = -sizing.topBarHeightDefault - 20

    shape2.width = 172
    shape2.height = 295

    shape3.width = 161
    shape3.height = 326
  }

  if (bp.desktop) {
    shape1.width = 120
    shape1.height = 271
    shape1.top = -sizing.topBarHeightDefault - 20

    shape2.width = 207
    shape2.height = 354

    shape3.width = 193
    shape3.height = 392
    shape3.bottom = 'auto'
    shape3.top = 0
  }

  const styles = StyleSheet.create({
    container,
    shape1,
    shape2,
    shape3
  })

  return { styles }
}
