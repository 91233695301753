import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing, bp } = STYLES.useStyles()

  const gap = {
    marginTop: spacing.s
  } as ViewStyle

  if (bp.desktop) {
    gap.marginTop = spacing.normal
  }

  const styles = StyleSheet.create({
    gap
  })

  return { styles }
}
