import { fontSize, lineHeight } from './FontSize'
import { grid } from './Grid'
import { useStyles } from './useStyles'

export const STYLES = {
  fontSize,
  grid,
  lineHeight,
  useStyles
}
