import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const BackArrow = memo(({
  size = getREM(1.0625),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="BackArrow"
      viewBox="0 0 17 14"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M0.884913 7.85187L5.9764 12.9434C6.44567 13.4126 7.20472 13.4122 7.67356 12.9434C8.1424 12.4745 8.1424 11.715 7.67356 11.2462L4.61952 8.19215L15.3172 8.19088C15.9731 8.19088 16.5073 7.6567 16.5077 7.00032C16.5081 6.34394 15.9727 5.80849 15.3159 5.80849L4.6174 5.80637L7.67059 2.75317C8.13986 2.28391 8.13943 1.52485 7.67059 1.05601C7.20175 0.587166 6.44227 0.587167 5.97343 1.05601L0.881944 6.14749C0.413952 6.61549 0.415648 7.3826 0.884913 7.85187Z"
        fill={color ?? colors.text}
      />
    </SVG.Svg>
  )
})
