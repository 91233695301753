import { StyleSheet, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { colors, spacing, paragraph, bp, fontBold, fontRegular } = STYLES.useStyles()

  const label = {
    ...fontBold,
    width: '100%',
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    textAlign: 'center'
  } as TextStyle

  if (bp.desktop) {
    label.fontSize = STYLES.fontSize.normal
    label.lineHeight = STYLES.lineHeight.normal
  }

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: spacing.xl
    },
    labelContainer: {
      width: '100%',
      maxWidth: STYLES.grid.maxInputWidth,
      alignSelf: 'center',
      marginBottom: spacing.normal
    },
    label,
    description: {
      ...fontRegular,
      color: paragraph.color,
      fontSize: STYLES.fontSize.s,
      lineHeight: STYLES.lineHeight.s,
      textAlign: 'center',
      marginTop: spacing.xxs
    }
  })

  return { styles }
}
