import { User } from 'models'

export const GetScore = async () => {
  const { data: user } = await User
    .includes('person')
    .select({
      users: [''],
      people: ['score']
    })
    .find('current')

  return user?.person?.score
}