import React, { memo, useRef, useEffect, useContext } from 'react'
import { ViewProps } from 'react-native'
import { useStyles } from './styles'
import { CommitmentState, Commitment, Action } from 'models'
import { Button, ButtonType, View, StepButtons } from 'components'
import { STYLES } from 'styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useStepLang } from 'locales'
import { LinkContext, AuthContext, ReplaceParam } from 'utils'

export interface Props extends ViewProps {
  actionParam: Action['param']
}

export const StepInteractionsGroup = memo(({
  actionParam,
  style,
  ...props
}: Props) => {
  useStepLang()

  const { t, i18n } = useTranslation()
  const { styles } = useStyles()
  const { spacing } = STYLES.useStyles()
  const _mountedRef = useRef(false)
  const { todoListPageLinkUrl } = useContext(LinkContext)
  const { isAuthenticated, login, signup } = useContext(AuthContext)

  const _getAction = async () => {
    /* istanbul ignore next */
    return (
      (
        await Action.where({ param: actionParam })
          .select(['name'])
          .extraParams({ locale: i18n.language })
          .first()
      ).data || undefined
    )
  }

  const { isLoading: isLoadingAction, data: action } = useQuery(
    ['data', 'action', actionParam, i18n.language],
    _getAction,
    { enabled: !!actionParam && isAuthenticated }
  )

  const _getCommitment = async () => {
    /* istanbul ignore next */
    if (!action?.id) return null
    const { data } = await Commitment.where({ action_id: action.id })
      .select(['state'])
      .extraParams({ paginate: false })
      .all()

    return data.find((item) => item.state !== CommitmentState.abandoned)
  }

  const { isLoading: isLoadingCommitment, data: commitment } = useQuery(['data', 'commitments', actionParam], _getCommitment, { enabled: isAuthenticated && !!action?.id })

  const Container = ({ children }: ViewProps) => (
    <View
      style={[styles.container, style]}
      testID="StepInteractionsGroup"
      spacer={spacing.s}
      {...props}
    >
      {children}
    </View>
  )

  const PlaceholderButtons = () => (
    <Container>
      <Button
        type={ButtonType.placeholder}
        label=" "
        isFake
      />
      <Button
        type={ButtonType.placeholder}
        label=" "
        isFake
      />
    </Container>
  )

  const PendingButtons = () => (
    <Container>
      <StepButtons.Succeed action={action} />
      <View style={styles.buttonGroup} spacer={spacing.s}>
        {!!todoListPageLinkUrl && (
          <Button
            label={t('step:buttons:todoList')}
            href={ReplaceParam(todoListPageLinkUrl, commitment!.id)}
            containerStyle={styles.buttonGroupItem}
          />
        )}
        <StepButtons.Abandon
          action={action}
          containerStyle={styles.buttonGroupItem}
        />
      </View>
    </Container>
  )

  const CompleteButtons = () => (
    <Container>
      <StepButtons.Abandon
        action={action}
      />
    </Container>
  )

  const DefaultButtons = () => (
    <Container>
      <StepButtons.Commit
        action={action}
      />
      <View style={styles.buttonGroup} spacer={spacing.s}>
        <StepButtons.Current
          action={action}
          containerStyle={styles.buttonGroupItem}
        />
        <StepButtons.Suppress
          action={action}
          containerStyle={styles.buttonGroupItem}
        />
      </View>
    </Container>
  )

  const AuthButtons = () => (
    <Container>
      {signup && (
        <Button
          type={ButtonType.brand}
          label={t('step:buttons.signUp')}
          onPress={signup}
        />
      )}
      {login && (
        <Button
          label={t('step:buttons.signIn')}
          onPress={login}
        />
      )}
    </Container>
  )

  const CommitmentButtons = () => {
    switch (commitment?.state) {
      case CommitmentState.pending:
        return <PendingButtons />

      case CommitmentState.existing:
      case CommitmentState.successful:
      case CommitmentState.complete:
        return <CompleteButtons />

      default:
        return <DefaultButtons />
    }
  }

  useEffect(() => {
    _mountedRef.current = true

    return () => {
      _mountedRef.current = false
    }
  }, [])

  if (isLoadingAction || isLoadingCommitment) {
    return <PlaceholderButtons />
  }

  if (isAuthenticated) {
    return <CommitmentButtons />
  }

  return <AuthButtons />
})
