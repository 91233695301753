import { STYLES, getREM } from 'styles'
import { Platform, TextStyle } from 'react-native'
import { TypeStylesProps } from './index'

export const useIconOutlined = () => {
  const { colors, boxShadows } = STYLES.useStyles()

  const _border = 0.125

  const _small = 2.5625
  const _normal = 2.8125
  const _large = 3.8125

  const iconOutlined = {
    nativeContainer: {
      ...boxShadows.button.ios
    },
    label: {
      textDecorationLine: 'none',
      backgroundColor: colors.brandContrast,
      color: colors.brand,
      padding: 0,
      justifyContent: 'center',
      alignItems: 'center',
      ...Platform.select({
        web: {
          display: 'inline-flex',
          border: `${getREM(_border)} solid ${colors.brand}`,
          cursor: 'pointer'
        },
        default: {
          borderColor: colors.brand,
          borderWidth: getREM(_border),
          borderStyle: 'solid'
        }
      }),
      ...boxShadows.button.web,
      ...boxShadows.button.android
    } as TextStyle,
    pointerOver: {
      ...boxShadows.button_pointerOver.web
    },
    small: {
      width: getREM(_small),
      height: getREM(_small),
      borderRadius: getREM(_small / 2)
    },
    normal: {
      width: getREM(_normal),
      height: getREM(_normal),
      borderRadius: getREM(_normal / 2)
    },
    large: {
      width: getREM(_large),
      height: getREM(_large),
      borderRadius: getREM(_large / 2)
    }
  } as TypeStylesProps

  return {
    iconOutlined,
    sizes: {
      border: _border,
      small: _small,
      normal: _normal,
      large: _large
    }
  }
}
