import { createContext } from 'react'

const missingNavigationContextWarning = "No navigation context has been registered."

export const NavigationContext = createContext<{
  navigate: (url: string) => void,
  transform: (url: string) => string,
  getCurrentRoute: () => string
}>({
  navigate: () => console.warn(missingNavigationContextWarning),
  transform: (): string => {
    console.warn(missingNavigationContextWarning); 
    return ""
  },
  getCurrentRoute: () =>
  { 
    console.warn(missingNavigationContextWarning)
    return ""
  }
})
