import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'
import { fontSize, lineHeight } from 'styles/STYLES/FontSize'

export const useStyles = () => {
  const { bp, spacing, COLORS, viewports, fontBold, fontSemiBold } = STYLES.useStyles()

  const orgProgressContainer: ViewStyle = {
    display: 'flex',
    alignItems: 'center',
    paddingRight: spacing.page.padding.paddingRight,
    paddingLeft: spacing.page.padding.paddingLeft
  }

  const stepCount: TextStyle = {
    fontSize: fontSize.xxl,
    ...fontBold,
    color: COLORS.cherry,
    lineHeight: lineHeight.xxl
  }

  const stepsContainer: ViewStyle = {
    alignItems: 'center'
  }

  const navContainer: ViewStyle = {
    marginBottom: spacing.xxxl
  }

  const stepCountContainer: TextStyle = {
    marginBottom: 0,
    textAlign: 'center'
  }

  const stepHeading: TextStyle = {
    marginBottom: 0,
    textAlign: 'center'
  }

  const totalStepsTextContainer: TextStyle = {
    textAlign: 'center'
  }

  const totalStepsText: TextStyle = {
    fontSize: fontSize.normal,
    color: COLORS.aubergine,
    ...fontSemiBold
  }

  // valueBoxContainer properties set further down
  const valueBoxContainer: ViewStyle = {}

  // valueBox properties set further down
  const valueBox: ViewStyle = {}

  const progressToggle: ViewStyle = {
    marginTop: spacing.l
  }

  const stepsButtonContainer: ViewStyle = {
    marginTop: spacing.l
  }

  const achievements: ViewStyle = {
    width: '100%',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  }

  const footprintSection: ViewStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: spacing.xxxl
  }

  const mediaObjectContainer: ViewStyle = {
    width: '100%',
    alignItems: 'center'
  }

  const footPrintButton: ViewStyle = {
    maxWidth: getREM(14),
    marginHorizontal: 'auto',
    marginVertical: spacing.l
  }

  const barChartContainer: ViewStyle = {
    marginVertical: spacing.m
  }

  if (bp.gt(viewports.mobile_normal)) {
    valueBoxContainer.flexDirection = 'row'
    valueBox.maxWidth = getREM(11.25)
    achievements.flexDirection = 'row'
  }

  if (bp.gt(viewports.mobile_l)) {
    valueBox.padding = spacing.l
    footprintSection.flexDirection = 'row'
    mediaObjectContainer.flex = 1
    mediaObjectContainer.width = 450
    mediaObjectContainer.paddingHorizontal = spacing.s
  }

  const achievementsGroup: ViewStyle = {
    marginTop: spacing.xxxl,
    alignItems: 'center'
  }

  const alignCenter: TextStyle = {
    textAlign: 'center'
  }

  const styles = StyleSheet.create({
    orgProgressContainer,
    stepCount,
    stepCountContainer,
    stepHeading,
    totalStepsTextContainer,
    totalStepsText,
    valueBoxContainer,
    valueBox,
    progressToggle,
    stepsButtonContainer,
    achievements,
    footprintSection,
    mediaObjectContainer,
    footPrintButton,
    barChartContainer,
    achievementsGroup,
    stepsContainer,
    navContainer,
    alignCenter
  })

  return { styles }
}
