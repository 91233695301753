import React, {
  useRef,
  memo,
  useState,
  ComponentProps,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from 'react'
import {
  View,
  Animated,
  ViewProps,
  Platform,
  StyleSheet,
  KeyboardAvoidingView,
  Text
} from 'react-native'
import { useStyles as useLayoutStyles } from '../OnboardingGroup/OnboardingGroupPages/Shared/Layout/styles'
import { Heading as OnboardingHeading } from '../OnboardingGroup/OnboardingGroupPages/Shared'
import {
  useNativeDriver,
  GenerateId,
  useNavigationContext,
  LinkContext
} from 'utils'
import {
  TopBar,
  Column,
  SpeechBubble,
  NumberInput,
  Form,
  FieldSet,
  Button,
  ButtonType,
  ActivityIndicator,
  ShowMessage,
  Heading,
  SvgImage
} from 'components'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { STYLES } from 'styles'
import { HouseholdImage, HouseholdHeightRatio } from 'images'
import { HouseholdFootprintGroupController } from './HouseholdFootprintGroupController'
import { useStyles } from './styles'
import { useFootprintLang, useErrorLang } from 'locales'
import { Person } from 'models'

type NumberInputProps = ComponentProps<typeof NumberInput>

export interface Props {
  controller?: HouseholdFootprintGroupController
  topBarLogoLinkUrl?: string
}

export const HouseholdFootprintIcons = {
  appliances: memo((props) => (
    <SvgImage image={require('images/Footprints/Appliances.svg')} {...props} />
  )) as typeof SvgImage,
  cleaning: memo((props) => (
    <SvgImage image={require('images/Footprints/Cleaning.svg')} {...props} />
  )) as typeof SvgImage,
  electricity: memo((props) => (
    <SvgImage image={require('images/Footprints/Electricity.svg')} {...props} />
  )) as typeof SvgImage,
  furniture: memo((props) => (
    <SvgImage image={require('images/Footprints/Furniture.svg')} {...props} />
  )) as typeof SvgImage,
  gas: memo((props) => (
    <SvgImage image={require('images/Footprints/Gas.svg')} {...props} />
  )) as typeof SvgImage,
  home_improvement: memo((props) => (
    <SvgImage
      image={require('images/Footprints/HomeImprovement.svg')}
      {...props}
    />
  )) as typeof SvgImage,
  oil: memo((props) => (
    <SvgImage image={require('images/Footprints/Oil.svg')} {...props} />
  )) as typeof SvgImage,
  pet: memo((props) => (
    <SvgImage image={require('images/Footprints/Pet.svg')} {...props} />
  )) as typeof SvgImage,
  waste: memo((props) => (
    <SvgImage image={require('images/Footprints/Waste.svg')} {...props} />
  )) as typeof SvgImage,
  water: memo((props) => (
    <SvgImage image={require('images/Footprints/Water.svg')} {...props} />
  )) as typeof SvgImage,
  wood: memo((props) => (
    <SvgImage image={require('images/Footprints/Wood.svg')} {...props} />
  )) as typeof SvgImage
}

/* istanbul ignore next */
const ContentContainer = memo(({ children }: ViewProps) => {
  const { styles: layoutStyles } = useLayoutStyles()

  return (
    <>
      {Platform.OS === 'web' ? (
        <main
          style={
            StyleSheet.flatten([
              layoutStyles.content
            ]) as unknown as React.CSSProperties
          }
          id="Content"
          tabIndex={-1}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {children}
        </main>
      ) : (
        <View style={layoutStyles.content}>{children}</View>
      )}
    </>
  )
})

interface ItemsProps {
  person?: Person
}

const Items = memo(({ person }: ItemsProps) => {
  useLang()
  useFootprintLang()
  useErrorLang()

  const { t } = useTranslation()
  const { styles } = useStyles()

  const _Items = useCallback(
    () => (
      <>
        {Object.keys(HouseholdFootprintIcons)
          .filter(
            (key) =>
              typeof person?.metaHouseholdFootprintKgCo2e?.[key] !==
                'undefined' && person.metaHouseholdFootprintKgCo2e[key]! > 0
          )
          .sort(
            /* istanbul ignore next */
            (key1, key2) => {
              const _value1 = person!.metaHouseholdFootprintKgCo2e[key1]!
              const _value2 = person!.metaHouseholdFootprintKgCo2e[key2]!

              if (_value1 > _value2) return -1

              if (_value1 < _value2) return 1

              if (key1 > key2) return 1

              if (key1 < key2) return -1

              return 0
            }
          )
          .map((key, index) => {
            const Icon =
              HouseholdFootprintIcons[
                key as keyof typeof HouseholdFootprintIcons
              ]
            const _isFirst = index === 0
            const _isOdd = !!((index + 1) % 2)
            const _householdValue = person?.metaHouseholdFootprintKgCo2e?.[key]
            /* istanbul ignore next */
            const _personValue =
              _householdValue! / (person?.householdMemberCount || 1)

            return (
              <View
                key={key}
                style={[
                  styles.row,
                  _isFirst ? styles.row_first : undefined,
                  _isOdd ? styles.row_odd : undefined
                ]}
                testID={`HouseholdFootprintItem-${key}`}>
                <Icon style={styles.rowIcon} />
                <Text style={styles.rowLabel}>{t(`footprint:${key}`)}</Text>
                <Text style={styles.rowValue}>
                  <Text style={styles.rowValueNumber}>
                    {t('format:number', {
                      value: _personValue,
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  kg
                </Text>
                <Text style={styles.rowValue}>
                  <Text style={styles.rowValueNumber}>
                    {t('format:number', {
                      value: _householdValue,
                      maximumFractionDigits: 0
                    })}
                  </Text>
                  kg
                </Text>
              </View>
            )
          })}
      </>
    ),
    [
      person,
      styles.row,
      styles.rowIcon,
      styles.rowLabel,
      styles.rowValue,
      styles.rowValueNumber,
      styles.row_first,
      styles.row_odd,
      t
    ]
  )

  return <_Items />
})

export const HouseholdFootprintGroup = memo(
  ({
    controller = new HouseholdFootprintGroupController(),
    topBarLogoLinkUrl
  }: Props) => {
    useLang()
    useFootprintLang()
    useErrorLang()

    const {
      isLoading,
      getHouseholdCount,
      saveHousehold,
      person,
      invalidateHouseholdData
    } = controller.useController()
    const { t } = useTranslation()
    const { spacing, bp } = STYLES.useStyles()
    const { styles: layoutStyles } = useLayoutStyles()
    const { styles, imageWidth } = useStyles()
    const navigationContext = useNavigationContext()
    const [_value, _setValue] = useState<number>(0)
    const _descriptionId = useMemo(() => GenerateId(), [])
    const _offsetY = useRef(new Animated.Value(1)).current
    const _doOnce = useRef(false)
    const [_isUpdating, _setIsUpdating] = useState(false)
    const [_message, _setMessage] = useState<string | undefined>()
    const { footprintPageLinkUrl } = useContext(LinkContext)

    const _imageWidth = imageWidth
    const _imageHeight = _imageWidth * HouseholdHeightRatio

    const _hasBackButton = !!footprintPageLinkUrl
    const _hasMessage = !!_message

    /* istanbul ignore next */
    const _onBackButtonPress = () => {
      if (!_hasBackButton) return
      navigationContext.navigate(footprintPageLinkUrl)
    }

    const _onSaveButtonPress = async () => {
      _setIsUpdating(true)

      const _success = await saveHousehold(_value)

      if (_success) {
        _setMessage(t('householdFootprintGroup:success'))
      } else {
        ShowMessage({
          text: t('error:default'),
          isCloseable: true
        })
      }

      await invalidateHouseholdData()

      _setIsUpdating(false)
    }

    /* istanbul ignore next */
    const _onChange: NumberInputProps['onChange'] = (value) => {
      _setValue(value)

      if (typeof _message !== 'undefined') {
        _setMessage(undefined)
      }
    }

    const _SpeechBubble = useCallback(
      (props: ViewProps) => (
        <SpeechBubble
          categoryLabel={t('householdFootprintGroup:speechBubbleCategory')}
          text={t('householdFootprintGroup:speechBubbleText')}
          {...props}
        />
      ),
      [t]
    )

    const _Image = useCallback(
      ({ ...args }) => <HouseholdImage {...args} />,
      []
    )

    useEffect(() => {
      if (isLoading || _doOnce.current) return

      _doOnce.current = true

      _setValue(getHouseholdCount())
    }, [getHouseholdCount, isLoading])

    if (isLoading) {
      return <ActivityIndicator />
    }

    return (
      <View style={{ flex: 1 }}>
        <KeyboardAvoidingView
          behavior={
            /* istanbul ignore next */
            Platform.OS === 'ios' ? 'padding' : undefined
          }
          style={layoutStyles.container}
          testID="HouseholdFootprintGroup">
          <TopBar
            style={layoutStyles.topBar}
            offsetY={_offsetY}
            showBackButton={_hasBackButton}
            onBackButtonPress={_onBackButtonPress}
            backButtonLabel={t('householdFootprintGroup:backButtonLabel')}
            logoLinkUrl={topBarLogoLinkUrl}
          />
          <Animated.ScrollView
            contentContainerStyle={layoutStyles.scrollContentContainer}
            keyboardShouldPersistTaps="handled"
            keyboardDismissMode="none"
            onScroll={Animated.event(
              [
                {
                  nativeEvent: {
                    contentOffset: {
                      y: _offsetY
                    }
                  }
                }
              ],
              {
                useNativeDriver
              }
            )}
            scrollEventThrottle={16}>
            <ContentContainer>
              <Column.Container>
                <Column.Left>
                  <OnboardingHeading nativeID={_descriptionId}>
                    {t('householdFootprintGroup:title')}
                  </OnboardingHeading>

                  <Column.HiddenContent hidden={bp.desktop}>
                    <_Image
                      style={{
                        marginBottom: spacing.l,
                        width: _imageWidth,
                        height: _imageHeight
                      }}
                    />
                  </Column.HiddenContent>

                  <Form>
                    <FieldSet legend={t('householdFootprintGroup:title')}>
                      <NumberInput
                        label={t('householdFootprintGroup:fieldLabel')}
                        descriptionId={_descriptionId}
                        step={1}
                        min={0}
                        max={20}
                        onChange={_onChange}
                        value={_value}
                      />
                      <Button
                        type={ButtonType.brand}
                        label={t('householdFootprintGroup:saveButtonLabel')}
                        onPress={_onSaveButtonPress}
                        containerStyle={styles.saveButton}
                      />
                    </FieldSet>
                  </Form>

                  <View style={{ marginTop: spacing.xxxl, width: '100%' }}>
                    <Heading level={2} style={styles.tableHeading}>
                      {t('householdFootprintGroup:sharedFootprints.title')}
                    </Heading>

                    {_isUpdating ? (
                      <ActivityIndicator />
                    ) : (
                      <>
                        {_hasMessage && (
                          <View style={styles.messageContainer}>
                            <Text style={styles.messageText}>{_message}</Text>
                          </View>
                        )}
                        <View style={styles.table}>
                          <View style={styles.tableHead}>
                            <Text style={styles.tableHeadLabel}>
                              {t(
                                'householdFootprintGroup:sharedFootprints.personLabel'
                              )}
                            </Text>
                            <Text style={styles.tableHeadLabel}>
                              {t(
                                'householdFootprintGroup:sharedFootprints.householdLabel'
                              )}
                            </Text>
                          </View>
                          <Items person={person} />
                        </View>
                      </>
                    )}
                  </View>
                </Column.Left>
                <Column.Right>
                  <Column.HiddenContent hidden={bp.not([bp.desktop])}>
                    <_Image
                      style={{
                        marginBottom: spacing.xxl,
                        width: _imageWidth,
                        height: _imageHeight
                      }}
                    />
                  </Column.HiddenContent>
                  <_SpeechBubble />
                </Column.Right>
              </Column.Container>
            </ContentContainer>
          </Animated.ScrollView>
        </KeyboardAvoidingView>
      </View>
    )
  }
)
