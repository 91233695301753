import React, { memo } from 'react'
import { useStyles } from './styles'
import { View, ViewProps } from 'components'
import { STYLES } from 'styles'

interface CardActionsProps extends ViewProps {
  children?: React.ReactNode
  isCentred?: boolean
}

export const CardActions = memo(
  ({ children, style, isCentred, ...props }: CardActionsProps) => {
    const { styles } = useStyles({ isCentred })
    const { spacing } = STYLES.useStyles()

    return (
      <View
        style={[styles.container, style]}
        testID="CardActions"
        pointerEvents="box-none"
        spacer={spacing.normal}
        {...props}>
        {children}
      </View>
    )
  }
)
