import {
  StyleSheet,
  ViewStyle,
  ImageStyle,
  Platform,
  TextStyle
} from 'react-native'
import { getREM, STYLES } from 'styles'

export const useStyles = () => {
  const { COLORS, boxShadows, fontRegular } = STYLES.useStyles()

  const container: ViewStyle = {
    zIndex: 1,
    flexDirection: 'row'
  }

  const container_top: ViewStyle = {
    marginBottom: getREM(-0.5)
  }

  const container_bottom: ViewStyle = {
    marginTop: getREM(-0.5)
  }

  const imageContainer: ViewStyle = {
    ...boxShadows.card.all,
    width: getREM(2),
    height: getREM(2),
    borderRadius: getREM(1),
    borderStyle: 'solid',
    borderColor: COLORS.white,
    borderWidth: getREM(0.125),
    backgroundColor: COLORS.aubergine,
    overflow: 'hidden',
    ...Platform.select({
      android: {
        lineHeight: getREM(2.125)
      },
      default: {
        lineHeight: getREM(1.75)
      }
    })
  }

  const imageContainer_pointerOver: ViewStyle = {
    ...boxShadows.card_pointerOver.all
  }

  const image: ImageStyle = {
    width: getREM(1.75),
    height: getREM(1.75),
    backgroundColor: COLORS.white
  }

  const text: TextStyle = {
    ...fontRegular,
    color: COLORS.white,
    fontSize: STYLES.fontSize.s,
    textAlign: 'center',
    ...Platform.select({
      android: {
        lineHeight: getREM(2)
      },
      default: {
        lineHeight: getREM(1.75)
      }
    })
  }

  const styles = StyleSheet.create({
    container,
    container_top,
    container_bottom,
    imageContainer,
    imageContainer_pointerOver,
    image,
    text
  })

  return { styles }
}
