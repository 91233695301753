import { useContext } from 'react'
import { STYLES } from 'styles'
import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { HeaderContext } from 'components'

export const useStyles = () => {
  const { paragraph } = STYLES.useStyles()
  const { textColor } = useContext(HeaderContext)

  const container = {
    marginBottom: paragraph.marginBottom,
    width: '100%'
  } as ViewStyle

  const text = {
    fontSize: paragraph.fontSize,
    lineHeight: paragraph.lineHeight,
    fontFamily: paragraph.fontFamily,
    color: textColor || paragraph.color
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    text
  })

  return {
    styles
  }
}
