import { Attr, Model, BelongsTo, HasOne } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Action } from './Action'
import { Theme } from './Theme'

@Model()
export class ActionTheme extends ApplicationRecord {
  static jsonapiType = 'actions_themes'

  static endpoint = '/actions/themes'

  @Attr({ persist: false }) themeId!: string

  @BelongsTo(Action) action!: Action

  @HasOne(Theme) theme!: Theme
}
