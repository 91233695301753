import { useContext } from 'react'
import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'
import { HeaderContext } from 'components'

export const useStyles = () => {
  const { textColor } = useContext(HeaderContext)
  const { colors, bp, spacing, fontBold, fontSemiBold } = STYLES.useStyles()

  const container = {
    alignItems: 'center'
  } as ViewStyle

  const shortTermTarget = {
    alignItems: 'flex-end'
  } as ViewStyle

  if (bp.not([bp.mobile_xs, bp.mobile_s])) {
    shortTermTarget.flexDirection = 'row'
  }

  const shortTermTargetItem = {
    width: getREM(8.125)
  } as ViewStyle

  const shortTermTargetItemTitle = {
    ...fontBold,
    textAlign: 'center',
    color: textColor || colors.alternative,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  const shortTermTargetItemTitleAlt = {
    color: textColor || colors.positive
  } as TextStyle

  const shortTermTargetItemText = {
    ...fontSemiBold,
    textAlign: 'center',
    color: textColor || colors.brand,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs
  } as TextStyle

  const shortTermValueTargetItemGroup = {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  } as ViewStyle

  const shortTermTargetItemIcon = {
    marginRight: spacing.xxs,
    width: getREM(1),
    height: getREM(1)
  } as ViewStyle

  const longTermTarget = {
    backgroundColor: colors.focus,
    borderRadius: getREM(1.25),
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs,
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: spacing.l,
    minHeight: getREM(2.5)
  } as ViewStyle

  const longTermTargetText = {
    ...fontBold,
    textAlign: 'center',
    color: colors.brand,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    shortTermTarget,
    shortTermTargetItem,
    shortTermTargetItemTitle,
    shortTermTargetItemTitleAlt,
    shortTermTargetItemText,
    shortTermValueTargetItemGroup,
    shortTermTargetItemIcon,
    longTermTarget,
    longTermTargetText
  })

  return { styles }
}
