// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SearchObjType = (obj: any, query: string) => string | undefined

export const SearchObject: SearchObjType = (obj, query) => {
  for (const key in obj) {
    if (key === query) {
      return obj[key]
    }

    if (typeof obj[key] === 'object') {
      return SearchObject(obj[key], query)
    }
  }

  return undefined
}
