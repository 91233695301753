import { Attr, BelongsTo, Model } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Announcement } from './Announcement'
import { Person } from './Person'

@Model()
export class PeopleAnnouncement extends ApplicationRecord {
  static jsonapiType = 'people_announcements'

  static endpoint = '/people/announcements'

  @Attr({ persist: true }) personId!: string

  @Attr({ persist: true }) announcementId!: string

  @Attr({ persist: true }) placementId!: string

  @BelongsTo(Person) person!: Person

  @BelongsTo(Announcement) announcement!: Announcement
}
