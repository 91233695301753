import React, { memo, ComponentProps, useState } from 'react'
import { useStyles } from './styles'
import { STYLES } from 'styles'
import {
  Header,
  ScreenBackgroundImage,
  Button,
  ButtonType,
  StepCarousel,
  Carousel
} from 'components'
import { Action } from 'models'
import { ButtonProps } from '../Button'
import { View, ViewProps } from 'react-native'

type CarouselType = ComponentProps<typeof Carousel>

interface CarouselButtonProps extends Partial<ButtonProps> {
  buttonColor?: string
}

const CarouselButton = memo(
  ({ buttonColor, label, icon: Icon, ...buttonProps }: CarouselButtonProps) => {
    const { styles } = useStyles()
    const { spacing } = STYLES.useStyles()
    const [_isPointerOver, _setIsPointerOver] = useState(false)

    const _hasIcon = !!Icon

    /* istanbul ignore next */
    const _onPointerEnter = () => {
      _setIsPointerOver(true)
    }
    /* istanbul ignore next */
    const _onPointerLeave = () => {
      _setIsPointerOver(false)
    }

    return (
      <Button
        label={label}
        nativeContainerStyle={styles.headerButtonNativeContainer}
        containerStyle={styles.headerButtonContainer}
        textStyle={[
          styles.headerButtonText,
          !!buttonColor && { backgroundColor: buttonColor },
          /* istanbul ignore next */
          _isPointerOver && styles.headerButtonText_pointerOver
        ]}
        type={ButtonType.blank}
        onPointerEnter={_onPointerEnter}
        onPointerLeave={_onPointerLeave}
        {...buttonProps}>
        {label}
        {_hasIcon && (
          <>
            <View style={{ width: spacing.s }} />
            <Icon style={styles.headerButtonIcon} />
          </>
        )}
      </Button>
    )
  }
)

export interface Props {
  backgroundColor1?: string
  backgroundColor2?: string
  buttonColor?: string
  textColor?: string
  title: string
  description?: string
  Image?: CarouselType['Image']
  steps: Action[]
  ItemTemplate: ComponentProps<typeof StepCarousel>['ItemTemplate']
  buttonProps?: Partial<ButtonProps>
  hasTarget?: CarouselType['hasTarget']
  emptyMessage?: CarouselType['emptyMessage']
  style?: ViewProps['style']
}

export const StepCarouselHeader = memo(
  ({
    backgroundColor1,
    backgroundColor2,
    buttonColor,
    textColor,
    title,
    description,
    Image,
    steps,
    ItemTemplate,
    buttonProps,
    hasTarget,
    emptyMessage,
    style
  }: Props) => {
    const { styles } = useStyles()
    const { colors } = STYLES.useStyles()

    const _hasButton = !!buttonProps
    const _hasBackground = !!backgroundColor1 && !!backgroundColor2

    const _textColor = textColor ?? colors.brand

    return (
      <Header
        textColor={_textColor}
        backgroundImage={
          _hasBackground ? (
            <ScreenBackgroundImage
              color1={backgroundColor1}
              color2={backgroundColor2}
            />
          ) : undefined
        }
        style={[styles.header, style]}
        contentStyle={styles.headerContent}>
        <StepCarousel
          Image={Image}
          style={styles.headerCarousel}
          items={steps}
          ItemTemplate={ItemTemplate}
          title={title}
          description={description}
          testID="StepCarouselHeader"
          hasTarget={hasTarget}
          emptyMessage={emptyMessage}
        />

        {_hasButton && (
          <CarouselButton buttonColor={buttonColor} {...buttonProps} />
        )}
      </Header>
    )
  }
)
