import { getREM } from '../getREM'

export const spacing = {
  xxs: getREM(0.25), // 4
  xs: getREM(0.5), // 8
  s: getREM(0.75), // 12
  normal: getREM(1), // 16
  m: getREM(1.5), // 24
  l: getREM(2), // 32
  xl: getREM(2.5), // 40
  xxl: getREM(3) // 48
}
