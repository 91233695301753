import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Country } from './Country'

@Model()
export class Region extends ApplicationRecord {
  static jsonapiType = 'locations_regions'

  static endpoint = '/locations/regions'

  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) kgCo2eEmissions!: number

  @BelongsTo(Country) country!: Country
}
