import { createContext } from 'react'

interface LinkContextProps {
  homePageLinkUrl: string // '/'
  footprintPageLinkUrl?: string // '/footprints'
  footprintDetailPageLinkUrl?: string // '/footprints/{{param}}/new'
  stepsPageLinkUrl?: string // '/actions'
  stepsDoneLinkUrl?: string // '/actions?state=complete'
  stepDetailPageLinkUrl?: string // '/actions/{{param}}'
  todoListPageLinkUrl?: string // '/todo/{{param}}'
  challengeLeaderboardLinkUrl?: string // '/organizations/{{param}}/leaderboard/challenge'
  leaderboardLinkUrl?: string // '/organizations/{{param}}/leaderboard'
  teamLeaderboardLinkUrl?: string // '/teams/{{param}}/leaderboard'
}

export const LinkContext = createContext<LinkContextProps>({
  homePageLinkUrl: '/'
})
