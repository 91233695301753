import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const SelectAll = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="SelectAll"
      viewBox="0 0 140 140"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.G
        fill="none"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <SVG.Path
          strokeWidth={8.749995}
          d="M30.625 61.314H61.25M30.625 83.19h17.5M30.625 105.064h17.5M56.875 135.69h-43.75a8.75 8.75 0 0 1-8.75-8.75V35.063a8.75 8.75 0 0 1 8.75-8.75H35a21.875 21.875 0 0 1 43.75 0h21.875a8.75 8.75 0 0 1 8.75 8.75V48.19"
        />
        <SVG.Path
          strokeWidth={8.749995}
          d="M56.875 21.94a2.188 2.188 0 1 1-2.188 2.187 2.188 2.188 0 0 1 2.188-2.188M65.625 100.69a35 35 0 1 0 70 0 35 35 0 1 0-70 0Z"
        />
        <SVG.Path
          strokeWidth={8.749995}
          d="m116.223 90.51-16.946 22.598a4.38 4.38 0 0 1-6.597.467l-8.75-8.75"
        />
      </SVG.G>
    </SVG.Svg>
  )
})
