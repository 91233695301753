/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import SimpleMarkdown from 'simple-markdown'
import { useStyles } from './styles'
import { Text, Platform, StyleSheet } from 'react-native'
import { useNavigationContext } from 'utils'

export default Object.assign({}, SimpleMarkdown.defaultRules.link, {
  react: (
    node: SimpleMarkdown.SingleASTNode,
    output: SimpleMarkdown.Output<React.ReactNode>,
    state: SimpleMarkdown.State
  ) => {
    const { styles } = useStyles()
    const navigationContext = useNavigationContext()
    const [isFocused, setIsFocused] = useState(false)

    /* istanbul ignore next */
    const _isAppHref = node.target.indexOf('http') === -1 ?? false
    const _url = navigationContext.transform(node.target)

    const _linkArgs: {
      target?: string
      rel?: string
      href?: string
    } = {}

    _linkArgs.href = _url

    if (!_isAppHref) {
      _linkArgs.target = '_blank'
      _linkArgs.rel = 'noreferrer'
    }

    const _onPress = () => {
      navigationContext.navigate(_url)
    }

    /* istanbul ignore next */
    const _onFocus = () => {
      setIsFocused(true)
    }

    /* istanbul ignore next */
    const _onBlur = () => {
      setIsFocused(false)
    }

    /* istanbul ignore next */
    if (Platform.OS === 'web') {
      return (
        <a
          key={state.key}
          {..._linkArgs}
          style={
            StyleSheet.flatten([
              styles.link,
              state.textStyle,
              isFocused && styles.link_focused
            ]) as unknown as React.CSSProperties
          }
          onFocus={_onFocus}
          onBlur={_onBlur}>
          {output(node.content, state)}
        </a>
      )
    }

    return (
      <Text
        style={[styles.link, state.textStyle]}
        onPress={_onPress}
        key={state.key}>
        {output(node.content, state)}
      </Text>
    )
  }
})
