import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { bp, spacing, fontBold } = STYLES.useStyles()

  const container = {
    flex: 1,
    paddingLeft: spacing.page.padding.paddingLeft,
    paddingRight: spacing.page.padding.paddingRight,
    marginTop: spacing.xxxl,
    marginBottom: spacing.xxxl,
    width: '100%'
  } as ViewStyle

  const textContainer = {
    textAlign: 'center',
    maxWidth: STYLES.grid.maxTextBlockWidth,
    alignSelf: 'center'
  } as ViewStyle

  const actions = {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    actions.flexDirection = 'column-reverse'
  }

  const actionsTextContainer = {
    width: 'auto',
    marginBottom: 0,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  } as ViewStyle

  const actionsText = {
    ...fontBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    textContainer,
    actions,
    actionsTextContainer,
    actionsText
  })

  return { styles }
}
