import { useTypeStyles } from './useTypeStyles'
import { ButtonType, ButtonProps } from '../../index'
import { ViewStyle, TextStyle, StyleSheet } from 'react-native'
import { useSharedStyles } from './useSharedStyles'

export interface Props extends ButtonProps {
  isFocused: boolean
  isPointerOver: boolean
}

export const useStyles = ({ type, size, state, isFocused, isPointerOver }: Props) => {
  const { typeStyles } = useTypeStyles()
  const { sharedStyles } = useSharedStyles()

  const _hasType = !!type
  const _hasSize = !!size
  const _isDisabled = state?.disabled || false

  const label = [_hasType && typeStyles[type].label] as TextStyle[] | ViewStyle[]
  const nativeContainer: ViewStyle[] = []

  switch (type) {
    case ButtonType.normal:
      label.push(sharedStyles.label)
      _hasSize && label.push(sharedStyles[`label_${size}`])
      nativeContainer.push(typeStyles[type].nativeContainer!)
      break
    case ButtonType.alternative:
      label.push(sharedStyles.label)
      _hasSize && label.push(sharedStyles[`label_${size}`])
      nativeContainer.push(typeStyles[type].nativeContainer!)
      break
    case ButtonType.brand:
      label.push(sharedStyles.label)
      _hasSize && label.push(sharedStyles[`label_${size}`])
      nativeContainer.push(typeStyles[type].nativeContainer!)
      break
    case ButtonType.icon:
    case ButtonType.iconOutlined:
      label.push(sharedStyles.label)
      _hasSize && label.push(typeStyles[type][size]!)
      nativeContainer.push(typeStyles[type].nativeContainer!)
      break
    case ButtonType.outlined:
      label.push(sharedStyles.label)
      _hasSize && label.push(sharedStyles[`label_${size}`])
      nativeContainer.push(typeStyles[type].nativeContainer!)
      break
    case ButtonType.link:
    case ButtonType.brandLink:
      _hasSize && label.push(typeStyles[type][size]!)
      break
    case ButtonType.placeholder:
      label.push(sharedStyles.label)
      _hasSize && label.push(sharedStyles[`label_${size}`])
      nativeContainer.push(typeStyles[type].nativeContainer!)
      break
  }

  _isDisabled && label.push(sharedStyles.disabled)

  if (isFocused) {
    label.push(sharedStyles.focused)
    if (_hasType && typeStyles[type]?.focused) {
      label.push(typeStyles[type].focused!)
    }
  }

  if (isPointerOver) {
    if (_hasType && typeStyles[type]?.pointerOver) {
      label.push(typeStyles[type].pointerOver!)
    }
  }

  const styles = StyleSheet.create({
    label: Object.assign({}, ...label),
    nativeContainer: Object.assign({}, ...nativeContainer)
  })

  return { styles }
}
