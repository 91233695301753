import {
  StyleSheet,
  TextStyle,
  ViewStyle,
  Platform,
  DimensionValue
} from 'react-native'
import { STYLES, getREM, getOpaqueColor } from 'styles'

export const useStyles = () => {
  const { bp, colors, COLORS, fontSemiBold, fontRegular, paragraph, spacing } =
    STYLES.useStyles()

  const _isBp = bp.is([bp.tablet, bp.desktop])

  let _gap = getREM(0.5)

  if (bp.tablet) {
    _gap = getREM(0.375)
  }

  if (bp.desktop) {
    _gap = getREM(0.75)
  }

  let _columnWidth: DimensionValue = '100%'
  let _2ColumnWidth: DimensionValue = '100%'

  if (bp.tablet) {
    _columnWidth = getREM(7)
    _2ColumnWidth = getREM(10)
  }

  if (bp.desktop) {
    _columnWidth = getREM(8)
    _2ColumnWidth = getREM(12)
  }

  const leaderboardWrapper: ViewStyle = {
    maxWidth: STYLES.grid.maxTextWidth,
    width: '100%',
    alignSelf: 'center'
  }

  if (!_isBp) {
    leaderboardWrapper.maxWidth = STYLES.grid.maxBoxWidth
  }

  if (Platform.OS === 'android') {
    leaderboardWrapper.paddingHorizontal = getREM(0.75)
  }

  const columnHeadings: ViewStyle = {
    backgroundColor: COLORS.paper,
    position: 'relative',
    borderTopLeftRadius: getREM(1),
    borderTopRightRadius: getREM(1),
    paddingHorizontal: _gap,
    flexDirection: 'row',
    marginBottom: getREM(-3)
  }

  if (!_isBp) {
    columnHeadings.display = 'none'
  }

  const columnHeading: TextStyle = {
    minHeight: getREM(7),
    justifyContent: 'center',
    width: _columnWidth,
    paddingHorizontal: _gap,
    paddingBottom: getREM(3)
  }

  const columnHeadingTitle: TextStyle = {
    ...fontSemiBold,
    color: colors.brand,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    textAlign: 'center',
    position: 'relative',
    zIndex: 1
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    columnHeadingTitle.fontSize = STYLES.fontSize.s
    columnHeadingTitle.lineHeight = STYLES.lineHeight.s
  }

  const columnHeadingSubTitle: TextStyle = {
    ...columnHeadingTitle,
    ...fontRegular,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs
  }

  const rows: ViewStyle = {
    flex: 1,
    backgroundColor: colors.background,
    position: 'relative',
    borderRadius: getREM(1),
    ...Platform.select({
      web: {
        boxShadow: `0 ${getREM(0.125)} ${getREM(0.5)} ${getREM(
          0.25
        )} ${getOpaqueColor(COLORS.aubergine, 0.15)}`
      },
      android: {
        elevation: 4,
        shadowColor: COLORS.aubergine
        // marginHorizontal: getREM(0.5)
      },
      ios: {
        shadowOpacity: 0.15,
        shadowRadius: getREM(0.25),
        shadowOffset: {
          width: getREM(0.125),
          height: getREM(0.5)
        },
        shadowColor: COLORS.aubergine
      }
    })
  }

  const row: ViewStyle = {
    flexDirection: 'row',
    minHeight: getREM(5),
    paddingHorizontal: _gap,
    borderBottomColor: COLORS.steam,
    borderBottomWidth: 1
  }

  if (!_isBp) {
    row.flexDirection = 'column'
    row.alignItems = 'center'
    row.paddingVertical = spacing.normal
  }

  const finalRow: ViewStyle = {
    borderBottomWidth: 0
  }

  const rowCell: ViewStyle = {
    alignItems: 'center',
    width: _columnWidth
  }

  if (_isBp) {
    rowCell.paddingHorizontal = _gap
    rowCell.justifyContent = 'center'
  }

  if (!_isBp) {
    rowCell.marginVertical = _gap
    rowCell.paddingHorizontal = _gap
    rowCell.flexDirection = 'row'
    rowCell.justifyContent = 'space-between'
  }

  const rowCellContent: ViewStyle = {
    position: 'relative',
    flexShrink: 1
  }

  const rowCellHeading: TextStyle = {}

  if (_isBp) {
    rowCellHeading.display = 'none'
  }

  const individualRowCellHeading: Record<string, TextStyle> = {
    rank: {
      display: 'none'
    }
  }

  const rowCellHeadingTitle: TextStyle = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand
  }

  const rowCellHeadingSubTitle: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    color: colors.brand
  }

  const rowCellText: TextStyle = {
    ...fontSemiBold,
    color: colors.brand,
    fontSize: paragraph.fontSize,
    lineHeight: paragraph.lineHeight,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    alignItems: 'flex-start'
  }

  if (!_isBp) {
    rowCellText.textAlign = 'center'
  }

  const rowCellSecondaryText: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand
  }

  if (!_isBp) {
    rowCellSecondaryText.textAlign = 'center'
  }

  const defaultSortColumnStyling: ViewStyle = {
    backgroundColor: colors.focus,
    borderTopLeftRadius: getREM(1),
    borderTopRightRadius: getREM(1),
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0
  }

  const defaultSortColumnCellStyling: ViewStyle = {
    ...defaultSortColumnStyling,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    opacity: 0.3
  }

  if (!_isBp) {
    defaultSortColumnCellStyling.left = (
      Platform.OS === 'web' ? `calc(-1 * ${_gap})` : -1 * _gap
    ) as DimensionValue
    defaultSortColumnCellStyling.right = (
      Platform.OS === 'web' ? `calc(-1 * ${_gap})` : -1 * _gap
    ) as DimensionValue
    defaultSortColumnCellStyling.top = (
      Platform.OS === 'web' ? `calc(-0.5 * ${_gap})` : -1 * _gap
    ) as DimensionValue
    defaultSortColumnCellStyling.bottom = (
      Platform.OS === 'web' ? `calc(-0.5 * ${_gap})` : -1 * _gap
    ) as DimensionValue
  }

  const defaultSortColumnCellStyling_highlighted: ViewStyle = {
    opacity: 1
  }

  if (_isBp) {
    defaultSortColumnCellStyling_highlighted.marginTop = getREM(-0.25)
    defaultSortColumnCellStyling_highlighted.marginBottom = getREM(-0.25)
  }

  if (!_isBp) {
    defaultSortColumnCellStyling_highlighted.left = (
      Platform.OS === 'web'
        ? `calc(-1 * ${_gap} + ${getREM(-0.5)})`
        : -1 * _gap + getREM(-0.5)
    ) as DimensionValue
    defaultSortColumnCellStyling_highlighted.right = (
      Platform.OS === 'web'
        ? `calc(-1 * ${_gap} + ${getREM(-0.5)})`
        : -1 * _gap + getREM(-0.5)
    ) as DimensionValue
  }

  const highlightedRow: ViewStyle = {
    backgroundColor: COLORS.whisper,
    position: 'absolute',
    top: getREM(-0.25),
    left: getREM(-0.5),
    right: getREM(-0.5),
    bottom: getREM(-0.25),
    borderRadius: getREM(1),
    borderColor: COLORS.steam,
    borderWidth: getREM(0.0625),
    borderStyle: 'solid',
    ...Platform.select({
      web: {
        boxShadow: `0 ${getREM(0.125)} ${getREM(0.125)} ${getOpaqueColor(
          COLORS.aubergine,
          0.2
        )}`
      },
      android: {
        elevation: 4,
        shadowColor: COLORS.aubergine
      },
      ios: {
        shadowOpacity: 0.2,
        shadowRadius: getREM(0.125),
        shadowOffset: {
          width: 0,
          height: getREM(0.125)
        },
        shadowColor: COLORS.aubergine
      }
    })
  }

  const pagination: ViewStyle = {
    marginTop: spacing.xl,
    marginBottom: spacing.xl
  }

  const leaderboardHeading: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m,
    color: colors.brand,
    marginBottom: spacing.xl,
    textAlign: 'center'
  }

  const columnHeadingTitleFirst: TextStyle = {
    textAlign: 'left'
  }

  const rank: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand
  }

  const loading: ViewStyle = {
    minHeight: getREM(10)
  }

  const individualHeadingStyle: Record<string, ViewStyle> = {}

  const individualCellStyle: Record<string, ViewStyle> = {}

  individualHeadingStyle.rank = { flex: 1 }
  individualCellStyle.rank = { width: 'auto' }

  individualHeadingStyle.name = { flex: 0 }
  individualCellStyle.name = {
    flex: 1,
    alignItems: 'flex-start',
    paddingVertical: _gap,
    width: 'auto'
  }

  individualHeadingStyle.avatar = { flex: 0 }
  individualCellStyle.avatar = { width: 'auto' }

  individualHeadingStyle.total_step_activity = { width: _2ColumnWidth }
  individualCellStyle.total_step_activity = { width: _2ColumnWidth }

  individualHeadingStyle.total_completed_steps = { width: _2ColumnWidth }
  individualCellStyle.total_completed_steps = { width: _2ColumnWidth }

  individualHeadingStyle.average_step_activity = { width: _2ColumnWidth }
  individualCellStyle.average_step_activity = { width: _2ColumnWidth }

  individualHeadingStyle.average_completed_steps = { width: _2ColumnWidth }
  individualCellStyle.average_completed_steps = { width: _2ColumnWidth }

  const emptyMessage: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    color: colors.brand,
    backgroundColor: COLORS.whisper,
    textAlign: 'center',
    padding: spacing.normal,
    marginVertical: spacing.l,
    borderRadius: getREM(1),
    width: '100%',
    maxWidth: STYLES.grid.maxBoxWidth,
    alignSelf: 'center',
    overflow: 'hidden'
  }

  const styles = StyleSheet.create({
    columnHeadings,
    columnHeading,
    columnHeadingTitle,
    columnHeadingSubTitle,
    row,
    rowCell,
    rowCellContent,
    rowCellHeading,
    individualRowCellHeading,
    rowCellHeadingTitle,
    rowCellHeadingSubTitle,
    rows,
    rowCellText,
    rowCellSecondaryText,
    leaderboardWrapper,
    defaultSortColumnStyling,
    defaultSortColumnCellStyling,
    defaultSortColumnCellStyling_highlighted,
    finalRow,
    columnHeadingTitleFirst,
    individualHeadingStyle,
    individualCellStyle,
    highlightedRow,
    pagination,
    leaderboardHeading,
    rank,
    loading,
    emptyMessage
  })

  return { styles }
}
