import React, { createContext } from 'react'
import { ApplicationRecord } from 'models/ApplicationRecord'
import { ConfigurationProps, DefaultConfiguration } from './ConfigurationProps'

export const ConfigurationContext = createContext<ConfigurationProps>(DefaultConfiguration)

export type ConfigurationProviderProps = {
  value: ConfigurationProps,
  children: React.ReactNode
}

export const ConfigurationContextProvider = ({ value, children }: ConfigurationProviderProps) => {
  // These properties must be set here at every value change in order to be up-to-date.
  // Consequentially, having multiple of these ConfigurationContextProvider will most likely lead to
  // unexpected (though still deterministic) behaviour.
  ApplicationRecord.baseUrl = value.BaseUrl
  ApplicationRecord.apiNamespace = value.ApiNamespace
  ApplicationRecord.clientApplication = value.ClientApplication

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>)
}
