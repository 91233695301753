import { ImageStyle, StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, bp, COLORS, fontRegular } = STYLES.useStyles()

  const container_crown: ViewStyle = {}

  if (bp.not([bp.tablet, bp.desktop])) {
    container_crown.marginTop = getREM(0.5)
  }

  const avatar: ImageStyle = {
    width: getREM(2),
    height: getREM(2),
    borderRadius: getREM(1.5)
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    avatar.width = getREM(3)
    avatar.height = getREM(3)
  }

  const crown: ViewStyle = {
    position: 'absolute',
    top: getREM(-1),
    left: 0,
    zIndex: 1,
    width: getREM(1.5),
    height: getREM(1.5)
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    crown.width = getREM(2)
    crown.height = getREM(2)
    crown.top = getREM(-1.25)
  }

  const defaultAvatar: ViewStyle = {
    ...fontRegular,
    ...avatar,
    backgroundColor: colors.brand,
    justifyContent: 'center',
    alignItems: 'center'
  }

  const defaultAvatarText: TextStyle = {
    color: COLORS.white,
    fontSize: STYLES.fontSize.s,
    lineHeight: getREM(2),
    textAlign: 'center'
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    defaultAvatarText.fontSize = STYLES.fontSize.l
    defaultAvatarText.lineHeight = getREM(3)
  }

  const styles = StyleSheet.create({
    container_crown,
    avatar,
    crown,
    defaultAvatar,
    defaultAvatarText
  })

  return { styles }
}
