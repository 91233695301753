import { getREM } from '../../getREM'
import { useColors } from './useColors'
import { Platform } from 'react-native'

export const useFocused = () => {
  const { COLORS } = useColors()

  let focused = {}

  if (Platform.OS === 'web') {
    focused = {
      outlineColor: COLORS.studio,
      outlineWidth: getREM(0.125),
      outlineStyle: 'solid'
    }
  }

  return { focused }
}