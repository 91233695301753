import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { ViewStyle, Animated } from 'react-native'
import { useNativeDriver } from 'utils'

export interface StyleProps {
  startAnimation: boolean
}

type StyleType = {
  [key: string]: Animated.WithAnimatedValue<ViewStyle>
}

type ConfettiStyleType = {
  translateX: Animated.Value
  translateY: Animated.Value
  _translateX: number
  _translateY: number
}

interface ConfettiProps {
  [key: string]: ConfettiStyleType
}

export const useStyles = ({ startAnimation }: StyleProps) => {
  const [styles, setStyles] = useState<StyleType | null>(null)
  const _explositionDuration = 250
  const _fadeOutDuration = 1000
  const _fadeOutDelay = 200
  const _drop = 30

  const _containerOpacity = useRef(new Animated.Value(0)).current

  const container = useMemo(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: _containerOpacity
  } as ViewStyle & {
    opacity: Animated.Value
  }), [_containerOpacity])

  const _confetti: ConfettiProps = useMemo(() => ({
    DotPink: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: -18,
      _translateX: 0
    },
    DotPink2: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: 25,
      _translateX: -11
    },
    DotYellow: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: 11,
      _translateX: -9
    },
    SwirlBlue: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: 0,
      _translateX: -20
    },
    SwirlPink: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: -14,
      _translateX: 20
    },
    SwirlPink2: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: 28,
      _translateX: 18
    },
    SwirlYellow: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: 30,
      _translateX: -5
    },
    TriangleBlue: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: -9,
      _translateX: -9
    },
    TriangleDarkGreen: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: -9,
      _translateX: 9
    },
    TriangleGreen: {
      translateX: new Animated.Value(0),
      translateY: new Animated.Value(0),
      _translateY: 7,
      _translateX: 20
    }
  }), [])

  const _explosion = Animated.parallel([
    ...Object.keys(_confetti).map((key) => {
      return Animated.timing(_confetti[key]!.translateY, {
        useNativeDriver,
        toValue: _confetti[key]!._translateY,
        duration: _explositionDuration
      })
    }),
    ...Object.keys(_confetti).map((key) => {
      return Animated.timing(_confetti[key]!.translateX, {
        useNativeDriver,
        toValue: _confetti[key]!._translateX,
        duration: _explositionDuration
      })
    })
  ])

  const _fadeOut = Animated.parallel([
    ...Object.keys(_confetti).map((key) => {
      return Animated.timing(_confetti[key]!.translateY, {
        useNativeDriver,
        toValue: _confetti[key]!._translateY + _drop,
        duration: _fadeOutDuration,
        delay: _fadeOutDelay
      })
    }),
    Animated.timing(_containerOpacity, {
      useNativeDriver,
      toValue: 0,
      duration: _fadeOutDuration,
      delay: _fadeOutDelay
    })
  ])

  const _animate = useRef(Animated.sequence([
    Animated.timing(_containerOpacity, {
      useNativeDriver,
      toValue: 1,
      duration: 1
    }),
    _explosion,
    _fadeOut
  ])).current

  const _setStyles = useCallback((isReset = false) => {
    const _confettiStyles: StyleType = {}

    for (const key in _confetti) {
      const { translateX, translateY } = _confetti[key]!

      if (isReset) {
        translateX.setValue(0)
        translateY.setValue(0)
      }

      _confettiStyles[key] = {
        position: 'absolute',
        transform: [{ translateX }, { translateY }]
      }
    }

    setStyles({
      container,
      ..._confettiStyles
    })
  }, [_confetti, container])

  const _resetAnimation = useCallback(() => {
    _animate.reset()
    _setStyles(true)
  }, [_animate, _setStyles])

  useEffect(() => {
    if (startAnimation) {
      _animate.start(_resetAnimation)
    }
  }, [startAnimation, _animate, _resetAnimation])

  useEffect(() => {
    _setStyles()
  }, [_setStyles])

  useEffect(() => {
    return () => _animate.stop()
  })

  return { styles }
}
