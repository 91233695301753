import { StyleSheet } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      maxWidth: STYLES.grid.maxInputWidth
    }
  })

  return { styles }
}
