import React, { memo, forwardRef, useContext } from 'react'
import {
  Button,
  ButtonType,
  ShowCommitmentModal,
  CommitmentModalType
} from 'components'
import { useStepLang } from 'locales'
import { useTranslation } from 'react-i18next'
import { Action, CommitmentState } from 'models'
import {
  useErrorMessage,
  useAnalytics,
  useInvalidateDataQueries,
  GetCommitment,
  GetScore,
  DestroySuppression,
  AuthContext
} from 'utils'
import { TrackingContext } from '../TrackingContext'
import { StepButtonProps } from '../index'

export const useSucceed = () => {
  const { showErrorMessage } = useErrorMessage()
  const { trackEvent, trackingEvents } = useAnalytics()
  const { invalidateData } = useInvalidateDataQueries()
  const { source } = useContext(TrackingContext)

  const saveSucceed = async (action?: Action) => {
    const _commitment = await GetCommitment(action?.id, CommitmentState.pending)

    if (!_commitment) {
      showErrorMessage()
      return
    }

    await DestroySuppression(action!.id)

    _commitment.state = CommitmentState.successful

    const _success = await _commitment.save()

    if (!_success) {
      showErrorMessage()
      return
    }

    const _score = await GetScore()

    ShowCommitmentModal({
      modalType: CommitmentModalType.complete,
      score: _score,
      action
    })

    await invalidateData()

    trackEvent({
      eventName: trackingEvents.updateCommitment,
      props: {
        'commitment state': CommitmentState.successful,
        'action param': action!.param
      }
    })

    trackEvent({
      eventName: trackingEvents.stepsUserFlow,
      props: {
        source,
        type: 'succeed',
        'action param': action!.param
      }
    })
  }

  return saveSucceed
}

export const Succeed = memo(
  forwardRef(({ action, ...props }: StepButtonProps, ref) => {
    useStepLang()

    const { t } = useTranslation()
    const { isAuthenticated } = useContext(AuthContext)
    const saveSucceed = useSucceed()

    const _onPress = async () => {
      await saveSucceed(action)
    }

    if (!action || !isAuthenticated) return null

    return (
      <Button
        ref={ref}
        onPress={_onPress}
        testID="Succeed"
        type={ButtonType.brand}
        label={t('step:buttons.succeed')}
        {...props}
      />
    )
  })
)
