import { StyleSheet, TextStyle, ViewStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const _contentWidth = STYLES.grid.maxColumnWidth

  const {
    spacing,
    fontBold,
    fontRegular,
    fontSemiBold,
    colors,
    COLORS,
    bp,
    viewports
  } = STYLES.useStyles()

  const breakpoint = bp.gt(viewports.mobile_l)

  const row = {
    flex: 1,
    flexDirection: 'column'
  } as ViewStyle

  if (breakpoint) {
    row.alignItems = 'flex-start'
    row.flexDirection = 'row'
  }

  const about = {
    flex: 1
  } as ViewStyle

  const stats = {
    flex: 1,
    justifyContent: 'flex-end'
  } as ViewStyle

  if (breakpoint) {
    stats.maxWidth = getREM(18.125)
  }

  const container = {
    paddingRight: spacing.page.padding.paddingRight,
    paddingLeft: spacing.page.padding.paddingLeft,
    marginBottom: spacing.xxxl,
    width: '100%'
  } as ViewStyle

  const content = {
    maxWidth: _contentWidth,
    alignSelf: 'center',
    width: '100%'
  } as ViewStyle

  const cta = {
    flex: 1,
    flexGrow: 1,
    backgroundColor: 'red',
    flexShrink: 0
  } as ViewStyle

  const carousels = {
    paddingBottom: spacing.xxl,
    borderBottomColor: COLORS.silver,
    borderBottomWidth: 1
  } as ViewStyle

  const header = {
    paddingBottom: spacing.xxxl,
    marginBottom: spacing.xxxl
  } as ViewStyle

  const headerContent = {
    maxWidth: Platform.OS === 'web'
      ? `calc(${_contentWidth} + ${spacing.page.padding.paddingRight} + ${spacing.page.padding.paddingLeft})`
      : _contentWidth + spacing.page.padding.paddingRight + spacing.page.padding.paddingLeft,
    alignSelf: 'center',
    width: '100%'
  } as ViewStyle

  const improvements = {
    flexDirection: 'column'
  } as ViewStyle

  const improvementsHeading = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    marginBottom: spacing.xs,
    color: COLORS.aubergine
  } as TextStyle

  const improvementItem = {
    flexDirection: 'row',
    alignItems: 'center'
  } as ViewStyle

  const improvementItemText = {
    ...fontBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand
  } as TextStyle

  const dot = {
    width: 16,
    height: 16,
    borderRadius: 16,
    marginRight: spacing.s
  } as ViewStyle

  const where = {
    flexDirection: breakpoint
      ? 'row'
      : 'column',
    alignItems: breakpoint ? 'center' : 'flex-start'
  } as ViewStyle

  const whereHeading = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    color: COLORS.aubergine
  } as TextStyle

  const tags = {
    flexDirection: 'row'
  } as ViewStyle

  const sectionContainer = {
    marginBottom: spacing.xxl
  } as ViewStyle

  const H3 = {
    marginBottom: spacing.m
  } as TextStyle

  const summaryText = {
    ...fontSemiBold,
    color: COLORS.aubergine
  } as TextStyle

  const summaryTextContainer = {
    marginBottom: breakpoint
      ? spacing.xl
      : spacing.normal
  } as ViewStyle

  const descriptionText = {
    ...fontRegular,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  } as TextStyle

  const descriptionTextContainer = {
    marginBottom: 0
  } as ViewStyle

  const mainHeading = {
    marginBottom: breakpoint
      ? spacing.xl
      : spacing.normal
  } as TextStyle

  const styles = StyleSheet.create({
    row,
    about,
    stats,
    container,
    content,
    cta,
    carousels,
    header,
    headerContent,
    improvements,
    improvementsHeading,
    improvementItem,
    improvementItemText,
    dot,
    where,
    whereHeading,
    tags,
    H3,
    sectionContainer,
    summaryText,
    summaryTextContainer,
    descriptionText,
    descriptionTextContainer,
    mainHeading
  })

  return { styles, breakpoint }
}
