import React, { useContext, useState, memo, useMemo, useCallback } from 'react'
import { ViewProps } from 'react-native'
import { PageParamList } from '../index'
import {
  SpeechBubble,
  Combobox,
  Form,
  FieldSet,
  ActivityIndicator
} from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { Globe, GlobeHeightRatio } from 'images/Globe'
import { STYLES } from 'styles'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Region } from 'models'
import { ArraySorter } from 'utils'
import { useQuery } from 'react-query'

type ComboboxProps = React.ComponentProps<typeof Combobox>

type PageProps = NativeStackScreenProps<PageParamList, 'State'>

type OptionType = {
  id: string
  label: string
}

export const _updateRegions: (data: Region[] | undefined) => OptionType[] = (
  regionData
) => {
  const _regions =
    regionData?.map((region) => ({
      id: region.id!,
      label: region.name
    })) || []

  _regions.sort((item1, item2) => ArraySorter(item1, item2, 'label', 'asc'))

  return _regions
}

export const State = memo(({ navigation, route }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const { progress, saveRegion, getRegionsByCountry } = useContext(
    OnboardingGroupContext
  )
  const { imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_selectedId, _setSelectedId] = useState<ComboboxProps['selectedId']>()
  const [_isUpdating, _setIsUpdating] = useState(false)
  const [_showError, _setShowError] = useState(false)
  const { countryId } = route.params
  const { data: _regionData, isLoading } = useQuery(
    ['onboarding', 'regions', countryId],
    () => getRegionsByCountry(countryId!),
    { enabled: !!countryId }
  )

  const _isDisabled = typeof _selectedId === 'undefined'

  const _regions = useMemo(() => {
    return _updateRegions(_regionData)
  }, [_regionData])

  const _onPress = async () => {
    _setShowError(false)

    /* istanbul ignore next */
    if (_selectedId) {
      _setIsUpdating(true)

      const _success = await saveRegion(_selectedId)

      _setIsUpdating(false)

      if (_success) {
        navigation.navigate('Car')
        return
      }
    }

    _setShowError(true)
  }

  const _onSelect: ComboboxProps['onSelect'] = (id) => _setSelectedId(id)

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.didYouKnow')}
        text={t('onboardingState:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageHeight = imageWidth * GlobeHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <Globe
          style={{
            width: imageWidth,
            height: _imageHeight,
            marginTop: 0.07692307692307693 * _imageHeight
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, imageWidth]
  )

  if (_isUpdating || isLoading) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="State" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingState:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.State) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingState:title')}</Heading>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <FieldSet legend={t('onboardingState:title')}>
              <Combobox
                label={t('onboardingState:fieldLabel')}
                items={_regions}
                onSelect={_onSelect}
                selectedId={_selectedId}
                noResultsMessage={t('onboardingState:fieldNoResultsMessage')}
                hideLabel
              />
            </FieldSet>
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton
            onPress={_onPress}
            state={{
              disabled: _isDisabled
            }}
          />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
