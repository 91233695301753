import { getREM } from '../../getREM'
import { useBreakpoints } from './useBreakpoints'
import { baseSize } from '../../baseSize'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useSpacing = () => {
  const { bp } = useBreakpoints()
  const insets = useSafeAreaInsets()

  const _insetLeftRem = insets.left / baseSize
  const _insetRightRem = insets.right / baseSize

  const _insetTopRem = insets.top / baseSize
  const _insetBottomRem = insets.bottom / baseSize

  const page = {
    padding: {
      paddingTop: getREM(1.5 + _insetTopRem),
      paddingBottom: getREM(1.5 + _insetBottomRem),
      paddingRight: getREM(0.5 + _insetRightRem),
      paddingLeft: getREM(0.5 + _insetLeftRem)
    }
  }

  if (
    bp.is([
      bp.mobile_s,
      bp.mobile_normal,
      bp.mobile_m,
      bp.mobile_l,
      bp.mobile_xl
    ])
  ) {
    page.padding.paddingRight = getREM(1 + _insetRightRem)
    page.padding.paddingLeft = getREM(1 + _insetLeftRem)
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    page.padding.paddingTop = getREM(3 + _insetTopRem)
    page.padding.paddingBottom = getREM(3 + _insetBottomRem)

    page.padding.paddingRight = getREM(2.5 + _insetRightRem)
    page.padding.paddingLeft = getREM(2.5 + _insetLeftRem)
  }

  const spacing = {
    page,
    xxs: getREM(0.25), // 4
    xs: getREM(0.5), // 8
    s: getREM(0.75), // 12
    normal: getREM(1), // 16
    m: getREM(1.125), // 18
    l: getREM(1.5), // 24
    xl: getREM(2), // 32
    xxl: getREM(2.5), // 40
    xxxl: getREM(3), // 48
    xxxxl: getREM(3.5) // 56
  }

  return { spacing }
}