import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, fontSemiBold, COLORS } = STYLES.useStyles()

  const badge: ViewStyle = {
    paddingHorizontal: getREM(0.5),
    paddingVertical: getREM(0.25),
    borderRadius: getREM(0.625),
    borderWidth: getREM(0.0625),
    borderColor: COLORS.steam,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: getREM(1.75)
  }

  const badgeText: TextStyle = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  }

  const positiveBadge: ViewStyle = {
    backgroundColor: colors.positiveBackground,
    borderColor: colors.navBorder
  }

  const negativeBadge: ViewStyle = {
    backgroundColor: colors.negativeBackground,
    borderColor: colors.navBorder
  }

  const badgeTextPositive: TextStyle = {
    color: colors.positive
  }

  const badgeTextNegative: TextStyle = {
    color: colors.negative
  }

  const badgeIconWrapperPositive: TextStyle = {
    color: colors.positive
  }

  const badgeIconWrapperNegative: TextStyle = {
    color: colors.negative
  }

  const badgeIcon: TextStyle = {
    marginLeft: getREM(0.5)
  }

  const badgeIconReversed: TextStyle = {
    transform: [{ rotate: '180deg' }]
  }

  const styles = StyleSheet.create({
    badge,
    badgeText,
    positiveBadge,
    badgeTextPositive,
    badgeIconWrapperPositive,
    badgeIconWrapperNegative,
    badgeIcon,
    badgeTextNegative,
    negativeBadge,
    badgeIconReversed
  })

  return { styles }
}
