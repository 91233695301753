import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class Impact extends ApplicationRecord {
  static jsonapiType = 'impacts'

  @Attr({ persist: false }) lookupSlug!: string
  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) description!: string
  @Attr({ persist: false }) rating!: number
  @Attr({ persist: false }) colorBackground!: string
  @Attr({ persist: false }) colorForeground!: string
}
