import React, { memo } from 'react'
import { SVG } from 'components'
import { getREM, STYLES } from 'styles'
import { IconProps } from '../index'

export const ThumbUpOutline = memo(
  ({ size = getREM(1.5), color, style }: IconProps) => {
    const { colors } = STYLES.useStyles()

    return (
      <SVG.Svg
        testID="ThumbUpOutline"
        viewBox="0 0 60 60"
        style={[
          {
            width: size,
            height: size
          },
          style
        ]}>
        <SVG.G transform="matrix(2.5,0,0,2.5,0,0)">
          <SVG.Path
            d="M.75,21.75V11.25h1.5a7.5,7.5,0,0,0,7.5-7.5V3a2.25,2.25,0,0,1,4.5,0V6.75a1.5,1.5,0,0,0,1.5,1.5h3A4.5,4.5,0,0,1,23.227,13.2l-.465,4.649a6,6,0,0,1-5.97,5.4h-6.2a6.006,6.006,0,0,1-1.648-.231L5.308,21.981A6.006,6.006,0,0,0,3.66,21.75H.75"
            fill="none"
            stroke={color ?? colors.text}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"></SVG.Path>
        </SVG.G>
      </SVG.Svg>
    )
  }
)
