import React from 'react'
import { Text, ViewProps, ImageBackground } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, View, CategoryPills, SvgImage } from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export interface ListItemProps {
  id: string
  primaryText: string
  secondaryText?: string
  tertiaryText?: string
  avatar?: string | typeof SvgImage
  tags?: React.ComponentProps<typeof CategoryPills>['categoryPills']
  actionLabel?: string
  actionOnPress?: () => void
  onSelect?: (id: string) => void
}

export interface Props extends ViewProps {
  items: ListItemProps[]
  selectedItems?: string[]
  emptyMessage?: string
}

export const List = ({ items, selectedItems = [], emptyMessage, style, ...props }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()

  const _hasItems = !!items && items.length > 0
  const _hasEmptyMessage = !!emptyMessage

  if (!_hasItems) {
    if (_hasEmptyMessage) {
      return (
        <View style={[styles.messageContainer, style]} testID="List" {...props}>
          <Text style={styles.message}>{emptyMessage}</Text>
        </View>
      )
    } else {
      return null
    }
  }

  return (
    <View style={[styles.container, style]} testID="List" {...props}>
      {_hasItems && items.map((item, index) => {
        const _isSelected = selectedItems.includes(item.id)
        const _isFirst = index === 0
        const _isLast = index === items.length - 1

        const _hasImage = !!item.avatar
        const _hasTags = !!item.tags && item.tags.length > 0
        const _hasAction = !!item.actionLabel && !!item.actionOnPress

        return (
          <React.Fragment key={`listItem-${item.id}`}>
            {index > 0 && <View style={styles.divider} />}
            <View
              style={[
                styles.listItem,
                _isSelected && styles.listItem_selected,
                _isFirst && styles.listItem_first,
                _isLast && styles.listItem_last
              ]}>
              {!!item.onSelect && (
                <Button
                  type={ButtonType.blank}
                  onPress={() => item.onSelect?.(item.id)}
                  hideLabel
                  label={t('list:toggleButtonLabel', {
                    name: item.primaryText
                  })}
                  state={{ selected: _isSelected, checked: _isSelected }}
                  accessibilityRole="switch"
                  textStyle={[
                    styles.toggleButton,
                    _isFirst && styles.toggleButton_first,
                    _isLast && styles.toggleButton_last
                  ]}
                  containerStyle={styles.toggleButtonContainer}
                />
              )}
              {_hasImage && (
                <>
                  <ImageBackground
                    source={
                      typeof item.avatar === 'string'
                        ? { uri: item.avatar }
                        : {}
                    }
                    style={styles.imageContainer}
                    imageStyle={styles.image}
                    testID="ListItemImage">
                    {typeof item.avatar === typeof SvgImage && (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      <item.avatar
                        style={styles.image}
                        testID="ListItemImageSvg"
                      />
                    )}
                  </ImageBackground>
                  <View style={styles.spacer} />
                </>
              )}
              <View style={styles.textContainer}>
                <Text style={styles.primaryText}>{item.primaryText}</Text>
                {!!item.secondaryText && (
                  <Text style={styles.secondaryText}>{item.secondaryText}</Text>
                )}
              </View>
              {!!item.tertiaryText && (
                <>
                  <View style={styles.spacer} />
                  <Text style={styles.tertiaryText}>{item.tertiaryText}</Text>
                </>
              )}
              {_hasTags && (
                <>
                  <View style={styles.spacer} />
                  <CategoryPills
                    categoryPills={item.tags}
                    style={styles.tagsContainer}
                  />
                </>
              )}
              {_hasAction && (
                <>
                  <View style={styles.spacer} />
                  <Button
                    ariaLabel={`${item.actionLabel} ${item.primaryText}`}
                    label={item.actionLabel}
                    onPress={item.actionOnPress}
                    type={ButtonType.link}
                    textStyle={styles.action}
                    containerStyle={styles.actionContainer}
                  />
                </>
              )}
            </View>
          </React.Fragment>
        )
      })}
    </View>
  )
}
