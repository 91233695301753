import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class GasEstimate extends ApplicationRecord {
  static jsonapiType = 'gas_estimates'

  static endpoint = '/gas/estimates'

  @Attr({ persist: false }) houseType!: string
  @Attr({ persist: false }) minBedrooms!: number
  @Attr({ persist: false }) maxBedrooms!: number
}
