import React, { memo } from 'react'
import { View as NativeView, ViewProps as NativeViewProps } from 'react-native'

export interface ViewProps extends NativeViewProps {
  spacer?: number
}

const Component = React.forwardRef(
  (
    { style, spacer, children, ...props }: ViewProps,
    ref: React.ForwardedRef<NativeView>
  ) => {
    return (
      <NativeView
        style={[
          style,
          !!spacer && {
            gap: spacer
          }
        ]}
        {...props}
        ref={ref}>
        {children}
      </NativeView>
    )
  }
)

export const View = memo(Component)
