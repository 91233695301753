import { StyleSheet, ViewStyle } from 'react-native'

export const useStyles = () => {
  const container = {
    flexDirection: 'row'
  } as ViewStyle

  const styles = StyleSheet.create({
    container
  })

  return { styles }
}