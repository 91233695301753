import React, { ComponentProps, useState, memo, useContext } from 'react'
import { useStyles } from './styles'
import { Text, Platform } from 'react-native'
import {
  Button,
  ButtonSize,
  ButtonType,
  Icons,
  Select,
  SelectType,
  View,
  ShowMessage,
  MessageType,
  FullScreenDialog,
  SvgImage
} from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { STYLES } from 'styles'
import { useAnalytics, Storage, AuthContext } from 'utils'
import { User, LeaderboardTypes } from 'models'
import { useQuery } from 'react-query'

const EmailDownload: typeof SvgImage = memo((props) => (
  <SvgImage image={require('images/EmailDownload.svg')} {...props} />
))

type SelectProps = ComponentProps<typeof Select>

interface DataWranglingProps {
  selectedId?: string
  onSelect: SelectProps['onSelect']
  items?: LeaderboardTypes.LeaderboardFilterItem[]
}

export interface LeaderboardOptionsProps {
  sort?: DataWranglingProps
  filter?: DataWranglingProps
  downloadUrl?: string
}

export const LeaderboardOptions = memo(
  ({ sort, filter, downloadUrl }: LeaderboardOptionsProps) => {
    useLang()

    const { styles } = useStyles()
    const { t } = useTranslation()
    const { spacing } = STYLES.useStyles()
    const { trackEvent, trackingEvents } = useAnalytics()
    const [_showDownloadModal, _setShowDownloadModal] = useState(false)
    const { isAuthenticated } = useContext(AuthContext)

    const _hasSort = !!sort?.items && sort.items.length > 0

    const _hasFilter = !!filter?.items && filter.items.length > 0

    const _hasDownload = Platform.OS === 'web' && !!downloadUrl

    const _hasOptions = _hasSort || _hasFilter || _hasDownload

    const { data: user } = useQuery(
      ['data', 'user', 'email'],
      async () => {
        return (await User.select({ users: ['email'] }).find('current')).data
      },
      {
        enabled: _hasDownload && isAuthenticated
      }
    )

    const _closeDownloadModal = () => {
      _setShowDownloadModal(false)
    }

    const _downloadOnPress = async () => {
      trackEvent({
        eventName: trackingEvents.leaderboardDownload
      })

      const _showError = () => {
        ShowMessage({
          text: t('leaderboardOptions:download.error'),
          type: MessageType.alert,
          isCloseable: true
        })
      }

      try {
        const token =
          (await Storage.getItem('token')) ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          document.querySelector('[name=csrf-token][content]')?.content ||
          null

        if (!token) throw 'no token'

        const _response = await fetch(downloadUrl!, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            authenticity_token: token
          })
        })

        if (_response?.status === 202) {
          _setShowDownloadModal(true)
        } else {
          _showError()
        }
      } catch (e) {
        _showError()
      }
    }

    if (!_hasOptions) {
      return null
    }

    return (
      <>
        <View
          style={styles.container}
          spacer={spacing.l}
          testID="LeaderboardOptions">
          {(_hasSort || _hasFilter) && (
            <View style={styles.options} spacer={spacing.l}>
              {_hasSort && (
                <View style={styles.dropdown}>
                  <Text style={styles.dropdownSelectText}>
                    {t('leaderboardOptions:sortBy')}
                  </Text>
                  <Select
                    items={sort.items as SelectProps['items']}
                    selectedId={sort.selectedId}
                    onSelect={sort.onSelect}
                    style={styles.dropdownSelect}
                    type={SelectType.outline}
                    label={t('leaderboardOptions:sortBy')}
                    hideLabel
                  />
                </View>
              )}

              {_hasFilter && (
                <View style={styles.dropdown}>
                  <Text style={styles.dropdownSelectText}>
                    {t('leaderboardOptions:filterBy')}
                  </Text>
                  <Select
                    items={filter.items as SelectProps['items']}
                    selectedId={filter.selectedId}
                    onSelect={filter.onSelect}
                    style={styles.dropdownSelect}
                    type={SelectType.outline}
                    label={t('leaderboardOptions:filterBy')}
                    hideLabel
                  />
                </View>
              )}
            </View>
          )}

          {_hasDownload && (
            <View style={styles.download}>
              <Text style={styles.downloadLabel}>
                {t('leaderboardOptions:download.label')}
              </Text>
              <Button
                type={ButtonType.iconOutlined}
                icon={Icons.Download}
                size={ButtonSize.large}
                iconStyle={{ width: 25 }}
                onPress={_downloadOnPress}
                label={t('leaderboardOptions:download.ariaLabel')}
                hideLabel
                testID="LeaderboardOptionsDownload"
              />
            </View>
          )}
        </View>
        <FullScreenDialog
          isHidden={!_showDownloadModal}
          closeCallback={_closeDownloadModal}
          title={t('leaderboardOptions:download.modal.title')}
          text={t('leaderboardOptions:download.modal.text', {
            email: user?.email
          })}
          primaryActionLabel={t(
            'leaderboardOptions:download.modal.buttonLabel'
          )}
          primaryActionCallback={_closeDownloadModal}
          Image={EmailDownload}
          cardStyle={styles.downloadModalCard}
        />
      </>
    )
  }
)
