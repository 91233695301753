import { Impact } from 'models'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

export const useImpactFilters = () => {
  const { i18n } = useTranslation()

  const _getImpactFilters: () => Promise<Impact[]> = async () => {
    return (await Impact
      .order('rating')
      .select(['name', 'lookupSlug'])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()
    ).data
  }

  const { data: impactFilters } = useQuery(['steps', 'filters', 'impact', i18n.language], _getImpactFilters)

  return { impactFilters }
}