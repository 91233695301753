import React, { memo, useMemo } from 'react'
import { ViewProps } from 'react-native'
import { useStyles } from './styles'
import { Heading, View, Text } from 'components'
import { STYLES } from 'styles'
import { GenerateId, WebAccessibilityRole } from 'utils'

export interface BulletListProps extends ViewProps {
  items: string[]
  title?: string
  titleLevel?: React.ComponentProps<typeof Heading>['level']
  bulletColor?: string
}

export const BulletList = memo(
  ({
    items,
    title,
    titleLevel = 3,
    bulletColor,
    ...props
  }: BulletListProps) => {
    const { styles } = useStyles()
    const { spacing } = STYLES.useStyles()
    const _id = useMemo(() => GenerateId(), [])

    if (!items || items.length === 0) {
      return null
    }

    return (
      <View testID="BulletList" {...props}>
        {title && (
          <Heading level={titleLevel} style={styles.heading}>
            {title}
          </Heading>
        )}
        <View
          spacer={spacing.m}
          accessibilityRole={WebAccessibilityRole('list')}>
          {items.map((item, index) => (
            <View
              style={[styles.item]}
              key={`BenefitsList-${_id}-${index}`}
              accessibilityRole={WebAccessibilityRole('listitem')}>
              <View
                style={[
                  styles.itemBullet,
                  !!bulletColor && { backgroundColor: bulletColor }
                ]}
              />
              <Text
                containerStyle={styles.itemTextContainer}
                textStyle={[styles.itemText]}>
                {item}
              </Text>
            </View>
          ))}
        </View>
      </View>
    )
  }
)
