import React, { useState, useEffect } from 'react'
import {
  ViewProps,
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
  AccessibilityRole
} from 'react-native'
import { IconLabel } from './IconLabel'
import { ButtonProps, ButtonType, ButtonSize } from '../index'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { useNavigationContext } from 'utils'

export const Label = React.forwardRef(
  (
    {
      children,
      href,
      state,
      size = ButtonSize.normal,
      type = ButtonType.normal,
      textStyle,
      nativeContainerStyle,
      iconColor,
      iconStyle,
      iconContainerStyle,
      icon,
      onPress,
      onPressIn,
      onPressOut,
      isFake = false,
      label,
      ariaLabel,
      hideLabel = false,
      accessibilityRole = 'button',
      ariaHaspopup,
      ariaControls,
      description,
      descriptionId,
      onPointerEnter,
      onPointerLeave
    }: ButtonProps,
    ref
  ) => {
    useLang()
    const { t } = useTranslation()

    const [isFocused, setIsFocused] = useState(false)
    const [isPointerOver, setIsPointerOver] = useState(false)

    const { styles } = useStyles({
      state,
      size,
      type,
      isFocused,
      isPointerOver
    })

    const navigationContext = useNavigationContext()

    const _isAppHref = href?.indexOf('http') === -1 ? true : false

    const _url = href ? navigationContext.transform(href) : ''

    /* istanbul ignore next */
    const _onFocus = () => {
      setIsFocused(true)
    }

    /* istanbul ignore next */
    const _onBlur = () => {
      setIsFocused(false)
    }

    /* istanbul ignore next */
    const _onPointerEnter = () => {
      setIsPointerOver(true)
      onPointerEnter?.()
    }

    /* istanbul ignore next */
    const _onPointerLeave = () => {
      setIsPointerOver(false)
      onPointerLeave?.()
    }

    const _onPress = async () => {
      if (onPress) {
        onPress()
      } else {
        navigationContext.navigate(_url)
      }
    }

    const _onClick = async (event: React.MouseEvent) => {
      event?.preventDefault()
      onPress?.()
    }

    const _hasIcon =
      !!icon &&
      type &&
      [ButtonType.icon, ButtonType.iconOutlined, ButtonType.blank].includes(
        type
      )

    const _isLink =
      type === ButtonType.link ||
      type === ButtonType.brandLink ||
      (Platform.OS === 'web' && _url !== '')

    const _hint =
      _url !== '' && !_isAppHref ? ` (${t('buttonLabel:hint')})` : ''

    /* istanbul ignore next */
    const _accessibilityLabel = (ariaLabel || label || '') + _hint

    let Wrapper = (props: ViewProps) =>
      Platform.OS === 'web' ? (
        <>{props.children}</>
      ) : (
        <View style={[styles.nativeContainer, nativeContainerStyle]}>
          {props.children}
        </View>
      )

    useEffect(() => {
      if (state?.disabled) {
        setIsFocused(false)
      }
    }, [state])

    const _extraAttrs: {
      accessibilityRole?: AccessibilityRole
      accessibilityLabel?: string
      accessibilityHint?: string
      accessibilityState?: ButtonProps['state']
      onPress?: () => Promise<void>
    } = {}

    if (!isFake && Platform.OS !== 'web') {
      if (_isLink) {
        _extraAttrs.accessibilityRole = 'link'
        _extraAttrs.accessibilityLabel = _accessibilityLabel
        _extraAttrs.accessibilityHint = description
        _extraAttrs.accessibilityState = state
        _extraAttrs.onPress = _onPress
      } else {
        Wrapper = (props) => (
          <TouchableOpacity
            ref={ref as React.RefObject<TouchableOpacity>}
            accessibilityRole={accessibilityRole as AccessibilityRole}
            accessibilityLabel={_accessibilityLabel}
            accessibilityHint={description}
            accessibilityState={state}
            onPress={_onPress}
            onPressIn={onPressIn}
            onPressOut={onPressOut}
            disabled={state?.disabled}
            style={[styles.nativeContainer, nativeContainerStyle]}
            activeOpacity={0.6}>
            {props.children}
          </TouchableOpacity>
        )
      }
    }

    if (!isFake && Platform.OS === 'web') {
      let WebTag: keyof JSX.IntrinsicElements = 'button'

      const _linkArgs: {
        target?: string
        rel?: string
        href?: string
      } = {}

      if (_isLink) {
        WebTag = 'a'

        _linkArgs.href = _url

        if (!_isAppHref) {
          _linkArgs.target = '_blank'
          _linkArgs.rel = 'noreferrer'
        }
      }

      return (
        <WebTag
          ref={
            ref as React.Ref<HTMLAnchorElement> & React.Ref<HTMLButtonElement>
          }
          style={
            StyleSheet.flatten([
              styles.label,
              textStyle
            ]) as unknown as React.CSSProperties
          }
          aria-label={_accessibilityLabel}
          aria-expanded={state?.expanded}
          aria-selected={state?.selected}
          aria-disabled={state?.disabled}
          aria-busy={state?.busy}
          aria-haspopup={ariaHaspopup}
          aria-controls={ariaControls}
          aria-describedby={descriptionId}
          role={accessibilityRole}
          onClick={onPress ? _onClick : undefined}
          disabled={state?.disabled}
          onFocus={_onFocus}
          onBlur={_onBlur}
          onPointerEnter={_onPointerEnter}
          onPointerLeave={_onPointerLeave}
          {..._linkArgs}>
          {_hasIcon && (
            <IconLabel
              icon={icon}
              size={size}
              type={type}
              iconColor={iconColor}
              iconStyle={iconStyle}
              iconContainerStyle={iconContainerStyle}
            />
          )}
          {!hideLabel && <>{children || label}</>}
        </WebTag>
      )
    }

    if (type === 'icon') {
      return (
        <Wrapper>
          <View style={[styles.label, textStyle]} {..._extraAttrs}>
            {_hasIcon && (
              <IconLabel
                icon={icon}
                size={size}
                type={type}
                iconColor={iconColor}
                iconStyle={iconStyle}
                iconContainerStyle={iconContainerStyle}
              />
            )}
            {!hideLabel && <>{children || label}</>}
          </View>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <Text
          textBreakStrategy="simple"
          style={[styles.label, textStyle]}
          {..._extraAttrs}>
          {_hasIcon && (
            <IconLabel
              icon={icon}
              size={size}
              type={type}
              iconColor={iconColor}
              iconStyle={iconStyle}
              iconContainerStyle={iconContainerStyle}
            />
          )}
          {!hideLabel && <>{children || label}</>}
        </Text>
      </Wrapper>
    )
  }
)