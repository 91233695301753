import React, { memo } from 'react'
import { useStyles } from './styles'
import { View, ViewProps } from 'components'
import { STYLES } from 'styles'

export interface ColumnProps extends ViewProps {
  hidden?: boolean
}

export const Container = memo(({ style, children, ...props }: ViewProps) => {
  const { styles } = useStyles()

  return (
    <View style={[styles.container, style]} testID="ColumnContainer" {...props}>
      {children}
    </View>
  )
})

export const Left = memo(({ hidden = false, style, children, ...props }: ColumnProps) => {
  const { styles } = useStyles()

  if (hidden) return null

  return (
    <View style={[styles.left, style]} testID="ColumnLeft" {...props}>
      {children}
    </View>
  )
})

export const Right = memo(({ hidden = false, style, children, ...props }: ColumnProps) => {
  const { styles } = useStyles()
  const { bp } = STYLES.useStyles()

  const _hasSpacer = !!props.spacer && bp.not([bp.desktop])

  if (hidden) return null

  return (
    <View style={[styles.right, style, _hasSpacer && { marginTop: props.spacer }]} testID="ColumnRight" {...props}>
      {children}
    </View>
  )
})

export const HiddenContent = memo(({ hidden = false, children }: ColumnProps) => {
  if (hidden) return null

  return (
    <>
      {children}
    </>
  )
})

export const Column = {
  Container,
  Left,
  Right,
  HiddenContent
}
