import React, { memo, useRef, useState, useCallback, useEffect } from 'react'
import { ButtonType, Button, View, Icons, SVG } from 'components'
import {
  ViewProps,
  View as NativeView,
  Text,
  Modal,
  Pressable,
  Dimensions,
  Platform,
  ViewStyle
} from 'react-native'
import { useStyles } from './styles'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { STYLES } from 'styles'

export interface RoundedNavigationProps extends ViewProps {
  items: RoundedNavigationItemProps[]
}

export interface RoundedNavigationItemProps {
  active?: boolean
  label: string
  href: string
}

export const RoundedNavigation = memo(
  ({ items, style, ...props }: RoundedNavigationProps) => {
    useLang()
    const { t } = useTranslation()
    const { styles, buttonHeight } = useStyles()
    const { COLORS } = STYLES.useStyles()
    const _itemsRef = useRef<Array<NativeView | null>>([])
    const [_visibleItems, _setVisibleItems] = useState<number[]>([])
    const [_isMoreMenuOpen, _setIsMoreMenuOpen] = useState(false)
    const [_showMoreButton, _setShowMoreButton] = useState(true)
    const [_isCheckingItems, _setIsCheckingItems] = useState(true)
    const [_moreMenuPosition, _setMoreMenuPosition] = useState<{
      top: number
      left: number
      maxWidth: string | number
    }>()
    const _containerRef = useRef<NativeView>(null)
    const _containerWidth = useRef(0)
    const _moreButtonRef = useRef<HTMLButtonElement>(null)
    const [_isPointerOver, _setIsPointerOver] = useState<string | null>(null)

    /* istanbul ignore next */
    const _checkVisibleItems = useCallback(() => {
      if (items.length === 0 || !_itemsRef.current) return

      const _tempVisibleItems: number[] = []
      const _checkedItems: number[] = []

      if (_itemsRef.current.length > 0 && _containerRef.current) {
        _setIsCheckingItems(true)

        for (let i = 0; i < _itemsRef.current.length; i++) {
          _itemsRef.current[i]?.measureLayout(
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            _containerRef.current,
            (_, y) => {
              _checkedItems.push(i)

              if (y < buttonHeight) {
                _tempVisibleItems.push(i)
              }

              if (_checkedItems.length === _itemsRef.current.length) {
                _setVisibleItems(_tempVisibleItems)
                _setShowMoreButton(
                  _tempVisibleItems.length !== _itemsRef.current.length
                )
                _setIsCheckingItems(false)
              }
            },
            () => {
              /* empty */
            }
          )
        }
      }
    }, [buttonHeight, items])

    /* istanbul ignore next */
    const _onLayout: ViewProps['onLayout'] = ({
      nativeEvent: {
        layout: { width }
      }
    }) => {
      if (_containerWidth.current !== width) {
        _containerWidth.current = width
        _checkVisibleItems()
      }
    }

    /* istanbul ignore next */
    const _getMeasurements = () => {
      _containerRef.current?.measure((_x, _y, width, height, px, py) => {
        if (py && height && px) {
          _setMoreMenuPosition({
            top: py + height,
            left: px,
            maxWidth: width
          })
        }
      })
    }

    /* istanbul ignore next */
    const _onPressMoreButton = () => {
      if (!_isMoreMenuOpen) {
        _getMeasurements()
      }

      _setIsMoreMenuOpen((prevState) => !prevState)
    }

    /* istanbul ignore next */
    const _hideMenu = () => {
      _setIsMoreMenuOpen(false)
      _moreButtonRef.current?.focus()
    }

    /* istanbul ignore next */
    useEffect(() => {
      const _dimensionEvent = Dimensions.addEventListener('change', _hideMenu)

      if (Platform.OS === 'web') {
        window.addEventListener('wheel', _hideMenu)
      }

      return () => {
        _dimensionEvent?.remove()

        if (Platform.OS === 'web') {
          window.removeEventListener('wheel', _hideMenu)
        }
      }
    }, [])

    if (!items || items.length === 0) return null

    return (
      <>
        <View style={styles.container} onLayout={_onLayout}>
          <View
            style={[
              styles.roundedNavigation,
              /* istanbul ignore next */
              (_isCheckingItems || _showMoreButton) &&
                styles.roundedNavigation_withmore,
              _isCheckingItems && {
                opacity: 0
              },
              style
            ]}
            testID="RoundedNavigation"
            ref={_containerRef}
            {...props}>
            {items.map((item: RoundedNavigationItemProps, index: number) => {
              const _key = `RoundedNavigationItem-${index}`
              const _isVisible = _visibleItems.includes(index)

              /* istanbul ignore next */
              if (!_isVisible && !_isCheckingItems) return null

              return (
                <Button
                  key={_key}
                  type={ButtonType.blank}
                  containerStyle={[
                    styles.buttonStyleContainer,
                    index !== 0 && styles.buttonStyleContainer_marginLeft
                  ]}
                  textStyle={[
                    styles.buttonStyle,
                    /* istanbul ignore next */
                    _isPointerOver === _key && styles.buttonStyle_pointerOver,
                    item.active && styles.buttonStyle_active
                  ]}
                  containerRef={(itemRef) =>
                    (_itemsRef.current[index] = itemRef)
                  }
                  href={item.href}
                  onPointerEnter={
                    /* istanbul ignore next */
                    () => _setIsPointerOver(_key)
                  }
                  onPointerLeave={
                    /* istanbul ignore next */
                    () => _setIsPointerOver(null)
                  }
                  ariaLabel={item.label}>
                  <Text numberOfLines={1} style={styles.buttonText}>
                    {item.label}
                  </Text>
                </Button>
              )
            })}
            {_showMoreButton && (
              <Button
                containerStyle={[
                  styles.buttonStyleContainer,
                  styles.buttonStyleContainer_moreButton
                ]}
                textStyle={[
                  styles.buttonStyle,
                  styles.buttonStyle_moreButton,
                  /* istanbul ignore next */
                  _isPointerOver === 'moreButton' &&
                    styles.buttonStyle_pointerOver,
                  /* istanbul ignore next */
                  _isMoreMenuOpen && styles.buttonStyle_active
                ]}
                ariaLabel={t('roundedNavigation:moreButtonLabel')}
                onPress={_onPressMoreButton}
                icon={Icons.MoreDots}
                iconContainerStyle={styles.iconContainer}
                iconStyle={styles.icon}
                type={ButtonType.blank}
                ref={_moreButtonRef}
                onPointerEnter={
                  /* istanbul ignore next */
                  () => _setIsPointerOver('moreButton')
                }
                onPointerLeave={
                  /* istanbul ignore next */
                  () => _setIsPointerOver(null)
                }
              />
            )}
          </View>
        </View>
        <Modal
          onRequestClose={_hideMenu}
          animationType="fade"
          transparent={true}
          visible={
            /* istanbul ignore next */
            !_isCheckingItems && _isMoreMenuOpen
          }>
          <Pressable style={styles.modalPressable} onPress={_hideMenu}>
            <View
              style={[
                styles.roundedNavigationDropdownContainer,
                {
                  ...(_moreMenuPosition as unknown as ViewStyle)
                }
              ]}>
              <View style={styles.roundedNavigationDropdown}>
                <SVG.Svg
                  viewBox="0 0 24 12"
                  style={styles.roundedNavigationDropdownArrow}>
                  <SVG.Polygon
                    fill={COLORS.whisper}
                    points="12 0, 0 12, 24 12, 12 0"
                  />
                </SVG.Svg>
                {items
                  .filter((_, index) => !_visibleItems.includes(index))
                  .map((item: RoundedNavigationItemProps, index: number) => {
                    const _key = `RoundedNavigationMoreItem-${index}`
                    return (
                      <Button
                        key={_key}
                        testID="RoundedNavigationMoreItem"
                        type={ButtonType.blank}
                        containerStyle={[
                          styles.buttonStyleContainer,
                          index !== 0 && styles.buttonStyleContainer_marginTop
                        ]}
                        textStyle={[
                          styles.buttonStyle,
                          styles.buttonStyle_moreMenu,
                          /* istanbul ignore next */
                          _isPointerOver === _key &&
                            styles.buttonStyle_pointerOver,
                          item.active && styles.buttonStyle_active
                        ]}
                        href={item.href}
                        onPointerEnter={
                          /* istanbul ignore next */
                          () => _setIsPointerOver(_key)
                        }
                        onPointerLeave={
                          /* istanbul ignore next */
                          () => _setIsPointerOver(null)
                        }
                        ariaLabel={item.label}>
                        <Text numberOfLines={1} style={styles.buttonText}>
                          {item.label}
                        </Text>
                      </Button>
                    )
                  })}
              </View>
            </View>
          </Pressable>
        </Modal>
      </>
    )
  }
)
