import React, { memo } from 'react'
import { useStyles } from './styles'
import { View, Icons } from 'components'
import { getREM, STYLES } from 'styles'
import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'

interface LeaderboardBadgeProps {
  value: number
  type?: string
}

export const LeaderboardBadge = memo(({ value, type }: LeaderboardBadgeProps) => {
  const { styles } = useStyles()
  const isPositiveValue = value > 0
  const { colors } = STYLES.useStyles()
  const { t } = useTranslation()

  return (
    <View
      testID="LeaderboardBadge"
      style={[
        styles.badge,
        isPositiveValue ? styles.positiveBadge : styles.negativeBadge
      ]}>
      <Text
        style={[
          styles.badgeText,
          isPositiveValue ? styles.badgeTextPositive : styles.badgeTextNegative
        ]}>
        {t('format:number', { value })}
      </Text>

      {type && type === 'trying' ? (
        isPositiveValue ? (
          <View
            style={styles.badgeIconWrapperPositive}
            testID="LeaderboardBadgeTryingPositive">
            <Icons.ThumbUpOutline
              style={styles.badgeIcon}
              size={getREM(1)}
              color={colors.positive}
            />
          </View>
        ) : (
          <View
            style={styles.badgeIconWrapperNegative}
            testID="LeaderboardBadgeTryingNegative">
            <Icons.ThumbUpOutline
              style={[styles.badgeIcon, styles.badgeIconReversed]}
              size={getREM(1)}
              color={colors.negative}
            />
          </View>
        )
      ) : isPositiveValue ? (
        <View
          style={styles.badgeIconWrapperPositive}
          testID="LeaderboardBadgeCompletedPositive">
          <Icons.TickRosetteOutline
            style={styles.badgeIcon}
            size={getREM(1)}
            color={colors.positive}
          />
        </View>
      ) : (
        <View
          style={styles.badgeIconWrapperNegative}
          testID="LeaderboardBadgeCompletedNegative">
          <Icons.TickRosetteOutline
            style={styles.badgeIcon}
            size={getREM(1)}
            color={colors.negative}
          />
        </View>
      )}
    </View>
  )
})
