import React, { memo } from 'react'
import { View, ViewProps, ImageProps } from 'react-native'
import { useStyles } from './styles'
import { SvgImage } from 'components'

interface Props extends ViewProps {
  backgroundStyle?: ImageProps['style']
}

export const IllustrationBackground = memo(({ style, backgroundStyle, children, ...props }: Props) => {
  const { styles } = useStyles()

  return (
    <View style={[styles.container, style]} testID="IllustrationBackground" {...props}>
      <SvgImage
        image={require('./Background.svg')}
        style={[styles.background, backgroundStyle]}
        testID="Background"
      />
      <View style={styles.children}>{children}</View>
    </View>
  )
})

export const IllustrationBackgroundHeightRatio = 548.22 / 675.02
