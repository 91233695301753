import { getREM, STYLES } from 'styles'
import { Platform, StyleSheet, ViewStyle } from 'react-native'

export const useStyles = () => {
  const { spacing } = STYLES.useStyles()

  const row = {
    flexDirection: 'row',
    width: 'auto',
    flexWrap: 'wrap',
    marginHorizontal: Platform.OS === 'web'
      ? `calc(-1 * ${spacing.xxs})`
      : -1 * spacing.xxs
  } as ViewStyle
  
  const button = {
    width: 'auto',
    height: 'auto',
    margin: spacing.xxs
  } as ViewStyle

  const icon = {
    height: getREM(2.7),
    width: getREM(2.7),
    padding: spacing.s,
    borderRadius: 999
  } as ViewStyle

  const styles = StyleSheet.create({
    row,
    button,
    icon
  })
  return { styles }
}
