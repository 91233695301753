import { StyleSheet, TextStyle, ViewStyle, Platform, PixelRatio } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'
import { useWindowDimensions } from 'utils'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { SelectType, containerPositionProps } from './index'
import { useSharedStyles } from 'components/Button/Label/styles/useSharedStyles'

interface Props {
  showList?: boolean
  containerPosition: containerPositionProps
  type: SelectType
}

export const useStyles = ({ showList, containerPosition, type }: Props) => {
  const { input, bp, spacing, COLORS, fontBold, boxShadows, colors } = STYLES.useStyles()
  const { width } = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const { sharedStyles } = useSharedStyles()

  const listMargin = 0.75 * PixelRatio.getFontScale() * baseSize
  const listVisibleItems = 5

  const container = {
    maxWidth: input.maxWidth,
    alignSelf: 'center'
  } as ViewStyle

  const buttonNativeContainer = {
    ...boxShadows.button.ios,
    width: '100%',
    maxWidth: input.maxWidth
  } as ViewStyle

  const buttonContainer = {
    alignItems: 'center',
    width: '100%',
    maxWidth: input.maxWidth
  } as ViewStyle

  const buttonText = {
    ...input,
    textDecorationLine: 'none',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...boxShadows.button.web,
    ...boxShadows.button.android
  } as TextStyle

  if (type === SelectType.outline) {
    buttonText.height = 'auto'
    buttonText.paddingTop = sharedStyles.label_normal.paddingTop
    buttonText.paddingBottom = sharedStyles.label_normal.paddingBottom
    buttonText.paddingLeft = sharedStyles.label_normal.paddingLeft
    buttonText.paddingRight = sharedStyles.label_normal.paddingRight
    buttonText.fontSize = sharedStyles.label_normal.fontSize
    buttonText.lineHeight = sharedStyles.label_normal.lineHeight
    buttonText.borderRadius = sharedStyles.label_normal.borderRadius
    buttonText.backgroundColor = colors.brandContrast
    buttonText.borderColor = colors.brand
    buttonText.borderWidth = getREM(0.125)
  }

  const buttonInnerText = {
    color: input.color,
    fontFamily: input.fontFamily,
    fontSize: input.fontSize,
    lineHeight: input.lineHeight,
    paddingRight: spacing.s,
    flexGrow: 1
  } as TextStyle

  if (type === SelectType.outline) {
    buttonInnerText.fontSize = sharedStyles.label_normal.fontSize
    buttonInnerText.lineHeight = sharedStyles.label_normal.lineHeight
  }

  const iconContainer = {
    width: getREM(0.75),
    height: getREM(0.43)
  } as ViewStyle

  if (bp.desktop) {
    iconContainer.width = getREM(1)
    iconContainer.height = getREM(0.57)
  }

  const icon = {} as ViewStyle

  if (showList) {
    icon.transform = [
      {
        rotateX: '180deg'
      }
    ]
  }

  const listContainer = {
    ...spacing.page.padding,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    ...Platform.select({
      web: {
        cursor: 'default'
      }
    })
  } as ViewStyle

  const list = {
    width: Platform.OS === 'web' ? `calc(100% - ${spacing.page.padding.paddingLeft} - ${spacing.page.padding.paddingRight})` : width - spacing.page.padding.paddingLeft - spacing.page.padding.paddingRight,
    backgroundColor: COLORS.paper,
    borderRadius: getREM(1.25),
    ...boxShadows.button.all
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop]) && containerPosition.top && containerPosition.left) {
    list.position = 'absolute'
    list.top = containerPosition.top - insets.top
    list.left = containerPosition.left
    list.marginTop = listMargin
  } else {
    list.marginBottom = listMargin + insets.bottom
  }

  if (containerPosition.width) {
    list.maxWidth = containerPosition.width
  } else {
    list.maxWidth = input.maxWidth
  }

  const listItemText = {
    ...fontBold,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    paddingTop: spacing.s,
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    textAlign: 'left',
    color: COLORS.aubergine,
    marginTop: spacing.xxs,
    marginLeft: spacing.xxs,
    marginRight: spacing.xxs,
    overflow: 'hidden',
    borderRadius: getREM(1.25),
    ...Platform.select({
      web: {
        transition: 'background-color 0.2s ease-out'
      }
    })
  } as TextStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    listItemText.paddingLeft = spacing.s
    listItemText.paddingRight = spacing.s
  }

  if (bp.desktop) {
    listItemText.paddingTop = spacing.normal
    listItemText.paddingBottom = spacing.normal
    listItemText.paddingLeft = spacing.l
    listItemText.paddingRight = spacing.l
  }

  let listItemHeight = 0

  if (Platform.OS === 'web') {
    listItemHeight = ((parseFloat(STYLES.fontSize.normal.toString()) * (parseFloat(STYLES.lineHeight.normal.toString()) / 100)) + parseFloat(listItemText.paddingTop!.toString()) + parseFloat(listItemText.paddingBottom!.toString()) + parseFloat(listItemText.marginTop!.toString())) * baseSize
  } else {
    listItemHeight = parseFloat(STYLES.lineHeight.normal.toString()) + parseFloat(listItemText.paddingTop!.toString()) + parseFloat(listItemText.paddingBottom!.toString()) + parseFloat(listItemText.marginTop!.toString())
  }

  list.maxHeight = listItemHeight * listVisibleItems

  const listItemText_last = {
    marginBottom: spacing.xxs
  } as ViewStyle

  const listItemText_selected = {
    backgroundColor: COLORS.mischka
  } as ViewStyle

  const listItemText_pointerOver = {
    backgroundColor: COLORS.snuff
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    buttonNativeContainer,
    buttonContainer,
    buttonText,
    buttonInnerText,
    iconContainer,
    icon,
    listContainer,
    list,
    listItemText,
    listItemText_last,
    listItemText_selected,
    listItemText_pointerOver
  })

  return { styles, listVisibleItems, listItemHeight, listMargin }
}
