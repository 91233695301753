import React, { memo } from 'react'
import { View, Text } from 'react-native'
import { FOOTPRINT_SECTIONS, FootprintSectionsType } from '../index'
import { useTranslation } from 'react-i18next'
import { useLang as useFootprintSectionsLang } from '../Lang'
import { useLang } from './Lang'
import { useStyles } from './styles'
import { SvgImage } from 'components'

export interface Props {
  item: FootprintSectionsType
  completePercentage: number
}

export const ProgressCard = memo(({ item, completePercentage }: Props) => {
  useFootprintSectionsLang()
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <SvgImage
          image={FOOTPRINT_SECTIONS[item]}
          testID={`ProgressCardImage_${item}`}
          style={styles.image}
        />
      </View>
      <View style={styles.content}>
        <Text textBreakStrategy="simple" style={styles.title}>{`${t(
          `footprintSections:sections.${item}`
        )} ${t('progressCard:footprint')}`}</Text>
        <View style={styles.progressBarContainer}>
          <View
            style={[styles.progressBar, { width: `${completePercentage}%` }]}
            testID="ProgressCardBar"
          />
        </View>
        <Text textBreakStrategy="simple" style={styles.label}>
          {completePercentage}% {t('progressCard:completed')}
        </Text>
      </View>
    </View>
  )
})
