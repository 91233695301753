import { Country, Region } from 'models'
import { DefaultProps } from '../index'
import { useTranslation } from 'react-i18next'

export type UseLocationType = {
  getCountries: () => Promise<Country[] | undefined>
  getRegion: (regionId: string) => Promise<Region | null>
  getRegionsByCountryCount: (countryId: string) => Promise<{ count: number, data: Region[] | undefined }>
  getRegionsByCountry: (countryId: string) => Promise<Region[] | undefined>
  saveRegion: (regionId: string | null) => Promise<boolean | undefined>
}

export const useLocation = ({ person }: DefaultProps) => {
  const { i18n } = useTranslation()

  const getCountries: UseLocationType['getCountries'] = async () => {
    return (await Country
      .select(['name'])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()
    ).data
  }

  const getRegion: UseLocationType['getRegion'] = async (id) => {
    return (await Region
      .where({ id })
      .includes('country')
      .select({ regions: [''], countries: [''] })
      .extraParams({ locale: i18n.language })
      .first()
    ).data
  }

  const getRegionsByCountryCount: UseLocationType['getRegionsByCountryCount'] = async (locations_country_id) => {
    const { meta, data } = await Region
      .where({ locations_country_id })
      .per(1)
      .stats({ total: 'count' })
      .select([''])
      .extraParams({ locale: i18n.language })
      .all()
    return { count: meta?.stats?.total?.count || 0, data }
  }

  const getRegionsByCountry: UseLocationType['getRegionsByCountry'] = async (locations_country_id) => {
    return (await Region
      .where({ locations_country_id })
      .select(['name'])
      .extraParams({ paginate: false, locale: i18n.language })
      .all()
    ).data
  }

  const saveRegion: UseLocationType['saveRegion'] = async (id) => {
    if (!person) return false

    person.locationsRegionId = id

    return await person.save()
  }

  return {
    getCountries,
    getRegion,
    getRegionsByCountryCount,
    getRegionsByCountry,
    saveRegion
  }
}