import { STYLES } from 'styles'
import { StyleSheet, TextStyle, ViewStyle } from 'react-native'

export const useStyles = () => {
  const { input, spacing, fontRegular, fontSemiBold, COLORS } = STYLES.useStyles()

  const container = {
    width: '100%'
  } as ViewStyle

  const containerText = {
    width: '100%',
    borderRadius: input.borderRadius
  } as TextStyle

  const content = {
    ...input,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '100%'
  } as TextStyle

  const linkText = {
    ...fontRegular,
    color: COLORS.cherry,
    marginRight: spacing.l,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  const fakeButton = {
    ...fontSemiBold,
    color: COLORS.cherry,
    textTransform: 'uppercase',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    containerText,
    content,
    linkText,
    fakeButton
  })
  return { styles }
}
