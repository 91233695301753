export interface ConfigurationProps {
  ApiNamespace: string
  BaseUrl: string
  ClientApplication: string
}

export const DefaultConfiguration = {
  ApiNamespace: "/api/v2",
  BaseUrl: "http://localhost:3000",
  ClientApplication: "clarity"
}
