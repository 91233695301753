import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing } = STYLES.useStyles()

  const card = {
    maxWidth: STYLES.grid.maxBoxWidth
  } as ViewStyle

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      alignItems: 'center'
    },
    card,
    actions: {
      marginTop: spacing.m
    }
  })

  return { styles }
}
