import { useBox } from './useBox'
import { useColors } from './useColors'
import { useParagraphStyles } from './useParagraphStyles'
import { useBreakpoints } from './useBreakpoints'
import { useFocused } from './useFocused'
import { useSpacing } from './useSpacing'
import { useInput } from './useInput'
import { useDisabled } from './useDisabled'
import { useSizing } from './useSizing'
import { useFont } from './useFont'
import { useBoxShadows } from './useBoxShadows'

export const useStyles = () => {
  const { box } = useBox()
  const { colors, COLORS, improvementColors } = useColors()
  const { paragraph } = useParagraphStyles()
  const { bp, viewports } = useBreakpoints()
  const { focused } = useFocused()
  const { spacing } = useSpacing()
  const { input } = useInput()
  const { disabled } = useDisabled()
  const { sizing } = useSizing()
  const fonts = useFont()
  const { boxShadows, boxShadowsRaw } = useBoxShadows()

  return {
    ...fonts,
    box,
    colors,
    COLORS,
    improvementColors,
    paragraph,
    bp,
    viewports,
    focused,
    spacing,
    input,
    disabled,
    sizing,
    boxShadows,
    boxShadowsRaw
  }
}
