import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Reset = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Reset"
      viewBox="0 0 140 140"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.G
        fill="none"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <SVG.Path
          strokeWidth={8.749995}
          d="M78.75 128.333a56.875 56.875 0 1 0-56.875-56.875v4.375"
        />
        <SVG.Path strokeWidth={8.749995} d="m4.375 58.316 17.5 17.5 17.5-17.5" />
      </SVG.G>
    </SVG.Svg>
  )
})
