import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'
import { useSharedStyles } from 'components/Button/Label/styles/useSharedStyles'

export const useStyles = () => {
  const { spacing, COLORS, boxShadows } = STYLES.useStyles()
  const { sharedStyles } = useSharedStyles()

  const header = {
    paddingBottom: getREM(1.5)
  } as ViewStyle

  const headerContent = {
    paddingLeft: 0,
    paddingRight: 0
  } as ViewStyle

  const headerCarousel = {
    marginBottom: spacing.xl
  } as ViewStyle

  const headerButtonContainer = {
    alignSelf: 'center'
  } as ViewStyle

  const headerButtonNativeContainer = {
    ...boxShadows.button.ios
  } as ViewStyle

  const headerButtonText = {
    ...sharedStyles.label,
    ...sharedStyles.label_normal,
    display: 'flex',
    backgroundColor: COLORS.cherry,
    color: COLORS.white,
    width: 'auto',
    flexDirection: 'row',
    borderColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    textDecorationLine: 'none',
    ...Platform.select({
      web: {
        border: 0,
        cursor: 'pointer'
      }
    }),
    ...boxShadows.button.web,
    ...boxShadows.button.android
  } as TextStyle

  const headerButtonText_pointerOver = {
    ...boxShadows.button_pointerOver.web
  } as TextStyle

  const headerButtonIcon = {
    width: getREM(1),
    height: getREM(1),
    ...Platform.select({
      ios: {
        transform: [
          { translateX: getREM(1.5) },
          { translateY: getREM(0.4) }
        ]
      },
      android: {
        transform: [
          { translateY: getREM(0.1) }
        ]
      }
    })
  } as ViewStyle

  const styles = StyleSheet.create({
    header,
    headerContent,
    headerCarousel,
    headerButtonContainer,
    headerButtonNativeContainer,
    headerButtonText,
    headerButtonText_pointerOver,
    headerButtonIcon
  })

  return { styles }
}
