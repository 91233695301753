import React from 'react'
import { Text, View } from 'react-native'
import SimpleMarkdown from 'simple-markdown'
import { useStyles } from './styles'
import { WebAccessibilityRole } from 'utils'

export default Object.assign({}, SimpleMarkdown.defaultRules.list, {
  react: (
    node: SimpleMarkdown.SingleASTNode,
    output: SimpleMarkdown.Output<React.ReactNode>,
    state: SimpleMarkdown.State
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { styles } = useStyles()

    return (
      <View
        accessibilityRole={WebAccessibilityRole('list')}
        key={state.key}
        style={[styles.list, state.containerStyle]}>
        {node.items.map((item: SimpleMarkdown.ASTNode, i: number) => {
          return (
            <View
              key={`${state.key}__${i}`}
              accessibilityRole={WebAccessibilityRole('listitem')}
              style={styles.listItem}>
              {node.ordered && (
                <Text textBreakStrategy="simple" style={styles.number}>
                  {`${i + 1})`}
                </Text>
              )}
              {!node.ordered && (
                <Text textBreakStrategy="simple" style={styles.bullet}>
                  {'\u2022'}
                </Text>
              )}
              <Text textBreakStrategy="simple" style={styles.text}>
                {output(item, state)}
              </Text>
            </View>
          )
        })}
      </View>
    )
  }
})
