/* istanbul ignore file */
import { useRef } from 'react'
import { Animated, StyleSheet } from 'react-native'
import { useStyles } from './styles'
import { useNativeDriver } from 'utils'

export const useCloudWhiteAnimation = () => {
  const opacity = useRef(new Animated.Value(0)).current

  const animation = Animated.timing(opacity, {
    useNativeDriver,
    toValue: 1,
    duration: 750
  })

  const styles = {
    opacity
  }

  return {
    styles,
    animation
  }
}

export const useCloudPurpleAnimation = () => {
  const scale = useRef(new Animated.Value(0)).current

  const animation = Animated.timing(scale, {
    useNativeDriver,
    toValue: 1,
    duration: 1000
  })

  const styles = {
    transform: [{ scale }]
  }

  return {
    styles,
    animation
  }
}

export const useLeafSmall1Animation = (isFixedSize = false) => {
  const { styles: componentStyles } = useStyles(isFixedSize)

  const duration = 1000

  const opacity = useRef(new Animated.Value(0)).current

  const _rotate = useRef(new Animated.Value(0)).current

  const rotate = _rotate.interpolate({
    inputRange: [0, 1],
    outputRange: ['45deg', '0deg']
  })

  const _height = parseInt(
    (StyleSheet.flatten(componentStyles.leafSmall1).height ?? 0).toString()
  )

  const translate = useRef(new Animated.Value(_height)).current

  const animation = Animated.parallel([
    Animated.timing(opacity, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(_rotate, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(translate, {
      useNativeDriver,
      toValue: 0,
      duration
    })
  ])

  const styles = {
    opacity,
    transform: [
      { rotate },
      { translateX: translate },
      { translateY: translate }
    ]
  }

  return {
    styles,
    animation
  }
}

export const useLeafBig1Animation = (isFixedSize = false) => {
  const { styles: componentStyles } = useStyles(isFixedSize)

  const duration = 1000

  const opacity = useRef(new Animated.Value(0)).current

  const _rotate = useRef(new Animated.Value(0)).current

  const rotate = _rotate.interpolate({
    inputRange: [0, 1],
    outputRange: ['45deg', '0deg']
  })

  const _height = parseInt(
    (StyleSheet.flatten(componentStyles.leafBig1).height ?? 0).toString()
  )

  const translate = useRef(new Animated.Value(_height)).current

  const animation = Animated.parallel([
    Animated.timing(opacity, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(_rotate, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(translate, {
      useNativeDriver,
      toValue: 0,
      duration
    })
  ])

  const styles = {
    opacity,
    transform: [
      { rotate },
      { translateX: translate },
      { translateY: translate }
    ]
  }

  return {
    styles,
    animation
  }
}

export const useLeafSmall2Animation = (isFixedSize = false) => {
  const { styles: componentStyles } = useStyles(isFixedSize)

  const duration = 1000

  const opacity = useRef(new Animated.Value(0)).current

  const _rotate = useRef(new Animated.Value(0)).current

  const rotate = _rotate.interpolate({
    inputRange: [0, 1],
    outputRange: ['90deg', '0deg']
  })

  const _height = parseInt(
    (StyleSheet.flatten(componentStyles.leafSmall2).height ?? 0).toString()
  )

  const translate = useRef(new Animated.Value(-_height)).current

  const animation = Animated.parallel([
    Animated.timing(opacity, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(_rotate, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(translate, {
      useNativeDriver,
      toValue: 0,
      duration
    })
  ])

  const styles = {
    opacity,
    transform: [
      { rotate },
      { translateX: translate },
      { translateY: translate }
    ]
  }

  return {
    styles,
    animation
  }
}

export const useLeafBig2Animation = (isFixedSize = false) => {
  const { styles: componentStyles } = useStyles(isFixedSize)

  const duration = 1000

  const opacity = useRef(new Animated.Value(0)).current

  const _rotate = useRef(new Animated.Value(0)).current

  const rotate = _rotate.interpolate({
    inputRange: [0, 1],
    outputRange: ['90deg', '0deg']
  })

  const _height = parseInt(
    (StyleSheet.flatten(componentStyles.leafBig2).height ?? 0).toString()
  )

  const translate = useRef(new Animated.Value(-_height)).current

  const animation = Animated.parallel([
    Animated.timing(opacity, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(_rotate, {
      useNativeDriver,
      toValue: 1,
      duration
    }),
    Animated.timing(translate, {
      useNativeDriver,
      toValue: 0,
      duration
    })
  ])

  const styles = {
    opacity,
    transform: [
      { rotate },
      { translateX: translate },
      { translateY: translate }
    ]
  }

  return {
    styles,
    animation
  }
}

export const useButterflyAnimation = () => {
  const opacity = useRef(new Animated.Value(0)).current
  const translateX = useRef(new Animated.Value(-150)).current
  const translateY = useRef(new Animated.Value(-15)).current

  const animation = Animated.parallel([
    Animated.timing(opacity, {
      useNativeDriver,
      toValue: 1,
      duration: 1000
    }),
    Animated.timing(translateX, {
      useNativeDriver,
      toValue: 0,
      duration: 2000
    }),
    Animated.sequence([
      Animated.timing(translateY, {
        useNativeDriver,
        toValue: 15,
        duration: 500
      }),
      Animated.timing(translateY, {
        useNativeDriver,
        toValue: -5,
        duration: 500
      }),
      Animated.timing(translateY, {
        useNativeDriver,
        toValue: 5,
        duration: 500
      }),
      Animated.timing(translateY, {
        useNativeDriver,
        toValue: 0,
        duration: 500
      })
    ])
  ])

  const styles = {
    opacity,
    transform: [{ translateX: translateX }, { translateY: translateY }]
  }

  return {
    styles,
    animation
  }
}
