import { Model } from 'spraypaint'
import { Estimate } from './Estimate'

@Model()
export class CarEstimate extends Estimate {
  static jsonapiType = 'cars_estimates'

  static endpoint = '/cars/estimates'
}

