import { User, Person } from 'models'
import { useQuery } from 'react-query'

export type UsePersonType = {
  person: Person | undefined
  isLoading: boolean
  saveName: (firstName: string, lastName: string) => Promise<boolean | undefined>
  finalize: () => Promise<boolean | undefined>
}

export const usePerson = () => {
  const _getData = async () => {
    const { data } = await User
      .includes(['person'])
      .select({
        users: [''],
        people: [
          'firstName',
          'lastName',
          'finalized',
          'onboarded',
          'locationsRegionId',
          'kgCo2eEmissions',
          'kgCo2eForHome',
          'kgCo2eForFood',
          'kgCo2eForTransport',
          'kgCo2eForPurchases',
          'kgCo2eForServices',
          'householdMemberCount'
        ]
      })
      .find('current')

    return data
  }

  const { data: user, isFetching: isLoading } = useQuery(['onboarding', 'user'], _getData)

  const saveName: UsePersonType['saveName'] = async (firstName, lastName) => {
    if (!user?.person) return false

    user.person.firstName = firstName
    user.person.lastName = lastName

    return await user.person.save()
  }

  const finalize: UsePersonType['finalize'] = async () => {
    if (!user?.person) return false

    user.person.finalized = true

    return await user.person.save()
  }

  return { person: user?.person, isLoading, saveName, finalize }
}