import { ShowMessage, MessageType } from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export const useErrorMessage = () => {
  useLang()
  const { t } = useTranslation()

  const showErrorMessage = (text?: string) => {
    ShowMessage({
      text: text || t('useErrorMessage:message'),
      type: MessageType.alert,
      isCloseable: true
    })
  }

  return { showErrorMessage }
}
