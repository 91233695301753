import { StyleSheet, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { bp, spacing } = STYLES.useStyles()

  const title = {
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    maxWidth: STYLES.grid.maxBoxWidth,
    marginBottom: spacing.l
  } as TextStyle

  if (bp.is([bp.mobile_l, bp.mobile_xl])) {
    title.fontSize = STYLES.fontSize.l
    title.lineHeight = STYLES.lineHeight.l
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    title.fontSize = STYLES.fontSize.xl
    title.lineHeight = STYLES.lineHeight.xl
  }

  if (bp.tablet) {
    title.marginBottom = spacing.xl
  }

  if (bp.desktop) {
    title.marginBottom = spacing.xxl
  }

  if (bp.desktop) {
    title.textAlign = 'left'
  }

  const styles = StyleSheet.create({
    title
  })

  return { styles }
}
