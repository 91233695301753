import React, { memo, forwardRef } from 'react'
import {
  StyleProp,
  ViewStyle,
  TextStyle,
  AccessibilityState,
  ViewProps,
  View,
  Text
} from 'react-native'
import 'core-js/features/url'
import 'core-js/features/url-search-params'
import { Container } from './Container'
import { Label } from './Label'
import { IconType } from 'components'

export enum ButtonSize {
  normal = 'normal',
  small = 'small',
  large = 'large'
}

export enum ButtonType {
  normal = 'normal',
  brand = 'brand',
  alternative = 'alternative',
  outlined = 'outlined',
  link = 'link',
  brandLink = 'brandLink',
  icon = 'icon',
  iconOutlined = 'iconOutlined',
  blank = 'blank',
  placeholder = 'placeholder'
}

export interface ButtonProps extends ViewProps {
  label?: string
  ariaLabel?: string
  onPress?: () => void
  onPressIn?: () => void
  onPressOut?: () => void
  type?: ButtonType
  size?: ButtonSize
  fullWidth?: boolean
  containerStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  nativeContainerStyle?: StyleProp<ViewStyle>
  iconColor?: string
  iconContainerStyle?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
  isFake?: boolean
  href?: string
  icon?: IconType
  state?: AccessibilityState
  testID?: string
  hideLabel?: boolean
  containerRef?: React.Ref<View & Text>
  ariaHaspopup?: React.HTMLAttributes<HTMLElement>['aria-haspopup']
  ariaControls?: React.HTMLAttributes<HTMLElement>['aria-controls']
  description?: string
  descriptionId?: string
  onPointerEnter?: () => void
  onPointerLeave?: () => void
}

const Component = forwardRef(({
  fullWidth,
  testID,
  containerStyle,
  children,
  containerRef,
  ...attrs
}: ButtonProps, ref) => {
  return (
    <Container
      ref={containerRef}
      fullWidth={fullWidth}
      testID={testID}
      containerStyle={containerStyle}
      isFake={attrs.isFake}>
      <Label {...attrs} ref={ref}>{children}</Label>
    </Container>
  )
})

export const Button = memo(Component)