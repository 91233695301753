import React, { useContext, useState, useEffect, useCallback, memo } from 'react'
import { PageProps } from '../index'
import {
  Text,
  CarbonFootprintHero,
  HorizontalBarChart,
  ActivityIndicator
} from 'components'
import {
  Layout,
  Column,
  Heading,
  NextButton,
  useSharedStyles,
  useSharedLang,
  ProgressBar
} from '../Shared'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import { OnboardingGroupContext } from '../../index'
import { STYLES } from 'styles'

export const Summary = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const { sharedStyles } = useSharedStyles()
  const { styles } = useStyles()
  const { bp } = STYLES.useStyles()
  const { person, getRegion, finalize } = useContext(OnboardingGroupContext)
  const [_carbonFootprintHero, _setCarbonFootprintHero] = useState<JSX.Element | null>(null)
  const [_isLoading, _setIsLoading] = useState(
    /* istanbul ignore next */
    person?.finalized === false ? true : false ?? true
  )
  const [_showError, _setShowError] = useState(false)

  const _onPress = () => {
    navigation.navigate('Steps')
  }

  const _getData = useCallback(async () => {
    if (!person || _carbonFootprintHero !== null) return

    const _personKgCo2eEmissions = person.kgCo2eEmissions
    let _comparisonValue

    /* istanbul ignore next */
    if (person.locationsRegionId) {
      const _region = await getRegion(person.locationsRegionId)

      /* istanbul ignore next */
      if (_region?.kgCo2eEmissions) {
        _comparisonValue = _personKgCo2eEmissions - _region.kgCo2eEmissions
      }
    }

    _setCarbonFootprintHero(
      <CarbonFootprintHero
        title={t('onboardingSummary:carbonFootprintTitle')}
        value={_personKgCo2eEmissions}
        comparisonValue={_comparisonValue}
      />
    )
  }, [_carbonFootprintHero, getRegion, person, t])

  const _finalize = useCallback(async () => {
    /* istanbul ignore next */
    if (!person || person.finalized) return

    _setShowError(false)

    const success = await finalize()

    _setIsLoading(false)

    if (!success) {
      _setShowError(true)
    }
  }, [finalize, person])

  useEffect(() => {
    if (_isLoading) return
    void _getData()
  }, [_isLoading, _getData])

  useEffect(() => {
    void _finalize()
  }, [_finalize])

  if (_isLoading) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Summary" showBackButton showError={_showError}>
      <ProgressBar isHidden max={0} value={0} label={t('onboardingSummary:progressLabel')} />

      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingSummary:title')}</Heading>
          <Text containerStyle={[sharedStyles.textContainer, styles.text]}>{t('onboardingSummary:text')}</Text>

          <Column.HiddenContent hidden={bp.desktop}>
            {_carbonFootprintHero}
          </Column.HiddenContent>

          {person?.footprintSectionsKgCo2eEmissions && (
            <HorizontalBarChart
              style={styles.barChart}
              items={person.footprintSectionsKgCo2eEmissions.map((item) => ({
                unit: 'kg',
                label: t(`onboardingSummary:footprintCategories.${item.key}`),
                value: item.value
              }))}
            />
          )}

          <NextButton label={t('onboardingSummary:nextLabel')} onPress={_onPress} />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          {_carbonFootprintHero}
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
