import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { spacing, COLORS } = STYLES.useStyles()

  const maxWidth = STYLES.grid.maxContentWidth
  const spacerWidth = spacing.page.padding.paddingRight

  const container = {
    width: '100%'
  } as ViewStyle

  const indicatorContainer = {
    marginTop: spacing.l,
    marginBottom: spacing.l,
    paddingLeft: spacing.page.padding.paddingLeft,
    paddingRight: spacing.page.padding.paddingRight,
    alignSelf: 'center',
    width: '100%',
    maxWidth
  } as ViewStyle

  const indicatorBar = {
    backgroundColor: COLORS.whisper,
    borderRadius: getREM(0.3125),
    height: getREM(0.625)
  } as ViewStyle

  const indicator = {
    backgroundColor: COLORS.aubergine,
    borderRadius: getREM(0.3125),
    height: getREM(0.625)
  } as ViewStyle

  const spacer = {
    width: spacerWidth,
    height: 1
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    indicatorContainer,
    indicatorBar,
    indicator,
    spacer
  })

  return { styles, maxWidth, spacerWidth }
}
