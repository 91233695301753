import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES } from 'styles'
import { useStyles as HorizontalScrollViewUseStyles } from 'components/HorizontalScrollView/styles'
import { useWindowDimensions } from 'utils'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { COLORS, spacing, bp, boxShadowsRaw } = STYLES.useStyles()
  const { maxWidth, spacerWidth } = HorizontalScrollViewUseStyles()
  const { width } = useWindowDimensions()
  const insets = useSafeAreaInsets()

  const title: TextStyle = {
    textAlign: 'center'
  }

  const textContainer: TextStyle = {
    textAlign: 'center'
  }

  const horizontalScrollView: ViewStyle = {
    width: (Platform.OS === 'web' ? `calc(100% + ${spacing.page.padding.paddingLeft} + ${spacing.page.padding.paddingRight})` : width - insets.left - insets.right) as ViewStyle['width']
  }

  const horizontalScrollViewContent: ViewStyle = {
    paddingTop: boxShadowsRaw.card.radius,
    paddingBottom: boxShadowsRaw.card.radius
  }

  const horizontalScrollViewSpacer: ViewStyle = {
    width: spacerWidth
  }

  if (bp.desktop_m) {
    horizontalScrollViewSpacer.width = (Platform.OS === 'web' ? `calc((${width}px - ${maxWidth}) / 2 + ${spacerWidth})` : width - maxWidth / 2 + spacerWidth) as ViewStyle['width']
  }

  const stepCard: ViewStyle = {
    flexGrow: 1
  }

  const cardActions: ViewStyle = {
    alignItems: 'flex-start'
  }

  const linkText: TextStyle = {
    textAlign: 'center',
    fontSize: STYLES.fontSize.normal,
    color: COLORS.aubergine
  }

  const styles = StyleSheet.create({
    title,
    textContainer,
    horizontalScrollView,
    horizontalScrollViewContent,
    horizontalScrollViewSpacer,
    stepCard,
    cardActions,
    linkText
  })

  return { styles }
}
