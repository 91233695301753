import { useContext } from 'react'
import { AnalyticsContext, TrackEventProps } from './AnalyticsContext'
import { Events } from './Events'
import { Platform } from 'react-native'

export const useAnalytics = () => {
  const analyticsContext = useContext(AnalyticsContext)

  const getTrackingURL = (pathname: string) => {
    if (typeof pathname === 'undefined') return undefined

    if (Platform.OS === 'web') {
      const _url = new URL(window.location.href)
      _url.pathname = pathname
      return _url.href
    }

    return `app://localhost${pathname}`
  }

  return {
    trackEvent: (props: TrackEventProps) =>
      analyticsContext.trackEvent(props),
    trackingEvents: Events,
    getTrackingURL
  }
}
