import React, { useState, useEffect, useRef, ComponentProps, useCallback, memo, useContext } from 'react'
import {
  Header,
  ScreenBackgroundImage,
  CTAMessage,
  StepsValueHero,
  View,
  ActivityIndicator,
  StepCheckboxListItem,
  StepButtons
} from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { useStyles } from './styles'
import { STYLES } from 'styles'
import { Commitment } from 'models'
import { IToDoListGroupController, ToDoListGroupController } from './Data'
import { IController, LinkContext } from 'utils'
import { ViewProps, LayoutChangeEvent } from 'react-native'
import { useStepLang } from 'locales'

type StepCheckboxListItemProps = ComponentProps<typeof StepCheckboxListItem>

export interface Props {
  controller?: IController<IToDoListGroupController>
  navigationTarget?: string
  onNavigationTargetAvailable?: (y: number) => void
}

export const ToDoListGroup = memo(({
  controller = new ToDoListGroupController(),
  navigationTarget,
  onNavigationTargetAvailable,
}: Props) => {
  useLang()
  useStepLang()

  const { t } = useTranslation()
  const { styles } = useStyles()
  const { colors, spacing } = STYLES.useStyles()
  const [_commitments, _setCommitments] = useState<Commitment[]>([])
  const _mountedRef = useRef(true)
  const _headerY = useRef<number>()
  const _currentCommitmentY = useRef<number>()

  const { stepsPageLinkUrl } = useContext(LinkContext)

  const _navigationCallbackCalled = useRef<boolean>(false)

  const {
    isLoading,
    setCheckboxChecked,
    user: data
  } = controller.useController()

  /* istanbul ignore next */
  const _sendY = useCallback(() => {
    if (
      !_headerY.current ||
      !_currentCommitmentY.current ||
      _navigationCallbackCalled.current
    )
      return

    onNavigationTargetAvailable?.(
      _headerY.current + _currentCommitmentY.current
    )
    _navigationCallbackCalled.current = true
  }, [onNavigationTargetAvailable])

  /* istanbul ignore next */
  const _onHeaderLayout: ViewProps['onLayout'] = ({
    nativeEvent: { layout }
  }) => {
    _headerY.current = layout.y + layout.height
    _sendY()
  }

  /* istanbul ignore next */
  const _onItemLayout = useCallback(
    ({
      nativeEvent: {
        layout: { y }
      }
    }: LayoutChangeEvent) => {
      if (y) {
        _currentCommitmentY.current = y
        _sendY()
      }
    },
    [_sendY]
  )

  const _isEmpty = _commitments.length === 0

  /* istanbul ignore next */
  useEffect(() => {
    if (isLoading) return

    const _newCommitments = data?.person?.commitments || []

    if (_mountedRef.current) _setCommitments(_newCommitments)
  }, [data?.person?.commitments, isLoading])

  useEffect(() => {
    _mountedRef.current = true

    return () => {
      _mountedRef.current = false
    }
  }, [])

  if (isLoading) {
    return <ActivityIndicator />
  }

  return (
    <>
      <Header
        testID="toDoListHeader"
        onLayout={_onHeaderLayout}
        textColor={colors.brand}
        backgroundImage={
          <ScreenBackgroundImage color1="#73CC8D" color2="#53B16E" />
        }>
        <StepsValueHero
          value={data?.person?.pendingActionsCount ?? 0}
          label={t('toDoListScreen:stepsInProgress')}
        />
      </Header>

      <View style={styles.container}>
        {_isEmpty && !!stepsPageLinkUrl && (
          <CTAMessage
            heading={t('toDoListScreen:emptyMessage.heading')}
            text={t('toDoListScreen:emptyMessage.text')}
            linkLabel={t('toDoListScreen:emptyMessage.linkLabel')}
            linkUrl={stepsPageLinkUrl}
            style={styles.emptyMessage}
          />
        )}

        {!_isEmpty && (
          <StepButtons.TrackingContext.Provider
            value={{ source: 'ToDoListGroup' }}>
            <View style={styles.list} spacer={spacing.l}>
              {_commitments.map((item) => {
                const _selectedItems: StepCheckboxListItemProps['selectedItems'] =
                  data?.person?.personalChecklistItems.map(
                    (pItem) => pItem.checklistItem.id!
                  )

                const _onSelectItem: StepCheckboxListItemProps['onPress'] =
                  async (checklistItemId, checked) => {
                    await setCheckboxChecked({
                      commitmentId: item.id,
                      checklistItemId,
                      checked
                    })
                  }

                const _hasOnLayout = navigationTarget === item.id

                return (
                  <View
                    key={`toDoListItem-${item.id}`}
                    onLayout={_hasOnLayout ? _onItemLayout : undefined}
                    style={styles.messageContainer}
                    testID={`toDoListItem-${item.id}`}
                    spacer={spacing.l}>
                    <StepCheckboxListItem
                      items={
                        /* istanbul ignore next */
                        item.checklistItems?.map((checklistItem, index) => {
                          return {
                            id: checklistItem.id!,
                            label: checklistItem.description,
                            index: (index + 1).toString(),
                            showConfetti: true
                          }
                        }) || []
                      }
                      selectedItems={_selectedItems}
                      onPress={_onSelectItem}
                      action={item.action}
                    />
                  </View>
                )
              })}
            </View>
          </StepButtons.TrackingContext.Provider>
        )}
      </View>
    </>
  )
})

export {
  ToDoListGroupController
} from './Data'
