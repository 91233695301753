import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = ({ isCentred }: { isCentred?: boolean }) => {
  const { bp } = STYLES.useStyles()

  const container: ViewStyle = {
    maxWidth: STYLES.grid.maxCardWidth,
    minHeight: getREM(14)
  }

  const categoryPills: ViewStyle = {}

  if (isCentred || bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    categoryPills.justifyContent = 'center'
  }

  const styles = StyleSheet.create({
    container,
    categoryPills
  })

  return { styles }
}
