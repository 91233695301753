import React, { memo, useEffect, useState, useCallback, useContext } from 'react'
import { useStyles } from './styles'
import { PublicOrganizationSignUpController } from './PublicOrganizationSignUpController'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import {
  Button,
  ButtonType,
  View,
  ActivityIndicator,
  ProHeader,
  TextInput,
  Heading,
  Text
} from 'components'
import { STYLES } from 'styles'
import {
  useNavigationContext,
  ReplaceParam,
  AuthContext,
  LinkContext
} from 'utils'

export interface Props {
  controller?: PublicOrganizationSignUpController
  publicSlug: string
  homePageLinkUrl?: string
  leaderboardLinkUrl?: string
}

export const PublicOrganizationSignUp = memo(
  ({
    controller = new PublicOrganizationSignUpController(),
    publicSlug,
    homePageLinkUrl,
    leaderboardLinkUrl
  }: Props) => {
    useLang()

    const { styles } = useStyles()
    const { t } = useTranslation()
    const { spacing } = STYLES.useStyles()
    const { isAuthenticated, login, signup } = useContext(AuthContext)
    const {
      homePageLinkUrl: linkContext_homePageLinkUrl,
      leaderboardLinkUrl: linkContext_leaderboardLinkUrl
    } = useContext(LinkContext)
    const { isLoading, organization, user, addToOrganization } =
      controller.useController({ publicSlug, isAuthenticated })
    const [verificationAnswer, setVerificationAnswer] = useState('')
    const [_isMembershipRequested, _setIsMembershipRequested] = useState(false)
    const [_message, _setMessage] = useState<null | string>(null)
    const navigationContext = useNavigationContext()
    const [_hasMembershipRequest, _setHasMembershipRequest] = useState(false)

    const _homePageLinkUrl = homePageLinkUrl || linkContext_homePageLinkUrl
    const _leaderboardLinkUrl =
      leaderboardLinkUrl || linkContext_leaderboardLinkUrl

    const _isOrganizationMember =
      (isAuthenticated &&
        user?.person.organizationSlugs.includes(publicSlug)) ||
      false

    /* istanbul ignore next */
    const _navigateToLeaderboard = useCallback(() => {
      if (organization?.id && _leaderboardLinkUrl) {
        const _url = ReplaceParam(_leaderboardLinkUrl, organization.id)
        navigationContext.navigate(_url)
      }
    }, [organization?.id, _leaderboardLinkUrl, navigationContext])

    const _joinOnPress = useCallback(async () => {
      /* istanbul ignore next */
      if (!organization || !isAuthenticated || _isMembershipRequested) return

      /* istanbul ignore next */
      if (_isOrganizationMember) {
        _navigateToLeaderboard()
      } else if (organization.requiresVerification) {
        _setIsMembershipRequested(true)

        const _success = await addToOrganization(verificationAnswer)

        if (_success) {
          _setMessage(
            t('publicOrganizationSignUp:success', {
              organization_name: organization.name
            })
          )
        } else {
          _setMessage(
            t('publicOrganizationSignUp:fail', {
              organization_name: organization.name
            })
          )
        }

        _setHasMembershipRequest(true)
        _setIsMembershipRequested(false)
      } else {
        _setIsMembershipRequested(true)

        const _success = await addToOrganization()

        if (_success) {
          _navigateToLeaderboard()
        } else {
          _setMessage(
            t('publicOrganizationSignUp:fail', {
              organization_name: organization.name
            })
          )
          _setHasMembershipRequest(true)
        }
        _setIsMembershipRequested(false)
      }
    }, [
      organization,
      isAuthenticated,
      _isMembershipRequested,
      _isOrganizationMember,
      _navigateToLeaderboard,
      addToOrganization,
      verificationAnswer,
      t
    ])

    const _onChangeText = (value: string) => {
      setVerificationAnswer(value)
    }

    useEffect(() => {
        if (!isLoading && !organization) {
          navigationContext.navigate(_homePageLinkUrl)
        }
    }, [_homePageLinkUrl, isLoading, navigationContext, organization])

    useEffect(
      () => {
        if (
          isAuthenticated &&
          !isLoading &&
          !organization?.verificationQuestion &&
          !_isOrganizationMember
        ) {
          _joinOnPress()
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        isLoading,
        isAuthenticated,
        organization?.verificationQuestion,
        _isOrganizationMember
      ]
    )

    useEffect(() => {
      if (!isLoading && isAuthenticated && _isOrganizationMember) {
        _navigateToLeaderboard()
      }
    }, [
      isLoading,
      isAuthenticated,
      _isOrganizationMember,
      _navigateToLeaderboard
    ])

    if (
      isLoading ||
      _isMembershipRequested ||
      !organization ||
      _isOrganizationMember
    ) {
      return <ActivityIndicator />
    }

    return (
      <>
        <ProHeader
          backgroundImage={{ uri: organization?.banner }}
          logoImage={{ uri: organization?.logo }}
          logoLabel={t('publicOrganizationSignUp:logo', {
            organization_name: organization?.name
          })}
        />
        <View style={styles.container} testID="PublicOrganizationSignUp">
          <Heading level={1}>{organization?.name}</Heading>

          <Text containerStyle={styles.textContainer}>
            {organization?.publicGreeting ||
              t('publicOrganizationSignUp:greeting', {
                organization_name: organization?.name
              })}
          </Text>

          {_hasMembershipRequest && (
            <>
              {_message && (
                <Text containerStyle={styles.textContainer}>{_message}</Text>
              )}

              {!user?.person.onboarded && (
                <Text containerStyle={styles.textContainer}>
                  {t('publicOrganizationSignUp:onboarding', {
                    organization_name: organization?.name
                  })}
                </Text>
              )}

              <View style={styles.actions} spacer={spacing.s}>
                <Button
                  label={t('publicOrganizationSignUp:continue')}
                  type={ButtonType.brand}
                  href={_homePageLinkUrl}
                />
              </View>
            </>
          )}

          {!_isOrganizationMember && !_hasMembershipRequest && (
            <>
              {!isAuthenticated && (
                <>
                  <Text containerStyle={styles.textContainer}>
                    {t('publicOrganizationSignUp:login', {
                      organization_name: organization?.name
                    })}
                  </Text>
                  <View style={styles.actions} spacer={spacing.s}>
                    <Button
                      label={t('publicOrganizationSignUp:authButtons.signUp')}
                      type={ButtonType.brand}
                      onPress={signup}
                    />
                    <Text
                      containerStyle={styles.actionsTextContainer}
                      textStyle={styles.actionsText}>
                      {t('publicOrganizationSignUp:authButtons.separator')}
                    </Text>
                    <Button
                      label={t('publicOrganizationSignUp:authButtons.signIn')}
                      type={ButtonType.normal}
                      onPress={login}
                    />
                  </View>
                </>
              )}

              {isAuthenticated && organization?.verificationQuestion && (
                <>
                  <Text containerStyle={styles.textContainer}>
                    {t('publicOrganizationSignUp:question', {
                      organization_name: organization.name
                    })}
                  </Text>
                  <TextInput
                    label={organization.verificationQuestion}
                    onChangeText={_onChangeText}
                    value={verificationAnswer}
                    onSubmitEditing={_joinOnPress}
                  />
                  <View style={styles.actions} spacer={spacing.s}>
                    <Button
                      label={t('publicOrganizationSignUp:submit')}
                      type={ButtonType.brand}
                      onPress={_joinOnPress}
                      state={{
                        disabled: verificationAnswer === ''
                      }}
                    />
                  </View>
                </>
              )}
            </>
          )}
        </View>
      </>
    )
  }
)
