import React, { memo } from 'react'
import {
  StyleProp,
  Text,
  View,
  ViewStyle
} from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, ShowMessage, MessageType } from 'components'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'

export interface Props {
  url: string,
  style?: StyleProp<ViewStyle>
}

export const CopyLink = memo(({
  url,
  style
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()

  const copyLink = async () => {
    await window?.navigator?.clipboard?.writeText(url)

    ShowMessage({
      text: t('copyLink:message'),
      type: MessageType.notice
    })
  }

  return (
    <Button
      containerStyle={[styles.container, style]}
      type={ButtonType.blank}
      textStyle={[styles.containerText]}
      onPress={copyLink}
      label={t('copyLink:ariaLabel')}
      testID="CopyLink">
      <View style={styles.content}>
        <Text numberOfLines={1} style={[styles.linkText]}>
          {url}
        </Text>
        <Text style={styles.fakeButton}>{t('copyLink:copy')}</Text>
      </View>
    </Button>
  )
})
