import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { COLORS, bp, spacing, fontSemiBold, fontRegular } = STYLES.useStyles()

  const container = {
    borderWidth: getREM(0.125),
    borderStyle: 'solid',
    borderColor: COLORS.steam,
    borderRadius: getREM(0.625),
    flexDirection: bp.not([bp.tablet, bp.desktop]) ? 'column' : 'row',
    justifyContent: 'space-between',
    paddingVertical: spacing.l,
    paddingHorizontal: spacing.xl,
    marginBottom: spacing.l
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    container.paddingVertical = spacing.s
    container.paddingHorizontal = spacing.s
  }

  if (bp.desktop) {
    container.paddingVertical = spacing.xxl
    container.paddingHorizontal = spacing.xxxl
  }

  const description = {
    width: 'auto',
    maxWidth: STYLES.grid.maxBoxWidth,
    flexShrink: 1
  } as ViewStyle

  const name = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m,
    color: COLORS.aubergine,
    marginBottom: getREM(0.625)
  } as TextStyle

  const message = {
    ...fontRegular,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  } as TextStyle

  const toggle = {
    marginTop: getREM(1.25),
    paddingLeft: 0,
    alignSelf: 'center'
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    toggle.paddingLeft = getREM(3.125)
  }

  const styles = StyleSheet.create({
    container,
    description,
    name,
    message,
    toggle
  })

  return { styles }
}
