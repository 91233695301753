import React from 'react'
import { SafeAreaProvider as RNSafeAreaProvider } from 'react-native-safe-area-context'
import { ViewProps, ViewStyle, Platform } from 'react-native'

export const SafeAreaProvider = ({ children }: ViewProps) => {
  const _SafeAreaProviderStyles: ViewStyle = {}

  if (Platform.OS === 'web') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    _SafeAreaProviderStyles.position = 'static'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    _SafeAreaProviderStyles.zIndex = 'auto'
  }

  return (
    <RNSafeAreaProvider
      style={_SafeAreaProviderStyles}
      testID="SafeAreaProvider">
      {children as React.ReactNode[]}
    </RNSafeAreaProvider>
  )
}