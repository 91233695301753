import { StyleSheet, ViewStyle, TextStyle, PixelRatio, Platform } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'

export const useStyles = () => {
  const { COLORS, fontSemiBold, bp, spacing } = STYLES.useStyles()

  const _containerHeight = PixelRatio.getFontScale() * baseSize * 2.25
  const _spacer = PixelRatio.getFontScale() * baseSize * 0.25
  const _triangleSize = PixelRatio.getFontScale() * baseSize * 1.5
  const buttonHeight = _containerHeight - (_spacer * 2)

  const container = {
    width: '100%'
  } as ViewStyle

  const roundedNavigation = {
    flexWrap: 'wrap',
    flexDirection: 'row',
    backgroundColor: COLORS.whisper,
    height: _containerHeight,
    borderRadius: _containerHeight,
    overflow: 'hidden',
    padding: _spacer,
    alignSelf: 'center',
    position: 'relative'
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    roundedNavigation.width = '100%'
  }

  const roundedNavigation_withmore = {
    paddingRight: _containerHeight
  } as ViewStyle

  const buttonStyleContainer = {
    flexShrink: 1
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    buttonStyleContainer.flexGrow = 1
  }

  const buttonStyleContainer_moreButton = {
    flexGrow: 0,
    position: 'absolute',
    top: _spacer,
    right: _spacer,
    width: buttonHeight,
    height: buttonHeight,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  } as ViewStyle

  const buttonStyleContainer_marginLeft = {
    marginLeft: _spacer
  } as ViewStyle

  const buttonStyleContainer_marginTop = {
    marginTop: _spacer
  } as ViewStyle

  const buttonStyle = {
    textDecorationLine: 'none',
    textAlign: 'center',
    borderRadius: buttonHeight / 2,
    justifyContent: 'center',
    alignItems: 'center',
    height: buttonHeight,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  } as TextStyle

  const buttonStyle_moreButton = {
    borderRadius: buttonHeight,
    width: buttonHeight,
    paddingLeft: 0,
    paddingRight: 0
  } as TextStyle

  const buttonStyle_moreMenu = {
    borderRadius: getREM(1),
    justifyContent: 'flex-start',
    textAlign: 'left'
  } as TextStyle

  const buttonStyle_pointerOver = {
    backgroundColor: COLORS.snuff
  } as TextStyle

  const buttonStyle_active = {
    backgroundColor: COLORS.mischka,
    ...Platform.select({
      web: {
        cursor: 'default'
      }
    })
  } as TextStyle

  const buttonText = {
    ...fontSemiBold,
    color: COLORS.aubergine,
    fontSize: STYLES.fontSize.xs,
    lineHeight: buttonHeight
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    buttonText.fontSize = STYLES.fontSize.s
  }

  const modalPressable = {
    flex: 1,
    ...Platform.select({
      web: {
        cursor: 'default'
      }
    })
  } as ViewStyle

  const roundedNavigationDropdownContainer = {
    position: 'absolute',
    zIndex: 1,
    alignItems: 'flex-end',
    width: '100%'
  } as ViewStyle

  const roundedNavigationDropdown = {
    marginTop: Platform.OS === 'android' ? 0 : _triangleSize / 2,
    minWidth: getREM(11),
    backgroundColor: COLORS.whisper,
    flexDirection: 'column',
    borderRadius: getREM(1),
    padding: _spacer,
    transform: [
      {
        translateX: buttonHeight / 4
      }
    ]
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    roundedNavigationDropdown.minWidth = 0
    roundedNavigationDropdown.width = '100%'
  }

  const roundedNavigationDropdownArrow = {
    position: 'absolute',
    zIndex: 0,
    top: -1 * (_triangleSize / 2),
    right: getREM(0.75),
    width: _triangleSize,
    height: _triangleSize / 2
  } as ViewStyle

  const iconContainer = {
    height: buttonHeight,
    width: buttonHeight
  } as ViewStyle

  const icon = {
    width: getREM(1.25),
    height: getREM(1.25),
    ...Platform.select({
      ios: {
        transform: [
          { translateY: getREM(0.1) }
        ]
      },
      android: {
        transform: [
          { translateY: getREM(-0.1) }
        ]
      }
    })
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    roundedNavigation,
    roundedNavigation_withmore,
    buttonStyle,
    buttonStyle_moreButton,
    buttonStyle_moreMenu,
    buttonStyle_pointerOver,
    buttonStyle_active,
    buttonText,
    buttonStyleContainer,
    buttonStyleContainer_moreButton,
    buttonStyleContainer_marginLeft,
    buttonStyleContainer_marginTop,
    modalPressable,
    roundedNavigationDropdownContainer,
    roundedNavigationDropdown,
    roundedNavigationDropdownArrow,
    iconContainer,
    icon
  })

  return { styles, buttonHeight }
}
