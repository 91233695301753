import React, { memo } from 'react'
import { View, StyleSheet } from 'react-native'
import { SvgProps } from 'react-native-svg'
import { SVG } from 'components'
import { STYLES, getREM, baseSize } from 'styles'
import { useWindowDimensions } from 'utils'

export interface ColorProps {
  color1: string
  color2: string
}

const _imageWidth = 424

const BackgroundImage = memo(({
  color1,
  color2,
  style,
  ...props
}: ColorProps & SvgProps) => {
  return (
    <SVG.Svg
      viewBox="0 0 424 375"
      style={[
        {
          width: getREM(_imageWidth / baseSize),
          height: getREM(375 / baseSize),
          flexShrink: 0
        },
        style
      ]}
      {...props}
      fill="none"
      {...{ 'aria-hidden': true }}
    >
      <SVG.Path
        id="bottom"
        d="M424 0H0V364.985H75.1741C93.0508 364.985 127.429 364.985 157.682 370.466C187.935 375.946 218.331 368.5 238.5 368.5C258.669 368.5 285.5 375 310 375C329.117 375 364.519 364.985 372.203 364.985H401.539H424V0Z"
        fill={color2}
      />
      <SVG.Path
        id="top"
        d="M424 0H0V339.438H33C45.9106 339.438 81.6639 345.438 98.9518 345.438C118.3 345.438 130.172 346.703 150.362 345.438C170.553 344.172 176.089 345.438 192.5 345.438C213.5 345.438 227.481 339.438 244 339.438C260.519 339.438 288.878 348.012 312.5 339.438C338.5 330 373.983 339.438 388.208 339.438H424V0Z"
        fill={color1}
      />
    </SVG.Svg>
  )
})

export const ScreenBackgroundImage = memo(({ color1, color2 }: ColorProps) => {
  const { width } = useWindowDimensions()
  const _slices = Math.ceil(width / _imageWidth)
  const { sizing } = STYLES.useStyles()

  return (
    <View
      testID="ScreenBackgroundImage"
      style={{
        ...StyleSheet.absoluteFillObject,
        bottom: getREM(
          sizing.headerPaddingBottom / baseSize
        ),
        backgroundColor: color1
      }}>
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          marginBottom: getREM(
            -sizing.headerPaddingBottom / baseSize
          ),
          justifyContent: 'flex-end'
        }}>
        <View
          style={{
            ...StyleSheet.absoluteFillObject,
            alignItems: 'flex-end',
            flexDirection: 'row'
          }}>
          {new Array(_slices).fill('').map((_, index) => (
            <BackgroundImage
              testID={`BackgroundImage-${index}`}
              color1={color1}
              color2={color2}
              key={`header-bg-image-${index}`}
            />
          ))}
        </View>
      </View>
    </View>
  )
})
