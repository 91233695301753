import { fontSize, lineHeight } from '../FontSize'
import { useFont } from './useFont'
import { useColors } from './useColors'
import { useBreakpoints } from './useBreakpoints'
import { Platform } from 'react-native'

export const useParagraphStyles = () => {
  const { colors } = useColors()
  const { bp } = useBreakpoints()
  const { fontRegular } = useFont()

  const paragraph = {
    ...fontRegular,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    color: colors.text,
    marginBottom: Platform.OS === 'web' ? `calc(${fontSize.normal} * 2)` : fontSize.normal * 2
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    paragraph.fontSize = fontSize.s
    paragraph.lineHeight = lineHeight.s
    paragraph.marginBottom = Platform.OS === 'web' ? `calc(${fontSize.s} * 2)` : fontSize.s * 2
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    paragraph.fontSize = fontSize.m
    paragraph.lineHeight = lineHeight.m
    paragraph.marginBottom = Platform.OS === 'web' ? `calc(${fontSize.m} * 2)` : fontSize.m * 2
  }

  return { paragraph }
}
