import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { fontBold, COLORS, bp, spacing } = STYLES.useStyles()

  const container = {
    flexBasis: 'auto',
    width: '100%'
  } as ViewStyle

  const title = {
    ...fontBold,
    color: COLORS.aubergine,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    textAlign: 'center',
    marginBottom: spacing.m
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    title.fontSize = STYLES.fontSize.m
    title.lineHeight = STYLES.lineHeight.m
  }

  const filters = {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%'
  } as ViewStyle

  const toggle = {
    alignSelf: 'center',
    marginTop: spacing.l
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    title,
    filters,
    toggle
  })

  return { styles }
}
