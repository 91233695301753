import { StyleSheet, ViewStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { box, COLORS, colors, bp, spacing } = STYLES.useStyles()
  const insets = useSafeAreaInsets()

  const imageContainer = {
    marginBottom: spacing.normal,
    width: '100%',
    height: 120
  } as ViewStyle

  if (bp.not([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    imageContainer.height = 140
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    imageContainer.height = 180
    imageContainer.marginBottom = spacing.xl
  }

  const background = {
    flexGrow: 1,
    backgroundColor: COLORS.mushroom,
    paddingTop: Platform.OS === 'web' ? spacing.normal : spacing.normal + insets.top,
    paddingRight: Platform.OS === 'web' ? spacing.normal : spacing.normal + insets.right,
    paddingBottom: Platform.OS === 'web' ? spacing.normal : spacing.normal + insets.bottom,
    paddingLeft: Platform.OS === 'web' ? spacing.normal : spacing.normal + insets.left,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    background.paddingRight = Platform.OS === 'web' ? spacing.xxxl : spacing.xxxl + insets.right
    background.paddingLeft = Platform.OS === 'web' ? spacing.xxxl : spacing.xxxl + insets.left
  }

  const closeButtonContainer = {
    marginTop: spacing.normal
  } as ViewStyle

  const closeIconContainer = {
    position: 'absolute',
    zIndex: 0,
    top: spacing.m,
    right: spacing.m
  } as ViewStyle

  const closeIcon = {
    borderColor: COLORS.aubergine
  } as ViewStyle

  const styles = StyleSheet.create({
    container: {
      ...StyleSheet.absoluteFillObject
    },
    background,
    contentContainer: {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      zIndex: 1,
      position: 'relative',
      height: '100%'
    },
    content: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100%',
      maxWidth: STYLES.grid.maxContentWidth
    },
    card: {
      ...box,
      backgroundColor: colors.background,
      paddingTop: spacing.xxxl,
      paddingBottom: spacing.xxxl,
      paddingLeft: spacing.xl,
      paddingRight: spacing.xl,
      borderRadius: getREM(1.25),
      flexBasis: '100%',
      minHeight: 0,
      maxWidth: STYLES.grid.maxContentWidth // we need maxWidth here to overwrite what comes from box
    },
    image: { width: '100%', height: '100%' },
    imageContainer,
    title: {
      textAlign: 'center'
    },
    textContainer: {
      textAlign: 'center',
      maxWidth: STYLES.grid.maxTextWidth,
      alignSelf: 'center',
      marginBottom: spacing.xl
    },
    closeButtonContainer,
    closeIconContainer,
    closeIcon
  })

  return { styles }
}
