import React, { useState } from 'react'
import { useStyles } from './styles'
import { TextInput, Button, ButtonType, Icons, View } from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { getREM, STYLES } from 'styles'
import { ViewProps } from 'react-native'

export interface Props extends ViewProps {
  label?: string
  onPress: (value: string) => void
}

export const SearchInput = ({ label, onPress, style, ...props }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { spacing } = STYLES.useStyles()
  const [_value, _setValue] = useState('')

  const _onChangeText = (value: string) => {
    _setValue(value)

    if (value === '') {
      onPress?.('')
    }
  }

  const _onPress = () => {
    onPress?.(_value)
  }

  const _onReset = () => {
    _setValue('')
    onPress?.('')
  }

  return (
    <View style={[styles.container, style]} testID="SearchInput" spacer={spacing.xs} {...props}>
      <TextInput
        containerStyle={styles.inputContainer}
        placeholder={t('searchInput:label')}
        label={t('searchInput:label')}
        style={styles.input}
        value={_value}
        onChangeText={_onChangeText}
        onSubmitEditing={_onPress}
        hideLabel
      />
      <View style={styles.actions} spacer={spacing.xs}>
        <Button
          hideLabel
          label={t('searchInput:ariaLabel', { value: label })}
          type={ButtonType.iconOutlined}
          icon={Icons.Search}
          iconStyle={{ width: getREM(1.5), height: getREM(1.5) }}
          onPress={_onPress}
          state={{ disabled: _value === '' }}
        />
        <Button
          hideLabel
          label={t(`searchInput:resetLabel`)}
          type={ButtonType.iconOutlined}
          icon={Icons.Reset}
          onPress={_onReset}
          state={{ disabled: _value === '' }}
        />
      </View>
    </View>
  )
}
