import React, { memo } from 'react'
import {
  StyleProp,
  ViewStyle
} from 'react-native'
import { useStyles } from './styles'
import { SelectionCard, ItemProps as SelectionCardItemProps } from './SelectionCard'
import { FieldSet } from 'components'

export interface Props {
  items: SelectionCardItemProps[]
  onSelect: (ids: string[]) => void
  style?: StyleProp<ViewStyle>
  isMulti?: boolean
  legend?: string
  selectedIds?: string[]
}

export const SelectionCards = memo(({ items, onSelect, style, isMulti = false, legend, selectedIds }: Props) => {
  const { styles } = useStyles()

  const _onSelect = (id: string) => {
    const _newIds = JSON.parse(
      JSON.stringify(/* istanbul ignore next */ selectedIds || [])
    )
    const _idIndex = _newIds.indexOf(id)

    if (_idIndex > -1) {
      _newIds.splice(_idIndex, 1)
    } else {
      if (!isMulti) {
        _newIds.length = 0
      }

      _newIds.push(id)
    }

    onSelect?.(_newIds)
  }

  return (
    <FieldSet style={style} testID="SelectionCards" legend={legend ?? ""}>
      {items?.map((item, index) => {
        const _isSelected = selectedIds?.includes(item.id) || false

        return (
          <SelectionCard {...item} onSelect={_onSelect} selected={_isSelected} key={item.id} style={index > 0 && styles.gap} />
        )
      })}
    </FieldSet>
  )
})
