import { ImageStyle, StyleSheet, ViewStyle } from 'react-native'
import { getREM, STYLES, baseSize } from 'styles'
import { iconSize } from './config'

export const useStyles = (
  size: 'default' | 'big' | 'huge' = 'default'
) => {
  const { COLORS, improvementColors } = STYLES.useStyles()

  const _defaultborderSize = 0.75

  const _sizing = {
    default: {
      baseSize: iconSize['default'] / baseSize,
      borderSize: 0.5
    },
    big: {
      baseSize: iconSize['big'] / baseSize,
      borderSize: _defaultborderSize
    },
    huge: {
      baseSize: iconSize['huge'] / baseSize,
      borderSize: _defaultborderSize
    }
  }

  const _baseSizeREM = getREM(_sizing[size].baseSize)
  const _borderSizeREM = getREM(_sizing[size].borderSize)

  const _computed = getREM(_sizing[size].baseSize + _sizing[size].borderSize)

  const icon = {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    justifyContent: 'center',
    position: 'relative',
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    width: _baseSizeREM,
    height: _baseSizeREM
  } as ViewStyle

  const iconBorder = {
    backgroundColor: COLORS.white,
    borderWidth: _borderSizeREM,
    borderStyle: 'solid',
    borderColor: COLORS.white,
    borderRadius: 9999,
    height: _computed,
    width: _computed
  } as ViewStyle

  const border = {
    width: _baseSizeREM,
    height: _baseSizeREM
  } as ViewStyle

  const imageContainer = {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  } as ViewStyle

  const image = {
    height: getREM(0.5 * _sizing[size].baseSize),
    width: getREM(0.5 * _sizing[size].baseSize)
  } as ImageStyle

  const styles = StyleSheet.create({
    icon,
    border,
    imageContainer,
    image,
    iconBorder
  })

  return { 
    styles, 
    improvements: improvementColors
  }
}
