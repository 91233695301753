import React, { useContext, memo, useState, useMemo, useEffect, useCallback } from 'react'
import { Action, CommitmentState, StepState } from 'models'
import { STYLES } from 'styles'
import { useLang } from './Lang'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonType,
  FullScreenDialog,
  View,
  Heading,
  BenefitsList,
  StepCarousel,
  StepCardTemplate,
  StepCarouselItemTemplateProps,
  StepList,
  StepButtons,
  SvgImage
} from 'components'
import { Text, Platform } from 'react-native'
import {
  useNavigationContext,
  ReplaceParam,
  LinkContext,
  GetCommitment,
  useAnalytics
} from 'utils'
import { EventRegister } from 'react-native-event-listeners'
import { useQuery } from 'react-query'

export enum CommitmentModalType {
  pending = 'pending',
  complete = 'complete',
  none = 'none'
}

export interface StateProps {
  modalType: CommitmentModalType
  action?: Action
  score?: number
  isHidden: boolean
}

export const CommitmentModalEventKey = 'showCommitmentModalKey'

export const ShowCommitmentModal = (state: Partial<StateProps>) => {
  EventRegister.emit(CommitmentModalEventKey, state)
}

const PendingImage = memo(({ ...props }) => (
  <SvgImage image={require('images/OKGesture.svg')} {...props} />
)) as typeof SvgImage

export const CommitmentModalContainer = memo(() => {
  useLang()

  const { t, i18n } = useTranslation()
  const { spacing, bp } = STYLES.useStyles()
  const navigationContext = useNavigationContext()
  const {
    footprintDetailPageLinkUrl,
    stepsPageLinkUrl,
    todoListPageLinkUrl,
    stepDetailPageLinkUrl
  } = useContext(LinkContext)
  const { trackEvent, trackingEvents } = useAnalytics()

  const _initialState = useMemo(
    () => ({
      modalType: CommitmentModalType.none,
      action: undefined,
      score: undefined,
      isHidden: true
    }),
    []
  )

  const [_state, _setState] = useState<StateProps>(_initialState)
  const { styles } = useStyles({ modalType: _state.modalType })

  const _trackingSource = `CommitmentModal ${_state.modalType}`

  const _showStepGrid = bp.is([bp.tablet, bp.desktop])
  const _centreStepCard = bp.is([
    bp.mobile_xs,
    bp.mobile_s,
    bp.mobile_normal,
    bp.mobile_m
  ])

  const _getAction = async () => {
    return (
      await Action.where({ id: _state?.action?.id })
        .includes(['checklistItems'])
        .select({
          actions: ['summary'],
          actionsChecklistItems: ['description', 'order']
        })
        .extraParams({ locale: i18n.language })
        .first()
    ).data
  }

  const { data: action, isFetching: isLoadingAction } = useQuery(
    [
      'data',
      'commitmentModal',
      'action',
      _state?.action?.id,
      CommitmentModalType.pending,
      i18n.language
    ],
    _getAction,
    {
      enabled:
        !!_state?.action?.id && _state.modalType === CommitmentModalType.pending
    }
  )

  const _getActionFootprint = async () => {
    return (
      await Action.where({ id: _state?.action?.id })
        .select({
          actions: ['footprintParam']
        })
        .first()
    ).data
  }

  const { data: actionFootprint, isFetching: isLoadingActionFootprint } =
    useQuery(
      [
        'data',
        'commitmentModal',
        'actionFootprint',
        _state?.action?.id,
        CommitmentModalType.complete
      ],
      _getActionFootprint,
      {
        enabled:
          !!_state?.action?.id &&
          _state.modalType === CommitmentModalType.complete
      }
    )

  const _getRecommendedSteps = async () => {
    return (
      await Action.where({
        state: StepState.applicable,
        recommended: true,
        randomOrder: true
      })
        .includes(['impact', { themes: 'theme' }])
        .select({
          actions: ['summary', 'param'],
          impacts: ['name', 'lookupSlug', 'colorForeground', 'colorBackground'],
          themes: ['name', 'lookupSlug', 'colorForeground', 'colorBackground']
        })
        .extraParams({ locale: i18n.language })
        .page(1)
        .per(4)
        .all()
    ).data
  }

  const { data: recommendedSteps, isFetching: isLoadingRecommendedSteps } =
    useQuery(
      [
        'data',
        'commitmentModal',
        'recommendedSteps',
        CommitmentModalType.complete,
        i18n.language
      ],
      _getRecommendedSteps,
      {
        enabled: _state.modalType === CommitmentModalType.complete
      }
    )

  const _title = useMemo(() => {
    switch (_state.modalType) {
      case CommitmentModalType.pending:
        return t('commitmentModal:pending.title')

      case CommitmentModalType.complete:
        return t('commitmentModal:complete.title')

      default:
        return ''
    }
  }, [_state.modalType, t])

  const _isLoading =
    isLoadingAction || isLoadingRecommendedSteps || isLoadingActionFootprint

  const _hasScore = !!_state.score
  const _hasRecommendedSteps = !!recommendedSteps && recommendedSteps.length > 0
  const _hasFootprintPageLink =
    _state.modalType === CommitmentModalType.complete &&
    footprintDetailPageLinkUrl &&
    actionFootprint?.footprintParam &&
    !isLoadingActionFootprint

  const _closeModal = () => {
    _setState((prevState) => ({ ...prevState, isHidden: true }))
  }

  const _closeCallback = () => {
    trackEvent({
      eventName: trackingEvents.stepsUserFlow,
      props: {
        source: _trackingSource,
        type: 'close modal',
        'action param': _state.action?.param
      }
    })

    _closeModal()
  }

  const _stepsPageLinkOnPress = () => {
    trackEvent({
      eventName: trackingEvents.stepsUserFlow,
      props: {
        source: _trackingSource,
        type: 'take another step',
        'action param': _state.action?.param
      }
    })

    _closeModal()

    /* istanbul ignore next */
    if (stepsPageLinkUrl) {
      navigationContext.navigate(stepsPageLinkUrl)
    }
  }

  const _footprintPageLinkOnPress = () => {
    trackEvent({
      eventName: trackingEvents.stepsUserFlow,
      props: {
        source: _trackingSource,
        type: 'update your footprint',
        'action param': _state.action?.param
      }
    })

    /* istanbul ignore next */
    if (footprintDetailPageLinkUrl) {
      const _url = ReplaceParam(
        footprintDetailPageLinkUrl,
        actionFootprint!.footprintParam
      )
      navigationContext.navigate(_url)
    }

    // modal closed from within Firefly as footprints open within in-app browser
  }

  const _todoListPageLinkOnPress = async () => {
    trackEvent({
      eventName: trackingEvents.stepsUserFlow,
      props: {
        source: _trackingSource,
        type: 'start your to do list',
        'action param': _state.action?.param
      }
    })

    let _commitmentId = ''

    const commitment = await GetCommitment(
      _state.action?.id,
      CommitmentState.pending
    )

    if (commitment) {
      _commitmentId = commitment.id
    }

    _closeModal()

    /* istanbul ignore next */
    if (todoListPageLinkUrl) {
      const _url = ReplaceParam(todoListPageLinkUrl, _commitmentId)
      navigationContext.navigate(_url)
    }
  }

  const ItemTemplate = useCallback(
    ({ item, style, ...props }: StepCarouselItemTemplateProps) => {
      /* istanbul ignore next */
      const _primaryActionOnPress = () => {
        if (stepDetailPageLinkUrl) {
          navigationContext.navigate(
            ReplaceParam(stepDetailPageLinkUrl, item.param)
          )
        }

        _closeModal()
      }
      return (
        <StepCardTemplate
          action={item}
          style={[styles.recommendedStepsItem, style]}
          isCardContentCentred={_centreStepCard}
          primaryActionOnPress={
            /* istanbul ignore next */
            Platform.OS !== 'web' ? _primaryActionOnPress : undefined
          }
          {...props}
        />
      )
    },
    [
      _centreStepCard,
      navigationContext,
      stepDetailPageLinkUrl,
      styles.recommendedStepsItem
    ]
  )

  const ItemTemplateCarousel = useCallback(
    ({ item, style, ...props }: StepCarouselItemTemplateProps) => (
      <View style={styles.recommendedStepsItemContainer}>
        <ItemTemplate item={item} style={style} {...props} />
      </View>
    ),
    [ItemTemplate, styles.recommendedStepsItemContainer]
  )

  useEffect(() => {
    const _listener = EventRegister.addEventListener(
      CommitmentModalEventKey,
      (newState: Partial<StateProps>) => {
        _setState((prevState) => ({
          ...prevState,
          isHidden: false,
          ...newState
        }))
      }
    )

    return () => {
      EventRegister.removeEventListener(_listener as string)
    }
  }, [])

  return (
    <FullScreenDialog
      isHidden={_state.isHidden}
      closeCallback={_closeCallback}
      trackingLabel={_trackingSource}
      isLoading={_isLoading}
      cardStyle={styles.modalCardStyle}
      Image={
        _state.modalType === CommitmentModalType.pending
          ? PendingImage
          : undefined
      }>
      <Heading style={styles.title} level={2}>
        {_title}
      </Heading>

      {_hasScore && (
        <View style={styles.scoreContainer} spacer={spacing.xs}>
          <Text style={styles.scorePrefixText}>
            {t('commitmentModal:scorePrefix')}
          </Text>
          <Text style={styles.scoreText}>
            {t('commitmentModal:score', { value: _state.score })}
          </Text>
        </View>
      )}

      {_state.modalType === CommitmentModalType.pending && action && (
        <View style={styles.benefits}>
          <Heading level={3} style={styles.modalContentHeading}>
            {action.summary}
          </Heading>
          <BenefitsList
            items={action.checklistItems
              ?.sort(
                /* istanbul ignore next */
                (item1, item2) => {
                  if (item1.order > item2.order) return 1
                  if (item1.order < item2.order) return -1
                  return 0
                }
              )
              .slice(0, 3)
              .map((item) => item.description)}
          />
        </View>
      )}

      {_state.modalType === CommitmentModalType.complete &&
        _hasRecommendedSteps && (
          <StepButtons.TrackingContext.Provider
            value={{ source: _trackingSource }}>
            <Heading level={3} style={styles.modalContentHeading}>
              {t('commitmentModal:recommended')}
            </Heading>
            {_showStepGrid && (
              <StepList
                visibleItems={4}
                items={recommendedSteps}
                ItemTemplate={ItemTemplate}
              />
            )}
            {!_showStepGrid && (
              <StepCarousel
                items={recommendedSteps}
                ItemTemplate={ItemTemplateCarousel}
                testID="CommitmentModalStepCarousel"
                style={styles.recommendedStepsCarousel}
                isOverflowVisible={false}
              />
            )}
          </StepButtons.TrackingContext.Provider>
        )}

      <View style={styles.modalButtons}>
        <Button
          type={ButtonType.brand}
          label={t('commitmentModal:stepsPageLinkLabel')}
          onPress={_stepsPageLinkOnPress}
          containerStyle={
            _hasFootprintPageLink ||
            _state.modalType === CommitmentModalType.pending
              ? styles.modalButtonsLeft
              : undefined
          }
        />
        {_hasFootprintPageLink && (
          <Button
            type={ButtonType.normal}
            label={t('commitmentModal:footprintPageLinkLabel')}
            onPress={_footprintPageLinkOnPress}
            containerStyle={styles.modalButtonsRight}
          />
        )}
        {_state.modalType === CommitmentModalType.pending && (
          <Button
            type={ButtonType.normal}
            label={t('commitmentModal:todoListPageLinkLabel')}
            onPress={_todoListPageLinkOnPress}
            containerStyle={styles.modalButtonsRight}
          />
        )}
      </View>
    </FullScreenDialog>
  )
})
