import React, { useEffect, useState, useCallback, memo } from 'react'
import { View, Text, ImageBackground, Animated, AccessibilityInfo } from 'react-native'
import { Button, ButtonType, MassValue, SvgImage } from 'components'
import {
  useCloudWhiteAnimation,
  useCloudPurpleAnimation,
  useLeafSmall1Animation,
  useLeafBig1Animation,
  useLeafSmall2Animation,
  useLeafBig2Animation,
  useButterflyAnimation
} from './Animations'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export interface Props {
  title?: string
  linkLabel?: string
  linkUrl?: string
  linkOnPress?: () => void
  value: number
  comparisonValue?: number
  isFixedSize?: boolean
}

enum ComparisonType {
  less = 'less',
  equal = 'equal',
  more = 'more'
}

export const CarbonFootprintHero = memo(({
  title,
  linkLabel,
  linkUrl,
  value,
  linkOnPress,
  comparisonValue,
  isFixedSize = false
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles, backgroundHidden } = useStyles(isFixedSize)
  const [_hasAnimation, _setHasAnimation] = useState<boolean | null>(!backgroundHidden || null)
  const [_comparisonType, _setComparisonType] = useState<ComparisonType>()

  const _hasTitle = !!title
  const _hasLink = !!linkLabel && (!!linkUrl || !!linkOnPress)
  const _hasComparisonValue = !_hasLink && typeof comparisonValue !== 'undefined'

  const _calculateComparison = useCallback(() => {
    if (!_hasComparisonValue) return

    let _type: ComparisonType

    if (comparisonValue < 0) {
      _type = ComparisonType.less
    } else if (comparisonValue === 0) {
      _type = ComparisonType.equal
    } else {
      _type = ComparisonType.more
    }

    _setComparisonType(_type)
  }, [_hasComparisonValue, comparisonValue])

  const _cloudWhite = useCloudWhiteAnimation()
  const _cloudPurple = useCloudPurpleAnimation()
  const _leafSmall1 = useLeafSmall1Animation(isFixedSize)
  const _leafBig1 = useLeafBig1Animation(isFixedSize)
  const _leafSmall2 = useLeafSmall2Animation(isFixedSize)
  const _leafBig2 = useLeafBig2Animation(isFixedSize)
  const _butterfly = useButterflyAnimation()

  const _animateBackground = Animated.sequence([
    _cloudWhite.animation,
    _cloudPurple.animation,
    Animated.parallel([_leafSmall1.animation, _leafSmall2.animation]),
    Animated.parallel([_leafBig1.animation, _leafBig2.animation]),
    _butterfly.animation
  ])

  const _checkReduceMotion = useCallback(async () => {
    if (backgroundHidden) {
      _setHasAnimation(false)
      return
    }

    const _reduceMotion = await AccessibilityInfo.isReduceMotionEnabled()

    if (_reduceMotion) {
      _setHasAnimation(false)
      return
    }

    _setHasAnimation(true)
    _animateBackground.start()
  }, [_animateBackground, backgroundHidden])

  useEffect(() => {
    _checkReduceMotion()
  }, [_checkReduceMotion])

  useEffect(() => {
    _calculateComparison()
  }, [comparisonValue, _calculateComparison])

  useEffect(() => {
    return () => _animateBackground.stop()
  }, [_animateBackground])

  if (_hasAnimation === null) return null

  return (
    <View style={styles.container} testID="CarbonFootprintHero">
      <View style={styles.background}>
        <Animated.View
          style={[styles.cloudPurple, _hasAnimation && _cloudPurple.styles]}>
          <ImageBackground
            source={require('./Images/CloudPurple.png')}
            style={styles.backgroundImage}
          />
        </Animated.View>
        <Animated.View
          style={[styles.leafSmall1, _hasAnimation && _leafSmall1.styles]}>
          <SvgImage image={require('./Images/LeafSmall1.svg')} style={styles.backgroundImage} />
        </Animated.View>
        <Animated.View
          style={[styles.leafBig1, _hasAnimation && _leafBig1.styles]}>
          <SvgImage image={require('./Images/LeafBig1.svg')} style={styles.backgroundImage} />
        </Animated.View>
        <Animated.View
          style={[styles.leafSmall2, _hasAnimation && _leafSmall2.styles]}>
          <SvgImage image={require('./Images/LeafSmall2.svg')} style={styles.backgroundImage} />
        </Animated.View>
        <Animated.View
          style={[styles.leafBig2, _hasAnimation && _leafBig2.styles]}>
          <SvgImage image={require('./Images/LeafBig2.svg')} style={styles.backgroundImage} />
        </Animated.View>
        <Animated.View
          style={[styles.cloudWhite, _hasAnimation && _cloudWhite.styles]}>
          <ImageBackground
            source={require('./Images/CloudWhite.png')}
            style={styles.backgroundImage}
          />
        </Animated.View>
        <Animated.View
          style={[styles.butterfly, _hasAnimation && _butterfly.styles]}>
          <SvgImage image={require('./Images/Butterfly.svg')} style={styles.backgroundImage} />
        </Animated.View>
      </View>
      <View style={styles.content}>
        {_hasTitle && (
          <Text textBreakStrategy="simple" style={styles.title}>
            {title}
          </Text>
        )}
        <MassValue
          valueStyle={styles.value}
          unitStyle={styles.unit}
          value={Math.trunc(value)} />
        <Text textBreakStrategy="simple" style={styles.text}>
          {t('carbonFootprint:text')}
        </Text>
        {_hasComparisonValue && _comparisonType && (
          <View style={[styles.comparisonContainer, styles[`comparisonContainer_${_comparisonType}`]]}>
            <Text style={styles.comparisonText}>
              {
                `${_comparisonType !== ComparisonType.equal
                  ? `${t('format:number', { value: Math.abs(comparisonValue) })}kg `
                  : ''
                }${t(`carbonFootprint:comparison.${_comparisonType}`)}`
              }
            </Text>
          </View>
        )}
        {_hasLink && (
          <Button
            type={ButtonType.brand}
            href={linkUrl}
            onPress={linkOnPress}
            label={linkLabel}
            containerStyle={styles.button}
          />
        )}
      </View>
    </View>
  )
})
