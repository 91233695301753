import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class OrganizationInvitationBatch extends ApplicationRecord {
  static jsonapiType = 'organization_invitation_batches'

  static endpoint = '/organizations/invitation-batches'

  @Attr({ persist: true }) emails!: string[]
  @Attr({ persist: true }) organizationId!: string
  @Attr({ persist: true }) teamId!: string
}
