import React, {
  useContext,
  useState,
  useEffect,
  memo,
  useCallback
} from 'react'
import { ViewProps } from 'react-native'
import { PageProps } from '../index'
import {
  SpeechBubble,
  Form,
  NumberInput,
  FieldSet,
  ActivityIndicator
} from 'components'
import {
  Layout,
  ProgressBar,
  Column,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  useSharedLang
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { Plane, PlaneHeightRatio } from 'images/Plane'
import { STYLES } from 'styles'
import { useQuery } from 'react-query'

type ValueType = { [key: string]: number }

export const Flights = memo(({ navigation }: PageProps) => {
  useLang()
  useSharedLang()

  const { t } = useTranslation()
  const {
    progress,
    getAviationEstimates,
    getAviationFootprint,
    saveAviationFootprint
  } = useContext(OnboardingGroupContext)
  const { imageWidth } = useSharedStyles()
  const { spacing, bp } = STYLES.useStyles()
  const [_values, _setValues] = useState<ValueType>({})
  const [_isUpdating, _setIsUpdating] = useState(false)
  const { data: _aviationEstimates, isLoading } = useQuery(
    ['onboarding', 'aviationEstimates'],
    getAviationEstimates
  )
  const { data: _aviationFootprint } = useQuery(
    ['onboarding', 'aviationFootprint'],
    getAviationFootprint
  )
  const [_showError, _setShowError] = useState(false)

  const _onPress = async () => {
    _setShowError(false)

    /* istanbul ignore next */
    if (_aviationEstimates && _values) {
      _setIsUpdating(true)

      const _success = await saveAviationFootprint(_aviationEstimates, _values)

      _setIsUpdating(false)

      if (_success) {
        navigation.navigate('Diet')
        return
      }
    }

    _setShowError(true)
  }

  /* istanbul ignore next */
  const _onChange = (id: string, value: number) => {
    _setValues((prevState) => ({
      ...prevState,
      [id]: value
    }))
  }

  const _SpeechBubble = useCallback(
    (props: ViewProps) => (
      <SpeechBubble
        categoryLabel={t('onboardingShared:speechBubbleCategories.didYouKnow')}
        text={t('onboardingFlights:speechBubbleText')}
        {...props}
      />
    ),
    [t]
  )

  const _imageHeight = imageWidth * PlaneHeightRatio

  const _Image = useCallback(
    () => (
      <IllustrationBackground>
        <Plane
          style={{
            width: imageWidth,
            height: _imageHeight,
            marginTop: 0.2717227220578467 * _imageHeight
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, imageWidth]
  )

  useEffect(() => {
    if (_aviationEstimates) {
      const _values: ValueType = _aviationEstimates.reduce(
        (a, v) => ({ ...a, [v.id!]: 0 }),
        {}
      )

      _aviationFootprint?.footprintEstimates?.forEach((item) => {
        /* istanbul ignore next */
        if (
          item?.estimate?.id &&
          typeof _values[item.estimate.id] !== 'undefined' &&
          _values[item.estimate.id] === 0
        ) {
          _values[item.estimate.id] = item.quantity
        }
      })

      _setValues(_values)
    }
  }, [_aviationFootprint, _aviationEstimates])

  if (_isUpdating || isLoading) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Flights" showBackButton showError={_showError}>
      <ProgressBar
        label={t('onboardingFlights:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.Flights) + 1}
        max={progress.length}
      />
      <Column.Container>
        <Column.Left>
          <Heading>{t('onboardingFlights:title')}</Heading>

          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>

          <Form>
            <FieldSet legend={t('onboardingFlights:title')}>
              {_aviationEstimates?.map((item) => (
                <NumberInput
                  key={item.id}
                  label={item.name}
                  description={item.description}
                  step={1}
                  min={0}
                  max={25}
                  onChange={
                    /* istanbul ignore next */
                    (value) => _onChange(item.id!, value)
                  }
                  value={
                    item.id && _values[item.id]
                      ? (_values[item.id] as number)
                      : 0
                  }
                />
              ))}
            </FieldSet>
          </Form>

          <Column.HiddenContent hidden={bp.desktop}>
            <_SpeechBubble style={{ marginBottom: spacing.xl }} />
          </Column.HiddenContent>

          <NextButton onPress={_onPress} />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image />
          <_SpeechBubble />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
