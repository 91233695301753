import { PeopleActionsSuppression } from 'models'

export const DestroySuppression = async (action_id?: string) => {
  if (!action_id) return

  try {
    const { data: suppression } = await PeopleActionsSuppression.where({ action_id }).select(['']).first()

    if (suppression) {
      await suppression.destroy()
    }
  } catch (e) { /* empty */ }
}