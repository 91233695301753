import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Share = memo(({
  size = getREM(1.0625),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Share"
      viewBox="0 0 100 100"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        fill={color ?? colors.text}
        d="M25,8.333c6.857,0 12.5,5.643 12.5,12.5c-0.003,0.784 -0.079,1.566 -0.228,2.336l29.647,17.293c2.257,-1.912 5.122,-2.962 8.081,-2.962c6.857,0 12.5,5.643 12.5,12.5c0,6.857 -5.643,12.5 -12.5,12.5c-2.954,-0.006 -5.813,-1.059 -8.065,-2.97l-29.663,17.301c0.149,0.77 0.225,1.552 0.228,2.336c-0,6.857 -5.643,12.5 -12.5,12.5c-6.857,-0 -12.5,-5.643 -12.5,-12.5c-0,-6.858 5.643,-12.5 12.5,-12.5c2.957,0.004 5.819,1.056 8.073,2.97l29.655,-17.301c-0.149,-0.77 -0.225,-1.552 -0.228,-2.336c0.003,-0.784 0.079,-1.566 0.228,-2.336l-29.647,-17.293c-2.257,1.913 -5.122,2.963 -8.081,2.962c-6.857,0 -12.5,-5.642 -12.5,-12.5c-0,-6.857 5.643,-12.5 12.5,-12.5Z"
      />
    </SVG.Svg>
  )
})
