import { StyleSheet, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { bp, spacing } = STYLES.useStyles()

  const heading = {
    textAlign: 'center',
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.fontSize.normal
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    heading.fontSize = STYLES.fontSize.l
    heading.lineHeight = STYLES.lineHeight.l
  }

  const text = {
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.normal
    text.lineHeight = STYLES.lineHeight.normal
  }

  const styles = StyleSheet.create({
    container: {
      maxWidth: STYLES.grid.maxBoxWidth,
      width: '100%',
      alignSelf: 'center',
      alignItems: 'center'
    },
    heading,
    textContainer: {
      textAlign: 'center',
      marginBottom: spacing.normal
    },
    text
  })

  return { styles }
}
