import { StyleSheet, type TextStyle, type ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing, bp } = STYLES.useStyles()

  const container = {
    width: '100%',
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: STYLES.grid.maxColumnWidth
  } as ViewStyle

  const title = {
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: spacing.xxl
  } as TextStyle

  const topSection = {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as ViewStyle

  if (bp.is([bp.mobile_xl, bp.tablet, bp.desktop])) {
    topSection.flexDirection = 'row'
  }

  const list = {
    marginTop: spacing.xl
  } as ViewStyle

  const pagination = {
    marginTop: spacing.xl
  } as ViewStyle

  const createReviewTextContainer = {
    maxWidth: STYLES.grid.maxInputWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    title,
    topSection,
    list,
    pagination,
    createReviewTextContainer
  })

  return { styles }
}
