import React, { useState, useRef, useEffect, useMemo, memo } from 'react'
import {
  StyleProp,
  ViewStyle,
  ScrollView,
  NativeSyntheticEvent,
  NativeScrollEvent,
  Platform,
  StyleSheet,
  View,
  ViewProps,
  Animated,
  Text
} from 'react-native'
import { Heading, Button, ButtonType, Pagination } from 'components'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { useStyles } from './styles'
import { useAnalytics, useNativeDriver, GenerateId } from 'utils'
import { SvgProps } from 'react-native-svg'
import { STYLES } from 'styles'

export interface Props {
  containerStyle?: StyleProp<ViewStyle>
  itemStyle?: StyleProp<ViewStyle>
  title?: string
  description?: string
  linkLabel?: string
  linkUrl?: string
  children?: React.ReactElement | React.ReactElement[]
  isOverflowVisible?: boolean
  maxItemWidth?: number
  Image?: React.MemoExoticComponent<(props: SvgProps | { size?: number }) => JSX.Element>
  hasTarget?: boolean
  emptyMessage?: string
}

interface SlideChildProps extends ViewProps {
  index: number
  style: ViewStyle
}

interface SlideChildProps extends ViewProps {
  index: number
}

interface ImageContainerProps extends ViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: any
  spacerWidth: number
  offsetX: Animated.Value
}

 /* istanbul ignore next */
const ImageContainer = ({
  children,
  styles,
  spacerWidth,
  offsetX
}: ImageContainerProps) => {
  const { bp } = STYLES.useStyles()

  if (bp.desktop) {
    return (
      <Animated.View
        style={[
          styles.imageContainer,
          {
            width: spacerWidth,
            opacity: offsetX.interpolate({
              inputRange: [0, 100],
              outputRange: [1, 0],
              extrapolate: 'clamp'
            })
          }
        ]}>
        {children}
      </Animated.View>
    )
  }

  return <>{children}</>
}

export const Carousel = memo(
  ({
    containerStyle,
    title,
    description,
    linkLabel,
    linkUrl,
    children,
    isOverflowVisible,
    maxItemWidth,
    Image,
    hasTarget = false,
    emptyMessage
  }: Props) => {
    useLang()
    const { t } = useTranslation()
    const [_children] = useState(children)
    const _childrenCount = React.Children.count(_children)
    const { styles, spacerWidth, rawMaxItemWidth, targetSize } = useStyles({
      childrenCount: _childrenCount,
      maxItemWidth,
      isOverflowVisible
    })
    const { trackEvent, trackingEvents } = useAnalytics()
    const { bp } = STYLES.useStyles()

    const _scrollViewContainer = useRef(null)
    const _scrollView = useRef<ScrollView>(null)
    const [_contentWidth, _setContentWidth] = useState(0)
    const [_page, _setPage] = useState(0)
    const _offsetX = useRef(new Animated.Value(0)).current
    const _webCarouselId = useRef(`Carousel-${GenerateId()}`)
    const _webIsMouseDown = useRef(false)
    const _webStartX = useRef(0)
    const _webScrollLeft = useRef(0)

    const _hasTitle = !!title
    const _hasDescription = !!description
    const _hasLink = !!linkLabel && !!linkUrl
    const _hasChildren = _childrenCount > 0
    const _hideImage = !isOverflowVisible || !bp.desktop
    const _hasImage = !!Image && !_hideImage
    const _hasEmptyMessage = !!emptyMessage && !_hasChildren

    const _itemWidth = useMemo(() => {
      return Math.min(
        rawMaxItemWidth,
        (_contentWidth - spacerWidth * 2) / _childrenCount
      )
    }, [_childrenCount, _contentWidth, rawMaxItemWidth, spacerWidth])

    const _handlePaging = async (index: number) => {
      _scrollView?.current?.scrollTo({
        x: _itemWidth * index,
        animated: true
      })
      trackEvent({
        eventName: trackingEvents.carouselPaging,
        props: {
          title,
          page: index + 1
        }
      })
    }

    let _isScrolling: NodeJS.Timeout

    /* istanbul ignore next */
    const _handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (!event) return

      let _item = 0

      if (event.nativeEvent.contentOffset.x > 0) {
        _item = Math.round(event.nativeEvent.contentOffset.x / _itemWidth)
      }

      if (Platform.OS === 'web') {
        window.clearTimeout(_isScrolling)

        _isScrolling = setTimeout(() => {
          _setPage(_item)
        }, 50)
      } else {
        if (_item === _page) return

        _setPage(_item)
      }
    }

    const SlideChild = memo(
      ({ children, index, style, ...props }: SlideChildProps) => {
        if (Platform.OS === 'web') {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <div
              aria-roledescription="item"
              aria-label={`${index + 1} of ${_childrenCount}`}
              style={
                StyleSheet.flatten(style) as unknown as React.CSSProperties
              }
              {...(props as React.HTMLAttributes<HTMLDivElement>)}>
              {children as React.ReactNode}
            </div>
          )
        }

        return (
          <View style={style} {...props}>
            {children}
          </View>
        )
      }
    )

    /* istanbul ignore next */
    const _webStartDrag = (e: MouseEvent) => {
      const _scrollViewElement = _scrollView.current as unknown as HTMLElement
      _webIsMouseDown.current = true
      _webStartX.current = e.pageX - _scrollViewElement.offsetLeft
      _webScrollLeft.current = _scrollViewElement.scrollLeft
    }

    /* istanbul ignore next */
    const _webDragging = (e: MouseEvent) => {
      e.preventDefault()
      if (!_webIsMouseDown.current) return
      const _scrollViewElement = _scrollView.current as unknown as HTMLElement
      const _x = e.pageX - _scrollViewElement.offsetLeft
      const _scroll = _x - _webStartX.current
      _scrollViewElement.scrollLeft = _webScrollLeft.current - _scroll
    }

    /* istanbul ignore next */
    const _webStopDrag = () => {
      _webIsMouseDown.current = false
    }

    /* istanbul ignore next */
    useEffect(() => {
      const _scrollViewContainerElement =
        _scrollViewContainer.current as unknown as HTMLElement
      const _scrollViewElement = _scrollView.current as unknown as HTMLElement

      if (Platform.OS === 'web') {
        if (_scrollViewContainerElement) {
          _scrollViewContainerElement?.setAttribute?.(
            'aria-roledescription',
            'carousel'
          )
        }

        if (_scrollViewElement) {
          _scrollViewElement?.setAttribute?.('role', 'group')
          _scrollViewElement?.setAttribute?.('aria-live', 'polite')
          title && _scrollViewElement?.setAttribute?.('label', title)

          _scrollViewElement?.addEventListener?.('mousedown', _webStartDrag)
          _scrollViewElement?.addEventListener?.('mouseup', _webStopDrag)
          _scrollViewElement?.addEventListener?.('mouseleave', _webStopDrag)
          _scrollViewElement?.addEventListener?.('mousemove', _webDragging)
        }

        if (window?.document?.createElement) {
          // react-native-web doesn't currently allow us to change the scroll-snap-align property so we have to add ourselves
          const _style = window.document.createElement('style')
          _style.innerHTML = `[id="${_webCarouselId.current}"] [class*="-scrollSnapAlign-"] { scroll-snap-align: center ;}`
          document.head.append(_style)
        }
      }

      return () => {
        if (_scrollViewElement && Platform.OS === 'web') {
          _scrollViewElement?.removeEventListener?.('mousedown', _webStartDrag)
          _scrollViewElement?.removeEventListener?.('mouseup', _webStopDrag)
          _scrollViewElement?.removeEventListener?.('mouseleave', _webStopDrag)
          _scrollViewElement?.removeEventListener?.('mousemove', _webDragging)
        }
      }
    }, [title])

    return (
      <View
        style={[styles.container, containerStyle]}
        nativeID={_webCarouselId.current}
        testID="Carousel">
        {_hasTitle && (
          <Heading level={2} style={styles.title}>
            {title}
          </Heading>
        )}

        {_hasDescription && (
          <Text style={styles.description}>{description}</Text>
        )}

        {_hasEmptyMessage && (
          <Text style={styles.emptyMessage}>{emptyMessage}</Text>
        )}

        {_hasChildren && (
          <View
            style={styles.carousel}
            testID="CarouselItems"
            ref={_scrollViewContainer}>
            <View>
              {_hasImage && (
                <ImageContainer
                  styles={styles}
                  offsetX={_offsetX}
                  spacerWidth={spacerWidth}>
                  {hasTarget ? (
                    <View style={styles.target} testID="CarouselImageTarget">
                      <Image size={targetSize} />
                    </View>
                  ) : (
                    <Image />
                  )}
                </ImageContainer>
              )}
              <Animated.ScrollView
                ref={_scrollView}
                onContentSizeChange={_setContentWidth}
                horizontal
                decelerationRate={'fast'}
                disableIntervalMomentum={true}
                showsHorizontalScrollIndicator={false}
                onScroll={Animated.event(
                  [
                    {
                      nativeEvent: {
                        contentOffset: {
                          x: _offsetX
                        }
                      }
                    }
                  ],
                  { useNativeDriver, listener: _handleScroll }
                )}
                scrollEventThrottle={16}
                contentContainerStyle={styles.scrollContainer}
                pagingEnabled
                style={styles.scrollview}
                snapToOffsets={React.Children.map(
                  _children,
                  (_, index) => index * _itemWidth
                )}>
                <View style={{ width: spacerWidth }} />
                {React.Children.map(_children, (child, index) => {
                  /* istanbul ignore next */
                  if (!child) return null

                  return (
                    <SlideChild
                      key={`${child.key}-item`}
                      index={index}
                      style={styles.carouselItem}>
                      {child}
                    </SlideChild>
                  )
                })}
                <View style={{ width: spacerWidth }} />
              </Animated.ScrollView>
            </View>
            {_childrenCount > 1 && (
              <Pagination
                currentItem={_page}
                totalItems={_childrenCount}
                label={t(`carousel:item`)}
                onPress={_handlePaging}
              />
            )}
          </View>
        )}
        {_hasLink && (
          <Button
            label={linkLabel}
            href={linkUrl}
            type={ButtonType.brand}
            containerStyle={styles.link}
          />
        )}
      </View>
    )
  }
)