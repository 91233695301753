import React, { memo } from 'react'
import {
  View,
  Text,
  ViewProps
} from 'react-native'
import { useStyles } from './styles'

export interface Props extends ViewProps {
  label?: string
  description?: string
  descriptionId?: string
  hideLabel?: boolean
}

export const Field = memo(({ children, label, description, descriptionId, style, hideLabel = false, ...props }: Props) => {
  const { styles } = useStyles()

  const _hasLabel = !hideLabel && !!label
  const _hasDescription = !hideLabel && !!description

  return (
    <View style={[styles.container, style]} testID="Field" {...props}>
      {(_hasLabel || _hasDescription) && (
        <View style={styles.labelContainer} testID="Label">
          {_hasLabel && (<Text textBreakStrategy="simple" style={styles.label}>
            {label}
          </Text>)}
          {_hasDescription && (
            <Text textBreakStrategy="simple" style={styles.description} nativeID={descriptionId}>
              {description}
            </Text>
          )}
        </View>
      )}
      {children}
    </View>
  )
})