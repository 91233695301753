import { DietEstimate, PeopleDietFootprint } from 'models'
import { DefaultProps } from '../index'
import { useTranslation } from 'react-i18next'

export type UseDietType = {
  getDietEstimates: () => Promise<DietEstimate[] | undefined>
  getDietFootprint: () => Promise<PeopleDietFootprint | null>
  saveDietFootprint: (diet: DietEstimate) => Promise<boolean | undefined>
}

export const useDiet = ({ source }: DefaultProps) => {
  const { i18n } = useTranslation()

  const getDietEstimates: UseDietType['getDietEstimates'] = async () => {
    return (await DietEstimate.select(['name', 'description']).extraParams({ paginate: false, locale: i18n.language }).all()).data
  }

  const getDietFootprint: UseDietType['getDietFootprint'] = async () => {
    return (await PeopleDietFootprint.order({ created_at: 'desc' }).includes('diet').select({ peopleDietFootprints: [''], diets: [''] }).extraParams({ locale: i18n.language }).first()).data
  }

  const saveDietFootprint: UseDietType['saveDietFootprint'] = async (diet) => {
    const { data: oldFootprints } = await PeopleDietFootprint.select(['']).extraParams({ paginate: false, locale: i18n.language }).all()

    if (oldFootprints && oldFootprints.length > 0) {
      for (let i = 0; i < oldFootprints.length; i++) {
        await oldFootprints[i]?.destroy()
      }
    }

    const _newFootprint = new PeopleDietFootprint({
      diet,
      source
    })

    return await _newFootprint.save({ with: 'diet' })
  }

  return {
    getDietEstimates,
    getDietFootprint,
    saveDietFootprint
  }
}