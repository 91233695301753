import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing } = STYLES.useStyles()

  const carbonFootprintTargets = {
    marginTop: spacing.l
  } as ViewStyle

  const styles = StyleSheet.create({
    carbonFootprintTargets
  })

  return { styles }
}
