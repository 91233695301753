import React, { memo } from 'react'
import { Heading as HeadingComponent } from 'components'
import { useStyles } from './styles'

export const Heading: typeof HeadingComponent = memo(({ children, style, ...props }) => {
  const { styles } = useStyles()

  return (
    <HeadingComponent level={1} style={[styles.title, style]} testID="Heading" {...props}>{children}</HeadingComponent>
  )
})
