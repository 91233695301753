import React, { ComponentProps, memo } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { Checkbox } from 'components'
import { STYLES } from 'styles'

type CheckboxProps = ComponentProps<typeof Checkbox>

interface ItemProps {
  id: CheckboxProps['id']
  label: CheckboxProps['label']
  disabled?: CheckboxProps['disabled']
  index?: CheckboxProps['index']
  showConfetti?: CheckboxProps['showConfetti']
}

export interface Props {
  items: ItemProps[]
  style?: StyleProp<ViewStyle>
  selectedItems?: CheckboxProps['id'][]
  onPress: CheckboxProps['onPress']
}

export const CheckboxList = memo(({ items, style, selectedItems, onPress }: Props) => {
  const { spacing } = STYLES.useStyles()

  if (!items?.length) return null

  return (
    <View style={style} testID="CheckboxList">
      {items?.map((item, index) => {
        const _isChecked = selectedItems?.includes(item.id)

        return (
          <Checkbox
            key={`checklistitem-${item.id}`}
            {...item}
            checked={_isChecked}
            onPress={onPress}
            style={index > 0 && { marginTop: spacing.normal }}
          />
        )
      })}
    </View>
  )
})
