import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class ActionResource extends ApplicationRecord {
  static jsonapiType = 'actions_resources';

  @Attr({ persist: false }) title!: string

  @Attr({ persist: false }) order!: number

  @Attr({ persist: false }) category!: string

  @Attr({ persist: false }) url!: string
}
