import { StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { spacing, sizing } = STYLES.useStyles()
  const insets = useSafeAreaInsets()

  const container = {
    paddingRight: spacing.page.padding.paddingRight,
    paddingLeft: spacing.page.padding.paddingLeft,
    marginBottom: spacing.xxxl,
    width: '100%'
  } as ViewStyle

  const container_noHeader = {
    marginTop: getREM((sizing.topBarHeight + insets.top) / baseSize),
    paddingTop: spacing.xxxl
  } as ViewStyle

  const heading = {
    textAlign: 'center'
  } as TextStyle

  const toggle = {
    alignSelf: 'center',
    marginBottom: spacing.l
  } as ViewStyle

  const filterGroup = {
    marginBottom: spacing.l,
    maxWidth: STYLES.grid.maxTextWidth,
    alignSelf: 'center'
  } as ViewStyle

  const activityIndicator = { marginTop: spacing.xl, marginBottom: spacing.xxxl } as ViewStyle

  const list = { marginTop: spacing.xl } as ViewStyle

  const styles = StyleSheet.create({
    container,
    container_noHeader,
    heading,
    toggle,
    filterGroup,
    activityIndicator,
    list
  })

  return { styles }
}
