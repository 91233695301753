import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const MoreDots = memo(({
  size = getREM(1.0625),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="MoreDots"
      viewBox="0 0 24 24"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.Path
        d="M12.5 3a2.5 2.5 0 000 5 2.5 2.5 0 000-5zm0 15a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0-7.5a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"
        fill={color ?? colors.text}
      />
    </SVG.Svg>
  )
})
