import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = ({ isCentred }: { isCentred?: boolean }) => {
  const { bp } = STYLES.useStyles()

  const container = {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 'auto'
  } as ViewStyle

  if (isCentred || bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    container.flexDirection = 'column'
  }

  const styles = StyleSheet.create({
    container
  })

  return { styles }
}
