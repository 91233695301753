import React, { memo } from 'react'
import { useStyles } from './style'
import { SVG, View } from 'components'
import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'

interface LeaderboardChangeProps {
  value: number
  unit?: string
}

export const LeaderboardChange = memo(
  ({ value, unit }: LeaderboardChangeProps) => {
    const { styles } = useStyles()
    const { t } = useTranslation()

    const changeIcon = () => {
      if (value > 0) {
        return (
          <SVG.Svg
            style={{ width: 17, height: 18, transform: [{ rotate: '180deg' }] }}
            viewBox="0 0 17 18"
            fill="#3a804e"
            testID="LeaderboardChangePositive">
            <SVG.Path d="M8.41389 18L0.655273 10.125H5.08877L5.08877 0L11.739 0L11.739 10.125H16.1725L8.41389 18Z"></SVG.Path>
          </SVG.Svg>
        )
      } else if (value < 0) {
        return (
          <SVG.Svg
            style={{ width: 17, height: 18 }}
            viewBox="0 0 17 18"
            fill="#c44170"
            testID="LeaderboardChangeNegative">
            <SVG.Path d="M8.41389 18L0.655273 10.125H5.08877L5.08877 0L11.739 0L11.739 10.125H16.1725L8.41389 18Z"></SVG.Path>
          </SVG.Svg>
        )
      } else {
        return (
          <View
            style={styles.leaderboardChangeIconEqual}
            testID="LeaderboardChangeNeutral"
          />
        )
      }
    }

    return (
      <View style={styles.leaderboardChangeWrapper} testID="LeaderboardChange">
        <View style={styles.leaderboardChangeIconWrapper}>{changeIcon()}</View>
        <Text textBreakStrategy="simple">
          <Text
            textBreakStrategy="simple"
            style={styles.leaderboardChangeValue}>
            {t('format:number', { value })}
          </Text>
          {unit && (
            <Text
              textBreakStrategy="simple"
              style={styles.leaderboardChangeUnit}
              testID="LeaderboardChangeUnit">
              {unit}
            </Text>
          )}
        </Text>
      </View>
    )
  }
)
