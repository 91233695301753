import React, { memo } from 'react'
import { SVG } from 'components'
import { getREM, STYLES } from 'styles'
import { IconProps } from '../index'

export const Download = memo(
  ({ size = getREM(1.25), color, style, ...props }: IconProps) => {
    const { colors } = STYLES.useStyles()

    return (
      <SVG.Svg
        viewBox="0 0 140 140"
        testID="Download"
        style={[
          {
            width: size,
            height: size
          },
          style
        ]}
        {...props}>
        <SVG.G transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
          <SVG.Path
            d="M12 14.25L12 23.25"
            fill="none"
            stroke={color ?? colors.text}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"></SVG.Path>
          <SVG.Path
            d="M15.75 19.5L12 23.25 8.25 19.5"
            fill="none"
            stroke={color ?? colors.text}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"></SVG.Path>
          <SVG.Path
            d="M16.5,15.75h1.875a4.875,4.875,0,1,0-2.764-8.885,7.5,7.5,0,1,0-8.149,8.844"
            fill="none"
            stroke={color ?? colors.text}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"></SVG.Path>
        </SVG.G>
      </SVG.Svg>
    )
  }
)
