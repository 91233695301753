import { useQuery } from 'react-query'
import { User, CommitmentState, StepState } from 'models'
import { useTranslation } from "react-i18next"

export const useUserData = () => {
  const { i18n } = useTranslation()

  const _getUserData = async () => {
    const { data } = await User
      .where({
        'person.commitments.state': CommitmentState.pending,
        'person.campaigns.campaignActions.state': StepState.applicable
      })
      .includes([
        {
          person: [
            {
              highlighted_organization_leaderboard_membership: 'organization'
            },
            {
              commitments: [
                {
                  action: [
                    'impact',
                    'themes.theme'
                  ]
                }
              ]
            },
            {
              campaigns: [
                'target',
                {
                  'campaign_actions': [
                    {
                      'action': [
                        'themes.theme',
                        'impact'
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ])
      .select({
        users: [''],
        highlighted_organization_leaderboard_memberships: ['rank'],
        people: [
          'score',
          'climate_clock',
          'footprint_sections',
          'kg_co2e_emissions',
          'kg_co2e_reductions',
          'complete_actions_count',
          'pending_actions_count'
        ],
        organizations: ['name'],
        actions: ['summary', 'param'],
        impacts: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ],
        themes: [
          'name',
          'lookupSlug',
          'colorForeground',
          'colorBackground'
        ],
        campaigns: [
          'description',
          'organizationId',
          'participantsCount',
          'pendingCommitmentsCount',
          'public',
          'successfulCommitmentsCount',
          'title'
        ],
        targets: [
          'campaignId',
          'displayPublicly',
          'participantsCount',
          'pendingCommitmentsCount',
          'selected',
          'successfulCommitmentsCount'
        ],
        commitments: [
          'state'
        ]
      })
      .extraParams({
        locale: i18n.language
      })
      .find('current')

    return data
  }

  const { isLoading, data } = useQuery(
    ['data', 'progress', i18n.language],
    _getUserData
  )

  return {
    isLoading,
    person: data?.person,
    campaign: data?.person?.campaigns?.[0]
  }
}