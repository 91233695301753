import React, { memo } from 'react'
import { ButtonType, ButtonSize, Button } from 'components'
import { ButtonProps } from 'components/Button'
import { STYLES } from 'styles'
import { useSharedLang } from '../index'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

export const NextButton = memo((props: ButtonProps) => {
  useSharedLang()

  const { t } = useTranslation()
  const { bp } = STYLES.useStyles()
  const { styles } = useStyles()

  const buttonProps = {
    type: ButtonType.brand,
    size: ButtonSize.normal
  }

  /* istanbul ignore next */
  if (bp.desktop) {
    buttonProps.size = ButtonSize.large
  }

  return (
    <Button
      {...buttonProps}
      label={t('onboardingShared:next')}
      containerStyle={styles.buttonContainer}
      testID="NextButton"
      {...props}
    />
  )
})