import React, { memo } from 'react'
import { ButtonProps, ButtonType } from '../../index'
import { STYLES } from 'styles'
import { View } from 'react-native'
import { useStyles } from './styles'

export const IconLabel = memo(({ icon, size, type, iconColor, iconContainerStyle, iconStyle }: ButtonProps) => {
  const { colors } = STYLES.useStyles()
  const { styles } = useStyles()

  const _hasIcon = !!icon && type && [ButtonType.icon, ButtonType.iconOutlined, ButtonType.blank].includes(type)

  if (!_hasIcon || !size) return null

  const Icon = icon

  return (
    <View style={[styles?.[`container_${size}`], iconContainerStyle]} testID="IconLabel">
      <Icon color={iconColor || colors.brand} style={[styles?.[`icon_${size}`], iconStyle]} />
    </View>
  )
})
