import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Bin = memo(({
  size = getREM(1.25),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="Bin"
      viewBox="0 0 140 140"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style
      ]}
      {...props}>
      <SVG.G
        fill="none"
        stroke={color ?? colors.text}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <SVG.Path
          strokeWidth={8.749995}
          d="M100.625 122.5h-61.25a8.75 8.75 0 0 1-8.75-8.75V35h78.75v78.75a8.75 8.75 0 0 1-8.75 8.75ZM56.875 96.25v-35M83.125 96.25v-35M13.125 35h113.75M83.125 17.5h-26.25a8.75 8.75 0 0 0-8.75 8.75V35h43.75v-8.75a8.75 8.75 0 0 0-8.75-8.75Z"
        />
      </SVG.G>
    </SVG.Svg>
  )
})
