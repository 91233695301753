import { DataType } from '../getData'

export const removeItems = async (data: DataType, ids: string[]) => {
  if (!data || data.length === 0 || !ids || ids.length === 0) return false

  let _success = false

  for (let i = data.length - 1; i >= 0; i--) {
    const _item = data[i]

    if (typeof _item !== 'undefined') {
      const _index = ids.indexOf(_item.id!)

      if (_index > -1) {
        _success = await _item.destroy()

        if (!_success) break

        ids.splice(_index, 1)
      }
    }

    if (ids.length === 0) break
  }

  return _success
}