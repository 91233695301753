import React, { useState, useEffect, useRef, memo } from 'react'
import {
  View,
  Platform,
  AccessibilityRole,
  Pressable,
  StyleSheet,
  StyleProp,
  ViewStyle,
  AccessibilityInfo
} from 'react-native'
import { Icons, Text } from 'components'
import { STYLES } from 'styles'
import { Confetti } from './Confetti'
import { useStyles } from './styles'

export interface Props {
  id: string
  label: string
  onPress: (id: string, checked: boolean) => Promise<void>
  checked?: boolean
  disabled?: boolean
  index?: string
  style?: StyleProp<ViewStyle>
  showConfetti?: boolean
}

export const Checkbox = memo(
  ({
    id,
    label,
    checked = false,
    disabled = false,
    onPress,
    index = '',
    style,
    showConfetti = false
  }: Props) => {
    const { styles } = useStyles()
    const { COLORS } = STYLES.useStyles()
    const [_isChecked, _setIsChecked] = useState(checked)
    const [_isFocused, _setIsFocused] = useState(false)
    const [_startAnimation, _setStartAnimation] = useState(false)
    const _isWorking = useRef(false)
    const _accessibility =
      Platform.OS === 'web'
        ? { accessibilityRole: 'label' as AccessibilityRole }
        : { accessibilityLabel: label, disabled }

    const _hasIndex = index !== ''

    const _onChange = async () => {
      /* istanbul ignore next */
      if (_isWorking.current) return

    _isWorking.current = true

    const _reduceMotion = await AccessibilityInfo.isReduceMotionEnabled()

    const _newCheckedState = !_isChecked

    _setIsChecked(_newCheckedState)

    await onPress(id, _newCheckedState)

    if (!_reduceMotion && _newCheckedState) {
      _setStartAnimation(true)
    } else {
      _setStartAnimation(false)
    }

    _isWorking.current = false
  }

  /* istanbul ignore next */
  const _onFocus = () => {
    _setIsFocused(true)
  }

  /* istanbul ignore next */
  const _onBlur = () => {
    _setIsFocused(false)
  }

  useEffect(() => {
    _setIsChecked(checked)
  }, [checked])

  return (
    <Pressable
      style={[styles.container, style]}
      testID="Checkbox"
      data-checked={checked}
      onPress={Platform.OS !== 'web' ? _onChange : undefined}
      {..._accessibility}>
      {Platform.OS === 'web' && (
        <View testID="CheckboxInput">
          <input
            aria-label={label}
            type="checkbox"
            checked={_isChecked}
            onChange={_onChange}
            style={
              StyleSheet.flatten(
                styles.checkbox
              ) as unknown as React.CSSProperties
            }
            disabled={disabled}
            onFocus={_onFocus}
            onBlur={_onBlur}
          />
        </View>
      )}
      <View>
        {showConfetti && <Confetti startAnimation={_startAnimation} />}
        <View
          style={[
            styles.fakeCheckbox,
            _isChecked && styles.fakeCheckbox_checked,
            disabled && styles.disabled,
            _isFocused && styles.fakeCheckbox_focused
          ]}>
          {_isChecked && (
            <Icons.Tick style={styles.tick} color={COLORS.white} />
          )}
        </View>
      </View>
      {_hasIndex && (
        <View
          style={[
            styles.indexContainer,
            disabled && styles.disabled,
            _isChecked && styles.indexContainer_checked
          ]}>
          <Text
            containerStyle={styles.indexTextContainer}
            textStyle={[
              styles.index,
              disabled && styles.disabled,
              _isChecked && styles.index_checked
            ]}>
            {index}
          </Text>
        </View>
      )}
      <View style={{ flex: 1 }}>
        <Text
          containerStyle={styles.labelContainer}
          textStyle={[styles.label, disabled && styles.disabled]}>
          {label}
        </Text>
      </View>
    </Pressable>
  )
  }
)