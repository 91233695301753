import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = ({
  tickContainerSize = 1.125
}) => {
  const { COLORS, fontSemiBold, bp } = STYLES.useStyles()

  const container = {
    backgroundColor: COLORS.whisper,
    borderRadius: getREM(0.75),
    paddingTop: getREM(0.5),
    paddingBottom: getREM(0.5),
    paddingLeft: getREM(0.75),
    paddingRight: getREM(0.75),
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  } as ViewStyle

  const container_active = {
    backgroundColor: COLORS.pastelPink
  } as ViewStyle

  const buttonContainer = {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  } as ViewStyle

  const buttonText = {
    width: '100%',
    height: '100%',
    borderRadius: getREM(0.75)
  } as ViewStyle

  const tickContainer = {
    borderRadius: tickContainerSize,
    width: tickContainerSize,
    height: tickContainerSize,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.aubergine
  } as ViewStyle

  const text = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    color: COLORS.aubergine,
    marginLeft: getREM(0.375),
    marginRight: getREM(0.375)
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.s
    text.lineHeight = STYLES.lineHeight.s
  }

  const styles = StyleSheet.create({
    container,
    container_active,
    buttonContainer,
    buttonText,
    tickContainer,
    text
  })

  return { styles }
}
