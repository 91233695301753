import { Commitment } from 'models'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

interface Props {
  showCompleted: boolean
}

export const useCommitments = ({ showCompleted }: Props) => {
  const { i18n } = useTranslation()

  const _getCommitments: () => Promise<Commitment[]> = async () => {
    return (await Commitment.includes('action').select(['state']).extraParams({ paginate: false, locale: i18n.language }).all()).data
  }

  const { data: commitments } = useQuery(['data', 'commitments'], _getCommitments, { enabled: showCompleted })

  return { commitments }
}