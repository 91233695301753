import React, { memo } from 'react'
import { SvgImage } from 'components'

export interface ShareableProps {
  text: string
  url: string
}

export type SocialShareTarget = {
  name: string
  color: string
  icon: typeof SvgImage
  onClick: (shareable: ShareableProps) => Promise<void>
}

export const FACEBOOK: SocialShareTarget = {
  name: 'facebook',
  color: '#4267B2',
  icon: memo(() => <SvgImage image={require('images/Social/Facebook.svg')} />),
  onClick: async (shareable: ShareableProps) => {
    window?.open?.(
      `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
        shareable.url
      )}`
    )
  }
}

export const TWITTER: SocialShareTarget = {
  name: 'twitter',
  color: '#1DA1F2',
  icon: memo(() => <SvgImage image={require('images/Social/Twitter.svg')} />),
  onClick: async (shareable: ShareableProps) => {
    window?.open?.(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareable.url
      )}&text=${shareable.text}`
    )
  }
}

export const WHATSAPP: SocialShareTarget = {
  name: 'whatsapp',
  color: '#25D366',
  icon: memo(() => <SvgImage image={require('images/Social/Whatsapp.svg')} />),
  onClick: async (shareable: ShareableProps) => {
    window?.open?.(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareable.text
      )}%20${encodeURIComponent(shareable.url)}`
    )
  }
}

export const LINKEDIN: SocialShareTarget = {
  name: 'linkedin',
  color: '#0077B5',
  icon: memo(() => <SvgImage image={require('images/Social/Linkedin.svg')} />),
  onClick: async (shareable: ShareableProps) => {
    window?.open?.(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        shareable.url
      )}`
    )
  }
}

export const EMAIL: SocialShareTarget = {
  name: 'email',
  color: 'gray',
  icon: memo(() => <SvgImage image={require('images/Social/Email.svg')} />),
  onClick: async (shareable: ShareableProps) => {
    window?.open?.(
      `mailto:?body=${encodeURIComponent(
        shareable.text
      )}%20${encodeURIComponent(shareable.url)}`
    )
  }
}

export const socialMediaTargets = [FACEBOOK, TWITTER, WHATSAPP, LINKEDIN, EMAIL]
