import chunk from 'lodash/chunk'

export const Sizes = {
  DEFAULT: 'default',
  BIG: 'big',
  HUGE: 'huge'
}

export const dotsNumber: Record<'default' | 'big' | 'huge', { [key: number]: number }> = {
  default: {
    1: 16,
    2: 16,
    3: 15,
    4: 16,
    5: 15,
    6: 18,
    7: 14,
    8: 16,
    9: 18,
    10: 20
  },
  big: {
    1: 24,
    2: 24,
    3: 24,
    4: 24,
    5: 25,
    6: 24,
    7: 28,
    8: 24,
    9: 27,
    10: 30
  },
  huge: {
    1: 24,
    2: 24,
    3: 24,
    4: 24,
    5: 25,
    6: 24,
    7: 28,
    8: 24,
    9: 27,
    10: 30
  }
}

export const dot: {
  radius: Record<'default' | 'big' | 'huge', number>,
  diameter: Record<'default' | 'big' | 'huge', number>
} = {
  radius: {
    default: 3.5,
    big: 4,
    huge: 8
  },
  diameter: {
    default: 7,
    big: 8,
    huge: 16
  }
}

export const pieceOffset: Record<'default' | 'big' | 'huge', { offset: number, rotate: number }> = {
  default: {
    offset: 10,
    rotate: 80
  },
  big: {
    offset: 12,
    rotate: 83
  },
  huge: {
    offset: 20,
    rotate: 84
  }
}

export const circleRadius: {
  points: Record<'default' | 'big' | 'huge', number>,
  lines: Record<('default' | 'big' | 'huge'), number>
} = {
  points: {
    default: 34 - dot.radius.default,
    big: 52.5 - dot.radius.big,
    huge: 102 - dot.radius.huge
  },
  lines: {
    default: 34,
    big: 52.5,
    huge: 102
  }
}

export const iconSize: Record<'default' | 'big' | 'huge', number> = {
  default: 68,
  big: 105,
  huge: 204
}

export interface PointsGroupProps {
  pointx: number
  pointy: number
  dotDiameter: number
  dotRadius: number
}

export const calculatePoints = (improvements: string[], size: 'default' | 'big' | 'huge'): PointsGroupProps[][] => {
  const improvementsLength = improvements.length
  const radius: number = circleRadius.points[size]
  const dotRadius: number = dot.radius[size]
  const dotDiameter = dot.diameter[size]
  const dotOffset = radius + dotRadius

  const numberOfDots = dotsNumber[size][improvementsLength] ?? 0
  const alpha = (Math.PI * 2) / numberOfDots
  const angle = (90 * Math.PI) / 180

  const h = Math.sqrt(Math.pow(radius, 2) - Math.pow(dotRadius / 2, 2))
  const a = Math.asin(h / radius)
  const b = Math.PI - 2 * a

  const points = []
  let index = 1

  while (index <= numberOfDots) {
    const theta = alpha * index - angle - b * 2

    const pointx = Math.round(Math.cos(theta) * radius) + dotOffset
    const pointy = Math.round(Math.sin(theta) * radius) + dotOffset

    points.push({ pointx, pointy, dotDiameter, dotRadius })
    index += 1
  }

  const data = chunk(points, numberOfDots / improvementsLength)

  return data
}

export interface LineBorderProps {
  circumference: number
  rotate: number
  offset: number
  radius: number
}

export const calculateLine = (
  length: number,
  index: number,
  size: 'default' | 'big' | 'huge'
): LineBorderProps => {
  const radius: number = circleRadius.points[size]
  const circumference: number = radius * 2 * Math.PI
  const offset =
    length === 1
      ? 0
      : circumference -
      (10 / length / 10) * circumference +
      (pieceOffset[size].offset as number)
  const pieceIndex = index + 1
  const rotate = (360 / length) * pieceIndex - pieceOffset[size].rotate

  const data = {
    circumference,
    offset,
    radius,
    rotate
  }

  return data
}
