import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class Estimate extends ApplicationRecord {
  static jsonapiType = 'estimates'

  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) description?: string
}
