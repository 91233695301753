import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { bp, spacing } = STYLES.useStyles()

  const container = {
    flexDirection: 'row'
  } as ViewStyle

  if (bp.not([bp.mobile_xl, bp.tablet, bp.desktop])) {
    container.flexDirection = 'column'
  }

  const buttonGroup = {
    flex: 1,
    flexDirection: 'row',
    maxWidth: STYLES.grid.maxInputWidth
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    buttonGroup.flexDirection = 'column'
  }

  const buttonGroupItem = {} as ViewStyle

  if (bp.is([bp.mobile_normal, bp.mobile_m, bp.mobile_l])) {
    buttonGroupItem.flex = 1
  }

  const modalCloseButtonContainer = {
    flexGrow: 1
  } as ViewStyle

  const modalCloseButtonText = {
    ...StyleSheet.absoluteFillObject
  } as ViewStyle

  const modalButtons = {
    flexDirection: 'row',
    marginBottom: spacing.l
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m, bp.mobile_l])) {
    modalButtons.flexDirection = 'column'
  }

  const styles = StyleSheet.create({
    container,
    buttonGroup,
    buttonGroupItem,
    modalCloseButtonContainer,
    modalCloseButtonText,
    modalButtons
  })

  return { styles }
}
