import paragraph from './Paragraph'
import em from './EM'
import strong from './Strong'
import link from './Link'
import heading from './Heading'
import list from './List'
import small from './Small'

export default {
  paragraph,
  em,
  strong,
  link,
  heading,
  list,
  small
}
