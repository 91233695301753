import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const LeftChevron = memo(({
  size = getREM(1),
  color,
  style,
  ...props
}: IconProps) => {
  const { colors } = STYLES.useStyles()

  return (
    <SVG.Svg
      testID="LeftChevron"
      viewBox="0 0 14 8"
      fill="none"
      style={[
        {
          width: size,
          height: size
        },
        style,
        {
          transform: [{ rotate: '90deg' }]
        }
      ]}
      {...props}>
      <SVG.Path d="M1 1L6.55556 6L12.1111 0.999999" stroke={color ?? colors.text} strokeWidth="2" strokeLinecap="round" />
    </SVG.Svg>
  )
})
