import React, { memo } from 'react'
import { SVG } from 'components'
import { STYLES, getREM } from 'styles'
import { IconProps } from '../index'

export const Tree = memo(
  ({ size = getREM(1.0625), color, style, ...props }: IconProps) => {
    const { colors } = STYLES.useStyles()

    return (
      <SVG.Svg
        testID="Tree"
        style={[
          {
            width: size,
            height: size
          },
          style
        ]}
        viewBox="0 0 14 17"
        {...props}>
        <path
          fill={color ?? colors.text}
          d="M10.88 7.71h.07a.83.83 0 0 0 .68-1.31L7.4.4a.83.83 0 0 0-1.37 0l-4.2 6a.84.84 0 0 0 .7 1.31L.14 11.43a.83.83 0 0 0 .7 1.28h4.23v1.68a1.65 1.65 0 1 0 3.3 0v-1.68h4.3a.83.83 0 0 0 .69-1.29l-2.48-3.71Z"
        />
      </SVG.Svg>
    )
  }
)
