import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { AviationEstimate } from './AviationEstimate'
import { PeopleAviationFootprint } from './PeopleAviationFootprint'

@Model()
export class PeopleAviationFootprintEstimate extends ApplicationRecord {
  static jsonapiType = 'people_aviation_footprint_estimates'

  @Attr() quantity!: number

  @BelongsTo(PeopleAviationFootprint) footprint!: PeopleAviationFootprint

  @BelongsTo(AviationEstimate) estimate!: AviationEstimate
}
