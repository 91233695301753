import { useQuery } from 'react-query'
import { Organization, Team } from 'models'
import { IController } from 'utils'
import { ProProgressGroupType } from '../index'

export interface IProProgressGroupController {
  data: Organization | Team | undefined
  isLoading: boolean
}

const fetchOrganization = async ({
  organizationId
}: {
  organizationId: string
}) => {
  const { data } = await Organization.selectExtra([
    'score',
    'meta_commitments',
    'kg_co2e_emissions',
    'meta_reductions',
    'meta_co2e_reduction_sections',
    'total_steps'
  ])
    .select(['name', 'logo', 'banner'])
    .find(organizationId)
  return data
}

const fetchTeam = async ({ teamId }: { teamId: string }) => {
  const { data } = await Team.selectExtra([
    'score',
    'meta_commitments',
    'kg_co2e_emissions',
    'meta_reductions',
    'meta_co2e_reduction_sections',
    'total_steps'
  ])
    .select(['name', 'logo', 'banner'])
    .find(teamId)
  return data
}

export class ProProgressGroupController
  implements IController<IProProgressGroupController>
{
  useController = ({
    id,
    type
  }: {
    id: string
    type: ProProgressGroupType
    }) => {
    if (ProProgressGroupType.Organization === type) {
      const { isLoading, data } = useQuery(
        ['data', ProProgressGroupType.Organization],
        () => fetchOrganization({ organizationId: id })
      )

      return {
        data,
        isLoading
      }
    }

    if (ProProgressGroupType.Team === type) {
      const { isLoading, data } = useQuery(
        ['data', ProProgressGroupType.Team],
        () => fetchTeam({ teamId: id })
      )

      return {
        data,
        isLoading
      }
    }

    return {
      data: undefined,
      isLoading: false
    }
  }
}
