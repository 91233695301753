import { useState, useEffect } from 'react'
import { AdminListTypes } from '../index'
import { useQuery } from 'react-query'
import { getData } from './getData'
import { createTeams } from './createTeams'
import { removeItems } from './removeItems'
import { updateItems } from './updateItems'
import { updateMembershipRequests } from './updateMembershipRequests'

export const itemsPerPage = 9

export interface Props {
  organizationId?: string
  teamId?: string
  type: AdminListTypes
}

export class AdminListController {
  _getData = getData

  useController = ({ type, organizationId, teamId }: Props) => {
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState('')

    const {
      isLoading,
      data,
      refetch
    } = useQuery(
      ['data', 'admin', 'list', type, searchValue, currentPage],
      () => this._getData({
        type,
        searchValue,
        currentPage,
        organizationId,
        teamId,
        itemsPerPage
      }),
      {
        keepPreviousData: true
      }
    )

    useEffect(() => {
      setSelectedItems([])
    }, [currentPage])

    return {
      isLoading,
      data: data?.data || [],
      selectedItems,
      setSelectedItems,
      totalItems: data?.meta?.stats?.total?.count || 0,
      currentPage,
      setCurrentPage,
      setSearchValue,
      refetch,
      createTeams,
      removeItems,
      updateItems,
      updateMembershipRequests,
      hasSearchValue: !!searchValue
    }
  }
}