import { StyleSheet, TextStyle, ViewStyle, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'
import { useSharedStyles } from 'components/Button/Label/styles/useSharedStyles'
import { SkateboardWithSpeechBubbleHeightRatio, SkateboardHeightRatio } from 'images'

export const useStyles = () => {
  const {
    colors,
    COLORS,
    fontBold,
    fontRegular,
    spacing,
    bp
  } = STYLES.useStyles()
  const { sharedStyles } = useSharedStyles()

  const mobile = bp.is([
    bp.mobile_xs,
    bp.mobile_s,
    bp.mobile_normal,
    bp.mobile_m,
    bp.mobile_l
  ])

  const card = {
    padding: spacing.normal,
    width: '100%',
    borderRadius: getREM(0.625),
    flexShrink: 1,
    borderWidth: 1,
    borderColor: '#e1e3e6',
    backgroundColor: '#f4faf6',
    flexDirection: 'row'
  } as ViewStyle

  if (mobile) {
    card.flexDirection = 'column'
  }

  const content = {
    flex: 3
  }

  const smallImage = {
    flex: 1,
    marginRight: spacing.l,
    width: 80,
    height: 80 * SkateboardHeightRatio
  } as ImageStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal]) || !mobile) {
    smallImage.display = 'none'
  }

  const bigImage = {
    flex: 1,
    display: 'none',
    marginRight: spacing.l,
    alignSelf: 'center',
    maxWidth: 120,
    width: 120,
    height: 120 * SkateboardWithSpeechBubbleHeightRatio
  } as ImageStyle

  if (!mobile) {
    bigImage.display = 'flex'
  }

  const cta = {
    flexDirection: 'row',
    justifyContent: 'space-between'
  } as ViewStyle

  const textContainer = {
    flex: 3,
    alignSelf: 'flex-end'
  } as ViewStyle

  const title = {
    ...fontBold,
    fontSize: STYLES.fontSize.xl,
    lineHeight: STYLES.lineHeight.xl,
    color: colors.brand,
    marginBottom: spacing.s
  } as TextStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    title.textAlign = 'center'
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal, bp.mobile_m])) {
    title.fontSize = STYLES.fontSize.l
    title.lineHeight = STYLES.lineHeight.l
  }

  const explanation = {
    ...fontRegular,
    fontSize: spacing.normal,
    lineHeight: STYLES.lineHeight.normal,
    color: colors.text
  } as TextStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    explanation.textAlign = 'center'

  }

  const copyLink = {
    flexDirection: 'row',
    width: '100%',
    maxWidth: '100%',
    marginTop: spacing.normal
  } as ViewStyle

  const counter = {
    marginTop: spacing.normal,
    marginRight: spacing.normal
  } as ViewStyle

  if (mobile) {
    counter.alignSelf = 'center'
    counter.alignItems = 'center'
  }

  const socialButtons = {
    marginTop: spacing.normal
  } as ViewStyle

  if (mobile) {
    socialButtons.alignSelf = 'center'
  }

  const shareStepButton = {
    marginTop: spacing.normal
  } as ViewStyle

  const shareIconStyle = {
    marginLeft: spacing.xs
  } as ViewStyle

  const shareStepButtonContainer = {
    alignSelf: mobile
      ? 'center'
      : 'flex-start'
  } as ViewStyle

  const shareStepButtonText = {
    display: 'flex',
    backgroundColor: COLORS.cherry,
    color: COLORS.white,
    width: 'auto',
    flexDirection: 'row-reverse',
    borderColor: 'transparent'
  } as TextStyle

  const shareStepButtonInnerText = {
    ...sharedStyles.label,
    color: COLORS.white,
    paddingLeft: sharedStyles.label_small.paddingLeft
  } as TextStyle

  const counterIcons = {
    flexDirection: 'column'
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    counterIcons.flexDirection = 'row'
    counterIcons.flexWrap = 'wrap'
  }

  const styles = StyleSheet.create({
    card,
    content,
    cta,
    textContainer,
    smallImage,
    bigImage,
    title,
    explanation,
    copyLink,
    counter,
    socialButtons,
    shareStepButton,
    shareIconStyle,
    shareStepButtonContainer,
    shareStepButtonText,
    shareStepButtonInnerText,
    counterIcons
  })

  return { styles }
}
