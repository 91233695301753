import React, { useState, useMemo } from 'react'
import { Text, ViewProps, Platform } from 'react-native'
import { useStyles } from './styles'
import {
  Heading,
  View,
  Select,
  SelectType,
  Button,
  ButtonType,
  TextInput,
  ShowMessage,
  MessageType
} from 'components'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { AssignUsersToTeamController } from './AssignUsersToTeamController'
import { GetEmailsFromString } from 'utils'

export interface Props {
  organizationId: string
  teamId?: string
  controller?: AssignUsersToTeamController
  style?: ViewProps['style']
}

export const AssignUsersToTeam = ({ organizationId, teamId, controller = new AssignUsersToTeamController(), style }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const [_teamId, _setTeamId] = useState<undefined | string>(teamId)
  const [_value, _setValue] = useState('')

  const _hasTeamId = !!teamId

  const { isLoading, teams, assignUsers } = controller.useController({
    organizationId,
    enableGetTeams: !_hasTeamId
  })

  const _isDisabled =
    isLoading || _value.length === 0 || typeof _teamId === 'undefined'

  const _teams = useMemo(() => {
    return (
      teams?.map((team) => ({
        label: team.name,
        id: team.id
      })) || []
    )
  }, [teams])

  const _hasTeams = _teams.length > 0

  const _onSelect = (id: string) => {
    _setTeamId(id)
  }

  const _onPress = async () => {
    /* istanbul ignore next */
    if (!_teamId) return

    const { isValid, row, emails } = GetEmailsFromString(_value)

    if (!isValid) {
      ShowMessage({
        text: t('assignUsersToTeam:emailError', {
          email: emails[row],
          row_number: row + 1
        })
      })

      return
    }

    /* istanbul ignore next */
    const _isSuccess = await assignUsers(_teamId, emails).catch(() => false)

    if (_isSuccess) {
      ShowMessage({
        text: t('assignUsersToTeam:success'),
        type: MessageType.notice
      })

      _setValue('')
    } else {
      ShowMessage({
        text: t('assignUsersToTeam:error')
      })
    }
  }

  const _onChangeText = (value: string) => {
    _setValue(value)
  }

  let _placeholder: undefined | string = t('assignUsersToTeam:emailPlaceholder')

  // safari < 16 does not support multi-line placeholder so reduce down to a single line
  // text-align-last css property was added in safari 16 so can be used for browser detection
  /* istanbul ignore next */
  try {
    if (
      (Platform.OS === 'web' &&
        'text-align-last' in document.body.style === false) ||
      (Platform.OS === 'ios' && parseInt(Platform.Version) < 16)
    ) {
      _placeholder = t('assignUsersToTeam:emailPlaceholder').split('\n')[0]
    }
  } catch (e) {
    //
  }

  return (
    <View style={[styles.container, style]} testID="AssignUsersToTeam">
      <Heading level={3} style={styles.title}>
        {t('assignUsersToTeam:title')}
      </Heading>
      <Text style={styles.text}>
        {_hasTeamId
          ? t('assignUsersToTeam:descriptionSingleTeam')
          : t('assignUsersToTeam:descriptionMultiTeams')}
      </Text>
      <TextInput
        label={t('assignUsersToTeam:emailLabel')}
        hideLabel
        multiline
        placeholder={_placeholder}
        containerStyle={styles.inputContainer}
        style={styles.input}
        onChangeText={_onChangeText}
        value={_value}
      />
      <View style={styles.actions}>
        {!_hasTeamId && (
          <Select
            label={t('assignUsersToTeam:teamLabel')}
            items={_teams}
            hideLabel
            emptyLabel={t('assignUsersToTeam:teamEmptyLabel')}
            onSelect={_onSelect}
            disabled={!_hasTeams}
            selectedId={_teamId}
            style={styles.select}
            type={SelectType.outline}
          />
        )}
        <Button
          label={t('assignUsersToTeam:buttonLabel')}
          type={ButtonType.brand}
          textStyle={styles.buttonText}
          onPress={_onPress}
          state={{
            disabled: _isDisabled
          }}
        />
      </View>
    </View>
  )
}
