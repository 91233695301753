import { StyleSheet, ViewStyle, Platform, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { bp, paragraph } = STYLES.useStyles()

  const container = {
    width: '100%',
    maxWidth: getREM(31.5625)
  } as ViewStyle

  const bubble = {
    borderRadius: getREM(1.25),
    padding: getREM(1.25),
    marginBottom: getREM(2.25),
    position: 'relative'
  } as ViewStyle

  if (bp.mobile_xs) {
    bubble.borderRadius = getREM(0.5)
    bubble.padding = getREM(0.5)
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    bubble.padding = getREM(1.875)
  }

  const triangle = {
    position: 'absolute',
    zIndex: 0,
    bottom: getREM(-1.663125),
    right: getREM(1.5625),
    width: getREM(2.5),
    height: getREM(2.163125)
  } as ViewStyle

  const pill = {
    marginBottom: getREM(1)
  } as ViewStyle

  const textContainer = {
    marginBottom: Platform.OS === 'web' ? `calc(${paragraph.fontSize} * -2)` : paragraph.fontSize * -2
  } as ViewStyle

  const characterContainer = {
    width: getREM(5.625),
    height: getREM(5.625),
    borderRadius: getREM(2.8125),
    overflow: 'hidden',
    position: 'relative',
    alignSelf: 'flex-end'
  } as ViewStyle

  const character = {
    position: 'absolute',
    left: getREM(0.5),
    top: getREM(1),
    width: '100%',
    height: '121%'
  } as ImageStyle

  const styles = StyleSheet.create({
    container,
    bubble,
    triangle,
    pill,
    textContainer,
    characterContainer,
    character
  })

  return { styles }
}
