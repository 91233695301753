import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { COLORS, bp, spacing, fontRegular, fontBold } = STYLES.useStyles()

  const container = {
    width: '100%'
  } as ViewStyle

  const textContainer = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: spacing.xs
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    textContainer.flexDirection = 'column'
  }

  const title = {
    ...fontBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: COLORS.aubergine
  } as TextStyle

  const description = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: COLORS.aubergine
  } as TextStyle

  const barContainer = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  } as ViewStyle

  const item = {
    backgroundColor: COLORS.mushroom,
    height: getREM(0.5),
    borderRadius: getREM(0.25),
    flex: 1,
    overflow: 'hidden',
    position: 'relative'
  } as ViewStyle

  if (bp.tablet) {
    item.height = getREM(0.625)
    item.borderRadius = getREM(0.3125)
  }

  if (bp.desktop) {
    item.height = getREM(0.78375)
    item.borderRadius = getREM(0.391875)
  }

  const item_gap = {
    marginLeft: getREM(0.625)
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    item_gap.marginLeft = getREM(1)
  }

  const itemBar = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: COLORS.aubergine
  } as ViewStyle

  const value = {
    position: 'absolute',
    left: getREM(-99999),
    fontSize: 0
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    textContainer,
    title,
    description,
    barContainer,
    item,
    item_gap,
    itemBar,
    value
  })

  return { styles }
}
