import { ComponentProps } from 'react'
import { Action } from 'models'
import { Button } from 'components'

export interface StepButtonProps extends Partial<ComponentProps<typeof Button>> {
  action?: Action
}

export * from './LearnMore'
export * from './Succeed'
export * from './Abandon'
export * from './Commit'
export * from './Current'
export * from './Suppress'
export * from './TrackingContext'
