import { STYLES } from 'styles'
import { Platform } from 'react-native'
import { TypeStylesProps } from './index'

export const useAlternative = () => {
  const { colors, boxShadows } = STYLES.useStyles()

  const alternative = {
    nativeContainer: {
      ...boxShadows.button.ios
    },
    label: {
      textDecorationLine: 'none',
      backgroundColor: colors.alternative,
      color: colors.brandContrast,
      ...Platform.select({
        web: {
          border: 0,
          cursor: 'pointer'
        }
      }),
      ...boxShadows.button.web,
      ...boxShadows.button.android
    },
    pointerOver: {
      ...boxShadows.button_pointerOver.web
    }
  } as TypeStylesProps

  return { alternative }
}
