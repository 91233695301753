import { PersonalChecklistItem } from "models"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"
import { IController } from "utils"
import { IToDoListGroupController, SetCheckboxCheckedProps } from "./interfaces"
import { User } from 'models'

export class ToDoListGroupController implements IController<IToDoListGroupController> {
  useController = () => {
    const { i18n } = useTranslation()
    const queryClient = useQueryClient()

    const queryKey = ['data', 'todolist', i18n.language]

    const _getUserCommitments = async (locale: string) => {
      const { data } = await User.where({ 'person.commitments.state': 'pending' })
        .includes([
          {
            person: [
              {
                commitments: [
                  { action: ['impact', { themes: 'theme' }] },
                  { checklistItems: 'action' }
                ]
              },
              { personalChecklistItems: 'checklistItem' }
            ]
          }
        ])
        .select({
          users: [''],
          people: [
            'pendingActionsCount'
          ],
          commitments: [''],
          personalChecklistItems: ['completed'],
          actionsChecklistItems: ['description', 'order'],
          actions: ['name', 'param'],
          actionsThemes: [''],
          impacts: [
            'name',
            'lookupSlug',
            'colorForeground',
            'colorBackground'
          ],
          themes: [
            'name',
            'lookupSlug',
            'colorForeground',
            'colorBackground'
          ]
        })
        .extraParams({
          locale
        })
        .find('current')
      return data
    }

    const { isLoading, data, refetch } = useQuery(queryKey, () =>
      _getUserCommitments(i18n.language)
    )

    const setCheckboxChecked = async ({
      commitmentId,
      checklistItemId,
      checked
    }: SetCheckboxCheckedProps) => {
      /* istanbul ignore next */
      if (!data) return true

      if (!checked) {
        // destroy
        const _personalChecklistItem = data.person.personalChecklistItems.find(
          (item) => item.checklistItem.id === checklistItemId
        )

        /* istanbul ignore next */
        if (_personalChecklistItem) {
          await _personalChecklistItem.destroy()

          await queryClient.invalidateQueries(queryKey)
        }
      } else {
        // create
        const _commitment = data.person.commitments.find(
          (item) => item.id === commitmentId
        )

        const _checklistItem = _commitment?.checklistItems.find(
          (item) => item.id === checklistItemId
        )

        /* istanbul ignore next */
        if (_checklistItem) {
          const _personalChecklistItem = new PersonalChecklistItem({
            checklist_item: _checklistItem
          })

          await _personalChecklistItem.save({
            with: 'checklistItem.id'
          })

          await queryClient.invalidateQueries(queryKey)
        }
      }

      return true
    }

    useEffect(() => {
      refetch()
    }, [i18n.language, refetch])

    return {
      isLoading: isLoading,
      user: data,
      setCheckboxChecked
    }
  }
}
