import React, { memo, forwardRef, useContext } from 'react'
import { Button, ButtonType, ShowMessage } from 'components'
import { useStepLang } from 'locales'
import { useTranslation } from 'react-i18next'
import { CommitmentState } from 'models'
import {
  useErrorMessage,
  useAnalytics,
  useInvalidateDataQueries,
  GetCommitment,
  DestroySuppression,
  AuthContext
} from 'utils'
import { TrackingContext } from '../TrackingContext'
import { StepButtonProps } from '../index'

export const Abandon = memo(
  forwardRef(({ action, ...props }: StepButtonProps, ref) => {
    useStepLang()

    const { t } = useTranslation()
    const { showErrorMessage } = useErrorMessage()
    const { trackEvent, trackingEvents } = useAnalytics()
    const { invalidateData } = useInvalidateDataQueries()
    const { isAuthenticated } = useContext(AuthContext)
    const { source } = useContext(TrackingContext)

    const _onPress = async () => {
      const _commitment = await GetCommitment(action?.id)

      if (!_commitment) {
        showErrorMessage()
        return
      }

      await DestroySuppression(action!.id)

      _commitment.state = CommitmentState.abandoned

      const _success = await _commitment.save()

      if (!_success) {
        showErrorMessage()
        return
      }

      ShowMessage({
        text: t('step:messages.abandon'),
        isCloseable: true
      })

      await invalidateData()

      trackEvent({
        eventName: trackingEvents.updateCommitment,
        props: {
          'commitment state': CommitmentState.abandoned,
          'action param': action!.param
        }
      })

      trackEvent({
        eventName: trackingEvents.stepsUserFlow,
        props: {
          source,
          type: 'abandon',
          'action param': action!.param
        }
      })
    }

    if (!action || !isAuthenticated) return null

    return (
      <Button
        ref={ref}
        onPress={_onPress}
        testID="Abandon"
        type={ButtonType.normal}
        label={t('step:buttons.abandon')}
        {...props}
      />
    )
  })
)
