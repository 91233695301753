import React, { memo } from 'react'
import {
  View,
  ViewProps,
  Platform,
  AccessibilityRole
} from 'react-native'
import { useStyles } from './styles'

export interface Props extends ViewProps {
  legend: string
}

export const FieldSet = memo(({ children, style, legend, ...props }: Props) => {
  const { styles } = useStyles()

  const _accessibility =
    Platform.OS === 'web'
      ? {
        accessibilityRole: 'group' as AccessibilityRole
      }
      : null

  return (
    <View style={[styles.container, style]} testID="FieldSet" accessibilityLabel={legend} {..._accessibility} {...props}>
      {children}
    </View>
  )
})
