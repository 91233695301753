import React, { useState, useEffect, useRef, memo, useMemo } from 'react'
import {
  TextInput,
  ScrollView,
  View,
  Text,
  Keyboard
} from 'react-native'
import { useStyles } from './styles'
import { Field, Button, ButtonType } from 'components'
import { GenerateId, WebAccessibilityRole } from 'utils'

interface ItemProps {
  id: string
  label: string
}

export interface Props extends React.ComponentProps<typeof Field> {
  items: ItemProps[]
  onSelect: (id: string) => void
  disabled?: boolean
  selectedId?: string
  noResultsMessage: string
}

export const Combobox = memo(
  ({
    items,
    description,
    label,
    onSelect,
    disabled,
    selectedId,
    noResultsMessage,
    style,
    ...props
  }: Props) => {
    const [_isPointerOver, _setIsPointerOver] = useState<number | null>(null)
    const { styles } = useStyles()
    const _descriptionId = useMemo(
      () => (description ? GenerateId() : undefined),
      [description]
    )
    const _listId = useMemo(() => GenerateId(), [])
    const [_selectedId, _setSelectedId] = useState(selectedId)
    const _selectedItem = items?.find((item) => item.id === _selectedId)
    const [_isFocused, _setIsFocused] = useState(false)
    const [_value, _setValue] = useState(_selectedItem?.label || '')
    const _inputRef = useRef(null)
    const [_showList, _setShowList] = useState(true)

    const _filteredItems =
      items?.filter((item) => {
        return item.label.toLowerCase().includes(_value.toLowerCase())
      }) || []

    const _onSelect = (id: string) => {
      _setSelectedId(id)
      _setValue(items?.find((item) => item.id === id)!.label)
      _setShowList(false)
      onSelect?.(id)
      /* istanbul ignore next */
      if (_isFocused) Keyboard?.dismiss()
    }

    const _onChangeText = async (value: string) => {
      if (disabled) return

      _setValue(value)
      _setSelectedId(undefined)
      _setShowList(true)
    }

    const _onSubmitEditing = () => {
      if (disabled) return

      if (_filteredItems.length === 1) {
        _onSelect(_filteredItems[0]!.id)
      }
    }

    const _onFocus = () => {
      _setIsFocused(true)
    }

    const _onBlur = () => {
      _setIsFocused(false)
    }

    /* istanbul ignore next */
    const _onPointerEnter = (index: number) => {
      _setIsPointerOver(index)
    }
    /* istanbul ignore next */
    const _onPointerLeave = () => {
      _setIsPointerOver(null)
    }

    useEffect(() => {
      if (_selectedId !== selectedId) {
        _setSelectedId(selectedId)
      }
    }, [selectedId, _selectedId])

    return (
      <Field
        {...props}
        style={[styles.container, style]}
        testID="Combobox"
        description={description}
        label={label}
        descriptionId={_descriptionId}>
        <TextInput
          ref={_inputRef}
          style={[
            styles.textInput,
            disabled && styles.disabled,
            _isFocused && styles.focused
          ]}
          accessibilityLabel={label}
          accessibilityHint={description}
          accessibilityState={{ disabled: disabled }}
          accessibilityRole="combobox"
          autoCapitalize="none"
          returnKeyType="done"
          editable={!disabled}
          enablesReturnKeyAutomatically={true}
          onChangeText={_onChangeText}
          onSubmitEditing={_onSubmitEditing}
          autoCorrect={false}
          onFocus={_onFocus}
          onBlur={_onBlur}
          defaultValue={_selectedItem?.label}
          value={_value}
          /* 
          The following properties are written this way otherwise the following error is shown:
          > Property '...' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes & Readonly'.ts(2769)
          The property does exist so might work in newer versions of @types dependencies in the future.
        */
          {...{
            accessibilityDescribedBy: _descriptionId,
            accessibilityDisabled: disabled,
            accessibilityControls: _listId
          }}
        />
        <View
          style={[
            styles.list,
            {
              display: _showList ? 'flex' : 'none'
            }
          ]}
          nativeID={_listId}>
          <ScrollView
            keyboardShouldPersistTaps="always"
            keyboardDismissMode="on-drag">
            {_filteredItems?.map((item, index) => {
              const _isSelected = item.id === _selectedId
              const _onPressItem = () => {
                _onSelect(item.id)
              }

              /* istanbul ignore next */
              const _onPointerEnterItem = () => {
                _onPointerEnter(index)
              }

              return (
                <Button
                  key={item.id}
                  label={item.label}
                  onPress={_onPressItem}
                  textStyle={[
                    styles.listItemText,
                    _isSelected && styles.listItemText_selected,
                    index === _filteredItems.length - 1 &&
                      styles.listItemText_last,
                    /* istanbul ignore next */
                    _isPointerOver === index && styles.listItemText_pointerOver
                  ]}
                  type={ButtonType.blank}
                  state={{
                    selected: _isSelected
                  }}
                  accessibilityRole={WebAccessibilityRole('option')}
                  onPointerEnter={_onPointerEnterItem}
                  onPointerLeave={_onPointerLeave}>
                  {item.label}
                </Button>
              )
            })}
            {_filteredItems.length === 0 && (
              <Text style={styles.noResultsMessage}>{noResultsMessage}</Text>
            )}
          </ScrollView>
        </View>
      </Field>
    )
  }
)
