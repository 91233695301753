import { StyleSheet, ViewStyle, TextStyle, Platform, ImageStyle } from 'react-native'
import { STYLES, getOpaqueColor, getREM } from 'styles'

export const useStyles = (isFixedSize = false) => {
  const { box, colors, COLORS, bp, spacing, fontBold, fontSemiBold, fontRegular } = STYLES.useStyles()

  const _isNotFixedSize = !isFixedSize && bp.is([bp.tablet, bp.desktop_normal, bp.desktop_m])

  let _factor = 1

  if (_isNotFixedSize) {
    _factor = 1.35
  }

  let container: ViewStyle = {
    maxWidth: '100%'
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    container = {
      ...container,
      ...box
    }
  }

  const background: ViewStyle = {
    width: 368 * _factor,
    height: 265 * _factor,
    alignSelf: 'center'
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    background.display = 'none'
  }

  const backgroundImage: ImageStyle = {
    width: '100%',
    height: '100%'
  }

  const cloudPurple: ViewStyle = {
    position: 'absolute',
    top: 2 * _factor,
    left: 0 * _factor,
    width: 368 * _factor,
    height: 242 * _factor
  }

  const leafSmall1: ViewStyle = {
    position: 'absolute',
    top: 18 * _factor,
    left: 269 * _factor,
    width: 55 * _factor,
    height: 48 * _factor
  }

  const leafBig1: ViewStyle = {
    position: 'absolute',
    top: 50 * _factor,
    left: 275 * _factor,
    width: 86 * _factor,
    height: 30 * _factor
  }

  const leafSmall2: ViewStyle = {
    position: 'absolute',
    top: 211 * _factor,
    left: 46 * _factor,
    width: 62 * _factor,
    height: 53 * _factor
  }

  const leafBig2: ViewStyle = {
    position: 'absolute',
    top: 197 * _factor,
    left: 9 * _factor,
    width: 94 * _factor,
    height: 36 * _factor
  }

  const cloudWhite: ViewStyle = {
    position: 'absolute',
    top: 14 * _factor,
    left: 23 * _factor,
    width: 322 * _factor,
    height: 221 * _factor
  }

  const butterfly: ViewStyle = {
    position: 'absolute',
    top: 36 * _factor,
    left: 23 * _factor,
    width: 67 * _factor,
    height: 55 * _factor
  }

  const content: ViewStyle = {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  }

  if (bp.is([bp.mobile_xs, bp.mobile_s, bp.mobile_normal])) {
    content.position = 'relative'
  }

  const title: TextStyle = {
    ...fontBold,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m,
    color: colors.alternative,
    textAlign: 'center',
    marginTop: spacing.xs
  }

  if (_isNotFixedSize) {
    title.fontSize = STYLES.fontSize.l
    title.lineHeight = STYLES.lineHeight.l
  }

  const value: TextStyle = {
    fontSize: STYLES.fontSize.xxl,
    color: colors.brand,
    ...Platform.select({
      web: {
        lineHeight: `calc(${STYLES.fontSize.xxl} * 1.2)` as unknown as TextStyle['lineHeight']
      },
      default: {
        lineHeight: STYLES.fontSize.xxl * 1.2
      }
    })
  }

  const unit: TextStyle = {
    fontSize: STYLES.fontSize.normal,
    color: colors.brand
  }

  const text: TextStyle = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: colors.brand,
    textAlign: 'center'
  }

  if (_isNotFixedSize) {
    text.fontSize = STYLES.fontSize.normal
    text.lineHeight = STYLES.lineHeight.normal
  }

  const button: ViewStyle = {
    marginTop: spacing.xs
  }

  const comparisonContainer: ViewStyle = {
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
    borderRadius: getREM(1.15625),
    marginTop: spacing.xs,
    maxWidth: getREM(13 * _factor)
  }

  const comparisonContainer_less: ViewStyle = {
    backgroundColor: getOpaqueColor(
      COLORS.atlantis,
      0.2
    )
  }

  const comparisonContainer_equal: ViewStyle = {
    backgroundColor: getOpaqueColor(
      COLORS.cherry,
      0.2
    )
  }

  const comparisonContainer_more: ViewStyle = {
    backgroundColor: getOpaqueColor(
      COLORS.cherry,
      0.2
    )
  }

  const comparisonText: TextStyle = {
    ...fontRegular,
    fontSize: STYLES.fontSize.xs,
    lineHeight: STYLES.lineHeight.xs,
    textAlign: 'center',
    color: COLORS.aubergine
  }

  const styles = StyleSheet.create({
    container,
    background,
    backgroundImage,
    cloudPurple,
    leafSmall1,
    leafBig1,
    leafSmall2,
    leafBig2,
    cloudWhite,
    butterfly,
    content,
    title,
    value,
    unit,
    text,
    button,
    comparisonContainer,
    comparisonContainer_less,
    comparisonContainer_equal,
    comparisonContainer_more,
    comparisonText
  })

  return {
    styles,
    backgroundHidden: bp.mobile_xs || bp.mobile_s || bp.mobile_normal
  }
}
