import { Model, Attr, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Organization } from './Organization'

@Model()
export class GroupMembershipOrganization extends ApplicationRecord {
  static jsonapiType = 'group_membership_organizations'

  @Attr({ persist: true }) admin!: boolean
  @Attr({ persist: false }) email!: string
  @Attr({ persist: false }) fullName!: string
  @Attr({ persist: false }) avatar!: string
  @Attr({ persist: false }) teams!: {
    name: string
    admin: boolean
  }[]

  @BelongsTo(Organization) organization!: Organization
}
