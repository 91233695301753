import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { getREM, STYLES } from 'styles'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { fontBold, COLORS, fontRegular, boxShadows } = STYLES.useStyles()
  const insets = useSafeAreaInsets()

  const container = {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    top: 0 + insets.top,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: COLORS.white,
    paddingVertical: getREM(0.25),
    paddingHorizontal: getREM(0.5),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: getREM(2.25),
    ...boxShadows.button.all
  } as ViewStyle

  const container__alert = {
    backgroundColor: COLORS.peach
  }

  const container__notice = {
    backgroundColor: COLORS.apple
  }

  const indicator = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: COLORS.white,
    height: getREM(0.125)
  } as ViewStyle

  const close = {
    ...fontBold,
    marginLeft: getREM(0.5),
    marginRight: getREM(0.125),
    justifyContent: 'center',
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s
  } as TextStyle

  const text = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    flexShrink: 1,
    alignSelf: 'center',
    textAlign: 'center',
    width: 'auto'
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    container__alert,
    container__notice,
    indicator,
    text,
    close
  })

  return { styles }
}
