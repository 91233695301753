import { SVG } from 'components'
import React from 'react'
import { useStyles } from './styles'

export interface Props {
  animate?: boolean
  animationDelay?: number
  dotDiameter?: number
  dotRadius?: number
  improvement?: string
  index?: number
  pointx?: number
  pointy?: number
}

export const Point = ({
  dotDiameter = 0,
  dotRadius,
  improvement,
  index = 0,
  pointx = 0,
  pointy = 0
}: Props) => {
  const { improvements } = useStyles()

  return (
    <SVG.Path
      key={index}
      fill={improvements[improvement ?? '']}
      d={`M ${pointx}, ${pointy}
         m -${dotRadius}, 0
         a ${dotRadius},${dotRadius} 0 1,0 ${dotDiameter},0
         a ${dotRadius},${dotRadius} 0 1,0 -${dotDiameter},0
        `}
    />
  )
}
