import { Goal, PeopleGoal } from 'models'
import { useTranslation } from 'react-i18next'

export type UseGoalType = {
  getGoals: () => Promise<Goal[] | undefined>
  getPersonGoals: () => Promise<PeopleGoal[] | undefined>
  saveGoals: (goalIds: Goal[]) => Promise<boolean | undefined>
}

export const useGoal = () => {
  const { i18n } = useTranslation()

  const getGoals: UseGoalType['getGoals'] = async () => {
    return (await Goal.select(['name']).extraParams({ paginate: false, locale: i18n.language }).all()).data
  }

  const getPersonGoals: UseGoalType['getPersonGoals'] = async () => {
    return (await PeopleGoal
      .includes('goal')
      .select({ peopleGoals: [''], goals: [''] })
      .extraParams({ paginate: false, locale: i18n.language })
      .all()
    ).data
  }

  const saveGoals: UseGoalType['saveGoals'] = async (goals) => {
    const { data: oldGoals } = await PeopleGoal.select(['']).extraParams({ paginate: false, locale: i18n.language }).all()

    if (oldGoals && oldGoals.length > 0) {
      for (let i = 0; i < oldGoals.length; i++) {
        await oldGoals[i]?.destroy()
      }
    }

    const _success = []

    if (goals.length > 0) {
      for (let i = 0; i < goals.length; i++) {
        const _newGoal = new PeopleGoal({
          goal: goals[i]
        })
        const _response = await _newGoal.save({ with: 'goal.id' })
        _success.push(_response)
      }
    }

    return _success.every((item) => item === true)
  }

  return {
    getGoals,
    getPersonGoals,
    saveGoals
  }
}