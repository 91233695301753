import { Model, Attr, HasOne } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'

@Model()
export class User extends ApplicationRecord {
  static jsonapiType = 'users'

  @Attr({ persist: false }) email!: string

  @Attr({ persist: true }) locale!: string

  @HasOne(Person) person!: Person
}
