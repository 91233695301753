import React, { memo, useState } from 'react'
import { View, ViewProps, Text } from 'react-native'
import { useStyles } from './styles'
import { Button, ButtonType, Icons } from 'components'

export interface Props extends ViewProps {
  currentItem: number
  totalItems: number
  label: string
  onPress?: (index: number) => void
  isNumbered?: boolean
  showEllipsis?: boolean
  showBackForward?: boolean
}

export const Pagination = memo(
  ({
    currentItem,
    totalItems,
    label,
    onPress,
    isNumbered = false,
    showEllipsis = false,
    showBackForward = false,
    style,
    ...props
  }: Props) => {
    const [_isPointerOver, _setIsPointerOver] = useState<null | number>(null)
    /* istanbul ignore next */
    const _onPointerEnter = (index: number) => {
      _setIsPointerOver(index)
    }
    /* istanbul ignore next */
    const _onPointerLeave = () => {
      _setIsPointerOver(null)
    }
    const _lastIndex = totalItems - 1
    const _showEllipsis = showEllipsis && _lastIndex > 5
    const hideLabel = _showEllipsis || isNumbered ? false : true
    const { styles } = useStyles({ hideLabel })

    const Ellipsis = () => <Text style={styles.ellipsis}>...</Text>

    return (
      <View style={[styles.paging, style]} testID="Pagination" {...props}>
        {showBackForward && (
          <Button
            type={ButtonType.blank}
            ariaLabel={`${label} ${
              currentItem > 1 ? currentItem - 1 + 1 : 1
            } of ${totalItems}`}
            icon={Icons.LeftChevron}
            hideLabel
            textStyle={[
              styles.pagingItem,
              /* istanbul ignore next */
              _isPointerOver === -1 &&
                currentItem !== 0 &&
                styles.pagingItem_pointerOver
            ]}
            state={{ disabled: currentItem === 0 }}
            onPress={() => onPress?.(currentItem - 1)}
            iconContainerStyle={styles.iconContainer}
            iconStyle={styles.icon}
            testID="BackButton"
            nativeContainerStyle={styles.pagingItemNativeContainer}
            onPointerEnter={
              /* istanbul ignore next */
              () => _onPointerEnter(-1)
            }
            onPointerLeave={_onPointerLeave}
          />
        )}
        {new Array(totalItems).fill('').map((_, index) => {
          const _showItem =
            !_showEllipsis ||
            index === 0 ||
            index === _lastIndex ||
            index === currentItem ||
            (currentItem < 3 && index < 4) ||
            (currentItem > _lastIndex - 2 && index > _lastIndex - 4) ||
            index === currentItem - 1 ||
            index === currentItem + 1

          const _showPreEllipsis =
            _showEllipsis &&
            index === _lastIndex &&
            currentItem < _lastIndex - 2

          const _showPostEllipsis =
            _showEllipsis && index === 0 && currentItem > 2

          if (!_showItem) return null

          return (
            <React.Fragment key={`page-${index}`}>
              {_showPreEllipsis && <Ellipsis />}
              <Button
                testID="PagingItem"
                ariaLabel={`${label} ${index + 1} of ${totalItems}`}
                label={`${index + 1}`}
                textStyle={[
                  styles.pagingItem,
                  currentItem === index && styles.pagingItem_current,
                  /* istanbul ignore next */
                  _isPointerOver === index && styles.pagingItem_pointerOver
                ]}
                onPress={() => onPress?.(index)}
                type={ButtonType.blank}
                hideLabel={hideLabel}
                nativeContainerStyle={styles.pagingItemNativeContainer}
                onPointerEnter={
                  /* istanbul ignore next */
                  () => _onPointerEnter(index)
                }
                onPointerLeave={_onPointerLeave}
              />
              {_showPostEllipsis && <Ellipsis />}
            </React.Fragment>
          )
        })}
        {showBackForward && (
          <Button
            type={ButtonType.blank}
            ariaLabel={`${label} ${
              currentItem > 1 ? currentItem + 1 + 1 : 1
            } of ${totalItems}`}
            icon={Icons.RightChevron}
            hideLabel
            textStyle={[
              styles.pagingItem,
              /* istanbul ignore next */
              _isPointerOver === _lastIndex + 1 &&
                currentItem !== _lastIndex &&
                styles.pagingItem_pointerOver
            ]}
            state={{ disabled: currentItem === _lastIndex }}
            onPress={() => onPress?.(currentItem + 1)}
            iconContainerStyle={styles.iconContainer}
            iconStyle={styles.icon}
            testID="ForwardButton"
            nativeContainerStyle={styles.pagingItemNativeContainer}
            onPointerEnter={
              /* istanbul ignore next */
              () => _onPointerEnter(_lastIndex + 1)
            }
            onPointerLeave={_onPointerLeave}
          />
        )}
      </View>
    )
  }
)
