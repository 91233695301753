import { StyleSheet } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing } = STYLES.useStyles()

  const styles = StyleSheet.create({
    emptyMessage: {
      marginBottom: spacing.xxxl,
      justifyContent: 'center',
      flex: 1
    },
    list: {
      marginBottom: spacing.xxxl,
      width: '100%',
      alignItems: 'center'
    },
    messageContainer: {
      width: '100%',
      alignItems: 'center'
    },
    message: {
      width: '100%',
      alignItems: 'center'
    },
    container: {
      paddingRight: spacing.page.padding.paddingRight,
      paddingLeft: spacing.page.padding.paddingLeft,
      width: '100%'
    }
  })

  return { styles }
}
