import React, { memo } from 'react'
import { View } from 'react-native'
import { OrganizationMemberController } from './OrganizationMemberController'
import { Leaderboard } from 'components'

export interface Props {
  organizationId: string
  controller?: OrganizationMemberController
}

export const OrganizationMember = memo(
  ({
    organizationId,
    controller = new OrganizationMemberController()
  }: Props) => {
    const { data, isLoading, setCurrentPage, sort } = controller.useController({
      organizationId
    })

    return (
      <View testID="OrganizationMember">
        <Leaderboard
          {...data}
          onPaginationPress={setCurrentPage}
          sort={sort}
          isLoading={isLoading}
        />
      </View>
    )
  }
)
