import { useContext } from 'react'
import { useBreakpoints } from './useBreakpoints'
import { ConfigurationContext } from 'utils'

export const useSizing = () => {
  const { ClientApplication } = useContext(ConfigurationContext)
  const { bp } = useBreakpoints()

  const _topBarHeight: { [key: string]: number } = {
    default: 72
  }

  if (bp.is([bp.tablet, bp.desktop])) {
    _topBarHeight.default = 144
  }

  _topBarHeight.zero = 144

  if (bp.is([bp.mobile_normal, bp.mobile_m, bp.mobile_l, bp.mobile_xl])) {
    _topBarHeight.zero = 168
  }

  const sizing = {
    base: 16,
    headerPaddingBottom: 64,
    topBarHeight: _topBarHeight[ClientApplication] ?? _topBarHeight.default!,
    topBarHeightDefault: _topBarHeight.default!
  }

  return {
    sizing
  }
}