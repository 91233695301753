import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { User, PeopleAnnouncement } from 'models'

export interface UseControllerProps {
  organizationId: string
}

export interface GetDataProps {
  organizationId: string
  locale: string
}

export type GetDataType = (props: GetDataProps) => Promise<User | undefined>

export class ChallengeLeaderboardBannerController {
  _getData: GetDataType = async ({ organizationId, locale }) => {
    const campaigns = await User.includes([{ person: ['campaigns'] }])
      .select({
        users: [''],
        people: [''],
        campaigns: ['title', 'organizationId', 'id']
      })
      .extraParams({ locale })
      .find('current')

    if (
      !campaigns?.data?.person?.campaigns?.[0]?.organizationId ||
      campaigns?.data?.person?.campaigns?.[0]?.organizationId !== organizationId
    ) {
      return undefined
    }

    const announcement = await User.includes([
      { person: ['peopleAnnouncements'] }
    ])
      .where({
        'person.peopleAnnouncements.placementId':
          campaigns?.data?.person?.campaigns?.[0].id
      })
      .select({
        users: [''],
        people: [''],
        peopleAnnouncements: ['placementId']
      })
      .find('current')

    if (
      announcement?.data?.person?.peopleAnnouncements &&
      announcement?.data?.person?.peopleAnnouncements.length > 0
    ) {
      return undefined
    }

    return campaigns.data
  }

  _createPeopleAnnouncement = async (placementId: string, personId?: string) => {
    const peopleAnnouncement = new PeopleAnnouncement({
      personId,
      placementId
    })

    await peopleAnnouncement.save()
  }

  useController = ({ organizationId }: UseControllerProps) => {
    const { i18n } = useTranslation()
    const queryClient = useQueryClient()
    const queryKey = ['data', 'challengeLeaderboardBanner', organizationId, i18n.language]

    const { data, isLoading } = useQuery(queryKey, () => this._getData({ organizationId, locale: i18n.language }), { enabled: !!organizationId, cacheTime: 0 })

    const _onRead = async (placementId: string) => {
      await this._createPeopleAnnouncement(placementId, data?.person?.id)
      await queryClient.invalidateQueries(queryKey)
    }

    return {
      isLoading,
      challenge: data?.person?.campaigns?.[0],
      personId: data?.person?.id,
      onRead: _onRead
    }
  }
}