import { Action, Impact, Theme, Commitment } from 'models'
import { useAllApplicableSteps } from './useAllApplicableSteps'
import { useAllCompletedSteps } from './useAllCompletedSteps'
import { useImpactFilters } from './useImpactFilters'
import { useThemeFilters } from './useThemeFilters'
import { useCommitments } from './useCommitments'
import { useRecommendedSteps } from './useRecommendedSteps'

export type UseStepsGroupControllerType = {
  isLoading: boolean
  recommendedSteps?: Action[]
  allApplicableSteps?: Action[]
  allCompletedSteps?: Action[]
  isLoadingCompletedSteps?: boolean
  isLoadingAllApplicableSteps?: boolean
  impactFilters?: Impact[]
  themeFilters?: Theme[]
  commitments?: Commitment[]
}

export type UseControllerType = ({ showCompleted }: { showCompleted: boolean }) => UseStepsGroupControllerType

export class StepsGroupController {
  useController: UseControllerType = ({ showCompleted }) => {
    const { allApplicableSteps, isLoadingAllApplicableSteps } = useAllApplicableSteps()

    const { recommendedSteps, isLoadingRecommendedSteps } = useRecommendedSteps()

    const {
      allCompletedSteps,
      isLoadingCompletedSteps
    } = useAllCompletedSteps({ showCompleted })

    const { impactFilters } = useImpactFilters()

    const { themeFilters } = useThemeFilters()

    const { commitments } = useCommitments({ showCompleted })

    return {
      isLoading: isLoadingRecommendedSteps,
      impactFilters,
      themeFilters,
      allApplicableSteps,
      allCompletedSteps,
      isLoadingCompletedSteps,
      isLoadingAllApplicableSteps,
      commitments,
      recommendedSteps
    }
  }
}