import { Attr, Model, HasMany, BelongsTo } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'
import { Person } from './Person'
import { Action } from './Action'
import { ChecklistItem } from './ChecklistItem'
import { PersonalChecklistItem } from './PersonalChecklistItem'

@Model()
export class Commitment extends ApplicationRecord {
  static jsonapiType = 'commitments'

  @Attr({ persist: true }) state!: string

  @HasMany(ChecklistItem) checklistItems!: ChecklistItem[]

  @HasMany(PersonalChecklistItem)
  completeChecklistItems!: PersonalChecklistItem[]

  @BelongsTo(Person) person!: Person

  @BelongsTo(Action) action!: Action
}
