import React, { memo } from 'react'
import { StyleProp, TextStyle } from 'react-native'
import SimpleMarkdown from 'simple-markdown'
import MarkdownRules from './MarkdownRules'

export interface Props {
  children: React.ReactNode | React.ReactNode[]
  containerStyle?: StyleProp<TextStyle>
  textStyle?: StyleProp<TextStyle>
  nativeID?: string
}

export const Text = memo(({ children, containerStyle, textStyle, nativeID }: Props) => {
  /* istanbul ignore next */
  const text = children?.toString().replace(/<br\s*\/*>/gi, '\n') ?? ''

  const mdRules = Object.assign({}, SimpleMarkdown.defaultRules, MarkdownRules)

  const mdParse = SimpleMarkdown.parserFor(mdRules)
  const mdOutput = SimpleMarkdown.outputFor(mdRules, 'react', {
    containerStyle,
    textStyle,
    nativeID
  })

  const mdSyntaxTree = mdParse(text)
  const mdResult = mdOutput(mdSyntaxTree)

  return mdResult
})
