import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { fontSemiBold, COLORS, bp, spacing } = STYLES.useStyles()

  const container = {
    maxWidth: STYLES.grid.maxColumnWidth,
    marginLeft: 'auto',
    marginRight: 'auto'
  } as ViewStyle

  const title = {
    textAlign: 'center',
    alignSelf: 'center'
  } as TextStyle

  const text = {
    ...fontSemiBold,
    color: COLORS.aubergine,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    textAlign: 'center'
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.normal
    text.lineHeight = STYLES.lineHeight.normal
  }

  const inputContainer = {
    marginBottom: 0,
    marginTop: spacing.xl,
    width: 'auto'
  } as ViewStyle

  const input = {
    maxWidth: '100%'
  } as TextStyle

  const actions = {
    marginTop: spacing.xl,
    flexDirection: 'column'
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    actions.flexDirection = 'row'
    actions.alignItems = 'center'
    actions.justifyContent = 'center'
  }

  const select = {
    marginBottom: spacing.xl
  } as ViewStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    select.marginRight = spacing.xl
    select.marginBottom = 0
  }

  const buttonText = {
    alignSelf: 'center'
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    title,
    text,
    inputContainer,
    input,
    actions,
    select,
    buttonText
  })

  return { styles }
}
