import React, { useEffect, useState, memo } from 'react'
import { StyleProp, ViewStyle, TextStyle } from 'react-native'
import { Text, Button, ButtonType, ButtonSize, View } from 'components'
import { useTranslation } from 'react-i18next'
import { Locales } from 'utils'
import { useLang } from './Lang'
import { useStyles } from './styles'
import { STYLES } from 'styles'

export type LocaleKey = keyof typeof Locales

interface LocaleItemProps {
  lang: LocaleKey
  label: string
  itemStyle?: StyleProp<TextStyle>
  onPress: (lang: string) => void
}

const LocaleItem = memo(({ lang, label, itemStyle, onPress }: LocaleItemProps) => {
  const { styles } = useStyles()
  const [_isActive, _setIsActive] = useState(false)
  const { i18n } = useTranslation()

  const _onPress = () => onPress?.(lang)

  useEffect(() => {
    _setIsActive(i18n.language === lang)
  }, [i18n.language, lang])

  return (
    <Button
      type={ButtonType.link}
      onPress={_onPress}
      label={label}
      containerStyle={styles.localeItem}
      textStyle={[
        itemStyle,
        /* istanbul ignore next */
        _isActive && styles.activeLocale
      ]}
      size={ButtonSize.small}
    />
  )
})

export interface Props {
  containerStyle?: StyleProp<ViewStyle>
  headingStyle?: StyleProp<TextStyle>
  headingTextStyle?: StyleProp<TextStyle>
  itemStyle?: StyleProp<TextStyle>
  itemTextStyle?: StyleProp<TextStyle>
  itemOnPress: (lang: string) => void
}

export const LocalePicker = memo(({
  containerStyle,
  headingStyle,
  headingTextStyle,
  itemStyle,
  itemTextStyle,
  itemOnPress
}: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()
  const { spacing } = STYLES.useStyles()

  return (
    <View style={containerStyle} spacer={spacing.s}>
      <Text
        containerStyle={[styles.heading, headingStyle]}
        textStyle={headingTextStyle}>
        {t('localePicker:heading')}
      </Text>
      <View style={[styles.locales, itemStyle]}>
        {Object.keys(Locales).map((key: string, index: number) => (
          <LocaleItem
            key={index}
            label={Locales[key as LocaleKey]}
            lang={key as LocaleKey}
            itemStyle={itemTextStyle}
            onPress={itemOnPress}
          />
        ))}
      </View>
    </View>
  )
})
