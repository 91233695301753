import { StyleSheet, ViewStyle, ImageStyle, TextStyle } from 'react-native'
import { STYLES } from 'styles'
import { fontSize, lineHeight } from 'styles/STYLES/FontSize'

export const useStyles = () => {
  const { bp, spacing, COLORS, fontSemiBold, viewports } = STYLES.useStyles()

  const achievementGroup: ViewStyle = {
    alignItems: 'center'
  }

  const imageContainer: ViewStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    maxWidth: '100%'
  }

  const image: ImageStyle = {
    height: '100%',
    width: '100%'
  }

  const achievementContainerValue: ViewStyle = {
    marginTop: spacing.xxs,
    marginBottom: spacing.xxs
  }

  const achievementLabelContainer: TextStyle = {
    marginBottom: spacing.l,
    textAlign: 'center'
  }

  const achievementLabel: TextStyle = {
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    ...fontSemiBold,
    color: COLORS.aubergine
  }

  if (bp.gt(viewports.mobile_normal)) {
    achievementGroup.width = '50%'
  }

  if (bp.gt(viewports.mobile_l)) {
    achievementLabelContainer.marginBottom = 0
    achievementGroup.padding = spacing.l
  }

  if (bp.gt(viewports.tablet)) {
    achievementGroup.width = 210
  }

  const styles = StyleSheet.create({
    achievementContainerValue,
    achievementGroup,
    achievementLabel,
    achievementLabelContainer,
    imageContainer,
    image
  })

  return { styles }
}
