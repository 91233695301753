import { StyleSheet, Platform, ViewStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing } = STYLES.useStyles()

  const styles = StyleSheet.create({
    heading: {
      textAlign: 'center',
      marginBottom: 0
    },
    locales: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxWidth: STYLES.grid.maxTextWidth,
      alignSelf: 'center',
      margin: (Platform.OS === 'web' ? `-${spacing.xs}` : -spacing.xs) as unknown as ViewStyle['margin']
    },
    localeItem: {
      margin: spacing.xs
    },
    activeLocale: {
      textDecorationLine: 'none'
    }
  })

  return { styles }
}
