import { STYLES } from 'styles'
import { StyleSheet } from 'react-native'

export const useStyles = () => {
  const { fontRegularItalic } = STYLES.useStyles()

  const styles = StyleSheet.create({
    em: {
      ...fontRegularItalic
    }
  })

  return { styles }
}
