import React, { memo } from 'react'
import { View } from 'components'
import { useStyles } from './styles'
import { Text, ViewProps } from 'react-native'
import { ShoeRedCheckmark } from 'images'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { STYLES } from 'styles'

export const ToDoListCTA = memo(({
  children, ...props
}: ViewProps) => {
  useLang()
  const { styles } = useStyles()
  const { t } = useTranslation()
  const { spacing } = STYLES.useStyles()

  return (
    <View style={[styles.container]} testID="ToDoListCTA" {...props}>
      <View style={[styles.textContainer]}>
        <Text style={[styles.title]}>{t('todolistCta:title')}</Text>
        <Text style={[styles.text]}>{t('todolistCta:text')}</Text>
      </View>
      <View style={[styles.row]} spacer={spacing.l}>
        <View style={styles.buttonContainer}>
          {children}
        </View>
        <ShoeRedCheckmark style={[styles.illustration]} />
      </View>
    </View>
  )
})
