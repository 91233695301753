import React, { memo, useContext } from 'react'
import { Announcements, SvgImage } from 'components'
import { ChallengeLeaderboardBannerController } from './ChallengeLeaderboardBannerController'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { useNavigationContext, LinkContext, ReplaceParam } from 'utils'
import { ViewProps } from 'react-native'

export interface Props {
  organizationId: string
  controller?: ChallengeLeaderboardBannerController
  style?: ViewProps['style']
}

const Image = memo((props) => (
  <SvgImage
    image={require('images/HandsHoldingHearts.svg')}
    width={308}
    height={173}
    {...props}
  />
)) as typeof SvgImage

export const ChallengeLeaderboardBanner = memo(
  ({
    organizationId,
    controller = new ChallengeLeaderboardBannerController(),
    style
  }: Props) => {
    useLang()
    const { t } = useTranslation()
    const { challenge, onRead, isLoading } = controller.useController({
      organizationId
    })
    const navigationContext = useNavigationContext()
    const { challengeLeaderboardLinkUrl } = useContext(LinkContext)

    const _primaryActionOnPress = async (id: string) => {
      await onRead(id)

      if (challengeLeaderboardLinkUrl && organizationId) {
        const _url = ReplaceParam(challengeLeaderboardLinkUrl, organizationId)

        navigationContext.navigate(_url)
      }
    }

    if (isLoading || !challenge?.id) {
      return null
    }

    return (
      <Announcements
        items={[
          {
            id: challenge.id,
            body: t('challengeLeaderboardBanner:text', {
              title: challenge.title
            }),
            primaryActionLabel: t(
              'challengeLeaderboardBanner:primaryActionLabel'
            ),
            primaryActionOnPress: _primaryActionOnPress,
            category: t('challengeLeaderboardBanner:category'),
            backgroundColor: '#EBD7EE',
            Image
          }
        ]}
        onRead={onRead}
        containerStyle={style}
      />
    )
  }
)
