import React, { memo, useState } from 'react'
import { ImageBackground, Text } from 'react-native'
import { useStyles } from './styles'
import { LeaderboardTypes } from 'models'
import { Button, ButtonType, View } from 'components'
import { STYLES } from 'styles'

export enum LeaderboardIndicatorPosition {
  top = 'top',
  bottom = 'bottom'
}

export interface LeaderboardIndicatorsProps {
  items?: LeaderboardTypes.LeaderboardIndicator[]
  position: LeaderboardIndicatorPosition
  onPress?: (page: number) => void
}

export const LeaderboardIndicators = memo(
  ({ items, position, onPress }: LeaderboardIndicatorsProps) => {
    const { styles } = useStyles()
    const { spacing } = STYLES.useStyles()
    const [_isPointerOver, _setIsPointerOver] = useState<
      { [key: string]: boolean } | undefined
    >(items?.reduce((a, v) => ({ ...a, [v.id]: false }), {}))

    if (!items || items.length === 0) {
      return null
    }

    return (
      <View
        style={[styles.container, styles[`container_${position}`]]}
        testID="LeaderboardIndicators"
        spacer={spacing.xs}>
        {items.map((item) => (
          <Button
            key={item.id}
            label={item.label}
            onPress={() => onPress?.(item.page)}
            type={ButtonType.blank}
            onPointerEnter={() =>
              _setIsPointerOver((prev) => ({
                ...prev,
                [item.id]: true
              }))
            }
            onPointerLeave={() =>
              _setIsPointerOver((prev) => ({
                ...prev,
                [item.id]: false
              }))
            }
            testID="LeaderboardIndicatorsItem">
            <View
              style={[
                styles.imageContainer,
                _isPointerOver?.[item.id] && styles.imageContainer_pointerOver
              ]}>
              {item.image ? (
                <ImageBackground
                  source={{ uri: item.image }}
                  style={styles.image}
                  resizeMode="cover"
                />
              ) : (
                <Text style={styles.text}>{item.label.substring(0, 1)}</Text>
              )}
            </View>
          </Button>
        ))}
      </View>
    )
  }
)
