import React, { memo } from 'react'
import { Text, Logo } from 'components'
import { PageProps } from '../index'
import {
  Layout,
  Shapes,
  Heading,
  IllustrationBackground,
  NextButton,
  useSharedStyles,
  ProgressBar,
  IllustrationBackgroundHeightRatio
} from '../Shared'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { HighFive, HighFiveHeightRatio } from 'images/HighFive'
import { useStyles } from './styles'
import { STYLES } from 'styles'

export const StepsSuccess = memo(({ navigation }: PageProps) => {
  useLang()

  const { t } = useTranslation()
  const { sharedStyles, imageWidth } = useSharedStyles()
  const { styles } = useStyles()
  const { bp } = STYLES.useStyles()

  let _imageWidth = imageWidth * 1.6

  /* istanbul ignore next */
  if (bp.is([bp.tablet, bp.desktop])) {
    _imageWidth = imageWidth
  }

  const _imageHeight = _imageWidth * HighFiveHeightRatio

  const _onPress = () => navigation.navigate('Success')

  return (
    <Layout testID="StepsSuccess" hideTopBar>
      <ProgressBar
        isHidden
        label={t('onboardingStepsSuccess:progressLabel')}
        max={0}
        value={0}
      />

      <Shapes>
        <Logo style={sharedStyles.logo} />

        <Heading style={{ textAlign: 'center' }}>
          {t('onboardingStepsSuccess:title')}
        </Heading>

        <Text containerStyle={[sharedStyles.textContainer, styles.text]}>
          {t('onboardingStepsSuccess:text')}
        </Text>

        <IllustrationBackground
          backgroundStyle={{
            width: _imageWidth,
            height: _imageWidth * IllustrationBackgroundHeightRatio
          }}
          style={{
            minWidth: _imageWidth,
            minHeight: _imageWidth * IllustrationBackgroundHeightRatio
          }}>
          <HighFive
            style={{
              width: _imageWidth,
              height: _imageHeight,
              marginTop: 0.10143530963128265 * _imageHeight
            }}
          />
        </IllustrationBackground>

        <NextButton onPress={_onPress} />
      </Shapes>
    </Layout>
  )
})
