import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { focused, input, disabled, spacing, COLORS, bp, fontBold, boxShadows } = STYLES.useStyles()

  const container = {
    maxWidth: input.maxWidth,
    alignSelf: 'center'
  } as ViewStyle

  const list = {
    alignSelf: 'stretch',
    maxWidth: input.maxWidth,
    maxHeight: getREM(15),
    backgroundColor: COLORS.paper,
    borderRadius: getREM(1.25),
    marginTop: spacing.s,
    ...Platform.select({
      android: {
        marginLeft: spacing.xxs,
        marginRight: spacing.xxs
      }
    }),
    ...boxShadows.button.all
  } as ViewStyle

  if (bp.desktop) {
    list.maxHeight = getREM(17)
  }

  const listItemText = {
    ...fontBold,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    paddingTop: spacing.s,
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    textAlign: 'left',
    color: COLORS.aubergine,
    marginTop: spacing.xxs,
    marginLeft: spacing.xxs,
    marginRight: spacing.xxs,
    overflow: 'hidden',
    borderRadius: getREM(1.25),
    ...Platform.select({
      web: {
        transition: 'background-color 0.2s ease-out'
      }
    })
  } as TextStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    listItemText.paddingLeft = spacing.s
    listItemText.paddingRight = spacing.s
  }

  if (bp.desktop) {
    listItemText.paddingTop = spacing.normal
    listItemText.paddingBottom = spacing.normal
    listItemText.paddingLeft = spacing.l
    listItemText.paddingRight = spacing.l
  }

  const listItemText_last = {
    marginBottom: spacing.xxs
  } as ViewStyle

  const listItemText_selected = {
    backgroundColor: COLORS.mischka
  } as ViewStyle

  const listItemText_pointerOver = {
    backgroundColor: COLORS.snuff
  } as ViewStyle

  const noResultsMessage = {
    ...listItemText
  } as TextStyle

  const styles = StyleSheet.create({
    container,
    textInput: {
      ...input
    },
    disabled,
    focused,
    list,
    listItemText,
    listItemText_last,
    listItemText_selected,
    listItemText_pointerOver,
    noResultsMessage
  })

  return { styles }
}
