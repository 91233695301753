import React, { useRef, useState, memo } from 'react'
import { Animated, Text, TouchableOpacity, View, ViewProps, Platform, StyleSheet } from 'react-native'
import { useStyles } from './styles'
import { useNativeDriver } from 'utils'
import { getREM } from 'styles'

export interface Props extends ViewProps {
  value: boolean
  onChange?: (arg: boolean) => void
  trueLabel?: string
  falseLabel?: string
  accessibilityLabel: string
  disabled?: boolean
}

export const ToggleDuration = 200

export const Toggle = memo(({
  value,
  onChange,
  falseLabel,
  trueLabel,
  accessibilityLabel,
  disabled = false,
  ...props
}: Props) => {
  const { styles } = useStyles()
  const _buttonRef = useRef<HTMLButtonElement | null>(null)
  const _thumbPosition = useRef(new Animated.Value(+value)).current
  const _hasLabel = !!falseLabel && !!trueLabel
  const [_isFocused, _setIsFocused] = useState(false)

  /* istanbul ignore next */
  const _onFocus = () => _setIsFocused(true)
  /* istanbul ignore next */
  const _onBlur = () => _setIsFocused(false)

  const _onChange = (newValue: boolean) => {
    Animated.timing(_thumbPosition, {
      toValue: +newValue,
      duration: ToggleDuration,
      useNativeDriver
    }).start(() => {
      _buttonRef.current?.setAttribute('aria-checked', String(newValue))
      onChange?.(newValue)
    })
  }

  const _onPress = () => {
    _onChange(!value)
  }

  /* istanbul ignore next */
  const _onKeyUp = (event: React.KeyboardEvent) => {
    if (event.code === 'Space') {
      event.preventDefault()
      _onChange(!value)
    }
  }

  return (
    <View {...props}>
      <View style={[styles.row, disabled && styles.disabled]} testID="Toggle">
        {Platform.OS === 'web' ? (
          <button
            ref={_buttonRef}
            style={
              StyleSheet.flatten([
                styles.button,
                /* istanbul ignore next */
                _isFocused && styles.button_focused
              ]) as unknown as React.CSSProperties
            }
            role="switch"
            aria-label={accessibilityLabel}
            aria-checked={value}
            disabled={disabled}
            onClick={_onPress}
            onFocus={_onFocus}
            onBlur={_onBlur}
            onKeyUp={_onKeyUp}
          />
        ) : (
          <TouchableOpacity
            style={[
              styles.button,
              /* istanbul ignore next */
              _isFocused && styles.button_focused
            ]}
            accessibilityRole="switch"
            accessibilityLabel={accessibilityLabel}
            accessibilityHint={value ? falseLabel : trueLabel}
            accessibilityState={{
              checked: value,
              disabled
            }}
            onPress={_onPress}
            disabled={disabled}
          />
        )}

        {_hasLabel && (
          <Text
            textBreakStrategy="simple"
            style={value ? styles.text : styles.activeText}>
            {falseLabel}
          </Text>
        )}

        <View style={styles.touchableArea}>
          <View style={styles.gutter}>
            <Animated.View
              style={[
                styles.thumb,
                {
                  transform: [
                    {
                      translateX: _thumbPosition.interpolate({
                        inputRange: [0, 1],
                        outputRange: [getREM(0), getREM(1.5625)]
                      })
                    }
                  ]
                }
              ]}
            />
          </View>
        </View>

        {_hasLabel && (
          <Text
            textBreakStrategy="simple"
            style={value ? styles.activeText : styles.text}>
            {trueLabel}
          </Text>
        )}
      </View>
    </View>
  )
})
