import React, { memo } from 'react'
import { useStyles } from './styles'
import { Text } from 'react-native'
import { useTranslation } from 'react-i18next'

interface LeaderboardPointsProps {
  value: number
  unit?: string
}

export const LeaderboardPoints = memo(
  ({ value, unit }: LeaderboardPointsProps) => {
    const { styles } = useStyles()
    const { t } = useTranslation()

    return (
      <Text textBreakStrategy="simple" testID="LeaderboardPoints">
        <Text textBreakStrategy="simple" style={styles.leaderboardPointsValue}>
          {t('format:number', { value })}
        </Text>
        {unit && (
          <Text
            textBreakStrategy="simple"
            style={styles.leaderboardPointsValueUnit}
            testID="LeaderboardPointsUnit">
            {' '}
            {unit}
          </Text>
        )}
      </Text>
    )
  }
)
