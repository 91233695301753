import React, { memo } from 'react'
import { MassValue, Text, View, SvgImage } from 'components'
import { useStyles } from './styles'

export interface AchievementItem {
  achievementValue: number
  Image?: typeof SvgImage
  imageSize?: number
  achievementLabel: string
  achievementValueUnit: string
}

export const AchievementItem = memo(
  ({
    Image,
    imageSize,
    achievementValue,
    achievementLabel,
    achievementValueUnit,
  }: AchievementItem) => {
    const { styles } = useStyles()
    const _hasImage = !!Image

    return (
      <View testID="AchievementItem" style={styles.achievementGroup}>
        {_hasImage && (
          <View
            style={[
              styles.imageContainer,
              { width: imageSize, height: imageSize },
            ]}
          >
            <Image
              testID="OrganizationProgressAchievementImage"
              style={styles.image}
            />
          </View>
        )}
        <MassValue value={achievementValue} unit={achievementValueUnit} />
        <Text
          containerStyle={styles.achievementLabelContainer}
          textStyle={styles.achievementLabel}
        >
          {achievementLabel}
        </Text>
      </View>
    )
  }
)
