import React from 'react'
import { Image, ImageRequireSource, ImageStyle, StyleProp } from 'react-native'

export const Icons: Record<string, ImageRequireSource> = {
  '1_mile_walker': require('./assets/1_mile_walker.png'),
  'a_rated': require('./assets/a_rated.png'),
  'activist': require('./assets/activist.png'),
  'antique_dealer': require('./assets/antique_dealer.png'),
  'baby_booster': require('./assets/baby_booster.png'),
  'bag_recycler': require('./assets/bag_recycler.png'),
  'beach_clean': require('./assets/beach_clean.png'),
  'bee_lover': require('./assets/bee_lover.png'),
  'better_fish': require('./assets/better_fish.png'),
  'better_packaging': require('./assets/better_packaging.png'),
  'bike_share': require('./assets/bike_share.png'),
  'buy_sustainable_clothing': require('./assets/buy_sustainable_clothing.png'),
  'car_free': require('./assets/car_free.png'),
  'car_pool': require('./assets/car_pool.png'),
  'car_share': require('./assets/car_share.png'),
  'carbon_cutting_suggestion_at_work': require('./assets/carbon_cutting_suggestion_at_work.png'),
  'charity_shop_donation': require('./assets/charity_shop_donation.png'),
  'checklist': require('./assets/checklist.png'),
  'chemical_cutter': require('./assets/chemical_cutter.png'),
  'choo_choo': require('./assets/choo_choo.png'),
  'cleaning_refills': require('./assets/cleaning_refills.png'),
  'climate_podcast': require('./assets/climate_podcast.png'),
  'close_curtains_and_doors': require('./assets/close_curtains_and_doors.png'),
  'cloud_clear_up': require('./assets/cloud_clear_up.png'),
  'company_race_to_zero': require('./assets/company_race_to_zero.png'),
  'complete_the_work_from_home_checklist': require('./assets/complete_the_work_from_home_checklist.png'),
  'cooler_customer': require('./assets/cooler_customer.png'),
  'cut_a_tonne_in_21': require('./assets/cut_a_tonne_in_21.png'),
  'delayed_gratification': require('./assets/delayed_gratification.png'),
  'door_step_delivery': require('./assets/door_step_delivery.png'),
  'draught_proof_windows': require('./assets/draught_proof_windows.png'),
  'draught_proofer': require('./assets/draught_proofer.png'),
  'dump_trip': require('./assets/dump_trip.png'),
  'eat_animal_products_just_once_a_day_for_a_week': require('./assets/eat_animal_products_just_once_a_day_for_a_week.png'),
  'eat_the_seasons': require('./assets/eat_the_seasons.png'),
  'eco_appliance': require('./assets/eco_appliance.png'),
  'eco_dishwasher': require('./assets/eco_dishwasher.png'),
  'eco_meals': require('./assets/eco_meals.png'),
  'eco_search': require('./assets/eco_search.png'),
  'electric_motor': require('./assets/electric_motor.png'),
  'euro_traveller': require('./assets/euro_traveller.png'),
  'fairtrade_supporter': require('./assets/fairtrade_supporter.png'),
  'firmly_grounded': require('./assets/firmly_grounded.png'),
  'find_out_how_green_your_pension_is': require('./assets/find_out_how_green_your_pension_is.png'),
  'food_recycler': require('./assets/food_recycler.png'),
  'food_sharer': require('./assets/food_sharer.png'),
  'forest_friendly': require('./assets/forest_friendly.png'),
  'forest_planter': require('./assets/forest_planter.png'),
  'forest_protector': require('./assets/forest_protector.png'),
  'fossil_fuel_free_bank': require('./assets/fossil_fuel_free_bank.png'),
  'fridge_efficiency': require('./assets/fridge_efficiency.png'),
  'full_veggie': require('./assets/full_veggie.png'),
  'go_to_a_sustainability_event': require('./assets/go_to_a_sustainability_event.png'),
  'gold_standard': require('./assets/gold_standard.png'),
  'gone_camping': require('./assets/gone_camping.png'),
  'green_cleaning': require('./assets/green_cleaning.png'),
  'green_electricity': require('./assets/green_electricity.png'),
  'green_gas': require('./assets/green_gas.png'),
  'greener_delivery': require('./assets/greener_delivery.png'),
  'greenwash_checklist': require('./assets/greenwash_checklist.png'),
  'grow_your_own_veggies': require('./assets/grow_your_own_veggies.png'),
  'harvest_rainwater': require('./assets/harvest_rainwater.png'),
  'healthy_snacks': require('./assets/healthy_snacks.png'),
  'heat_pump': require('./assets/heat_pump.png'),
  'heat_reflector': require('./assets/heat_reflector.png'),
  'home_herb_grower': require('./assets/home_herb_grower.png'),
  'home_work_day': require('./assets/home_work_day.png'),
  'home_worker': require('./assets/home_worker.png'),
  'independent_thinker': require('./assets/independent_thinker.png'),
  'invite_a_friend': require('./assets/invite_a_friend.png'),
  'just_plants': require('./assets/just_plants.png'),
  'keep_cool_checklist': require('./assets/keep_cool_checklist.png'),
  'keep_cool_working_from_home_checklist': require('./assets/keep_cool_working_from_home_checklist.png'),
  'learn_to_be_water_wise': require('./assets/learn_to_be_water_wise.png'),
  'less_air_con': require('./assets/less_air_con.png'),
  'less_cheesy': require('./assets/less_cheesy.png'),
  'lights_off': require('./assets/lights_off.png'),
  'litter_picker': require('./assets/litter_picker.png'),
  'local_green_group': require('./assets/local_green_group.png'),
  'local_shopper': require('./assets/local_shopper.png'),
  'locally_grown': require('./assets/locally_grown.png'),
  'low_chemical_bathroom': require('./assets/low_chemical_bathroom.png'),
  'mainly_organic': require('./assets/mainly_organic.png'),
  'mainly_plants': require('./assets/mainly_plants.png'),
  'maintain_air_con': require('./assets/maintain_air_con.png'),
  'make_a_meal_with_leftovers': require('./assets/make_a_meal_with_leftovers.png'),
  'make_a_plant_based_meal_for_a_friend': require('./assets/make_a_plant_based_meal_for_a_friend.png'),
  'make_my_money_matter': require('./assets/make_my_money_matter.png'),
  'metal_head': require('./assets/metal_head.png'),
  'microwaver': require('./assets/microwaver.png'),
  'natural_drying': require('./assets/natural_drying.png'),
  'nature_walk': require('./assets/nature_walk.png'),
  'new_air_con': require('./assets/new_air_con.png'),
  'nice_driving': require('./assets/nice_driving.png'),
  'no_cruising': require('./assets/no_cruising.png'),
  'no_fly_zone': require('./assets/no_fly_zone.png'),
  'no_fossil_fuel_investments': require('./assets/no_fossil_fuel_investments.png'),
  'no_fossil_fuel_isa': require('./assets/no_fossil_fuel_isa.png'),
  'no_fossil_fuel_pension': require('./assets/no_fossil_fuel_pension.png'),
  'no_idling': require('./assets/no_idling.png'),
  'no_junk_mail': require('./assets/no_junk_mail.png'),
  'no_peat': require('./assets/no_peat.png'),
  'no_plastic_wipes': require('./assets/no_plastic_wipes.png'),
  'not_a_waster': require('./assets/not_a_waster.png'),
  'nothing_new': require('./assets/nothing_new.png'),
  'off_the_boil': require('./assets/off_the_boil.png'),
  'on_the_go_recycler': require('./assets/on_the_go_recycler.png'),
  'on_yer_bike': require('./assets/on_yer_bike.png'),
  'orangutan_helper': require('./assets/orangutan_helper.png'),
  'organic_clothing': require('./assets/organic_clothing.png'),
  'organic_fruit_month': require('./assets/organic_fruit_month.png'),
  'organic_veg_month': require('./assets/organic_veg_month.png'),
  'paper_saver': require('./assets/paper_saver.png'),
  'people_power': require('./assets/people_power.png'),
  'plant_based_for_a_day': require('./assets/plant_based_for_a_day.png'),
  'plant_milker': require('./assets/plant_milker.png'),
  'plastic_free_hair': require('./assets/plastic_free_hair.png'),
  'plastic_free_period': require('./assets/plastic_free_period.png'),
  'plastic_free_washing': require('./assets/plastic_free_washing.png'),
  'pledge_net_zero': require('./assets/pledge_net_zero.png'),
  'put_a_lid_on_it': require('./assets/put_a_lid_on_it.png'),
  'radiators_off': require('./assets/radiators_off.png'),
  'read_and_share_a_book_on_climate_change': require('./assets/read_and_share_a_book_on_climate_change.png'),
  'recycle_all_batteries': require('./assets/recycle_all_batteries.png'),
  'recycle_at_work_week': require('./assets/recycle_at_work_week.png'),
  'recycle_mobile_phones': require('./assets/recycle_mobile_phones.png'),
  'red_meat_free': require('./assets/red_meat_free.png'),
  'red_meat_reducer': require('./assets/red_meat_reducer.png'),
  'repair_your_clothes': require('./assets/repair_your_clothes.png'),
  'reusable_coffee_cup': require('./assets/reusable_coffee_cup.png'),
  'second_hand': require('./assets/second_hand.png'),
  'say_no_to_single_use_plastic': require('./assets/say_no_to_single_use_plastic.png'),
  'self_powered_commute': require('./assets/self_powered_commute.png'),
  'service_master': require('./assets/service_master.png'),
  'shorter_shower': require('./assets/shorter_shower.png'),
  'simple_cleaning': require('./assets/simple_cleaning.png'),
  'smart_heating': require('./assets/smart_heating.png'),
  'social_media_for_good': require('./assets/social_media_for_good.png'),
  'solar_powered': require('./assets/solar_powered.png'),
  'standby_saver': require('./assets/standby_saver.png'),
  'sustainability_team': require('./assets/sustainability_team.png'),
  'sustainable_day_out_checklist': require('./assets/sustainable_day_out_checklist.png'),
  'sustainable_sharer': require('./assets/sustainable_sharer.png'),
  'sustainable_wrapping': require('./assets/sustainable_wrapping.png'),
  'support_a_community_impacted_by_climate_change': require('./assets/support_a_community_impacted_by_climate_change.png'),
  'swap_rather_than_buy_new': require('./assets/swap_rather_than_buy_new.png'),
  'swap_some_stuff': require('./assets/swap_some_stuff.png'),
  'switch_to_insect_pet_food': require('./assets/switch_to_insect_pet_food.png'),
  'take_public_transport': require('./assets/take_public_transport.png'),
  'test_ride': require('./assets/test_ride.png'),
  'the_insulator': require('./assets/the_insulator.png'),
  'the_professor': require('./assets/the_professor.png'),
  'thoughtful_giving': require('./assets/thoughtful_giving.png'),
  'tree_planter': require('./assets/tree_planter.png'),
  'turn_off_the_tap': require('./assets/turn_off_the_tap.png'),
  'unwanted_presents': require('./assets/unwanted_presents.png'),
  'use_your_influence_at_work': require('./assets/use_your_influence_at_work.png'),
  'veggie_luncher': require('./assets/veggie_luncher.png'),
  'virtual_meeting_instead_of_plane': require('./assets/virtual_meeting_instead_of_plane.png'),
  'volunteer_at_work_day': require('./assets/volunteer_at_work_day.png'),
  'wash_at_30c': require('./assets/wash_at_30c.png'),
  'wash_clothes_on_a_full_load': require('./assets/wash_clothes_on_a_full_load.png'),
  'wash_dishes_on_a_full_load': require('./assets/wash_dishes_on_a_full_load.png'),
  'water_on_tap': require('./assets/water_on_tap.png'),
  'water_saving_checklist': require('./assets/water_saving_checklist.png'),
  'weekly_recycler': require('./assets/weekly_recycler.png'),
  'work_sustainability_plan': require('./assets/work_sustainability_plan.png'),
  'zero_food_waste': require('./assets/zero_food_waste.png'),
  'zero_sharer': require('./assets/zero_sharer.png'),
  'zero_waste': require('./assets/zero_waste.png')
}

export interface Props {
  actionSlug: string,
  style?: StyleProp<ImageStyle>
}

export const Icon = ({ actionSlug, style, ...props }: Props) => {
  const _source = Icons[actionSlug]

  if (!_source) return null

  return (<Image source={_source} style={[style]} testID={'image'} {...props} />)
}