import { StyleSheet, ViewStyle, TextStyle, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, fontSemiBold, bp, spacing, COLORS } = STYLES.useStyles()

  const _height = getREM(1.75)

  const container = {
    backgroundColor: colors.defaultCategoryPillBackground,
    borderRadius: getREM(0.375),
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    height: _height,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    borderWidth: 0
  } as ViewStyle

  const container_loading = {
    backgroundColor: COLORS.silver,
    width: getREM(8)
  } as ViewStyle

  const icon = {
    width: getREM(1),
    height: getREM(1),
    marginRight: spacing.xxs,
    ...Platform.select({
      ios: {
        transform: [
          { translateY: 2 }
        ]
      }
    })
  } as ViewStyle

  const link = {
    textDecorationLine: 'none'
  } as ViewStyle

  const content = {
    paddingLeft: spacing.xs,
    paddingRight: spacing.xs,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: _height
  } as ViewStyle

  const text = {
    ...fontSemiBold,
    color: colors.defaultCategoryPillText,
    textTransform: 'capitalize',
    fontSize: STYLES.fontSize.xxs,
    lineHeight: _height,
    ...Platform.select({
      ios: {
        height: _height + 6,
        lineHeight: _height + 10
      }
    })
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    text.fontSize = STYLES.fontSize.xs
  }

  const styles = StyleSheet.create({
    container,
    container_loading,
    content,
    icon,
    link,
    text
  })

  return { styles, defaultTextColor: colors.defaultCategoryPillText }
}
