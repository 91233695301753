import { StyleSheet, TextStyle, ViewStyle, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { box, COLORS, fontBold, fontRegular, spacing, bp } = STYLES.useStyles()

  const _borderRadius = getREM(1)

  const container = {
    ...box,
    maxWidth: STYLES.grid.maxColumnWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: _borderRadius,
    padding: 0
  } as ViewStyle

  const listItem = {
    position: 'relative',
    padding: box.padding,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  } as ViewStyle

  if (bp.not([
    bp.mobile_xl,
    bp.tablet,
    bp.desktop
  ])) {
    listItem.flexDirection = 'column'
  }

  const listItem_selected = {
    backgroundColor: COLORS.mischka
  } as ViewStyle

  const listItem_first = {
    borderTopLeftRadius: _borderRadius,
    borderTopRightRadius: _borderRadius
  } as ViewStyle

  const listItem_last = {
    borderBottomLeftRadius: _borderRadius,
    borderBottomRightRadius: _borderRadius
  } as ViewStyle

  const divider = {
    marginVertical: getREM(0.125),
    borderTopColor: COLORS.steam,
    borderTopWidth: 1,
    borderTopStyle: 'solid'
  } as ViewStyle

  const spacer = {
    width: spacing.xs,
    height: spacing.xs
  } as ViewStyle

  if (bp.is([
    bp.mobile_xl,
    bp.tablet
  ])) {
    spacer.width = spacing.s
    spacer.height = spacing.s
  }

  if (bp.desktop) {
    spacer.width = spacing.m
    spacer.height = spacing.m
  }

  const toggleButtonContainer = {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  } as ViewStyle

  const toggleButton_first = {
    borderTopLeftRadius: _borderRadius,
    borderTopRightRadius: _borderRadius
  } as ViewStyle

  const toggleButton_last = {
    borderBottomLeftRadius: _borderRadius,
    borderBottomRightRadius: _borderRadius
  } as ViewStyle

  const toggleButton = {
    width: '100%',
    height: '100%'
  } as TextStyle

  let _imageSize = 2.75

  if (bp.desktop) {
    _imageSize = 3.75
  }

  const _imageBorderWidth = 0.1875
  const _imageContainerSize = _imageSize + (_imageBorderWidth * 2)

  const imageContainer = {
    width: getREM(_imageContainerSize),
    height: getREM(_imageContainerSize),
    borderRadius: getREM(_imageContainerSize / 2),
    borderWidth: getREM(_imageBorderWidth),
    borderStyle: 'solid',
    borderColor: COLORS.white,
    overflow: 'hidden'
  } as ImageStyle

  const image = {
    width: getREM(_imageSize),
    height: getREM(_imageSize)
  } as ImageStyle

  const textContainer = {
    flexGrow: 1
  } as ViewStyle

  const primaryText = {
    ...fontBold,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    color: COLORS.aubergine
  } as TextStyle

  if (bp.not([
    bp.mobile_xl,
    bp.tablet,
    bp.desktop
  ])) {
    primaryText.textAlign = 'center'
  }

  if (bp.desktop) {
    primaryText.fontSize = STYLES.fontSize.m
    primaryText.lineHeight = STYLES.lineHeight.m
  }

  const secondaryText = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: COLORS.aubergine
  } as TextStyle

  if (bp.not([
    bp.mobile_xl,
    bp.tablet,
    bp.desktop
  ])) {
    secondaryText.textAlign = 'center'
  }

  if (bp.desktop) {
    secondaryText.fontSize = STYLES.fontSize.normal
    secondaryText.lineHeight = STYLES.lineHeight.normal
  }

  const tertiaryText = {
    ...fontRegular,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    color: COLORS.aubergine
  } as TextStyle

  if (bp.not([
    bp.mobile_xl,
    bp.tablet,
    bp.desktop
  ])) {
    tertiaryText.textAlign = 'center'
  }

  if (bp.desktop) {
    tertiaryText.fontSize = STYLES.fontSize.normal
    tertiaryText.lineHeight = STYLES.lineHeight.normal
  }

  const tagsContainer = {
    marginBottom: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexShrink: 1
  } as ViewStyle

  if (bp.is([
    bp.mobile_xl,
    bp.tablet,
    bp.desktop
  ])) {
    tagsContainer.justifyContent = 'flex-end'
  }

  const action = {
    color: COLORS.aubergine,
    fontSize: STYLES.fontSize.s,
    lineHeight: STYLES.lineHeight.s,
    boxShadow: 'none'
  } as TextStyle

  const actionContainer = {
    zIndex: 2
  } as ViewStyle

  if (bp.desktop) {
    action.fontSize = STYLES.fontSize.normal
    action.lineHeight = STYLES.lineHeight.normal
  }

  const message = {
    ...fontRegular,
    backgroundColor: COLORS.whisper,
    padding: spacing.normal,
    borderRadius: getREM(1),
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    textAlign: 'center',
    alignSelf: 'center',
    width: '100%',
    maxWidth: STYLES.grid.maxBoxWidth,
    color: COLORS.aubergine
  } as TextStyle

  const messageContainer = {
    width: '100%'
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    listItem,
    listItem_selected,
    listItem_first,
    listItem_last,
    divider,
    spacer,
    toggleButtonContainer,
    toggleButton,
    toggleButton_first,
    toggleButton_last,
    image,
    imageContainer,
    textContainer,
    primaryText,
    secondaryText,
    tertiaryText,
    tagsContainer,
    action,
    actionContainer,
    message,
    messageContainer
  })

  return { styles }
}
