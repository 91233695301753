import React from 'react'
import { View, Text } from 'react-native'
import { useStyles } from './styles'
import { ButtonProps } from '../index'

export const Container = React.forwardRef(({
  children,
  fullWidth,
  testID,
  containerStyle,
  isFake
}: ButtonProps, ref: React.Ref<View & Text>) => {
  const { styles } = useStyles()

  return (
    <View
      ref={ref}
      style={[fullWidth && styles.fullWidth, containerStyle]}
      testID={testID}
      pointerEvents={isFake ? "none" : undefined}
    >
      {children}
    </View>
  )
})
