import { StyleSheet, Platform } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, COLORS, focused, spacing, disabled, fontBold, fontRegular } = STYLES.useStyles()

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      ...Platform.select({
        web: {
          cursor: 'pointer'
        }
      })
    },
    checkbox: {
      position: 'absolute',
      left: getREM(-999)
    },
    fakeCheckbox: {
      width: getREM(1.125),
      height: getREM(1.125),
      borderWidth: getREM(0.0625),
      borderStyle: 'solid',
      borderColor: colors.brand,
      borderRadius: getREM(0.125),
      backgroundColor: COLORS.white,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: getREM(0.375)
    },
    fakeCheckbox_checked: {
      backgroundColor: colors.brand
    },
    fakeCheckbox_focused: {
      ...focused
    },
    tick: {
      width: getREM(0.625),
      height: getREM(0.625)
    },
    indexContainer: {
      width: getREM(1.875),
      height: getREM(1.875),
      borderRadius: getREM(0.9375),
      backgroundColor: COLORS.white,
      borderWidth: getREM(0.0625),
      borderStyle: 'solid',
      borderColor: COLORS.silver,
      marginLeft: spacing.xs,
      flexShrink: 0,
      justifyContent: 'center',
      alignItems: 'center'
    },
    indexContainer_checked: {
      backgroundColor: COLORS.cherry,
      borderColor: COLORS.cherry
    },
    index: {
      ...fontBold,
      color: colors.brand,
      textAlign: 'center',
      fontSize: STYLES.fontSize.s,
      lineHeight: getREM(1.75)
    },
    index_checked: {
      color: COLORS.white
    },
    indexTextContainer: {
      textAlign: 'center',
      marginBottom: 0
    },
    label: {
      ...fontRegular,
      fontSize: STYLES.fontSize.s,
      lineHeight: STYLES.lineHeight.s,
      color: colors.text,
      flexShrink: 1
    },
    labelContainer: {
      marginTop: getREM(0.25),
      marginLeft: spacing.xs,
      marginBottom: 0
    },
    disabled
  })

  return { styles }
}
