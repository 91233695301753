import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, bp, fontBold, spacing } = STYLES.useStyles()

  const _isBp = bp.is([bp.tablet, bp.desktop])

  const container: ViewStyle = {
    marginBottom: getREM(2),
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  }

  if (!_isBp) {
    container.flexDirection = 'column'
  }

  const options: ViewStyle = {
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1
  }

  if (!_isBp) {
    options.flexDirection = 'column'
    options.width = '100%'
  }

  const dropdown: ViewStyle = {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1
  }

  if (!_isBp) {
    dropdown.flexDirection = 'column'
    dropdown.width = '100%'
  }

  const dropdownSelect: ViewStyle = {
    marginBottom: 0,
    flexShrink: 1,
    maxWidth: getREM(16)
  }

  if (!_isBp) {
    dropdownSelect.marginTop = spacing.normal
  }

  const dropdownSelectText: TextStyle = {
    ...fontBold,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    marginRight: getREM(1),
    color: colors.brand
  }

  if (bp.tablet) {
    dropdownSelectText.fontSize = STYLES.fontSize.s
    dropdownSelectText.lineHeight = STYLES.lineHeight.s
  }

  const download: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'center'
  }

  const downloadLabel: TextStyle = {
    ...fontBold,
    marginRight: getREM(1),
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal,
    color: colors.brand
  }

  if (bp.tablet) {
    downloadLabel.fontSize = STYLES.fontSize.s
    downloadLabel.lineHeight = STYLES.lineHeight.s
  }

  const downloadModalCard: ViewStyle = {
    maxWidth: STYLES.grid.maxTextWidth
  }

  const styles = StyleSheet.create({
    container,
    dropdown,
    dropdownSelect,
    dropdownSelectText,
    options,
    download,
    downloadLabel,
    downloadModalCard
  })

  return { styles }
}
