import React, { memo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Announcement, AnnouncementProps } from './Announcement'
import { useStyles } from './styles'

export interface Props {
  containerStyle?: StyleProp<ViewStyle>
  items: AnnouncementProps[]
  onRead: (id: string) => void
}

export const Announcements = memo(
  ({ containerStyle, items, onRead }: Props) => {
    const { styles } = useStyles()

    const _hasItems = !!items && items.length > 0

    if (!_hasItems) return null

    return (
      <View
        style={[styles.announcements, containerStyle]}
        testID="Announcements"
        collapsable={false}>
        {items.map((item, index) => (
          <Announcement
            {...item}
            containerStyle={index > 0 ? styles.subsequentItem : null}
            key={item.id}
            onRead={onRead}
          />
        ))}
      </View>
    )
  }
)
