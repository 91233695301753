import { StyleSheet, ViewStyle } from 'react-native'
import { getREM } from 'styles'

export const useStyles = () => {
  const container: ViewStyle = {
    marginBottom: getREM(0.75),
    zIndex: 2,
    flexDirection: 'row',
    flexWrap: 'wrap'
  }

  const itemContainer: ViewStyle = {
    marginRight: getREM(0.375),
    marginBottom: getREM(0.375)
  }

  const styles = StyleSheet.create({
    container,
    itemContainer
  })

  return { styles }
}
