import React, { memo } from 'react'
import { ProgressBar as NativeProgressBar } from 'components'
import { useLang as useSharedLang } from '../Lang'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import { Platform } from 'react-native'

type NativeProgressBarProps = React.ComponentProps<typeof NativeProgressBar>

interface ProgressBarProps extends NativeProgressBarProps {
  isHidden?: boolean
}

export const ProgressBar = memo(({ isHidden = false, label, style, ...props }: ProgressBarProps) => {
  useSharedLang()

  const { t } = useTranslation()
  const { styles } = useStyles()

  const _label = label || t('onboardingShared:progressBarLabel')

  if (isHidden) {
    /* istanbul ignore next */
    if (Platform.OS === 'web') {
      return <div aria-live="polite" aria-label={label} />
    } else {
      return null
    }
  }

  return (
    <NativeProgressBar
      label={_label}
      style={[styles.progressBar, style]}
      {...props}
    />
  )
})
