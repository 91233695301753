import { STYLES } from 'styles'
import { StyleSheet, TextStyle } from 'react-native'

export const useStyles = () => {
  const { colors, bp, spacing, fontBold } = STYLES.useStyles()

  const defaultStyles = {
    ...fontBold,
    color: colors.brand,
    marginBottom: spacing.normal,
    width: '100%'
  } as TextStyle

  const h1 = {
    ...defaultStyles,
    fontSize: STYLES.fontSize.xl,
    lineHeight: STYLES.lineHeight.xl,
    textAlign: 'center'
  } as TextStyle

  const h2 = {
    ...defaultStyles,
    fontSize: STYLES.fontSize.l,
    lineHeight: STYLES.lineHeight.l
  } as TextStyle

  const h3 = {
    ...defaultStyles,
    fontSize: STYLES.fontSize.m,
    lineHeight: STYLES.lineHeight.m
  } as TextStyle

  const h4 = {
    ...defaultStyles,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  } as TextStyle

  const h5 = {
    ...defaultStyles,
    fontSize: STYLES.fontSize.s
  } as TextStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    h1.fontSize = STYLES.fontSize.xxl
    h1.lineHeight = STYLES.lineHeight.xxl

    h2.fontSize = STYLES.fontSize.xl
    h2.lineHeight = STYLES.lineHeight.xl

    h3.fontSize = STYLES.fontSize.l
    h3.lineHeight = STYLES.lineHeight.l

    h4.fontSize = STYLES.fontSize.m
    h4.lineHeight = STYLES.lineHeight.m
  }

  const styles = StyleSheet.create({
    h1,
    h2,
    h3,
    h4,
    h5
  })

  return { styles }
}
