import React, { memo, useContext } from 'react'
import { useStyles } from './styles'
import {
  ActivityIndicator,
  ButtonType,
  CategoryPill,
  Header,
  Heading,
  ProgressBar,
  ScreenBackgroundImage,
  ShareStepCTA,
  Text,
  ToDoListCTA,
  View,
  BenefitsList,
  Button,
  StepButtons,
  ResourceCard,
  Resources,
  BulletList
} from 'components'
import { StepDetailsGroupController } from './StepDetailsGroupController'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'
import { Text as NativeText } from 'react-native'
import { STYLES } from 'styles'
import {
  isCommitmentStateCompleted,
  LinkContext,
  ReplaceParam,
  AuthContext
} from 'utils'
import { StepDetailHeader } from './StepDetailHeader'
import { CommitmentState } from 'models'
import { useStepLang } from 'locales'

export interface Props {
  controller?: StepDetailsGroupController
  actionParam: string
}

export { StepDetailsGroupController }

export const StepDetailsGroup = memo(
  ({ controller = new StepDetailsGroupController(), actionParam }: Props) => {
    useLang()
    useStepLang()

    const { t } = useTranslation()
    const { styles, breakpoint } = useStyles()
    const { COLORS, improvementColors, spacing } = STYLES.useStyles()
    const { homePageLinkUrl, todoListPageLinkUrl } = useContext(LinkContext)
    const { isAuthenticated } = useContext(AuthContext)

    const { isLoading, action, commitment } = controller.useController({
      actionParam,
      isAuthenticated
    })

    const _shareLink = `https://zero.giki.earth/actions/${action?.param}`

    /* istanbul ignore next */
    const _shareCounter =
      (action?.completeCommitmentsCount || 0) +
      (action?.pendingCommitmentsCount || 0)

    const _commitmentPending = commitment?.state === CommitmentState.pending

    const _showToDoListCTA = !isCommitmentStateCompleted(
      commitment?.state as CommitmentState
    )

    const _hasImprovements =
      action?.improvements && action.improvements.length > 0
    const _hasTags = action?.tags && action.tags.length > 0
    const _hasBenefits = action?.benefits && action.benefits.length > 0
    const _hasFacts = action?.facts && action.facts.length > 0
    const _hasUsefulInformation =
      action?.informationItems && action.informationItems.length > 0
    const _hasTips = action?.tips && action.tips.length > 0
    const _hasResources = action?.resources && action.resources.length > 0

    const _formatImpact = () => {
      return action?.impact.name.toLowerCase()
    }

    const _formatImprovements = () => {
      return action?.improvements
        .map((improvement) => {
          return improvement.toLowerCase()
        })
        .join(', ')
        .replace(/, ([^,]*)$/, ` & $1`)
    }

    if (!actionParam) return null

    if (isLoading || !action) {
      return <ActivityIndicator testID="ActivityIndicatorIsLoading" />
    }

    return (
      <>
        <Header
          style={styles.header}
          backgroundImage={
            <ScreenBackgroundImage color1={'#FFB7D6'} color2={'#E97BA3'} />
          }
          contentStyle={[styles.headerContent]}>
          <StepButtons.TrackingContext.Provider
            value={{ source: 'StepDetailsGroup Header' }}>
            <StepDetailHeader action={action} />
          </StepButtons.TrackingContext.Provider>
        </Header>

        <View style={styles.container}>
          <View style={[styles.content]}>
            <Heading level={2} style={styles.mainHeading}>
              {t('stepDetailsGroup:about')}
            </Heading>

            <View
              style={[styles.row, styles.sectionContainer]}
              spacer={
                /* istanbul ignore next */
                breakpoint ? spacing.l : spacing.xxl
              }>
              <View style={[styles.about]}>
                <Text
                  textStyle={[styles.summaryText]}
                  containerStyle={styles.summaryTextContainer}>
                  {action.summary}
                </Text>
                <Text
                  containerStyle={styles.descriptionTextContainer}
                  textStyle={styles.descriptionText}>
                  {action.description}
                </Text>
              </View>
              <View style={[styles.stats]} spacer={spacing.m}>
                <View spacer={spacing.normal}>
                  <ProgressBar
                    label={t('stepDetailsGroup:ease')}
                    title={t('stepDetailsGroup:ease')}
                    description={action.ease.name}
                    max={1}
                    value={action.ease.rating / 5}
                  />

                  <ProgressBar
                    label={t('stepDetailsGroup:impact')}
                    title={t('stepDetailsGroup:impact')}
                    description={action.impact.name}
                    max={1}
                    value={action.impact.rating / 5}
                  />
                </View>

                {_hasImprovements && (
                  <View style={[styles.improvements]}>
                    <NativeText style={styles.improvementsHeading}>
                      {t('stepDetailsGroup:helps')}
                    </NativeText>
                    <View spacer={spacing.xs}>
                      {action.improvements.map((improvement) => (
                        <View
                          style={[styles.improvementItem]}
                          key={improvement}>
                          <View
                            style={[
                              styles.dot,
                              {
                                backgroundColor: improvementColors[improvement]
                              }
                            ]}></View>
                          <NativeText style={[styles.improvementItemText]}>
                            {t(`stepDetailsGroup:${improvement}`)}
                          </NativeText>
                        </View>
                      ))}
                    </View>
                  </View>
                )}

                {_hasTags && (
                  <View style={[styles.where]} spacer={spacing.s}>
                    <NativeText style={styles.whereHeading}>
                      {t('stepDetailsGroup:where')}
                    </NativeText>
                    <View style={[styles.tags]} spacer={spacing.xxs}>
                      {action.tags.map((tag) => (
                        <CategoryPill
                          label={tag}
                          key={tag}
                          backgroundColor={COLORS.mushroom}
                          textColor={COLORS.aubergine}
                        />
                      ))}
                    </View>
                  </View>
                )}
              </View>
            </View>

            {_showToDoListCTA && (
              <View style={[styles.sectionContainer]}>
                <ToDoListCTA>
                  {!!action.checklistItems && (
                    <BenefitsList
                      style={{ marginBottom: spacing.l }}
                      items={action.checklistItems
                        ?.sort(
                          /* istanbul ignore next */
                          (item1, item2) => {
                            if (item1.order > item2.order) return 1
                            if (item1.order < item2.order) return -1
                            return 0
                          }
                        )
                        .map((item) => item.description)}
                    />
                  )}

                  <View style={{ alignItems: 'flex-start' }}>
                    {!isAuthenticated && (
                      <Button
                        type={ButtonType.brand}
                        label={t('step:buttons.commit')}
                        href={homePageLinkUrl}
                      />
                    )}
                    {isAuthenticated &&
                      _commitmentPending &&
                      !!todoListPageLinkUrl && (
                        <Button
                          type={ButtonType.outlined}
                          label={t('step:buttons.todoList')}
                          href={ReplaceParam(
                            todoListPageLinkUrl,
                            commitment.id
                          )}
                        />
                      )}
                    {isAuthenticated && !_commitmentPending && (
                      <StepButtons.TrackingContext.Provider
                        value={{ source: 'StepDetailsGroup ToDoListCTA' }}>
                        <StepButtons.Commit action={action} />
                      </StepButtons.TrackingContext.Provider>
                    )}
                  </View>
                </ToDoListCTA>
              </View>
            )}

            {_hasBenefits && (
              <BulletList
                style={styles.sectionContainer}
                title={t('stepDetailsGroup:reasons')}
                items={action.benefits.map((item) => item.description)}
                bulletColor={COLORS.cherry}
              />
            )}

            {
              /* istanbul ignore next */
              (_hasUsefulInformation || _hasTips || _hasFacts) && (
                <View style={styles.sectionContainer} spacer={spacing.xxl}>
                  {_hasUsefulInformation && (
                    <BulletList
                      title={t('stepDetailsGroup:usefulToKnow')}
                      items={action.informationItems.map(
                        (info) => info.description
                      )}
                      bulletColor={COLORS.money}
                    />
                  )}
                  {_hasTips && (
                    <BulletList
                      title={t('stepDetailsGroup:tips')}
                      items={action.tips.map((info) => info.description)}
                      bulletColor={COLORS.water}
                    />
                  )}
                  {_hasFacts && (
                    <BulletList
                      title={t('stepDetailsGroup:facts')}
                      items={action.facts.map((info) => info.description)}
                      bulletColor={COLORS.health}
                    />
                  )}
                </View>
              )
            }

            {_hasResources && (
              <View style={styles.sectionContainer}>
                <Heading level={3} style={styles.H3}>
                  {t('stepDetailsGroup:usefulLinks')}
                </Heading>
                <View spacer={spacing.normal}>
                  {action.resources
                    .sort((a, b) => (a.order > b.order ? 1 : -2))
                    .map((resource) => (
                      <ResourceCard
                        key={resource.id}
                        title={resource.title}
                        category={resource.category as keyof typeof Resources}
                        linkUrl={resource.url}
                      />
                    ))}
                </View>
              </View>
            )}

            <ShareStepCTA
              text={t('stepDetailsGroup:shareText', {
                impact: _formatImpact(),
                improvements: _formatImprovements()
              })}
              url={_shareLink}
              counter={_shareCounter}
            />
          </View>
        </View>
      </>
    )
  }
)
