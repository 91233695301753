import { ImageStyle, StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { STYLES } from 'styles'

export const useStyles = () => {
  const { spacing, bp, viewports, boxShadows } = STYLES.useStyles()

  const header = {
    minHeight: 450
  } as ViewStyle

  const headerContent = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  } as ViewStyle

  const proHeaderImage = {
    flex: 1
  } as ViewStyle

  const logoImage = {
    width: '100%',
    height: '100%',
    borderRadius: 50
  } as ImageStyle

  const logo = {
    backgroundColor: '#FFFFFF',
    padding: spacing.normal,
    borderRadius: 50,
    width: 100,
    height: 100,
    ...boxShadows.button.all
  } as ViewStyle

  const nameContainer = {
    textAlign: 'center',
    paddingRight: spacing.page.padding.paddingRight,
    paddingLeft: spacing.page.padding.paddingLeft,
    marginBottom: spacing.xl
  } as ViewStyle

  const name = {
    marginBottom: 0
  } as TextStyle

  if (bp.gt(viewports.mobile_l)) {
    logo.width = 140
    logo.height = 140
    logo.padding = spacing.l
    logo.borderRadius = 70
  }

  const styles = StyleSheet.create({
    header,
    headerContent,
    logo,
    logoImage,
    proHeaderImage,
    nameContainer,
    name
  })

  return { styles }
}
