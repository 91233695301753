import React, {
  useContext,
  useState,
  memo,
  ComponentProps,
  useRef,
  useCallback
} from 'react'
import { PageProps } from '../index'
import { Form, TextInput, FieldSet, ActivityIndicator } from 'components'
import {
  Layout,
  NextButton,
  Heading,
  IllustrationBackground,
  useSharedStyles,
  Column,
  ProgressBar
} from '../Shared'
import { OnboardingGroupContext, OnboardingGroupPages } from '../../index'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import {
  CharacterHello,
  CharacterHelloHeightRatio
} from 'images/CharacterHello'
import { useStyles } from './styles'
import { STYLES } from 'styles'

type TextInputProps = ComponentProps<typeof TextInput>

export const Name = memo(({ navigation }: PageProps) => {
  useLang()

  const { t } = useTranslation()
  const { imageWidth } = useSharedStyles()
  const { styles } = useStyles()
  const { bp } = STYLES.useStyles()
  const { person, saveName, progress } = useContext(OnboardingGroupContext)
  const [_firstName, _setFirstName] = useState('')
  const [_lastName, _setLastName] = useState('')
  const [_isUpdating, _setIsUpdating] = useState(false)
  const [_showError, _setShowError] = useState(false)
  const _lastNameRef = useRef(null)

  const _isDisabled =
    _firstName?.trim() === '' || _lastName?.trim() === '' || !person

  const _onPress = async () => {
    _setIsUpdating(true)

    const _success = await saveName(_firstName, _lastName)

    _setIsUpdating(false)

    if (_success) {
      navigation.navigate('Country')
      return
    }

    _setShowError(true)
  }

  const _imageWidth = imageWidth * 0.8
  const _imageHeight = _imageWidth * CharacterHelloHeightRatio

  const _Image = useCallback(
    (props: React.ComponentProps<typeof IllustrationBackground>) => (
      <IllustrationBackground
        backgroundStyle={{
          top: 0.133 * _imageHeight
        }}
        {...props}>
        <CharacterHello
          style={{
            width: _imageWidth,
            height: _imageHeight
          }}
        />
      </IllustrationBackground>
    ),
    [_imageHeight, _imageWidth]
  )

  const _onChangeFirstName: TextInputProps['onChangeText'] = (value) =>
    _setFirstName(value)

  const _onChangeLastName: TextInputProps['onChangeText'] = (value) =>
    _setLastName(value)

  /* istanbul ignore next */
  const _onSubmitEditing = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    _lastNameRef.current?.focus()
  }

  if (_isUpdating) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Name" showError={!person || _showError}>
      <ProgressBar
        label={t('onboardingName:progressLabel')}
        value={progress.indexOf(OnboardingGroupPages.Name) + 1}
        max={progress.length}
      />
      <Column.Container style={styles.container}>
        <Column.Left>
          <Heading level={1}>{t('onboardingName:title')}</Heading>
          <Column.HiddenContent hidden={bp.desktop}>
            <_Image />
          </Column.HiddenContent>
          <Form>
            <FieldSet legend={t('onboardingName:title')}>
              <TextInput
                label={t('onboardingName:firstNameLabel')}
                value={_firstName}
                onChangeText={_onChangeFirstName}
                blurOnSubmit={false}
                onSubmitEditing={_onSubmitEditing}
                returnKeyType="next"
              />
              <TextInput
                ref={_lastNameRef}
                label={t('onboardingName:lastNameLabel')}
                value={_lastName}
                onChangeText={_onChangeLastName}
                returnKeyType="done"
              />
            </FieldSet>
          </Form>
          <NextButton
            onPress={_onPress}
            state={{
              disabled: _isDisabled
            }}
          />
        </Column.Left>
        <Column.Right hidden={bp.not([bp.desktop])}>
          <_Image style={styles.rightImage} />
        </Column.Right>
      </Column.Container>
    </Layout>
  )
})
