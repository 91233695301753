import { Platform } from 'react-native'
import { useColors } from './useColors'
import { getREM } from '../../getREM'
import { getOpaqueColor } from '../../getOpaqueColor'

export const useBoxShadows = () => {
  const { colors } = useColors()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _shadows: any = {}

  _shadows.box = {
    color: colors.shadow,
    opacity: 0.2,
    elevation: 4,
    offset: {
      width: 0,
      height: getREM(0.25)
    },
    radius: getREM(0.25)
  }

  _shadows.box_pointerOver = {
    ..._shadows.box,
    opacity: 0.4,
    elevation: 6
  }

  _shadows.card = {
    color: colors.shadow,
    opacity: 0.2,
    elevation: 4,
    offset: {
      width: 0,
      height: 0
    },
    radius: getREM(0.5)
  }

  _shadows.card_pointerOver = {
    ..._shadows.card,
    opacity: 0.4,
    elevation: 6
  }

  _shadows.button = {
    color: colors.shadow,
    opacity: 0.2,
    elevation: 4,
    offset: {
      width: 0,
      height: getREM(0.1875)
    },
    radius: getREM(0.125)
  }

  _shadows.button_pointerOver = {
    ..._shadows.button,
    opacity: 0.4,
    elevation: 6
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const boxShadows: any = {}

  Object.keys(_shadows).forEach((key) => {
    const _shadow = _shadows[key]

    if (!_shadow) return

    boxShadows[key] = {}

    boxShadows[key].web = {
      ...Platform.select({
        web: {
          boxShadow: `${_shadow.offset.width} ${_shadow.offset.height} ${_shadow.radius} ${getOpaqueColor(
            _shadow.color,
            _shadow.opacity
          )}`,
          transition: 'box-shadow 0.2s ease-out'
        }
      })
    }

    boxShadows[key].android = {
      ...Platform.select({
        android: {
          elevation: _shadow.elevation,
          shadowColor: _shadow.color
        }
      })
    }

    boxShadows[key].ios = {
      ...Platform.select({
        ios: {
          shadowOpacity: _shadow.opacity,
          shadowRadius: _shadow.radius / 1.5,
          shadowOffset: _shadow.offset,
          shadowColor: _shadow.color
        }
      })
    }

    boxShadows[key].all = {
      ...boxShadows[key].web,
      ...boxShadows[key].android,
      ...boxShadows[key].ios
    }
  })

  boxShadows.none = {
    ...Platform.select({
      web: {
        boxShadow: undefined
      },
      android: {
        elevation: undefined,
        shadowColor: undefined
      },
      ios: {
        shadowOpacity: undefined,
        shadowRadius: undefined,
        shadowOffset: undefined,
        shadowColor: undefined
      }
    })
  }

  return {
    boxShadows,
    boxShadowsRaw: _shadows
  }
}