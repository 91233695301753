import { DataType } from '../getData'
import { GroupMembershipRequest } from 'models'

export const updateMembershipRequests = async (data: DataType, ids: string[], isApproved: boolean) => {
  if (!data || data.length === 0 || !ids || ids.length === 0) return false

  let _success = false

  for (let i = data.length - 1; i >= 0; i--) {
    const _item = data[i] as GroupMembershipRequest

    if (typeof _item !== 'undefined') {
      const _index = ids.indexOf(_item.id!)

      if (_index > -1) {
        if (isApproved) {
          _item.state = 'accepted'
        } else {
          _item.state = 'declined'
        }

        _success = await _item.save()

        if (!_success) break

        ids.splice(_index, 1)
      }
    }

    if (ids.length === 0) break
  }

  return _success
}