import { StyleSheet, TextStyle, ViewStyle, ImageStyle } from 'react-native'
import { STYLES, getREM } from 'styles'

export const useStyles = () => {
  const { colors, fontRegular, fontSemiBold, spacing, bp } = STYLES.useStyles()

  const counter = {
    flexShrink: 0,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  } as TextStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    counter.flexDirection = 'column'
  }

  const number = {
    ...fontSemiBold,
    fontSize: STYLES.fontSize.l,
    lineHeight: STYLES.lineHeight.l,
    color: colors.brand,
    marginHorizontal: spacing.xs
  } as TextStyle

  const haveDoneText = {
    ...fontRegular,
    color: colors.brand,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.l,
    flexWrap: 'wrap'
  } as TextStyle

  const tick = {
    width: getREM(2),
    height: getREM(2),
    flexShrink: 0
  } as ImageStyle

  const text = {
    flexDirection: 'row',
    alignItems: 'center'
  } as ViewStyle

  if (bp.is([bp.mobile_xs, bp.mobile_s])) {
    text.flexDirection = 'column'
  }

  const styles = StyleSheet.create({
    counter,
    number,
    haveDoneText,
    tick,
    text
  })

  return { styles }
}
