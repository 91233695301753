import { StyleSheet, ViewStyle } from 'react-native'
import { getREM } from 'styles'
import { useIcon } from '../styles/useTypeStyles/Icon'

export const useStyles = () => {
  const { sizes } = useIcon()
  const _scale = 0.6

  const _container = {
    alignItems: 'center',
    justifyContent: 'center'
  } as ViewStyle

  const styles = StyleSheet.create({
    container_small: {
      ..._container,
      width: getREM(sizes.small),
      height: getREM(sizes.small + sizes.border * 2)
    },
    container_normal: {
      ..._container,
      width: getREM(sizes.normal),
      height: getREM(sizes.normal + sizes.border * 2)
    },
    container_large: {
      ..._container,
      width: getREM(sizes.large),
      height: getREM(sizes.large + sizes.border * 2)
    },
    icon_small: {
      width: getREM(sizes.small * _scale),
      height: getREM(sizes.small * _scale)
    },
    icon_normal: {
      width: getREM(sizes.normal * _scale),
      height: getREM(sizes.normal * _scale)
    },
    icon_large: {
      width: getREM(sizes.large * _scale),
      height: getREM(sizes.large * _scale)
    }
  })

  return { styles }
}
