import { StyleSheet, ViewStyle } from 'react-native'
import { STYLES, getREM, baseSize } from 'styles'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

export const useStyles = () => {
  const { spacing, sizing, bp } = STYLES.useStyles()
  const insets = useSafeAreaInsets()

  const container = {
    ...StyleSheet.absoluteFillObject
  } as ViewStyle

  const topBar = {
    position: 'absolute',
    zIndex: 1,
    top: getREM(insets.top / baseSize)
  } as ViewStyle

  const scrollContentContainer = {
    flexGrow: 1,
    paddingTop: sizing.topBarHeightDefault + insets.top,
    paddingBottom: getREM(3)
  } as ViewStyle

  if (bp.not([bp.tablet, bp.desktop])) {
    scrollContentContainer.marginTop = spacing.xl
  }

  const content = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: spacing.page.padding.paddingBottom,
    paddingRight: spacing.page.padding.paddingRight,
    paddingLeft: spacing.page.padding.paddingLeft
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    topBar,
    scrollContentContainer,
    content
  })

  return { styles }
}
