import { ImageStyle, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { getREM, STYLES } from 'styles'
import { ShoeRedCheckmarkHeightRatio } from 'images'

export const useStyles = () => {
  const {
    bp,
    viewports,
    spacing,
    colors,
    COLORS,
    fontBold,
    fontRegular
  } = STYLES.useStyles()

  const container = {
    padding: spacing.normal,
    width: '100%',
    borderRadius: getREM(0.625),
    flexShrink: 1,
    borderColor: colors.border,
    borderWidth: 1,
    backgroundColor: COLORS.lavenderBlush,
    paddingVertical: spacing.m
  } as ViewStyle

  if (bp.gt(viewports.mobile_l)) {
    container.paddingHorizontal = spacing.l
  }

  const textContainer = {
    marginBottom: spacing.l
  } as ViewStyle

  const title = {
    ...fontBold,
    color: colors.brand,
    fontSize: STYLES.fontSize.l,
    lineHeight: STYLES.lineHeight.l,
    marginBottom: spacing.m
  } as TextStyle

  const text = {
    ...fontRegular,
    color: colors.text,
    fontSize: STYLES.fontSize.normal,
    lineHeight: STYLES.lineHeight.normal
  } as TextStyle

  const row = {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  } as ViewStyle

  const illustration = {
    height: 190 * ShoeRedCheckmarkHeightRatio,
    width: 190,
    display: 'none',
    alignSelf: 'center',
    marginTop: -80
  } as ImageStyle

  if (bp.is([bp.tablet, bp.desktop])) {
    illustration.display = 'flex'
  }

  const buttonContainer = {
    flex: 1
  } as ViewStyle

  const styles = StyleSheet.create({
    container,
    textContainer,
    row,
    title,
    text,
    illustration,
    buttonContainer
  })

  return { styles }
}
