import React, { useContext, useMemo, useState, memo } from 'react'
import { PageProps, PagePathnames } from '../index'
import {
  Button,
  ButtonType,
  CardActions,
  Text,
  HorizontalScrollView,
  StepCard,
  ActivityIndicator
} from 'components'
import {
  Layout,
  NextButton,
  Heading,
  useSharedStyles,
  ProgressBar
} from '../Shared'
import { useLang } from './Lang'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import { OnboardingGroupContext } from '../../index'
import { STYLES } from 'styles'
import { useInfiniteQuery } from 'react-query'
import { Action } from 'models'
import { useAnalytics } from 'utils'

export const Steps = memo(({ navigation }: PageProps) => {
  useLang()

  const { t } = useTranslation()
  const { sharedStyles } = useSharedStyles()
  const { bp } = STYLES.useStyles()
  const { styles } = useStyles()
  const { getSteps, commitToStep } = useContext(OnboardingGroupContext)
  const {
    data: _stepsData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading
  } = useInfiniteQuery(
    ['onboarding', 'steps'],
    ({ pageParam = 1 }) => getSteps(pageParam),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) =>
        /* istanbul ignore next */
        lastPage && lastPage.total > pages.length * lastPage.itemsPerPage
          ? pages.length + 1
          : undefined
    }
  )
  const [_isUpdating, _setIsUpdating] = useState(false)
  const [_showError, _setShowError] = useState(false)
  const { trackEvent, trackingEvents, getTrackingURL } = useAnalytics()

  const _steps: Action[] = useMemo(() => {
    return _stepsData?.pages.reduce((p: Action[], c) => {
      /* istanbul ignore next */
      if (!c) return p
      return [...p, ...c.data]
    }, []) as unknown as Action[]
  }, [_stepsData])

  let _visibleItems = 1
  let _gap = 8

  /* istanbul ignore next */
  if (bp.is([bp.mobile_xl, bp.tablet])) {
    _visibleItems = 2
    _gap = 16
  }

  /* istanbul ignore next */
  if (bp.desktop) {
    _visibleItems = 3
    _gap = 32
  }

  const _onStepPress = async (step: Action) => {
    _setShowError(false)

    _setIsUpdating(true)

    const _success = await commitToStep(step)

    const _pathname = PagePathnames['Steps']

    /* istanbul ignore next */
    if (_pathname) {
      const _url = getTrackingURL(_pathname)

      trackEvent({
        eventName: trackingEvents.onboardingTakeAStep,
        props: {
          stepId: step.id,
          stepName: step.name
        },
        url: _url
      })
    }

    _setIsUpdating(false)

    if (_success) {
      navigation.navigate('StepsSuccess')
      return
    }

    _setShowError(true)
  }

  const _onPress = () => {
    navigation.navigate('Success')
  }

  const _onScrollEnd = async () => {
    !isFetching && hasNextPage && fetchNextPage()
  }

  if (_isUpdating || isLoading) {
    return <ActivityIndicator />
  }

  return (
    <Layout testID="Steps" showError={_showError}>
      <ProgressBar
        isHidden
        label={t('onboardingSteps:progressLabel')}
        max={0}
        value={0}
      />

      <Heading style={styles.title}>{t('onboardingSteps:title')}</Heading>

      <Text containerStyle={[sharedStyles.textContainer, styles.textContainer]}>
        {t('onboardingSteps:text')}
      </Text>

      <HorizontalScrollView
        style={styles.horizontalScrollView}
        contentContainerStyle={styles.horizontalScrollViewContent}
        spacerStyle={styles.horizontalScrollViewSpacer}
        visibleItems={_visibleItems}
        gap={_gap}
        onScrollEnd={_onScrollEnd}>
        {_steps?.map((item: Action) => (
          <StepCard
            key={item.id}
            title={item.summary}
            impact={item.impact}
            themes={item.themes?.map((themeItem) => themeItem.theme)}
            primaryActionLabel={t('onboardingSteps:stepAriaLabel', {
              value: item.summary
            })}
            primaryActionOnPress={() => _onStepPress(item)}
            style={styles.stepCard}>
            <CardActions style={styles.cardActions}>
              <Button
                type={ButtonType.brand}
                label={t('onboardingSteps:stepLabel')}
                isFake
              />
            </CardActions>
          </StepCard>
        ))}
      </HorizontalScrollView>

      <NextButton
        label={t('onboardingSteps:nextLabel')}
        onPress={_onPress}
        type={ButtonType.link}
        textStyle={styles.linkText}
      />
    </Layout>
  )
})
