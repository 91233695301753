import { STYLES, getREM } from 'styles'
import { Platform } from 'react-native'
import { TypeStylesProps } from './index'

export const useOutlined = () => {
  const { colors, boxShadows } = STYLES.useStyles()

  const outlined = {
    nativeContainer: {
      ...boxShadows.button.ios
    },
    label: {
      textDecorationLine: 'none',
      backgroundColor: colors.background,
      color: colors.brand,
      ...Platform.select({
        web: {
          border: `${getREM(0.125)} solid ${colors.brand}`,
          cursor: 'pointer'
        },
        default: {
          borderColor: colors.brand,
          borderWidth: getREM(0.125),
          borderStyle: 'solid'
        }
      }),
      ...boxShadows.button.web,
      ...boxShadows.button.android
    },
    pointerOver: {
      ...boxShadows.button_pointerOver.web
    }
  } as TypeStylesProps

  return { outlined }
}
