import { Model, Attr } from 'spraypaint'
import { ApplicationRecord } from './ApplicationRecord'

@Model()
export class Ease extends ApplicationRecord {
  static jsonapiType = 'eases';

  @Attr({ persist: false }) name!: string

  @Attr({ persist: false }) description!: string

  @Attr({ persist: false }) rating!: number

  @Attr({ persist: false }) unique_slug!: string
}
