import React, { memo } from 'react'
import { View, Text, StyleProp, ViewStyle } from 'react-native'
import { MassValue, SvgImage } from 'components'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useLang } from './Lang'

export interface Props {
  value?: number | undefined
  style?: StyleProp<ViewStyle>
}

export const TreeOffset = memo(({ value = 0, style }: Props) => {
  useLang()
  const { t } = useTranslation()
  const { styles } = useStyles()

  if (value === 0) return null

  return (
    <View style={[styles.container, style]}>
      <SvgImage image={require('images/FootprintSections/trees.svg')} style={styles.image} />
      <Text textBreakStrategy="simple" style={styles.text}>
        {t('treeOffset:text')}
      </Text>
      <MassValue value={Math.abs(value)} />
    </View>
  )
})
